import React from 'react'
import PropTypes from 'prop-types'

import { Col, Row } from 'reactstrap'

import { withAlerts } from 'contexts/alertsProvider'
import { useGetAccountQuery, useUpdateAccountMutation } from 'services/user'

const Notifications = ({ alerts }) => {
  const { data: account = {} } = useGetAccountQuery()
  const [updateAccount, { isLoading: isUpdating }] = useUpdateAccountMutation()

  const changeNotification = async (data) => {
    try {
      await updateAccount({ ...data, email: account.email }).unwrap()
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  return (
    <>
      <h2 className="m-0">Notifications</h2>
      <hr />
      <Row>
        <Col className="d-flex align-items-center mb-3">
          <label className="custom-toggle mr-1">
            <input
              type="checkbox"
              checked={!!account.email_notify}
              onChange={() => changeNotification({ email_notify: !account.email_notify })}
              disabled={isUpdating}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
          <span className="ml-2">Email notifications</span>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex align-items-center">
          <label className="custom-toggle mr-1">
            <input
              type="checkbox"
              // onChange={() => changeNotification({ site_notify: !account.site_notify })}
              disabled={true}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
          <span className="ml-2">Site notifications</span>
        </Col>
      </Row>
    </>
  )
}

Notifications.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
}

export default withAlerts(Notifications)
