import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledRow = styled.tr`
  td { font-size: 1rem; }
`

const TableRow = ({ name, text }) => (
  <StyledRow>
    <td>
      <strong>{name}:</strong>
    </td>
    <td className="text-wrap">{text}</td>
  </StyledRow>
)

TableRow.propTypes = {
  name: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default TableRow
