import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import { Link, useLocation } from 'react-router-dom'
import kebabCase from 'lodash/kebabCase'

import { NavItem, Input } from 'reactstrap'
import ActionsMenu from './ActionsMenu'

import { getUserData, selectKnowledgeBase } from 'reducers/user'
import { createKnowledgeBase, deleteKnowledgeBase } from 'reducers/knowledgeBase'
import { withAlerts } from 'contexts/alertsProvider'
import { withModals } from 'contexts/modalsProvider'
import { getDocPath } from 'components/Documentation/utils'

const ChildItem = ({
  page,
  rootPage,
  editChapterName,
  setIsLoading,
  setEditMode,
  modals,
  alerts,
}) => {
  const knowledgeBase = useSelector(state => selectKnowledgeBase(state))

  const location = useLocation()
  const dispatch = useDispatch()
  const [mode, setMode] = useState('')

  const isActive = useMemo(() => location.pathname === getDocPath(page), [location.pathname])

  const children = useMemo(() => {
    if (!knowledgeBase.length) return []
    return knowledgeBase.filter(page => page.parent_id === rootPage.uuid)
  }, [knowledgeBase])

  const createPage = async (e) => {
    const name = e.target.value
    try {
      if (!name.length) return
      setIsLoading(true)
      const currIndex = children.indexOf(page)
      const path = `/${kebabCase(name)}`
      await dispatch(createKnowledgeBase({
        name,
        path,
        prev_position: page.position,
        next_position: children[currIndex + 1]?.position,
        parent_id: rootPage.uuid,
      })).unwrap()
      await dispatch(getUserData({ type: 'knowledgebase' }))
    } catch ({ message }) {
      alerts.error({ message })
    } finally {
      setMode('')
      setIsLoading(false)
    }
  }

  const editPageName = async (e) => {
    await editChapterName(e, page)
    setMode('')
  }

  const deleteHandler = async () => {
    try {
      const confirmed = await modals.confirm({
        message: 'Are you sure you want to remove this page? All data will be removed permanently!',
      })
      if (!confirmed) return
      setIsLoading(true)
      await dispatch(deleteKnowledgeBase(page.uuid)).unwrap()
      await dispatch(getUserData({ type: 'knowledgebase' }))
    } catch ({ message }) {
      alerts.error({ message })
    } finally { setIsLoading(false) }
  }

  return (
    <>
      <NavItem
        active={isActive}
        className="nav-item-editable"
        key={page.uuid}
      >
        {mode !== 'edit' && (
          <Link className="pr-2" to={getDocPath(page)} onClick={() => setEditMode(false)}>
            {page.name}
          </Link>)
        }
        {mode === 'edit' && (
          <Input
            autoFocus
            onBlur={editPageName}
            onKeyPress={e => (e.code === 'Enter' || e.code === 'NumpadEnter') && editPageName(e)}
            className="form-control-sm mx-4"
            defaultValue={page.name}
            type="text"
          />
        )}
        {mode === '' && (
          <ActionsMenu
            page={page}
            editHandler={() => setMode('edit')}
            addPageHandler={() => setMode('addPage')}
            deleteHandler={deleteHandler}
            editTextHandler={() => setEditMode(true)}
            isActive={isActive}
          />
        )}
      </NavItem>
      {mode === 'addPage' && (
        <NavItem className="nav-item-editable">
          <span className="ml-4" />
          <Input
            autoFocus
            onBlur={e => createPage(e)}
            onKeyPress={e => (e.code === 'Enter' || e.code === 'NumpadEnter') && createPage(e)}
            className="form-control-sm ml-2 mr-4 my-1"
            placeholder="Page Name"
            type="text"
          />
        </NavItem>
      )}
    </>
  )
}

ChildItem.propTypes = {
  rootPage: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  page: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
    parent_id: PropTypes.string,
  }),
  createChapter: PropTypes.func,
  editChapterName: PropTypes.func,
  setIsLoading: PropTypes.func,
  setEditMode: PropTypes.func,
  modals: PropTypes.shape({
    confirm: PropTypes.func,
  }),
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
}

export default compose(withAlerts, withModals)(ChildItem)
