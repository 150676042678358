import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { closeSearch, openSearch } from 'components/Documentation/utils'
import ICONS from 'helpers/iconConstants'

import {
  Collapse,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Nav,
  NavItem,
  NavLink,
  Navbar,
} from 'reactstrap'

const Header = ({
  data,
  sidenavOpen,
  theme,
  toggleSidenav,
}) => (
  <Navbar expand tag="header" className={classnames(
    'align-items-md-center', 'ct-navbar', 'flex-row', 'navbar-horizontal',
    { 'navbar-dark bg-info': theme === 'dark' },
    { 'navbar-light bg-secondary': theme === 'light' },
  )}
  >
    <div className="h1 text-truncate text-white m-0 w-50">{data.name}</div>
    <Collapse navbar isOpen={true} className="justify-content-end">
      <Form
        className={classnames(
          'navbar-search form-inline mr-sm-3',
          { 'navbar-search-light': theme === 'dark' },
          { 'navbar-search-dark': theme === 'light' },
        )}
      >
        <FormGroup className="mb-0">
          <InputGroup className="input-group-alternative input-group-merge">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className={ICONS.SEARCH} />
              </InputGroupText>
            </InputGroupAddon>
            <Input placeholder="Search" type="text" />
          </InputGroup>
        </FormGroup>
        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={closeSearch}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </Form>
      <Nav className="align-items-center" navbar>
        <NavItem className="d-md-none">
          <div
            className={classnames(
              'pr-3 sidenav-toggler',
              { active: sidenavOpen },
              { 'sidenav-toggler-dark': theme === 'dark' },
            )}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className={ICONS.SIDENAV_TOGGLER_LINE} />
              <i className={ICONS.SIDENAV_TOGGLER_LINE} />
              <i className={ICONS.SIDENAV_TOGGLER_LINE} />
            </div>
          </div>
        </NavItem>
        <NavItem className="d-sm-none">
          <NavLink onClick={openSearch}>
            <i className={ICONS.ZOOM_SPLIT_IN} />
          </NavLink>
        </NavItem>
      </Nav>
    </Collapse>
  </Navbar>
)

Header.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark',
}

Header.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
  }),
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
}

export default Header
