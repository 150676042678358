import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useWizard } from 'react-use-wizard'

import {
  Col,
  Row,
  Table,
  Label,
} from 'reactstrap'
import ScrollBar from 'components/Custom/ScrollBar'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'
import { shouldReduceIndex } from 'helpers/datasets'

import { useGetDatasetQuery } from 'services/datasets'

const ThirdStep = ({ values, setValues }) => {
  const [errors, setErrors] = useState({})
  const { handleStep } = useWizard()
  const { data: dataset = {}, isLoading } = useGetDatasetQuery({ uuid: values.datasetUUID })

  const handleChange = (field, value) => {
    setValues(state => ({ ...state, [field]: value }))
    setErrors(state => ({ ...state, [field]: !value.length }))
  }

  handleStep(() => {
    if (!values.targetVariable) {
      setErrors(state => ({ ...state, targetVariable: false }))
      throw new Error('No target selected')
    }
  })

  const fields = useMemo(() => {
    if (!dataset?.data?.schema?.fields) return []
    const fields = [...dataset.data.schema.fields]
    if (shouldReduceIndex(fields)) fields.shift()
    return fields
  }, [dataset.data?.schema?.fields])

  return (
    <Row className="h-100 flex-column">
      <Col xs={12} className="flex-basis-0">
        <Label className="w-100 mt-3 mb-2 font-weight-bold">
          Select Target
        </Label>
      </Col>

      <Col xs={12} className="flex-grow-1 flex-basis-0">
        <div className={classnames('border px-3 py-4 h-100', { 'border-warning': errors.targetVariable })}>
          <ScrollBar>
            {isLoading
              ? (
                <div className="d-flex align-items-center justify-content-center position-absolute w-100 h-100">
                  <Spinner />
                </div>)
              : fields.length
                ? (
                  <Table hover size="sm" style={{ tableLayout: 'fixed' }}>
                    <colgroup>
                      <col />
                      <col width="20%" style={{ minWidth: '180px' }} />
                    </colgroup>

                    <tbody>
                      {fields.map(({ name, type }) => (
                        <tr
                          key={name}
                          onClick={() => handleChange('targetVariable', name)}
                          role="button"
                          style={{ ...values.targetVariable === name && { backgroundColor: '#f6f9fc' } }}
                          className={classnames({ 'font-weight-bold': values.targetVariable === name })}
                        >
                          <td className="text-truncate">
                            {name}
                          </td>
                          <td className="text-truncate">{type}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>)
                : <div className="d-flex align-items-center justify-content-center position-absolute w-100 h-100">No fields found</div>}
          </ScrollBar>
        </div>
      </Col>
    </Row>
  )
}

ThirdStep.propTypes = {
  values: PropTypes.shape({
    targetVariable: PropTypes.string,
    datasetUUID: PropTypes.string,
  }),
  setValues: PropTypes.func,
}

export default ThirdStep
