import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useActiveTab = (init) => {
  const { hash } = useLocation()
  const [activeTab, setActiveTab] = useState(hash.length ? hash.substring(1) : init)
  useEffect(() => {
    if (!hash.length) return
    setActiveTab(hash.substring(1))
  }, [hash])

  return activeTab
}
