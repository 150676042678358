import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import {
  Button,
  FormGroup,
  Form,
  Label,
  Input,
  ButtonGroup,
  Container,
  Row,
  Col,
} from 'reactstrap'
import Select from 'react-select'
import ModalContainer from 'components/Custom/ModalContainer'
import { ButtonWithSpinner } from 'components/Custom/Buttons'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { useAddSchedulerMutation, useUpdateSchedulerMutation, useGetSchedulerQuery } from 'services/schedulers'
import { useModalContext } from 'contexts/modalProvider'
import { withAlerts } from 'contexts/alertsProvider'

const REPEAT_OPTIONS = [
  // { value: 'SECONDLY', label: 'Secondly' },
  { value: 'MINUTELY', label: 'minute(s)' },
  { value: 'HOURLY', label: 'hour(s)' },
  { value: 'DAILY', label: 'day(s)' },
  { value: 'WEEKLY', label: 'week(s)' },
  { value: 'MONTHLY', label: 'month(s)' },
  { value: 'YEARLY', label: 'year(s)' },
]

const WEEK_LABEL = {
  6: 'SUN',
  0: 'MON',
  1: 'TUE',
  2: 'WED',
  3: 'THU',
  4: 'FRI',
  5: 'SAT',
}

const StyledSelect = styled(Select)`
  > .select2-dropdown__control { min-height: 31px; }
  .select2-dropdown__value-container { padding: 0 8px; }
  .select2-dropdown__input-container { margin: 0 2px; padding: 0; }
  .select2-dropdown__dropdown-indicator { padding: 0 8px; }
`

const DEFAULT_OPTIONS = {
  interval: 1,
  frequency: REPEAT_OPTIONS[0].value,
  byweekday: [],
  count: 1,
  dtstart: moment().format('YYYY-MM-DD HH:mm'),
  until: moment().add(1, 'day').format('YYYY-MM-DD HH:mm'),
  ends: 'never',
}

const CreateNewScheduleModal = ({ alerts }) => {
  const { hideModal, store: { modalProps } } = useModalContext()
  const {
    assetUUID,
    assetType,
    uuid,
  } = modalProps
  const { data: scheduler = {}, isFetching } = useGetSchedulerQuery(uuid, { skip: !uuid })

  const [values, setValues] = useState({
    ...DEFAULT_OPTIONS,
    asset_uuid: assetUUID,
    asset_type: assetType,
  })
  const [addScheduler, { isLoading: isAdding }] = useAddSchedulerMutation()
  const [updateScheduler, { isLoading: isUpdating }] = useUpdateSchedulerMutation()

  useEffect(() => {
    if (isFetching) return
    if (!isEmpty(scheduler)) {
      setValues({
        ...scheduler,
        dtstart: moment.unix(scheduler.dtstart).local().format('YYYY-MM-DD HH:mm'),
        until: scheduler.until ? moment.unix(scheduler.until).local().format('YYYY-MM-DD HH:mm') : DEFAULT_OPTIONS.until,
        count: scheduler.count ?? DEFAULT_OPTIONS.count,
        ends: scheduler.until ? 'on' : (scheduler.count ? 'after' : DEFAULT_OPTIONS.ends),
      })
    }
  }, [isFetching])

  const handleWeekSelect = (index) => {
    const updated = new Set(values.byweekday)
    if (!values.byweekday.includes(index)) {
      updated.add(index)
    } else {
      updated.delete(index)
    }
    setValues(state => ({ ...state, byweekday: [...updated] }))
  }

  const handleSubmit = async () => {
    try {
      if (uuid) await updateScheduler({ uuid, ...values }).unwrap()
      else await addScheduler({ ...values }).unwrap()
      hideModal()
    } catch ({ data: { message } }) {
      alerts.error(message)
    }
  }

  const disabled = useMemo(() => {
    const { interval, ends, count } = values
    return interval <= 0 || (ends === 'after' && count <= 0)
  }, [values])

  return (
    <ModalContainer
      title="New schedule"
      isOpen={true}
      toggle={hideModal}
    >
      {isFetching ? (
        <Container fluid>
          <Row style={{ minHeight: '362px' }}>
            <Col className="pb-4">
              <Spinner />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid>
          <Form className="pt-3">
            <FormGroup className="row align-items-center">
              <Col xs={4}>
                <Label className="form-control-label m-0" htmlFor="example-number-input">Repeat every</Label>
              </Col>
              <Col xs={8} className="d-flex flex-nowrap">
                <Input
                  value={values.interval}
                  className="form-control-sm mr-3 w-25"
                  type="number"
                  min="1"
                  step="1"
                  onChange={e => setValues(state => ({ ...state, interval: +e.target.value }))}
                  invalid={values.interval <= 0}
                />

                <StyledSelect
                  className="select2-container form-control-sm text-muted p-0 w-75"
                  classNamePrefix="select2-dropdown"
                  options={REPEAT_OPTIONS}
                  onChange={selected => setValues(state => ({ ...state, frequency: selected.value }))}
                  value={REPEAT_OPTIONS.find(option => option.value === values.frequency)}
                />
              </Col>
            </FormGroup>

            {values.frequency === 'WEEKLY' && (
              <FormGroup>
                <ButtonGroup className="w-100">
                  {
                    Object.keys(WEEK_LABEL).map(index => (
                      <Button
                        key={index}
                        type="button"
                        color="info"
                        size="sm"
                        className={classnames({ active: values.byweekday.includes(+index) })}
                        onClick={() => handleWeekSelect(+index)}
                      >
                        {WEEK_LABEL[index]}
                      </Button>
                    ))
                  }
                </ButtonGroup>
              </FormGroup>
            )}

            <FormGroup className="row align-items-center">
              <Col xs={4}>
                <Label className="form-control-label m-0" htmlFor="example-number-input">Start on</Label>
              </Col>
              <Col xs={8}>
                <Input
                  className="form-control-sm"
                  value={values.dtstart}
                  type="datetime-local"
                  onChange={e => setValues(state => ({ ...state, dtstart: e.target.value }))}
                />
              </Col>
            </FormGroup>

            <FormGroup className="row">
              <Col xs={12}>
                <Label className="form-control-label m-0">Ends</Label>
              </Col>

              <Col xs={12}>
                <div className="custom-control custom-radio my-2">
                  <input
                    className="custom-control-input"
                    id="radio1"
                    type="radio"
                    onChange={() => setValues(state => ({ ...state, ends: 'never' }))}
                    checked={values.ends === 'never'}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="radio1"
                  >
                      Never
                  </label>
                </div>
              </Col>

              <Col xs={12}>
                <Row className="align-items-center">
                  <Col xs={4}>
                    <div className="custom-control custom-radio my-2">
                      <input
                        className="custom-control-input"
                        id="radio2"
                        type="radio"
                        onChange={() => setValues(state => ({ ...state, ends: 'on' }))}
                        checked={values.ends === 'on'}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="radio2"
                      >
                        On
                      </label>
                    </div>
                  </Col>
                  <Col xs={8}>
                    <Input
                      className="form-control-sm"
                      value={values.until}
                      type="datetime-local"
                      onChange={e => setValues(state => ({ ...state, until: e.target.value }))}
                      disabled={values.ends !== 'on'}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={12}>
                <Row className="align-items-center">
                  <Col xs={4}>
                    <div className="custom-control custom-radio my-2">
                      <input
                        className="custom-control-input"
                        id="radio3"
                        name="custom-radio-1"
                        type="radio"
                        onChange={() => setValues(state => ({ ...state, ends: 'after' }))}
                        checked={values.ends === 'after'}
                      />
                      <label
                        className="custom-control-label "
                        htmlFor="radio3"
                      >
                        After
                      </label>
                    </div>
                  </Col>
                  <Col xs={8} className="d-flex flex-nowrap align-items-center">
                    <Input
                      className="form-control-sm w-25 mr-2"
                      id="example-number-input"
                      type="number"
                      value={values.count}
                      min={1}
                      step={1}
                      onChange={e => setValues(state => ({ ...state, count: +e.target.value }))}
                      invalid={values.count <= 0 && values.ends === 'after'}
                      disabled={values.ends !== 'after'}
                    />
                    <span>
                      occurrence(s)
                    </span>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          </Form>

          <Row>
            <Col xs={12} className="text-right p-3">
              <ButtonWithSpinner
                label={uuid ? 'Update' : 'Create'}
                type="submit"
                onClick={handleSubmit}
                isUpdating={isAdding || isUpdating}
                disabled={disabled}
              />
            </Col>
          </Row>
        </Container>
      )}
    </ModalContainer>
  )
}

CreateNewScheduleModal.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
}

export default withAlerts(CreateNewScheduleModal)
