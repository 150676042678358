import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
import take from 'lodash/take'
import isEmpty from 'lodash/isEmpty'
import {
  Collapse,
  ButtonGroup,
} from 'reactstrap'

import StickyTable from 'components/Catalog/Shared/StickyTable'
import { ActionButton } from 'components/Catalog/Shared/Buttons'

import ICONS from 'helpers/iconConstants'
import { formatDecimalNumber } from 'helpers/utils'

const Nodes = ({ data = {} }) => {
  const { nodes } = data
  const [isCollapsed, setCollapsed] = useState(false)
  const history = useHistory()
  const { workspaceUUID } = useParams()

  const { header, body } = useMemo(() => ({
    body: take(nodes, 20).map(el => ({ ...el, similarity: formatDecimalNumber(el.similarity) })),
    header: ['similarity', 'name', 'type', 'label', 'db_entity'],
  }), [nodes])

  const toggle = () => setCollapsed(!isCollapsed)

  if (isEmpty(nodes)) return null

  return (
    <div className="border mt-2 bg-white p-3 rounded">
      <div className="d-flex justify-content-between align-items-center">
        <span className="flex-grow-1">Relevant nodes:</span>
        <ButtonGroup className="ml-3">
          <ActionButton
            label={<i className={isCollapsed ? ICONS.ANGLE_UP : ICONS.ANGLE_DOWN} />}
            onClick={toggle}
            className="ha-info-icon"
          />
        </ButtonGroup>
      </div>
      <Collapse isOpen={isCollapsed}>
        <div className="pt-3">
          <StickyTable
            header={header}
            body={body}
            maxHeight="40rem"
            onClick={({ hash }) => hash && history.push(`/workspaces/${workspaceUUID}/catalog/glossary/${hash}`)}
          />
        </div>
      </Collapse>
    </div>
  )
}

Nodes.propTypes = {
  data: PropTypes.shape({
    nodes: PropTypes.oneOfType([PropTypes.array]),
  }),
}

export default Nodes
