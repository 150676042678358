import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Input, Spinner } from 'reactstrap'

import ICONS from 'helpers/iconConstants'

const StyledRow = styled.tr`
  td { font-size: 1rem; }
`

const Wrapper = styled.div`
  max-width: 500px;
`

const EditableRow = ({
  onUpdate,
  value: initValue,
  name,
  isUpdating,
}) => {
  const [hovered, setHovered] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(initValue)
  }, [initValue])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [initValue])

  const escFunction = (event) => {
    if (event.key === 'Escape') cancelEdit()
  }

  const onKeyPress = (event) => {
    if (['Enter', 'NumpadEnter'].includes(event.code)) updateHandler()
  }

  const updateHandler = () => {
    setEditMode(false)
    onUpdate(value)
  }

  const cancelEdit = () => {
    setEditMode(false)
    setValue(initValue)
  }

  return (
    <StyledRow onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <td>
        <strong>{name}</strong>
      </td>

      <td className="align-middle text-wrap position-relative">
        {isUpdating
          ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="text-primary" />
          : (
            <Wrapper className="d-flex align-items-center">
              {editMode ? (
                <Wrapper className="pr-2 w-100">
                  <Input
                    autoComplete="off"
                    bsSize="sm"
                    className="text-monospace"
                    invalid={!value.length}
                    value={value}
                    onChange={e => setValue(e.target.value || '')}
                    onKeyPress={onKeyPress}
                    autoFocus
                  />
                </Wrapper>
              ) : (
                <div className="pr-3 text-truncate">{value}</div>
              )}
              {editMode && (
                <div className="text-nowrap">
                  <i role="button" className={`${ICONS.CANCEL_ICON}`} onClick={cancelEdit}/>
                  <i role="button" className={`${ICONS.SAVE_ICON} ml-2`} onClick={updateHandler}/>
                </div>
              )}
              <div className={hovered && !editMode ? 'visible' : 'invisible'}>
                <i role="button" className={`${ICONS.PENCIL_SQUARE}`} onClick={() => setEditMode(true)}/>
              </div>
            </Wrapper>
          )}
      </td>
    </StyledRow>
  )
}

EditableRow.defaultProps = {
  value: '',
  isUpdating: false,
}

EditableRow.propTypes = {
  value: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onUpdate: PropTypes.func,
  isUpdating: PropTypes.bool,
}

export default EditableRow
