import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useWizard } from 'react-use-wizard'
import Select from 'react-select'

import {
  Badge,
  Col,
  Input,
  Label,
  Row,
} from 'reactstrap'

import { NewButton, SmallButton, ButtonWithSpinner } from 'components/Custom/Buttons'

import SourceList from 'components/Main/DatasetViewPage/CreateNewModal/Tables/SourceList'
import SelectSourceWrapper from 'components/Main/DatasetViewPage/CreateNewModal/SelectSourceWrapper'
import FormWrapper from 'components/Main/SourceViewPage/CreateSourceModal/Forms/FormWrapper'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { useAddFileSourceMutation, useAddDatabaseSourceMutation } from 'services/sources'
import { useGetContainerSourcesQuery } from 'services/containers'

import { FILTER_SOURCE_OPTIONS } from 'helpers/constants'

const FirstStep = ({
  values,
  setValues,
  isNewDataset,
  workspaceUUID,
}) => {
  const [addFileSource, { isLoading: addingFileSource }] = useAddFileSourceMutation()
  const [addDatabaseSource, { isLoading: addingDatabaseSource }] = useAddDatabaseSourceMutation()
  const [sourceKeyword, setSourceKeyword] = useState('')
  const [errors, setErrors] = useState({})

  const [filter, setFilter] = useState(FILTER_SOURCE_OPTIONS[0])
  const [addSourceMod, setSourceMod] = useState(false)

  const { handleStep } = useWizard()
  const { data = [], isFetching } = useGetContainerSourcesQuery()

  handleStep(() => {
    if ((!values?.name?.length && isNewDataset) || !values?.sourceUUID) {
      setErrors(state => ({ ...state, name: !values?.name?.length && isNewDataset, sourceUUID: !values?.sourceUUID }))
      throw new Error('No name or source')
    }
  })

  const filteredSources = useMemo(() => {
    if (!data.length) return []
    return data.filter((source) => {
      if (filter.value !== 'all') {
        if (filter.value === 'datasets') return !source.type
        return source.status === 'ready' && source.type === filter.value
      }
      return source.status === 'ready' || !source.type
    })
  }, [data, filter])

  const sources = useMemo(() => {
    if (!sourceKeyword.length) return filteredSources
    return filteredSources.filter(source => source.name.toLocaleUpperCase().includes(sourceKeyword.toLocaleUpperCase()))
  }, [data, filteredSources, sourceKeyword])

  const handleChange = (field, value) => {
    setValues(state => ({ ...state, [field]: value }))
    setErrors(state => ({ ...state, [field]: !value.length }))
  }

  return (
    <div className="h-100 d-flex flex-column">
      <h3 className="pt-3">What&#8217;s a {isNewDataset ? 'dataset' : 'connector'}?</h3>
      {isNewDataset
        ? <p>It&#8217;s a structured collection of data from one or more sources, stored on Divian Cloud. Datasets come with data profiling, version control and collaboration.</p>
        : <p>It&#8217;s a persistent link to a data source. A connector simplifies pulls from a source and lets you share its content without giving up credentials.</p>}

      {isNewDataset && !addSourceMod && (
        <Label className="w-100 my-3">
          <span className="font-weight-bold">Dataset name</span>
          <Badge className="text-capitalize" style={{ transform: 'translateY(-0.35rem)' }}>
            Required
          </Badge>
          <Input
            autoComplete="off"
            className="mt-2"
            defaultValue={values.name}
            name="name"
            onChange={e => handleChange('name', e.target.value)}
            invalid={errors.name}
          />
        </Label>
      )}
      <SelectSourceWrapper errors={errors}>
        {addSourceMod
          ? (
            <>
              <FormWrapper
                fromDataset
                addFileSource={addFileSource}
                addDatabaseSource={addDatabaseSource}
                workspaceUUID={workspaceUUID}
                onCreate={() => {
                  setSourceMod(false)
                  setSourceKeyword('')
                  setFilter(FILTER_SOURCE_OPTIONS[0])
                }}
              />
              <div className="d-flex justify-content-between pt-4">
                <SmallButton outline onClick={() => setSourceMod(false)}>Back</SmallButton>
                <ButtonWithSpinner
                  label="Create connector"
                  isUpdating={addingFileSource || addingDatabaseSource}
                  disabled={addingFileSource || addingDatabaseSource}
                  form="create-source-form"
                  type="submit"
                />
              </div>
            </>
          )
          : (
            <Row className="h-100 flex-column">
              <Col xs={12} className="flex-basis-0">
                <Row>
                  <Col md={{ size: 'auto' }} className="align-self-center">
                    <NewButton label="New connector" onClick={() => setSourceMod(true)} />
                  </Col>
                  <Col>
                    <Input
                      autoComplete="off"
                      bsSize="sm"
                      className="text-monospace"
                      name="search"
                      style={{ height: 'calc(0.45em + 1.25rem + 12px)' }}
                      placeholder="Search Source..."
                      type="text"
                      onChange={e => setSourceKeyword(e.target.value)}
                      value={sourceKeyword}
                    />
                  </Col>
                  <Col md={3}>
                    <Select
                      className="select2-container text-muted mt-3 mt-md-0 w-100"
                      classNamePrefix="select2-dropdown"
                      options={FILTER_SOURCE_OPTIONS}
                      onChange={selected => setFilter(FILTER_SOURCE_OPTIONS.find(option => option.value === selected.value))}
                      value={filter}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className="pt-4 flex-grow-1 flex-basis-0">
                {isFetching
                  ? <Spinner />
                  : sources.length
                    ? <SourceList data={sources} values={values} onChange={handleChange} invalid={errors.sourceUUID} />
                    : (
                      <div className="d-flex h-100 align-items-center justify-content-center text-muted">
                        {!sourceKeyword.length
                          ? 'No connectors found'
                          : <NewButton label="Create connector" onClick={() => setSourceMod(true)} />
                        }
                      </div>
                    )
                }
              </Col>
            </Row>
          )
        }
      </SelectSourceWrapper>
    </div>
  )
}

FirstStep.propTypes = {
  values: PropTypes.shape({
    name: PropTypes.string,
    sourceUUID: PropTypes.string,
  }),
  setValues: PropTypes.func,
  isNewDataset: PropTypes.bool,
  workspaceUUID: PropTypes.string,
}

export default FirstStep
