import divianApi from './api'
import remove from 'lodash/remove'

const getDatasetVersion = (state, uuid) => {
  const datasetCacheKey = Object.keys(state.api.queries).find(el => el.startsWith(`getDataset({"page":1,"uuid":"${uuid}"`))
  const { data } = state.api.queries[datasetCacheKey]
  return data?.version_uuid
}

const getCachedVersion = (state, uuid) => {
  const datasetCacheKey = Object.keys(state.api.queries).find(el => el.startsWith(`getDataset({"page":1,"uuid":"${uuid}"`))
  return state.api.queries[datasetCacheKey]?.originalArgs?.versionUUid
}

export const datasetsApi = divianApi.injectEndpoints({
  endpoints: build => ({
    getDatasets: build.query({
      query: (showAll = true) => ({
        url: 'datasets',
        params: { show_all: showAll },
      }),
      providesTags: ['Datasets'],
      keepUnusedDataFor: 600,
    }),
    getDataset: build.query({
      query: ({
        uuid,
        page = 1,
        perPage = 100,
        versionUUid = null,
      }) => ({
        url: `datasets/${uuid}`,
        params: {
          per_page: perPage,
          page,
          ...versionUUid && { version_uuid: versionUUid },
        },
      }),
      providesTags: ['Dataset'],
    }),
    deleteDataset: build.mutation({
      query: uuid => ({
        url: `datasets/${uuid}`,
        method: 'DELETE',
      }),
      onQueryStarted: (uuid, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(divianApi.util.updateQueryData('getDatasets', true, (draftDatasets) => {
          remove(draftDatasets, dataset => dataset.uuid === uuid)
        }))
        queryFulfilled.catch(patchResult.undo)
      },
      invalidatesTags: ['ContainerSources'],
    }),
    getDatasetDetails: build.query({
      query: ({ uuid, versionUUid = null }) => ({
        url: `datasets/${uuid}/details`,
        params: { ...versionUUid && { version_uuid: versionUUid } },
      }),
      providesTags: ['DatasetDetails'],
    }),
    getDatasetHistory: build.query({
      query: uuid => `datasets/${uuid}/history`,
      providesTags: ['DatasetHistory'],
    }),
    getDatasetJobs: build.query({
      query: ({ uuid, versionUUid }) => ({
        url: `datasets/${uuid}/jobs`,
        params: { ...versionUUid && { version_uuid: versionUUid } },
      }),
      providesTags: ['DatasetJobs'],
    }),
    getDatasetNotes: build.query({
      query: uuid => `datasets/${uuid}/notes`,
      providesTags: ['DatasetNotes'],
    }),
    getDatasetLineage: build.query({
      query: uuid => `datasets/${uuid}/lineage`,
      providesTags: ['DatasetLineage'],
    }),
    getDatasetSchedulers: build.query({
      query: uuid => `datasets/${uuid}/schedulers`,
      providesTags: ['DatasetSchedulers'],
    }),
    addDataset: build.mutation({
      query: body => ({
        url: 'datasets',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['WorkspaceDatasets', 'Datasets', 'ContainerSources', 'Ontology', 'Container', 'DatasetJobs', 'Dataset', 'DatasetDetails', 'DatasetHistory'],
    }),
    updateDataset: build.mutation({
      query: ({ uuid, ...patch }) => ({
        url: `datasets/${uuid}`,
        method: 'PUT',
        body: patch,
      }),
      async onQueryStarted({ uuid }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedDataset } = await queryFulfilled
          const versionUUid = getCachedVersion(getState(), uuid)
          dispatch(datasetsApi.util.updateQueryData('getDataset', { uuid, page: 1, versionUUid }, (draft) => {
            Object.assign(draft, updatedDataset)
          }))
          dispatch(datasetsApi.util.updateQueryData('getDatasets', 'all', (draftDatasets) => {
            const dataset = draftDatasets.find(dataset => dataset.uuid === uuid)
            Object.assign(dataset, updatedDataset)
          }))
        } catch {}
      },
      invalidatesTags: ['WorkspaceDatasets', 'Datasets', 'Ontology', 'DatasetLineage'],
    }),
    refreshDataset: build.mutation({
      queryFn: (uuid, { getState }, _, fetchWithBQ) => {
        const version_uuid = getDatasetVersion(getState(), uuid)
        return fetchWithBQ({
          url: `datasets/${uuid}/refresh`,
          method: 'POST',
          ...version_uuid && { body: { version_uuid } },
        })
      },
      invalidatesTags: (_, error) => error ? [] : ['Dataset', 'DatasetDetails', 'DatasetHistory', 'DatasetJobs', 'DatasetLineage'],
    }),
    updateDatasetVariable: build.mutation({
      queryFn: ({ uuid, index, ...patch }, { getState }, _, fetchWithBQ) => {
        const version_uuid = getDatasetVersion(getState(), uuid)
        return fetchWithBQ ({
          url: `datasets/${uuid}/variable/${index}`,
          method: 'PUT',
          body: {
            ...patch,
            ...version_uuid && { version_uuid },
          },
        })
      },
      invalidatesTags: (_, error) => error ? [] : ['Dataset', 'DatasetDetails', 'DatasetJobs'],
    }),
    deleteJob: build.mutation({
      query: ({ uuid, uuidJob }) => ({
        url: `jobs/${uuidJob}`,
        method: 'DELETE',
        body: {
          asset_type: 'dataset',
          asset_uuid: uuid,
        },
      }),
      async onQueryStarted({ uuid, uuidJob }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(datasetsApi.util.updateQueryData('getDatasetJobs', uuid, (draft) => {
          remove(draft, job => job.uuid === uuidJob)
        }))
        queryFulfilled.catch(patchResult.undo)
      },
    }),
    refetchDataset: build.mutation({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['WorkspaceDatasets', 'Datasets', 'Dataset', 'DatasetDetails', 'DatasetLineage', 'DatasetHistory', 'DatasetJobs', 'Ontology'],
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetDatasetDetailsQuery,
  useGetDatasetHistoryQuery,
  useGetDatasetJobsQuery,
  useGetDatasetNotesQuery,
  useGetDatasetQuery,
  useGetDatasetsQuery,
  useGetDatasetLineageQuery,
  useGetDatasetSchedulersQuery,
  useDeleteJobMutation,
  useAddDatasetMutation,
  useUpdateDatasetMutation,
  useUpdateDatasetVariableMutation,
  useDeleteDatasetMutation,
  useRefreshDatasetMutation,
  usePrefetch,
} = datasetsApi
