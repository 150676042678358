import React from 'react'

import { Row, Col } from 'reactstrap'

const TwoFactor = () => (
  <Row>
    <Col md={8} className="d-flex align-items-center mt-5 mb-4 disabled">
      <label className="custom-toggle mr-1">
        <input type="checkbox" checked={false} onChange={() => ''} />
        <span className="custom-toggle-slider rounded-circle" />
      </label>
      <span className="ml-2">Two-Factor Authentication</span>
    </Col>
  </Row>
)

export default TwoFactor
