import React from 'react'
import { Link } from 'react-router-dom'
import {
  NavbarBrand,
  Navbar,
  Nav,
} from 'reactstrap'

import NavRow from 'components/Catalog/Navigation/NavRow'
import { useModalContext, MODAL_TYPES } from 'contexts/modalProvider'
import logo from 'assets/img/divian-logo-montserrat-dark-alt.png'

const Navigation = () => {
  const { showModal } = useModalContext()

  return (
    <aside className="h-100 overflow-hidden bg-white border-right">
      <Navbar className="p-0 h-100 flex-column">
        <NavbarBrand tag={Link} className="m-2" to="/">
          <img alt="Divian" src={logo} width="50" />
        </NavbarBrand>

        <div className="border-bottom w-75 mx-auto mt-1 mb-3" />

        <Nav navbar className="w-100">
          <NavRow name="discover" />
        </Nav>

        <div className="border-bottom w-75 mx-auto my-3" />

        <Nav navbar className="w-100">
          <NavRow name="assets" />
          {/* <NavRow name="assetsV2" /> */}
          <NavRow name="glossary" />
        </Nav>

        <div className="border-bottom w-75 mx-auto mt-1 mb-3" />

        <Nav navbar className="w-100">
          <NavRow name="help" onClick={() => showModal(MODAL_TYPES.HELP_MODAL)} />
        </Nav>

        <div className="flex-grow-1" />
      </Navbar>
    </aside>
  )
}

export default Navigation
