import moment from 'moment'

export const shouldReduceIndex = (fields) => {
  const [{ name, type }] = fields
  return (name === 'index' && type === 'integer')
}

export const findNames = (indexes, fields) => indexes.map(i => fields[i].name)
export const convertIndexes = indexes => indexes.map(i => +i + 1)

export const formatTime = (timestamp, fromHistory = false) => {
  const time = moment.unix(timestamp)
  const timeNow = moment().unix()
  if ((timeNow - timestamp) >= 60 * 60 * 24) return fromHistory ? time.format('MMMM DD YYYY') : time.format('MMMM Do YYYY, HH:mm')
  return fromHistory ? 'Today' : time.fromNow()
}

export const formatHistoryTime = (timestamp, title = false) => {
  const time = moment.unix(timestamp)
  const timeNow = moment().unix()
  if ((timeNow - timestamp) >= 60 * 60 * 24) return title ? time.format('MMMM DD YYYY') : time.format('HH:mm')
  return title ? 'Today' : time.fromNow()
}
