import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'

import {
  Col,
  Input,
  Label,
  Row,
} from 'reactstrap'

const SSHToggleSection = ({ onChange }) => {
  const [{ value, ...field }] = useField('use_ssh')

  return (
    <>
      <hr className="my-4" />
      <Row>
        <Col>
          <Label size="sm" className="d-inline-flex align-items-center">
            <div className="custom-toggle">
              <Input
                {...field}
                checked={!!value}
                onChange={onChange}
                type="checkbox"
                value={value}
              />
              <span className="custom-toggle-slider rounded-circle" />
            </div>
            <span className="ml-2">Connect though SSH</span>
          </Label>
        </Col>
      </Row>
    </>
  )
}

SSHToggleSection.propTypes = {
  onChange: PropTypes.func,
}

export default SSHToggleSection
