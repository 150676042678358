import React, { useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import classnames from 'classnames'
import { useFormik, FormikProvider } from 'formik'
import { FullWidthButton } from 'components/Custom/Buttons'
import { useHistory } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
} from 'reactstrap'

import SourceForm from 'components/Main/SourceViewPage/CreateSourceModal/Forms/SourceForm'
import DropSection from 'components/Main/SourceViewPage/CreateSourceModal/Forms/FileForm/DropSection'
import SourceNameSection from 'components/Main/SourceViewPage/CreateSourceModal/Forms/SourceForm/SourceNameSection'
import SelectedFileSection from 'components/Main/SourceViewPage/CreateSourceModal/Forms/FileForm/SelectedFileSection'
import { FORM_VALIDATION } from 'components/Shared/AddSourceForm/fieldConfig'
import { IMPORT_OPTIONS, SOURCE_TYPES } from 'helpers/constants'
import ICONS from 'helpers/iconConstants'

import { withAlerts } from 'contexts/alertsProvider'

const BUTTON_TYPES = [
  { name: 'File', type: IMPORT_OPTIONS.FILE, icon: ICONS.CLOUD_UPLOAD_ALT },
  { name: 'MySQL', type: IMPORT_OPTIONS.MYSQL, icon: ICONS.DATABASE },
  { name: 'PostgreSQL', type: IMPORT_OPTIONS.PGSQL, icon: ICONS.DATABASE },
  { name: 'Snowflake', type: IMPORT_OPTIONS.SNOWFLAKE, icon: ICONS.SNOWFLAKE_V2 },
]
const MIN_SIZE = 10

const FORM_TYPES = {
  [IMPORT_OPTIONS.MYSQL]: {
    type: SOURCE_TYPES.MYSQL,
    values: {
      port: 3306,
    },
  },
  [IMPORT_OPTIONS.PGSQL]: {
    type: SOURCE_TYPES.PGSQL,
    values: {
      port: 5432,
    },
  },
  [IMPORT_OPTIONS.SNOWFLAKE]: {
    type: SOURCE_TYPES.SNOWFLAKE,
    values: {},
  },
}

const FormWrapper = ({
  alerts,
  fromDataset,
  onCreate,
  addFileSource,
  addDatabaseSource,
  workspaceUUID,
  hideModal,
}) => {
  const [activeSource, setActiveSource] = useState('')
  const [sourceName, setSourceName] = useState('')
  const [fileName, setFileName] = useState('')

  const history = useHistory()
  const importedFile = useRef(null)

  const onUnselectFile = () => {
    importedFile.current = null
    setFileName('')
  }

  const onDrop = async (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      alerts.error({ message: 'Please submit valid file type' })
    } else {
      importedFile.current = accepted?.[0]
      setFileName(importedFile.current?.name)
    }
  }

  const typeClick = (type) => {
    importedFile.current = null
    setActiveSource(type === activeSource ? '' : type)
    formik.resetForm()
    if (type === IMPORT_OPTIONS.FILE) setFileName('')
  }

  const submitFileHandler = async ({ sourceName: name, ...values }) => {
    if (!activeSource || !importedFile.current) return

    if (importedFile.current.size < MIN_SIZE) {
      alerts.error({ message: 'Your proposed upload is smaller than the minimum allowed size.' })
      return
    }

    try {
      const payload = await addFileSource({
        file: importedFile.current,
        name,
        ...workspaceUUID && { workspaceUUID },
        ...values,
      }).unwrap()

      if (fromDataset) {
        onCreate()
      } else {
        hideModal()
        history.push(`/workspaces/${workspaceUUID}/connectors/${payload.uuid}`)
      }
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  const submitDbHandler = async ({ sourceName: name, ...parameters }) => {
    if (!activeSource) return

    try {
      const payload = await addDatabaseSource({
        parameters,
        name,
        type: FORM_TYPES[activeSource].type,
        ...workspaceUUID && { workspaceUUID },
      }).unwrap()

      if (fromDataset) {
        onCreate()
      } else {
        hideModal()
        history.push(`/workspaces/${workspaceUUID}/connectors/${payload.uuid}`)
      }
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }
  const handleFormChange = (form) => {
    if (form.target.name === 'sourceName') setSourceName(form.target.value)
  }

  const formikOptions = useMemo(() => ({
    initialValues: {
      ...activeSource !== IMPORT_OPTIONS.FILE && { use_ssh: 0 },
      ...FORM_TYPES[activeSource]?.values,
      sourceName,
    },
    validationSchema: Yup.object().shape({
      ...activeSource && activeSource !== IMPORT_OPTIONS.FILE && FORM_VALIDATION[FORM_TYPES[activeSource]?.type],
      sourceName: Yup.string().required(),
    }),
    enableReinitialize: true,
    onSubmit: activeSource === IMPORT_OPTIONS.FILE
      ? submitFileHandler
      : submitDbHandler,
  }), [activeSource])

  const formik = useFormik(formikOptions)

  return (
    <FormikProvider value={formik}>
      <Form
        onSubmit={formik.handleSubmit}
        onChange={formDetails => handleFormChange(formDetails)}
        id="create-source-form"
        className="h-100 d-flex flex-column"
      >
        <SourceNameSection />

        <div className="font-weight-bold my-3">Connector type</div>
        <Row className="mr-md-0 flex-grow-1">
          <Col md={'auto'}>
            <Row className="flex-md-column">
              {BUTTON_TYPES.map(({ name, type, icon }, index, { length }) => (
                <Col xs={6} md={'auto'} key={type}>
                  <FullWidthButton
                    onClick={() => typeClick(type)}
                    className={classnames('justify-content-start btn-outline-primary mb-3', {
                      active: activeSource === type,
                      'mb-md-1': index === length - 1,
                    })}
                  >
                    <i className={icon} />
                    <b className="mx-2">{name}</b>
                  </FullWidthButton>
                </Col>
              ))}
            </Row>
          </Col>
          <Col className="border py-3 mx-3 mx-md-0">
            {activeSource
              ? activeSource === IMPORT_OPTIONS.FILE
                ? fileName
                  ? <SelectedFileSection onUnselect={onUnselectFile} fileName={fileName} />
                  : <DropSection onDrop={onDrop} />
                : <SourceForm type={FORM_TYPES[activeSource]?.type} />
              : (
                <div className="h-100 d-flex justify-content-center align-items-center text-muted">
                  Please select connector type
                </div>
              )
            }
          </Col>
        </Row>
      </Form>
    </FormikProvider>
  )
}

FormWrapper.propTypes = {
  alerts: PropTypes.shape({
    success: PropTypes.func,
    error: PropTypes.func,
  }),
  fromDataset: PropTypes.bool,
  onCreate: PropTypes.func,
  addFileSource: PropTypes.func,
  addDatabaseSource: PropTypes.func,
  workspaceUUID: PropTypes.string,
  hideModal: PropTypes.func,
}

export default withAlerts(FormWrapper)
