import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Wizard } from 'react-use-wizard'

import ModalContainer from 'components/Custom/ModalContainer'
import WizardSteps from 'components/Main/DatasetViewPage/CreateNewModal/WizardSteps'
import WizardWrapper from 'components/Main/DatasetViewPage/CreateNewModal/WizardWrapper'
import WizardFooter from 'components/Main/DatasetViewPage/CreateNewModal/WizardFooter'
import FirstStep from 'components/Main/DatasetViewPage/CreateNewModal/FirstStep'
import SecondStep from 'components/Main/DatasetViewPage/CreateNewModal/SecondStep'
import ThirdStep from 'components/Main/DatasetViewPage/CreateNewModal/ThirdStep'

import { useAddDatasetMutation } from 'services/datasets'
import { useAddContainerMutation } from 'services/containers'
import { useModalContext } from 'contexts/modalProvider'
import { withAlerts } from 'contexts/alertsProvider'

import { CONTAINER_ACTIONS } from 'helpers/constants'

const CreateNewModal = ({ alerts }) => {
  const { hideModal, store: { modalProps } } = useModalContext()
  const { workspaceUUID } = modalProps

  const [values, setValues] = useState({
    selectedKeys: [],
    type: 'connector',
    showQuery: false,
  })

  const history = useHistory()

  const [addDataset, { isLoading }] = useAddDatasetMutation()
  const [addContainer, { isLoading: isAdding }] = useAddContainerMutation()

  const data = useMemo(() => {
    const {
      name,
      tableName,
      query,
      schemaName,
      databaseName,
      sourceUUID,
      showQuery,
      selectedKeys,
    } = values
    return {
      name,
      workspace_uuid: workspaceUUID,
      connector_specs: {
        uuid: sourceUUID,
        indicators_list: selectedKeys,
        merge_on: {},
        edition_type: 'left_join',
        ...!showQuery && tableName && {
          table_name: tableName,
          schema_name: schemaName,
          db_name: databaseName,
        },
        ...showQuery && query && {
          query,
          db_name: databaseName,
        },
      },
    }
  }, [values])

  const handleFormSubmit = async () => {
    const getTab = ({ big_data }) => (big_data ? 'jobs' : 'data')
    try {
      const payload = await addDataset({ ...data }).unwrap()
      history.push(`/workspaces/${workspaceUUID}/datasets/${payload.uuid}#${getTab(payload)}`)
      hideModal()
    } catch ({ data: { message } = {} }) {
      alerts.error({ message })
    }
  }

  const additionalAction = async () => {
    const { name, type } = values
    try {
      const payload = {
        ...data.connector_specs,
        name,
        type,
        action_type: CONTAINER_ACTIONS.MERGE,
        workspace_uuid: workspaceUUID,
      }
      const { uuid: containerUUID } = await addContainer(payload).unwrap()
      history.push(`/workspaces/${workspaceUUID}/containers/${containerUUID}`)
      hideModal()
    } catch ({ data: { message } = {} }) {
      alerts.error({ message })
    }
  }

  return (
    <ModalContainer
      title="Create New Dataset"
      isOpen={true}
      toggle={hideModal}
      customSize="bg"
    >
      <Wizard
        header={<WizardSteps size="sm" compact />}
        footer={<WizardFooter
          handleFormSubmit={handleFormSubmit}
          setValues={setValues}
          additionalAction={additionalAction}
        />}
        wrapper={<WizardWrapper />}
      >
        <FirstStep
          isNewDataset
          values={values}
          setValues={setValues}
          workspaceUUID={workspaceUUID}
        />
        <SecondStep values={values} setValues={setValues} />
        <ThirdStep isLoading={isLoading || isAdding} values={values} />
      </Wizard>
    </ModalContainer>
  )
}

CreateNewModal.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
}

export default withAlerts(CreateNewModal)
