import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useWizard } from 'react-use-wizard'
import {
  Col,
  Row,
} from 'reactstrap'

import { ButtonWithSpinner } from 'components/Custom/Buttons'
import ICONS from 'helpers/iconConstants'

const WizardFooter = ({
  setValues,
  handleFormSubmit,
  additionalAction,
  finishButtonTitle,
}) => {
  const {
    previousStep,
    nextStep,
    isLastStep,
    isFirstStep,
    goToStep,
  } = useWizard()

  const goToNextStep = async () => {
    try {
      await nextStep()
      if (isLastStep) {
        await handleFormSubmit()
        goToStep(0)
      }
    } catch (err) {
      console.error(err.message)
    }
  }

  const goToPrevStep = () => {
    if (isFirstStep) setValues(state => ({
      ...state, tableName: null, schemaName: null, showQuery: false, query: null,
    }))
    return previousStep()
  }

  return (
    <Row className="justify-content-center">
      <Col xs={11} xl={10} className={classnames('d-flex m-1 p-3 align-items-center', {
        'justify-content-end': isFirstStep,
        'justify-content-between': !isFirstStep,
      })}>
        {!isFirstStep && (
          <ButtonWithSpinner
            onClick={goToPrevStep}
            label={(
              <><i className={ICONS.ARROW_LEFT} /> Previous</>
            )}
          />
        )}
        <div>
          {isLastStep && additionalAction && (
            <ButtonWithSpinner
              onClick={additionalAction}
              label="Merge with..."
            />
          )}
          <ButtonWithSpinner
            onClick={goToNextStep}
            label={isLastStep
              ? finishButtonTitle
              : <>Next <i className={ICONS.ARROW_RIGHT} /></>}
          />
        </div>
      </Col>
    </Row>
  )
}

WizardFooter.defaultProps = {
  finishButtonTitle: 'Create',
}

WizardFooter.propTypes = {
  setValues: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  finishButtonTitle: PropTypes.string.isRequired,
  additionalAction: PropTypes.func,
}

export default WizardFooter
