import React from 'react'
import PropTypes from 'prop-types'
import { useWizard } from 'react-use-wizard'

import { ListGroup } from 'reactstrap'

import ListItem from 'components/Catalog/AssetsPage/ListItem'
import ScrollBar from 'components/Custom/ScrollBar'

import { useGetWorkspaceConnectionsQuery } from 'services/workspaces'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

const FirstStep = ({ values, setValues }) => {
  const { handleStep } = useWizard()

  const { data = [], isLoading } = useGetWorkspaceConnectionsQuery(values.workspaceUUID)

  handleStep(() => {
    if (!values?.uuid) throw new Error('No connection selected')
  })

  if (isLoading) return <Spinner/>

  return (
    <ScrollBar autoHeight autoHeightMax="60vh">
      <ListGroup flush>
        {data.map((value, i) => (
          <ListItem
            data={value}
            onClick={() => setValues(state => ({ ...state, uuid: value.uuid }))}
            selected={values.uuid === value.uuid}
            key={i}
            fullWidth
          />
        ))}
      </ListGroup>
    </ScrollBar>
  )
}

FirstStep.propTypes = {
  values: PropTypes.shape({
    workspaceUUID: PropTypes.string.isRequired,
    uuid: PropTypes.string,
  }),
  setValues: PropTypes.func,
}

export default FirstStep
