import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Col,
  Row,
  Form,
  FormGroup,
  Input,
} from 'reactstrap'
import Select from 'react-select'
import { ButtonWithSpinner } from 'components/Custom/Buttons'
import ModalContainer from 'components/Custom/ModalContainer'

import { useAddInviteMutation } from 'services/notifications'

import { withAlerts } from 'contexts/alertsProvider'
import { useModalContext } from 'contexts/modalProvider'

const validationSchema = Yup.object().shape({
  username: Yup.string().required(),
  type: Yup.string().required(),
})

const ROLES = [
  { label: 'Modify Workspace Resources', value: 'member' },
  { label: 'View Workspace Resources', value: 'follower' },
]

const AddWorkspaceMemberModal = ({ alerts }) => {
  const { hideModal, store: { modalProps } } = useModalContext()
  const { name, workspaceUUID } = modalProps
  const [addInvite, { isLoading: isAdding }] = useAddInviteMutation()

  const submitForm = async (data) => {
    try {
      await addInvite({ workspaceUUID, ...data }).unwrap()
      hideModal()
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      type: 'member',
    },
    enableReinitialize: true,
    onSubmit: submitForm,
    validationSchema,
  })

  return (
    <ModalContainer
      title={`Invite Member to ${name || ''}`}
      isOpen={true}
      toggle={hideModal}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label">Email:</label>
              <Input
                name="username"
                value={formik.values.username}
                type="email"
                onChange={formik.handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label">Role:</label>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                options={ROLES}
                name="type"
                value={ROLES.find(option => option.value === formik.values['type']) ?? ''}
                onChange={option => formik.setFieldValue('type', option.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="text-right my-1 pt-3">
            <ButtonWithSpinner
              label="Invite"
              isUpdating={isAdding}
              onClick={formik.handleSubmit}
              disabled={!(formik.dirty && formik.isValid) || isAdding}
            />
          </Col>
        </Row>
      </Form>
    </ModalContainer>
  )}

AddWorkspaceMemberModal.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
}

export default withAlerts(AddWorkspaceMemberModal)
