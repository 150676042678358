import { apiV2Request } from 'helpers/request'
import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
const { REACT_APP_ENVIRONMENT } = process.env

export const selectKnowledgeBase = createSelector(
  state => state.knowledgeBase,
  (_, data) => data,
  (datasets, uuid) => datasets[uuid] || {},
)

export const getKnowledgeBase = createAsyncThunk('GET_KNOWLEDGEBASE', async (uuid) => {
  const { data } = await apiV2Request({ url: `knowledgebase/${uuid}`, baseURL: `https://api.divian.${REACT_APP_ENVIRONMENT}` })
  return data
})

export const createKnowledgeBase = createAsyncThunk('CREATE_KNOWLEDGEBASE', async ({ ...data }) => {
  try {
    const response = await apiV2Request({
      url: 'knowledgebase',
      method: 'POST',
      data,
    })
    return response.data
  } catch ({ response = {} }) {
    throw Error(response?.data?.message || 'Something went wrong!')
  }
})

export const updateKnowledgeBase = createAsyncThunk('UPDATE_KNOWLEDGEBASE', async ({ uuid, ...data }) => {
  try {
    const response = await apiV2Request({
      url: `knowledgebase/${uuid}`,
      method: 'PUT',
      data,
    })
    return response.data
  } catch ({ response = {} }) {
    throw Error(response?.data?.message || 'Something went wrong!')
  }
})

export const deleteKnowledgeBase = createAsyncThunk('DELETE_KNOWLEDGEBASE', async (uuid) => {
  try {
    const response = await apiV2Request({
      url: `knowledgebase/${uuid}`,
      method: 'DELETE',
    })
    return response
  } catch ({ response = {} }) {
    throw Error(response?.data?.message || 'Something went wrong!')
  }
})

export default createReducer({}, {
  [getKnowledgeBase.fulfilled]: (state, { payload, meta: { arg: uuid } }) => ({ ...state, [uuid]: { ...payload } }),
})

