import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar-edit'
import styled from 'styled-components'
import {
  Modal,
  ModalBody,
} from 'reactstrap'

import { withAlerts } from 'contexts/alertsProvider'
import { ButtonWithSpinner } from 'components/Custom/Buttons'

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const AvatarUpload = ({
  alerts,
  avatarModal,
  showAvatarModal,
  uploadImage,
  isUploading,
}) => {
  const [preview, setPreview] = useState(null)

  const uploadHandlerImage = async () => {
    try {
      await uploadImage(preview)
      setPreview(null)
      showAvatarModal(false)
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  const onBeforeFileLoad = (elem) => {
    if(elem.target.files[0].size > 20 * 1024 * 1024){
      alerts.error({ message: 'File is too big!' })
      elem.target.value = ''
    }
  }

  const toggleModal = () => {
    setPreview(null)
    showAvatarModal(false)
  }

  return (
    <Modal size="sm" isOpen={avatarModal} className="modal-dialog-centered" toggle={toggleModal}>
      <div className="modal-header">
        <h6 className="modal-title">Upload Image</h6>
        <button
          aria-hidden={true}
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggleModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody className="text-center">
        <AvatarWrapper>
          <Avatar
            width={195}
            height={195}
            onCrop={preview => setPreview(preview)}
            onClose={() => setPreview(null)}
            label="Click here to choose a file"
            labelStyle={{
              fontSize: '14px', color: '#222a42', fontWeight: '500', cursor: 'pointer',
            }}
            borderStyle={{ border: '#1f8ef1 2px dotted', borderRadius: '7px' }}
            onBeforeFileLoad={onBeforeFileLoad}
          />
        </AvatarWrapper>
        <ButtonWithSpinner
          label="Upload"
          isUpdating={isUploading}
          onClick={uploadHandlerImage}
          disabled={!preview || isUploading}
          className="mt-4"
        />
      </ModalBody>
    </Modal>
  )
}

AvatarUpload.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
  avatarModal: PropTypes.bool,
  showAvatarModal: PropTypes.func,
  uploadImage: PropTypes.func,
  isUploading: PropTypes.bool,
}

export default withAlerts(AvatarUpload)
