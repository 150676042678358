import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Badge } from 'reactstrap'
import { DATASET_STATUSES, CONTAINER_ACTIONS } from 'helpers/constants'

const StyledBadge = styled(Badge)`
  font-size: 64%;
`

const StatusBadge = ({ status }) => {
  const name = {
    [DATASET_STATUSES.READY]: 'success',
    [DATASET_STATUSES.UPDATING]: 'warning',
    [DATASET_STATUSES.FAILED]: 'danger',
    [DATASET_STATUSES.INITIALIZING]: 'dark opacity-6',
    [DATASET_STATUSES.PENDING]: 'info',

    [CONTAINER_ACTIONS.MERGE]: 'primary text-white',
    [CONTAINER_ACTIONS.EDITION]: 'primary text-white',
    [CONTAINER_ACTIONS.EXTRACTION]: 'primary text-white',
  }

  return (
    <StyledBadge color={name[status] || ''}>
      {status}
    </StyledBadge>
  )
}

StatusBadge.propTypes = {
  status: PropTypes.string,
}

export default StatusBadge
