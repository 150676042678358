import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { ListGroup, ListGroupItem, Input } from 'reactstrap'
import ScrollBar from 'components/Custom/ScrollBar'

import { useGetWorkspaceConnectionsQuery } from 'services/workspaces'

const SecondStep = ({ values, setValues }) => {
  const { data = [] } = useGetWorkspaceConnectionsQuery(values.workspaceUUID)

  const tables = useMemo(() => {
    const selected = data.find(connection => connection.uuid === values.uuid)
    return selected?.data?.map(data => data.name) ?? []
  }, [values.uuid])

  const handleChange = (index) => {
    if (index === 'all') {
      setValues(state => ({ ...state, tables }))
    } else if (index === 'none') {
      setValues(state => ({ ...state, tables: [] }))
    } else {
      const updated = new Set(values.tables)
      if (!values.tables.includes(index)) {
        updated.add(index)
      } else {
        updated.delete(index)
      }
      setValues(state => ({ ...state, tables: [...updated] }))
    }
  }

  const getTitle = (forTrue, forFalse) => {
    const length = tables.length
    return length === values.tables.length ? forTrue : forFalse
  }

  const handleAllSelection = (e) => {
    e.preventDefault()
    handleChange(getTitle('none', 'all'))
  }

  return (
    <ScrollBar autoHeight autoHeightMax="60vh">
      <a
        className="float-right"
        onClick={handleAllSelection}
        role="button"
        href="#select"
      >
        <span className="small">
          <span className="text-info">{`${getTitle('Unselect', 'Select')} all`}</span>
        </span>
      </a>
      <ListGroup flush className="mt-4">
        {tables?.map(name => (
          <ListGroupItem key={name} className="py-1 px-0 d-flex align-items-center">
            <Input
              type="checkbox"
              id={name}
              checked={values.tables.includes(name)}
              onChange={() => handleChange(name)}
              className="m-0 position-relative"
            />
            <label htmlFor={name} className="ml-2 my-0 flex-grow-1" role="button">{name}</label>
          </ListGroupItem>
        ))}
      </ListGroup>
    </ScrollBar>
  )
}

SecondStep.propTypes = {
  values: PropTypes.shape({
    workspaceUUID: PropTypes.string.isRequired,
    tables: PropTypes.arrayOf(PropTypes.string),
    uuid: PropTypes.string,
  }),
  setValues: PropTypes.func,
}

export default SecondStep
