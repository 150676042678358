import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useWizard } from 'react-use-wizard'

import { Col, Row } from 'reactstrap'

const STEP_NAMES = {
  1: 'Choose Model',
  2: 'Select Dataset',
  3: 'Select Target',
  4: 'Summary',
}

const WizardSteps = ({ steps = {} }) => {
  const { stepCount, activeStep } = useWizard()

  const circle = (text, { current, finished }) => (
    <div
      className={classnames('step-circle', { current, finished })}
      data-step-name={steps[text] ?? STEP_NAMES[text]}
    >
      {text}
    </div>
  )
  const line = ({ finished }) => <div className={classnames('step-line', { finished })} />

  return (
    <Row className="justify-content-center">
      <Col xs={11} xl={10}>
        <div className="wizard-modal-steps pb-5">
          {[...Array(stepCount)].map((_, index, { length }) => {
            const step = index + 1
            const isLastStep = length === step
            const finished = index < activeStep

            return (
              <React.Fragment key={index}>
                {circle(step, { finished, current: index === activeStep })}
                {!isLastStep && line({ finished })}
              </React.Fragment>
            )})}
        </div>
      </Col>
    </Row>
  )
}

WizardSteps.propTypes = {
  steps: PropTypes.shape({}),
}

export default WizardSteps
