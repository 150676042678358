import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useWizard } from 'react-use-wizard'

import { Col, Row } from 'reactstrap'

const STEP_NAMES = {
  1: 'Source',
  2: 'Columns',
  3: 'Summary',
}

const WizardSteps = ({
  steps,
  onClose,
  compact,
  size,
  showCloseButton,
}) => {
  const { stepCount, activeStep, goToStep } = useWizard()
  const circle = (text, { current, finished }) => (
    <div
      className={classnames('step-circle', { current, finished })}
      data-step-name={steps[text] ?? STEP_NAMES[text]}
    >
      {text}
    </div>
  )
  const line = ({ finished }) => <div className={classnames('step-line', { finished })} />

  return (
    <>
      {showCloseButton && (
        <Row>
          <Col>
            <button className="close mx-3 mt-2" type="button" onClick={() => {
              onClose()
              goToStep(0)
            }}>
              <span aria-hidden={true}>×</span>
            </button>
          </Col>
        </Row>
      )}

      <Row className="justify-content-center">
        <Col {...{
          ...size === 'bg' && { xs: 11, xl: 10 },
          ...size === 'sm' && { xs: 8 },
        }}>
          <div className={classnames('wizard-modal-steps', {
            'pb-5': !compact,
            'compact pb-4': compact,
          })}>
            {[...Array(stepCount)].map((_, index, { length }) => {
              const step = index + 1
              const isLastStep = length === step
              const finished = index < activeStep

              return (
                <React.Fragment key={index}>
                  {circle(step, { finished, current: index === activeStep })}
                  {!isLastStep && line({ finished })}
                </React.Fragment>
              )})}
          </div>
        </Col>
      </Row>
    </>
  )
}

WizardSteps.defaultProps = {
  compact: false,
  size: 'bg',
  steps: {},
}

WizardSteps.propTypes = {
  steps: PropTypes.shape({}),
  size: PropTypes.oneOf(['bg', 'sm']),
  onClose: PropTypes.func,
  compact: PropTypes.bool,
  showCloseButton: PropTypes.bool,
}

export default WizardSteps
