import { apiV2Request } from 'helpers/request'
import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import omit from 'lodash/omit'

export const selectDocument = createSelector(
  state => state.document,
  (_, data) => data.uuid,
  (documents, uuid) => documents[uuid] || {},
)

export const getDocument = createAsyncThunk('GET_DOCUMENT', async (uuid) => {
  try {
    const { data } = await apiV2Request({ url: `documents/${uuid}` })
    return data
  } catch ({ response = {} }) {
    throw Error(response?.data?.message || 'Something went wrong!')
  }
})

export const updateDocument = createAsyncThunk('UPDATE_DOCUMENT', async ({ uuid, data }) => {
  try {
    const response = await apiV2Request({
      url: `documents/${uuid}`,
      method: 'PUT',
      data,
    })
    return response.data
  } catch ({ response = {} }) {
    throw Error(response?.data?.message || 'Something went wrong!')
  }
})

export default createReducer({}, {
  [getDocument.fulfilled]: (state, { payload }) => ({ ...state, [payload.uuid]: { ...payload } }),
  [updateDocument.fulfilled]: (state, { payload, meta: { arg: { uuid } } }) => ({ ...state, [uuid]: { ...state[uuid], ...omit(payload, ['doc_html']) } }),
})
