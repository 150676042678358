import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'
import { Collapse } from 'reactstrap'

import { GRAPH_LABELS } from 'helpers/constants'
import ICONS from 'helpers/iconConstants'

const Wrapper = styled.div`
  width: 110px;
  z-index: 5;

  > .border { border-radius: 3px; }

  .toogle {
    cursor: pointer;
    > i { transform: rotate(-90deg); }
    &.closed > i { transform: rotate(90deg); }
  }

  ul {
    list-style: none;
    font-size: 12px;
  }
`

const Legend = ({ labels }) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <Wrapper className="position-absolute top-0 right-2 bg-white">
      <div className="border">

        <div className={classnames('w-100 toogle text-center', { closed: !isOpen })} onClick={toggle}>
          <i className={ICONS.ANGLE_RIGHT} />
        </div>

        <Collapse isOpen={isOpen}>
          <div className="border-top" />
          <ul className="m-0 p-2 text-left">
            {Object.keys(GRAPH_LABELS)
              .filter(key => labels.includes(key))
              .map(label => (
                <li className="d-flex flex-nowrap align-items-center" key={label}>
                  <div className="p-1 mr-2 circle-status" style={{ background: GRAPH_LABELS[label]?.background }} /> - {GRAPH_LABELS[label]?.name}
                </li>
              ))}
          </ul>
        </Collapse>
      </div>
    </Wrapper>
  )
}
Legend.propTypes = {
  labels: PropTypes.array,
}

export default Legend
