// function that returns route '/documentation' by default or route for doc page
export const getDocPath = ({ path = '' } = {}) => `/documentation${path}`

// function that on mobile devices makes the search open
export const openSearch = () => {
  document.body.classList.add('g-navbar-search-showing')
  setTimeout(function () {
    document.body.classList.remove('g-navbar-search-showing')
    document.body.classList.add('g-navbar-search-show')
  }, 150)
  setTimeout(function () {
    document.body.classList.add('g-navbar-search-shown')
  }, 300)
}

// function that on mobile devices makes the search close
export const closeSearch = () => {
  document.body.classList.remove('g-navbar-search-shown')
  setTimeout(function () {
    document.body.classList.remove('g-navbar-search-show')
    document.body.classList.add('g-navbar-search-hiding')
  }, 150)
  setTimeout(function () {
    document.body.classList.remove('g-navbar-search-hiding')
    document.body.classList.add('g-navbar-search-hidden')
  }, 300)
  setTimeout(function () {
    document.body.classList.remove('g-navbar-search-hidden')
  }, 500)
}
