import divianApi from './api'
import orderBy from 'lodash/orderBy'

export const containersApi = divianApi.injectEndpoints({
  endpoints: build => ({
    getContainer: build.query({
      query: ({
        uuid,
        page = 1,
        perPage = 100,
      }) => ({
        url: `containers/${uuid}`,
        params: {
          per_page: perPage,
          page,
        },
      }),
      providesTags: ['Container'],
    }),
    addContainer: build.mutation({
      query: body => ({
        url: 'containers',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Datasets', 'WorkspaceDatasets'],
    }),
    updateContainer: build.mutation({
      query: ({ uuid, ...patch }) => ({
        url: `containers/${uuid}`,
        method: 'PUT',
        body: patch,
      }),
      onQueryStarted: updateContainer,
    }),
    addStep: build.mutation({
      query: ({ containerUUID, ...body }) => ({
        url: `containers/${containerUUID}/jobs`,
        method: 'POST',
        body,
      }),
      onQueryStarted: updateContainer,
    }),
    updateStepOrder: build.mutation({
      query: ({ uuid, orderedJobs }) => ({
        url: `containers/${uuid}/jobs`,
        method: 'PUT',
        body: orderedJobs,
      }),
      onQueryStarted: updateContainer,
      invalidatesTags: ['DatasetHistory'],
    }),
    updateStep: build.mutation({
      query: ({ containerUUID, jobUUID, ...patch }) => ({
        url: `containers/${containerUUID}/jobs/${jobUUID}`,
        method: 'PUT',
        body: patch,
      }),
      onQueryStarted: updateContainer,
      invalidatesTags: ['DatasetHistory'],
    }),
    getContainerVariable: build.query({
      query: ({ uuid, index }) => ({
        url: `containers/${uuid}/variable/${index}`,
      }),
      providesTags: ['ContainerVariable'],
      keepUnusedDataFor: 10,
    }),
    deleteStep: build.mutation({
      query: ({ uuid, jobUUID }) => ({
        url: `containers/${uuid}/jobs/${jobUUID}`,
        method: 'DELETE',
      }),
      onQueryStarted: updateContainer,
    }),
    restoreContainer: build.mutation({
      query: uuid => ({
        url: `containers/${uuid}/restore`,
        method: 'POST',
      }),
      onQueryStarted: updateContainer,
    }),
    getContainerSources: build.query({
      queryFn: async (...args) => {
        const [,,,fetchWithBQ] = args
        const { data: sources } = await fetchWithBQ({ url: 'sources' })
        const { data: datasets } = await fetchWithBQ({ url: 'datasets', params: { show_all: false } })
        return { data: orderBy([...sources, ...datasets], ['created'], ['desc']) }
      },
      providesTags: ['ContainerSources'],
    }),
    getContainerSource: build.query({
      queryFn: async (...args) => {
        const [values, { getState, dispatch }] = args
        const state = getState()
        const { uuid, type } = values
        let data
        if (type === 'dataset') {
          ({ data } = divianApi.endpoints.getDataset.select({ uuid, page: 1, versionUUid: null })(state))
          if (!data) ({ data } = await dispatch(divianApi.endpoints.getDataset.initiate({ uuid, page: 1 })))
        } else {
          ({ data } = divianApi.endpoints.getSource.select(uuid)(state))
          if (!data) ({ data } = await dispatch(divianApi.endpoints.getSource.initiate(uuid)))
        }
        return { data }
      },
      providesTags: ['ContainerSource'],
    }),
  }),
  overrideExisting: true,
})

async function updateContainer (_, { dispatch, queryFulfilled, getState }) {
  const { data: updatedContainer } = await queryFulfilled
  for (const { endpointName, originalArgs } of containersApi.util.selectInvalidatedBy(getState(), ['Container'])) {
    if (endpointName !== 'getContainer') continue
    dispatch(containersApi.util.updateQueryData('getContainer', originalArgs, (draft) => {
      Object.assign(draft, updatedContainer)
    }))
  }
}


export const {
  useGetContainerQuery,
  useGetContainerVariableQuery,
  useGetContainerSourceQuery,
  useGetContainerSourcesQuery,
  useAddContainerMutation,
  useUpdateStepOrderMutation,
  useAddStepMutation,
  useDeleteStepMutation,
  useUpdateStepMutation,
  useUpdateContainerMutation,
  useRestoreContainerMutation,
} = containersApi
