import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import * as ReactStickyTable from 'react-sticky-table'

import ScrollBar from 'components/Custom/ScrollBar'
import SortIcon from 'components/Catalog/Shared/Table/SortIcon'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'
import { removeYes } from 'helpers/utils'

const StickyTable = ({
  header,
  body,
  isLoading,
  stickyHeaderCount,
  leftStickyColumnCount,
  maxHeight,
  onClick,
}) => {
  const [currentSort, setSort] = useState({ name: '', sort: 'asc' })

  const data = useMemo(() => {
    if (isEmpty(currentSort.name)) return body
    const sortedData = sortBy(body, [currentSort.name])
    return currentSort.sort === 'asc'
      ? sortedData
      : reverse(sortedData)
  }, [body, currentSort])

  const sortClick = (name) => {
    if (isEmpty(currentSort.name) || currentSort.name !== name) {
      setSort({ name, sort: 'asc' })
    } else if (currentSort.sort === 'asc') {
      setSort({ name, sort: 'desc' })
    } else {
      setSort({ name: '', sort: 'asc' })
    }
  }

  if (isLoading) return <Spinner />

  return (
    <ScrollBar {...maxHeight && { autoHeight: true, autoHeightMax: maxHeight }}>
      <Table
        borderColor="var(--lighter)"
        borderWidth="1px"
        className="overflow-visible"
        leftStickyColumnCount={leftStickyColumnCount}
        rightStickyColumnCount={0}
        stickyFooterCount={0}
        stickyHeaderCount={stickyHeaderCount}
      >
        <Tr>
          {header.map((name, i) => (
            <Th key={`th-${i}`} className="align-middle border-top bg-white px-3" role="button" onClick={() => sortClick(name)}>
              <div className="text-nowrap text-default text-uppercase font-weight-bold d-flex justify-content-between align-items-center">
                <span>{name}</span>
                <SortIcon column={{
                  isSorted: currentSort.name === name && !isEmpty(currentSort.name),
                  isSortedDesc: currentSort.sort === 'desc',
                }} />
              </div>
            </Th>
          ))}
        </Tr>

        {data.map((row, i) => (
          <Tr key={`tr-${i}`} {...onClick && { onClick: () => onClick(row), role: 'button' }}>
            {header.map((key, i) => (
              <Td key={`td-${i}`} className="text-nowrap text-default font-weight-normal align-top py-2 px-3">
                {removeYes(row[key])}
              </Td>
            ))}
          </Tr>
        ))}
      </Table>
    </ScrollBar>
  )
}

const Table = styled(ReactStickyTable.StickyTable)`
  > .sticky-table-table { width: 100%; }
`

const Tr = styled(ReactStickyTable.Row)`
  &:hover > .sticky-table-cell { background-color: var(--lightest); }
`

const Th = styled(ReactStickyTable.Cell)`
  font-size: 0.65rem;
  letter-spacing: 1px;
  padding: 0.75rem;
`

const Td = styled(ReactStickyTable.Cell)`
  background-color: var(--white);
  font-size: 0.8rem;
  transition: all 0.15s ease;
`

StickyTable.defaultProps = {
  body: [],
  header: [],
  isLoading: false,
  leftStickyColumnCount: 0,
  maxHeight: null,
  stickyHeaderCount: 1,
}

StickyTable.propTypes = {
  body: PropTypes.array,
  header: PropTypes.array,
  isLoading: PropTypes.bool,
  leftStickyColumnCount: PropTypes.number,
  maxHeight: PropTypes.string,
  stickyHeaderCount: PropTypes.number,
  onClick: PropTypes.func,
}

export default StickyTable
