import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { formatDateAndTime } from 'helpers/utils'
import { Table } from 'reactstrap'
import ScrollBar from 'components/Custom/ScrollBar'

const DatasetList = ({ data, onChange, values }) => (
  <ScrollBar>
    <Table hover size="sm" style={{ tableLayout: 'fixed' }}>
      <colgroup>
        <col />
        <col width="20%" style={{ minWidth: '180px' }} />
      </colgroup>
      <tbody>
        {data.map(({ created, name, uuid }) => (
          <tr
            key={uuid}
            onClick={() => {
              onChange('datasetUUID', uuid)
              onChange('datasetName', name)
            }}
            role="button"
            style={{ ...values.datasetUUID === uuid && { backgroundColor: '#f6f9fc' } }}
            className={classnames({ 'font-weight-bold': values.datasetUUID === uuid })}
          >
            <td className="text-truncate">
              {name}
            </td>
            <td className="text-truncate">{formatDateAndTime(created)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </ScrollBar>
)

DatasetList.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  values: PropTypes.shape({
    datasetUUID: PropTypes.string,
  }),
}

export default DatasetList
