import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'

export const EmptyContentWithText = ({ text }) => (
  <div className="position-absolute h-100 w-100 d-flex align-items-center justify-content-center ">
    {text}
  </div>
)

EmptyContentWithText.propTypes = {
  text: PropTypes.string,
}

const TableBodyWrapper = ({ children }) => (
  <Table hover size="sm" style={{ tableLayout: 'fixed' }}>
    <colgroup>
      <col />
      <col width="80px" style={{ minWidth: '80px' }} />
    </colgroup>
    <thead style={{ backgroundColor: '#fafafa' }}>
      <tr>
        <td
          className="text-uppercase font-weight-bold text-default border-right border-top-0"
          style={{ letterSpacing: '0.02rem', fontSize: '0.9rem', lineHeight: '1rem' }}
        >Name</td>
        <td
          className="text-uppercase font-weight-bold text-default border-top-0"
          style={{ letterSpacing: '0.02rem', fontSize: '0.9rem', lineHeight: '1rem' }}
        >Type</td>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </Table>
)

TableBodyWrapper.propTypes = {
  children: PropTypes.node,
}

export default TableBodyWrapper
