import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
import { usePagination, useTable } from 'react-table'
import { Col, Row, ListGroup } from 'reactstrap'

import ScrollBar from 'components/Custom/ScrollBar'
import Pagination from 'components/Catalog/Shared/Pagination'
import ListItem from 'components/Catalog/RequestsPage/ListItem'

const RequestList = ({ data }) => {
  const history = useHistory()
  const { workspaceUUID } = useParams()

  const onRowClick = uuid => history.push(`/workspaces/${workspaceUUID}/catalog/discover/${uuid}`)

  const columns = useMemo(() => ['name'].map(accessor => ({ accessor })), [])

  const options = useTable(
    {
      initialState: { pageSize: 25, pageIndex: 0 },
      columns,
      data,
    },
    usePagination,
  )

  return (
    <>
      <ScrollBar>
        <Row className="justify-content-center m-0">
          <Col xs={12} md={10} lg={8}>
            <ListGroup flush className="my-3">
              {options.page.map((row, i) => <ListItem data={row.original} onClick={() => onRowClick(row.original.uuid)} key={i} />)}
            </ListGroup>
          </Col>
        </Row>
      </ScrollBar>

      {options.pageCount > 1 && (
        <Row>
          <Col xs={12} className="py-3 bg-white">
            <Pagination {...options} />
          </Col>
        </Row>
      )}
    </>
  )
}

RequestList.defaultProps = {
  data: [],
}

RequestList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
}

export default RequestList
