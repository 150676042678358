import React, { useLayoutEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Col,
  Row,
  TabContent,
  TabPane,
  Card,
  CardBody,
} from 'reactstrap'
import { Helmet } from 'react-helmet'

import LoadingSpinner from 'components/Shared/LoadingSpinner'
import Navigation from 'components/Main/AccountPage/Navigation'
import Notifications from 'components/Main/AccountPage/Notifications'
import Profile from 'components/Main/AccountPage/Profile'
import Security from 'components/Main/AccountPage/Security'
import ApiToken from 'components/Main/AccountPage/ApiToken'
import Settings from 'components/Main/AccountPage/Settings'
import Members from 'components/Main/AccountPage/Members'
import Billing from 'components/Main/AccountPage/Billing'

import { useActiveTab } from 'hooks/useActiveTab'
import { useBreadcrumbs } from 'contexts/breadcrumbsProvider'
import { useGetAccountQuery } from 'services/user'

const AccountPage = () => {
  const { setBreadcrumbs } = useBreadcrumbs()
  useLayoutEffect(() => setBreadcrumbs({ parentName: '' }), [])

  const activeTab = useActiveTab('profile')
  const { data: account = {}, isLoading } = useGetAccountQuery()

  const isAdmin = useMemo(() => account.usertype === 'admin', [account.usertype])

  return (
    <>
      <Helmet title="Account" />
      <Row>
        <Card className="w-100">
          <CardBody>
            <Row>
              <Col md={3} xl={2}>
                <Navigation activeTab={activeTab} isAdmin={isAdmin} />
              </Col>
              <Col md={9} xl={10}>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="profile">
                    <Profile />
                  </TabPane>
                  <TabPane tabId="security">
                    <Security />
                  </TabPane>
                  <TabPane tabId="api_key">
                    <ApiToken />
                  </TabPane>
                  <TabPane tabId="notifications">
                    <Notifications />
                  </TabPane>
                  <TabPane tabId="settings">
                    <Settings />
                  </TabPane>
                  <TabPane tabId="members">
                    <Members />
                  </TabPane>
                  {isAdmin && (
                    <TabPane tabId="billing">
                      <Billing activeTab={activeTab} />
                    </TabPane>)}
                </TabContent>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
      <LoadingSpinner visible={isLoading} />
    </>
  )
}

AccountPage.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
}

export default AccountPage
