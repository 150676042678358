import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  ButtonGroup,
  Input,
} from 'reactstrap'

import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'
import { ActionButton } from 'components/Catalog/Shared/Buttons'
import ICONS from 'helpers/iconConstants'

import { useGetCatalogQuery, useUpdateCatalogMutation } from 'services/catalogs'
import { withAlerts } from 'contexts/alertsProvider'
import { useQuery } from 'hooks/useQuery'

const ReadmeSection = ({ alerts }) => {
  const { uuid, workspaceUUID } = useParams()
  const [value, setValue] = useState('No')
  const [editMode, setEditMode] = useState(false)
  const { getParam } = useQuery()

  const catalogParams = {
    uuid,
    db_name: getParam('db_name'),
    name: getParam('name'),
    schema: getParam('schema'),
    workspaceUUID,
  }

  const { data = {}, isFetching, isLoading } = useGetCatalogQuery(catalogParams)
  const [updateCatalog, { isLoading: isUpdating }] = useUpdateCatalogMutation()

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') cancelEdit()
  }, [])

  const cancelEdit = () => {
    setEditMode(false)
    setValue(data?.graph_meta?.description)
  }

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [])

  useEffect(() => {
    if (isLoading) return
    setValue(data?.graph_meta.description)
  }, [isLoading])

  const updateHandler = async () => {
    if (value === data.graph_meta.description) return
    try {
      await updateCatalog({ ...catalogParams, description: value }).unwrap()
      setEditMode(false)
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  return (
    <Card className="rounded-0 position-relative shadow-none">
      <CardHeader className="bg-white position-sticky top-0" style={{ zIndex: 1040 }}>
        <Row>
          <Col className="d-flex align-items-center">
            <i className={ICONS.BOOK} />
            <h3 className="m-0 pl-2 d-inline-block">Readme</h3>
          </Col>
          <Col xs="auto">
            <ButtonGroup>
              {editMode ? (
                <>
                  <ActionButton label={<i className={ICONS.SAVE_ICON} />} onClick={updateHandler} />
                  <ActionButton label={<i className={ICONS.CANCEL_ICON} />} onClick={cancelEdit} />
                </>
              ) : (
                <ActionButton label={<i className={ICONS.PENCIL_SQUARE} />} onClick={() => setEditMode(true)} />
              )}
            </ButtonGroup>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {(isFetching || isUpdating)
          ? <Spinner />
          : editMode ? (
            <Input
              autoComplete="off"
              bsSize="sm"
              className="text-monospace mt-2"
              type="textarea"
              value={value}
              onChange={e => setValue(e.target.value)}
              rows="20"
              autoFocus
            />
          ) : (
            data?.graph_meta?.description || 'This is readme file here...'
          )}
      </CardBody>
    </Card>
  )
}

ReadmeSection.propTypes = {
  data: PropTypes.shape({
    desc: PropTypes.string,
  }),
  alerts: PropTypes.shape({
    error: PropTypes.func.isRequired,
  }),
}

export default withAlerts(ReadmeSection)
