import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'

import ScrollBar from 'components/Custom/ScrollBar'
import HeadersPreview from 'components/Main/DatasetViewPage/CreateNewModal/HeadersPreview'
import QueryPreview from 'components/Main/DatasetViewPage/CreateNewModal/QueryPreview'
import DatabaseTree from 'components/Main/DatasetViewPage/CreateNewModal/DatabaseTree'

import { useGetTableHeaderQuery } from 'services/sources'
import { SOURCE_TYPES } from 'helpers/constants'

const DatabaseHeadersPreview = ({ values, setValues, sourceType }) => {
  const {
    sourceUUID: uuid,
    databaseName,
    tableName,
    schemaName,
    showQuery,
  } = values

  const { data: tableData = {}, isFetching } = useGetTableHeaderQuery({
    uuid,
    databaseName,
    tableName,
    schemaName,
  }, { skip: !tableName })

  return (
    <Row className="h-100">
      <Col xl={3}>
        <Row className="h-100">
          <Col className="d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between">
              <span className="py-2 text-uppercase font-weight-bold">Table</span>
              {sourceType !== SOURCE_TYPES.PGSQL && (
                <div className="d-flex align-items-center">
                  <label className="custom-toggle mr-1">
                    <input
                      type="checkbox"
                      checked={showQuery}
                      onChange={() => setValues(state => ({ ...state, showQuery: !showQuery }))}
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                  <small className="text-primary font-weight-bold">With query</small>
                </div>
              )}
            </div>
            <div className="border flex-grow-1">
              <ScrollBar forceOnUpdateSize>
                <DatabaseTree
                  values={values}
                  setValues={setValues}
                  sourceType={sourceType}
                />
              </ScrollBar>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xl={9} className="d-flex flex-column">
        {showQuery ? (
          <QueryPreview values={values} setValues={setValues} />
        ) : (
          <HeadersPreview
            header={tableData?.header}
            isLoading={isFetching}
            values={values}
            setValues={setValues}
            noSourceMessage="No data here yet, select table on the left or switch to the query mode"
          />
        )}
      </Col>
    </Row>
  )
}

DatabaseHeadersPreview.propTypes = {
  values: PropTypes.shape({
    selectedKeys: PropTypes.array,
    sourceUUID: PropTypes.string,
    tableName: PropTypes.string,
    databaseName: PropTypes.string,
    schemaName: PropTypes.string,
    showQuery: PropTypes.bool,
  }),
  setValues: PropTypes.func,
  sourceType: PropTypes.string,
}

export default DatabaseHeadersPreview
