import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import Field from 'components/Main/SourceViewPage/CreateSourceModal/Forms/SourceForm/Field'
import SSHSection from 'components/Main/SourceViewPage/CreateSourceModal/Forms/SourceForm/SSHSection'
import SSHToggleSection from 'components/Main/SourceViewPage/CreateSourceModal/Forms/SourceForm/SSHToggleSection'
import { FORM_FIELDS } from 'components/Shared/AddSourceForm/fieldConfig'
import { SOURCE_TYPES } from 'helpers/constants'

import { Row } from 'reactstrap'

const SourceForm = ({ type }) => {
  const [{ value: sshValue },,{ setValue: setSshValue }] = useField('use_ssh')

  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      <div>
        <Row>
          {FORM_FIELDS[type].map(options => (
            <Field {...options} key={options.name} />
          ))}
        </Row>
        {type !== SOURCE_TYPES.SNOWFLAKE && (
          <>
            <SSHToggleSection onChange={() => setSshValue(sshValue ? 0 : 1)} />
            {!!sshValue && <SSHSection />}
          </>
        )}
      </div>
    </div>
  )
}

SourceForm.propTypes = {
  type: PropTypes.string,
}

export default SourceForm
