import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import {
  Col,
  Input,
  Row,
} from 'reactstrap'

import { ButtonWithSpinner } from 'components/Custom/Buttons'
import HeadersPreview from 'components/Main/DatasetViewPage/CreateNewModal/HeadersPreview'

import { withAlerts } from 'contexts/alertsProvider'
import { FILE_SOURCES } from 'helpers/constants'

import divianApi from 'services/api'

const QueryPreview = ({ alerts, values, setValues }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState({})

  const testQueryHandler = async () => {
    const {
      sourceUUID,
      query,
      type,
      databaseName,
      versionUUID,
    } = values
    const data = {
      query,
      type,
      db_name: databaseName,
      version_uuid: versionUUID,
    }
    try {
      setData(state => ({ ...state, isFetching: true }))
      const payload = await dispatch(divianApi.endpoints.testQuery.initiate({ uuid: sourceUUID, ...data })).unwrap()
      setData({
        isFetching: false,
        success: true,
        ...payload,
      })
    } catch ({ data: { message } }) {
      alerts.error({ message })
      setData({
        isFetching: false,
        success: false,
        message,
      })
    }
  }

  return (
    <>
      <Row>
        <Col className="d-flex flex-column">
          <span className="py-2 text-uppercase font-weight-bold">Query</span>
          <Input
            resize="vertical"
            type="textarea"
            rows="4"
            value={values.query}
            placeholder="Type SQL query here…"
            onChange={e => setValues(state => ({ ...state, query: e.target.value }))}
          />
          <div className="text-right">
            <ButtonWithSpinner
              label="Test query"
              className="my-3"
              onClick={testQueryHandler}
            />
          </div>
        </Col>
      </Row>
      <HeadersPreview
        header={data?.header}
        isLoading={data.isFetching}
        values={values}
        setValues={setValues}
        noSourceMessage={values.type === 'dataset' ? (
          <>
              Use a keyword "dataset" as a reference to table name in your query. For example,
            <span className="ml-1 text-monospace text-nowrap">SELECT * FROM dataset</span>.
          </>
        ) : FILE_SOURCES.includes(values.sourceType) ? (
          <>
            Use a keyword "file" as a reference to table name in your query. For example,
            <span className="ml-1 text-monospace text-nowrap">SELECT * FROM file</span>.
          </>
        ) : (
          <>
            Use query to get preview. For example,
            <span className="ml-1 text-monospace text-nowrap">SELECT * FROM db_name.schema.table</span>.
          </>
        )}
      />
    </>
  )
}

QueryPreview.propTypes = {
  values: PropTypes.shape({
    sourceUUID: PropTypes.string,
    type: PropTypes.string,
    query: PropTypes.string,
    databaseName: PropTypes.string,
    versionUUID: PropTypes.string,
    sourceType: PropTypes.string,
  }),
  setValues: PropTypes.func,
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
}

export default withAlerts(QueryPreview)
