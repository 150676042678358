import React from 'react'
import { useParams } from 'react-router-dom'
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container,
} from 'reactstrap'

import BotChat from 'components/Catalog/RequestPageView/DataViewTilesOnly/BotChat'

import { useGetRequestQuery } from 'services/catalogs'

import ICONS from 'helpers/iconConstants'

const ChatTab = () => {
  const { uuid, workspaceUUID } = useParams()
  const { data = {} } = useGetRequestQuery({ uuid, workspaceUUID })

  return (
    <Container fluid>
      <Card className="rounded-0 position-relative shadow-none">
        <CardHeader className="position-sticky top-0 bg-white" style={{ zIndex: 1040 }}>
          <Row>
            <Col className="d-flex align-items-center">
              <i className={ICONS.COMMENT_DOTS} />
              <h3 className="m-0 pl-2 d-inline-block">Chat</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <BotChat data={data.log} />
        </CardBody>
      </Card>
    </Container>
  )
}

export default ChatTab
