import divianApi from './api'
import { customRequest } from 'helpers/request'
import remove from 'lodash/remove'
import { SOURCE_TYPES } from 'helpers/constants'

export const sourcesApi = divianApi.injectEndpoints({
  endpoints: build => ({
    getSource: build.query({
      query: uuid => `sources/${uuid}`,
      providesTags: ['Source'],
    }),
    getSources: build.query({
      query: () => 'sources',
      providesTags: ['Sources'],
      keepUnusedDataFor: 600,
    }),
    getTableHeader: build.query({
      query: ({
        uuid,
        databaseName,
        tableName,
        schemaName,
      }) => ({
        url: `sources/${uuid}/databases/${databaseName}/tables/${tableName}`,
        params: {
          ...schemaName && { schema_name: schemaName },
        },
      }),
      providesTags: ['SourceTableHeader'],
    }),
    getDatabaseSchema: build.query({
      query: ({ uuid, databaseName }) => `sources/${uuid}/databases/${databaseName}`,
      providesTags: ['SourceDatabaseSchema'],
    }),
    addDatabaseSource: build.mutation({
      query: data => ({
        url: 'sources',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: (_, error) => error ? [] : ['Sources', 'WorkspaceSources', 'ContainerSources'],
    }),
    addFileSource: build.mutation({
      queryFn: async (...args) => {
        const [{ file, ...rest },,,fetchWithBQ] = args
        const { data: { url, fields } } = await fetchWithBQ({ url: 'upload_url' })

        await customRequest({
          url,
          method: 'POST',
          multipart: true,
          data: { ...fields, file },
        })

        const { workspaceUUID } = rest
        let { name, desc } = rest
        if(!name) name = file.name
        if(!desc) desc = 'User uploaded file.'

        return fetchWithBQ({
          url: 'sources',
          method: 'POST',
          body: {
            name,
            desc,
            type: 'file',
            parameters: { file_uuid: fields.key },
            ...workspaceUUID && { workspace_uuid: workspaceUUID },
          },
        })
      },
      invalidatesTags: (_, error) => error ? [] : ['Sources', 'WorkspaceSources', 'ContainerSources'],
    }),
    updateSource: build.mutation({
      query: ({ uuid, ...patch }) => ({
        url: `sources/${uuid}`,
        method: 'PUT',
        body: patch,
      }),
      async onQueryStarted({ uuid }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedSource } = await queryFulfilled
          dispatch(sourcesApi.util.updateQueryData('getSource', uuid, (draft) => {
            Object.assign(draft, updatedSource)
          }))
          dispatch(sourcesApi.util.updateQueryData('getSources', 'all', (draftSources) => {
            const source = draftSources.find(source => source.uuid === uuid)
            Object.assign(source, updatedSource)
          }))
        } catch {}
      },
      invalidatesTags: ['WorkspaceSources', 'ContainerSources'],
    }),
    testQuery: build.query({
      query: ({ uuid, type, ...rest }) => ({
        url: `${type === 'connector' ? 'sources' : 'datasets'}/${uuid}/query`,
        method: 'POST',
        body: rest,
      }),
    }),
    getSourceHeader: build.query({
      queryFn: async (...args) => {
        const [values, { getState }] = args
        const state = getState()
        const {
          query,
          databaseName,
          tableName,
          schemaName,
          sourceUUID,
          showQuery,
          type,
        } = values
        let data
        ({ data } = divianApi.endpoints.getContainerSource.select({ uuid: sourceUUID, type })(state))
        if ([SOURCE_TYPES.FILE, SOURCE_TYPES.DIVIAN_API].includes(data.type) || type === 'dataset') return { data }
        if (showQuery) {
          ({ data } = sourcesApi.endpoints.testQuery.select({
            uuid: sourceUUID,
            query, type,
            db_name: databaseName,
          })(state))
          return { data }
        }
        ({ data } = sourcesApi.endpoints.getTableHeader.select({
          uuid: sourceUUID,
          databaseName,
          tableName,
          schemaName,
        })(state))
        return { data }
      },
    }),
    deleteSource: build.mutation({
      query: uuid => ({
        url: `sources/${uuid}`,
        method: 'DELETE',
      }),
      async onQueryStarted(uuid, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(sourcesApi.util.updateQueryData('getSources', 'all', (draftSources) => {
            remove(draftSources, source => source.uuid === uuid)
          }))
        } catch {}
      },
      invalidatesTags: ['ContainerSources'],
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetSourceQuery,
  useGetSourcesQuery,
  useGetTableHeaderQuery,
  useGetDatabaseSchemaQuery,
  useTestQueryQuery,
  useGetSourceHeaderQuery,
  useAddFileSourceMutation,
  useAddDatabaseSourceMutation,
  useUpdateSourceMutation,
  useDeleteSourceMutation,
} = sourcesApi
