import React from 'react'
import { MessageSpinner as Typing } from 'components/Custom/CustomSpinners'
import StyledWrapper from 'components/Catalog/RequestPageView/DataViewTilesOnly/BotChat/Message/StyledWrapper'

import ICONS from 'helpers/iconConstants'

const TypingMsg = () => (
  <div className="d-flex flex-nowrap mb-3 w-100">
    <i className={`${ICONS.ROBOT} mb-1 display-4`} />

    <StyledWrapper className="bg-lightest rounded border ml-3 p-3">
      <Typing />
    </StyledWrapper>
  </div>
)

export default TypingMsg
