import moment from 'moment'
import routes from 'mainRoutes'
import pluralize from 'pluralize'
import { DATASET_STATUSES, STATUS_TYPES } from 'helpers/constants'
import REACT_TABLE_SIZES from 'helpers/reactTableSizes'

export const formatDateAndTime = (timestamp, useSlash = false) => {
  const format = ['YYYY', 'MM', 'DD'].join(useSlash ? '/' : '-')
  return timestamp ? moment.unix(timestamp).format(`${format}, HH:mm:ss`) : ''
}

export const formatElapsedTime = ({ started, elapsed }) => {
  if (!elapsed) return ''
  return moment(moment.unix(started)).from(moment.unix(started + elapsed), true)
}

export const formatDecimalNumber = (value = 0, size = 2) => parseFloat(value).toFixed(size)

export const defaultAvatarSrc = (img) => {
  img.target.src = 'https://divian.io/users/default-avatar.png'
}

export const pathIcons = () => routes.reduce((acc, { path, icon }) => ({ ...acc, [path?.split('/')?.pop()]: icon?.replace(/text-primary/, '') }), {})

export const getDatasetStatusClasses = (status) => {
  const name = {
    [DATASET_STATUSES.READY]: 'bg-success circle-status',
    [DATASET_STATUSES.UPDATING]: 'bg-warning circle-status',
    [DATASET_STATUSES.FAILED]: 'bg-danger circle-status',
    [DATASET_STATUSES.INITIALIZING]: 'bg-dark opacity-6 circle-status',
    [DATASET_STATUSES.PENDING]: 'bg-info circle-status',
  }
  return name[status] || ''
}

export const getJobStatusClasses = (status) => {
  const name = {
    [STATUS_TYPES.COMPLETED]: 'bg-success circle-status',
    [STATUS_TYPES.PENGING]: 'bg-warning circle-status',
    [STATUS_TYPES.RUNNING]: 'bg-info circle-status',
    [STATUS_TYPES.FAILED]: 'bg-danger circle-status',
    [STATUS_TYPES.CANCELED]: 'bg-danger circle-status',
    [STATUS_TYPES.INITIALIZING]: 'bg-dark opacity-6 circle-status',
  }
  return name[status] || ''
}

export const bytesToHuman = (bytes, dp = 1) => {
  const THRESH = 1024
  const UNITS = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  bytes = parseInt(bytes)

  if (Math.abs(bytes) < THRESH) return bytes + ' B'
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= THRESH
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= THRESH && u < UNITS.length - 1)


  return bytes.toFixed(dp) + ' ' + UNITS[u]
}

export const numberToHuman = (number, text) => {
  number = parseInt(number)
  const pluralText = pluralize(text, number)
  return `${formatNumber(number)} ${pluralText}`
}

export const formatNumber = number => new Intl.NumberFormat().format(parseInt(number))

export const generateTableColgroup = (type, columns) => {
  if (!REACT_TABLE_SIZES[type]) return

  const cols = columns.map(({ accessor }) => {
    const { width, minWidth } = REACT_TABLE_SIZES[type][accessor] || {}
    return <col width={width} style={{ minWidth }} key={accessor}/>
  })

  return <colgroup>{cols}</colgroup>
}

export const removeYes = (word) => {
  try{
    return word.replace(/yes/i, '')
  } catch(e) {
    return word
  }
}
