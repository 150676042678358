import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { HorizontalBar } from 'react-chartjs-2'

const chartExample8 = {
  options: {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          display: false,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            padding: 20,
            fontColor: '#9e9e9e',
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#333',
      bodyFontColor: '#666',
      bodySpacing: 4,
      xPadding: 12,
      mode: 'nearest',
      intersect: 0,
      position: 'nearest',
    },
  },
}

const CategoricalChart = ({ showCategories, shortView }) => {
  const data = useCallback((canvas) => {
    if (isEmpty(showCategories)) return { labels: [], datasets: [{}] }
    const labels = Object.keys(showCategories)
    const data = Object.values(showCategories)

    const ctx = canvas.getContext('2d')
    const gradientStroke = ctx.createLinearGradient(0, shortView ? 38 : canvas.height, 0, 0)
    gradientStroke.addColorStop(1, '#1171ef')
    gradientStroke.addColorStop(0, '#344675')

    return {
      labels,
      datasets: [
        {
          fill: true,
          backgroundColor: gradientStroke,
          hoverBackgroundColor: '#1171ef',
          borderColor: '#344675',
          borderDash: [],
          borderDashOffset: 0.0,
          categoryPercentage: 0.85,
          barPercentage: 0.85,
          data: [...data],
        },
      ],
    }
  }, [showCategories])

  chartExample8.options.scales.yAxes = [
    {
      display: !shortView,
      gridLines: {
        display: false,
      },
      ticks: {
        fontColor: '#9e9e9e',
      },
    },
  ]

  chartExample8.options.tooltips.enabled = !shortView

  return (
    <HorizontalBar
      data={data}
      options={chartExample8.options}
      className="chart-canvas"
    />
  )
}

CategoricalChart.propTypes = {
  showCategories: PropTypes.shape({}),
  shortView: PropTypes.bool,
}

export default CategoricalChart
