import React from 'react'
import {
  Row,
  Col,
} from 'reactstrap'

const Example = () => (
  <Row>
    <Col>
      <div className="example-schema-wrapper d-flex justify-content-between">

        <div className="left-side h-100 d-flex flex-column">
          <div className="pt-2 pl-3 text-truncate text-left">Business concepts</div>

          <div className="flex-grow-1 d-flex justify-content-around align-items-center flex-row">
            <div className="cloud cloud-1">
              <span className="text-truncate">
                Massachusetts
              </span>
            </div>
            <span className="line-text">equivalent</span>
            <div className="cloud cloud-2">
              <span className="text-truncate">
              Boston Airport
              </span>
            </div>
          </div>
        </div>

        <div className="divided" />

        <div className="right-side h-100 d-flex flex-column">
          <div className="pt-2 pl-3 text-truncate text-left">Tech data</div>

          <div className="flex-grow-1 d-flex justify-content-around align-items-center flex-row">
            <div className="cloud cloud-1">
              <span className="text-truncate">
                Weather Station
              </span>
            </div>
            <span className="line-text">part of</span>
            <div className="cloud cloud-2">
              <span className="text-truncate">
                Weather
              </span>
            </div>
          </div>
        </div>
      </div>
    </Col>
  </Row>
)

export default Example
