import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ActionsMenu from 'components/Binders/Sidebar/ActionsMenu'
import InputField from 'components/Binders/Sidebar/InputField'
import ChildItem from 'components/Binders/Sidebar/ChildItem'
import { Nav, NavItem } from 'reactstrap'

const MODE = {
  ADD_ROOT: 'ADD_ROOT',
  EDIT_ROOT: 'EDIT_ROOT',
  ADD_CHILD: 'ADD_CHILD',
}

const RootItem = ({
  children,
  documentId,
  item,
  onItemClick,
}) => {
  const [mode, setMode] = useState(null)

  const editRootHandle = (value) => {
    alert(`EDIT_ROOT to: ${value}`)
    setMode(null)
  }
  const addRootHandle = (value) => {
    alert(`ADD_ROOT: ${value}`)
    setMode(null)
  }
  const addChildHandle = (value) => {
    alert(`ADD_CHILD: ${value}`)
    setMode(null)
  }

  return (
    <div className="ct-toc-item active">
      {mode === MODE.ADD_ROOT && (
        <div className="ct-toc-link-editable">
          <InputField onCloseHandler={() => setMode(null)} onSaveHandler={addRootHandle} isRoot />
        </div>
      )}

      <div className="ct-toc-link-editable">
        {mode === MODE.EDIT_ROOT && <InputField item={item} onCloseHandler={() => setMode(null)} onSaveHandler={editRootHandle} isRoot />}

        {mode !== MODE.EDIT_ROOT && <>
          <div className="ct-toc-link w-100 ml-n2">{item.name}</div>
          <ActionsMenu
            addChildHandler={() => setMode(MODE.ADD_CHILD)}
            addRootHandler={() => setMode(MODE.ADD_ROOT)}
            deleteHandler={() => alert('DELETE ROOT')}
            editRootHandler={() => setMode(MODE.EDIT_ROOT)}
          /></>}
      </div>

      <Nav vertical className="ct-sidenav">
        {mode === MODE.ADD_CHILD && (
          <NavItem className="nav-item-editable">
            <span className="ml-4" />
            <InputField onCloseHandler={() => setMode(null)} onSaveHandler={addChildHandle} />
          </NavItem>)}

        {children.map(child => (
          <ChildItem
            isActive={child.document_uuid === documentId}
            item={child}
            key={child.uuid}
            onClick={() => onItemClick(child.document_uuid)}
          />))}
      </Nav>
    </div>
  )
}

RootItem.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({})),
  documentId: PropTypes.string,
  item: PropTypes.shape({
    name: PropTypes.string,
  }),
  onItemClick: PropTypes.func,
}

export default RootItem
