import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import take from 'lodash/take'
import capitalize from 'lodash/capitalize'

import ScrollBar from 'components/Custom/ScrollBar'

import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'

import {
  useGetNotificationsQuery,
  useAcceptInviteMutation,
  useDeclineInviteMutation,
  useDeleteNotificationMutation,
} from 'services/notifications'

import { withAlerts } from 'contexts/alertsProvider'
import { withModals } from 'contexts/modalsProvider'
import ICONS from 'helpers/iconConstants'

const UUID_REGEX = /workspaces\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
const workspaceUUID = window.location.href.match(UUID_REGEX)?.[0]?.replace('workspaces/', '')

const InvitationRow = ({
  data,
  accept,
  decline,
}) => (
  <ListGroupItem
    className="list-group-item-action"
    onClick={e => e.preventDefault()}
  >
    <Row className="align-items-center flex-nowrap">
      <Col xs={10}>
        <h4 className="mb-0 text-sm">{data.username}</h4>
        <p className="text-sm mb-0">
          Wants you to join to <b>{data.project_name}</b> workspace
        </p>
      </Col>
      <Col xs={2} className="text-nowrap">
        <i className={`${ICONS.CHECK_CIRCLE} mr-2`} role="button" onClick={() => accept(data.uuid)} />
        <i className={ICONS.CANCEL_ICON} role="button" onClick={() => decline(data.uuid)} />
      </Col>
    </Row>
  </ListGroupItem>
)

const JobRow = ({
  data: {
    asset_type,
    asset_uuid,
    asset_name,
    status,
  },
  remove,
}) => {
  const history = useHistory()
  return (
    <ListGroupItem
      className="list-group-item-action"
      onClick={e => e.preventDefault()}
    >
      <Row className="align-items-center flex-nowrap">
        <Col xs={10} onClick={() => history.push(`/workspaces/${workspaceUUID}/${asset_type}s/${asset_uuid}#jobs`)} role="button">
          <h4 className="mb-0 text-sm">{asset_name}</h4>
          <p className="text-sm mb-0">
            {`${capitalize(asset_type)} is ${status}`}
          </p>
        </Col>
        <Col xs={2} className="text-nowrap">
          <i className={ICONS.TRASH} role="button" onClick={() => remove(asset_uuid, asset_type)} />
        </Col>
      </Row>
    </ListGroupItem>
  )
}

const Notifications = ({ alerts, modals }) => {
  const [viewAll, setViewAll] = useState(false)
  const { data: notifications = [] } = useGetNotificationsQuery()
  const [acceptInvite] = useAcceptInviteMutation()
  const [declineInvite] = useDeclineInviteMutation()
  const [deleteNotification] = useDeleteNotificationMutation()

  const listedNotifications = useMemo(() => {
    if (viewAll) return notifications
    return take(notifications, 5)
  }, [notifications, viewAll])

  const accept = async (uuid) => {
    try {
      const confirmed = await modals.confirm({
        message: 'Are you sure you want to accept this invite?',
      })
      if (!confirmed) return
      await acceptInvite(uuid).unwrap()
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  const decline = async (uuid) => {
    try {
      const confirmed = await modals.confirm({
        message: 'Are you sure you want to decline this invite?',
      })
      if (!confirmed) return
      await declineInvite(uuid).unwrap()
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  const remove = async (uuid, type) => {
    try {
      await deleteNotification({ uuid, type }).unwrap()
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  return (
    <UncontrolledDropdown nav>
      <DropdownToggle className="nav-link" color="neutral" tag="a">
        <i
          className={classnames(ICONS.ICON_BELL, {
            'text-default': !notifications.length,
            'text-danger': notifications.length,
          })}
          role="button"
          style={{ fontSize: '1.3rem' }}
        />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-xl py-0 _overflow-hidden" right>

        <div className="px-3 py-3">
          <h6 className="text-sm text-muted m-0">
            {notifications.length
              ? <>You have <strong className="text-info">{notifications.length}</strong> notifications.</>
              : 'No new notifications'}
          </h6>
        </div>

        {!!notifications.length &&
          <ScrollBar autoHeight autoHeightMax={viewAll ? '60vh' : 400}>
            <ListGroup flush>
              {listedNotifications.map((notification, index) => {
                const { type, data } = notification
                if (type === 'INVITATION') return (<InvitationRow data={data} key={index} accept={accept} decline={decline} />)
                if (type === 'JOB' || type === 'DATASET') return (<JobRow data={data} key={index} remove={remove} />)
                return <InvitationRow data={notification} key={index} accept={accept} decline={decline} />
              })}
              {notifications.length > 5 && !viewAll && (
                <ListGroupItem
                  className="list-group-item-action text-center text-info font-weight-bold py-2"
                  role="button"
                  onClick={() => setViewAll(true)}
                >
                  View all
                </ListGroupItem>
              )}
            </ListGroup>
          </ScrollBar>}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

InvitationRow.propTypes = {
  data: PropTypes.shape({
    username: PropTypes.string,
    uuid: PropTypes.string,
    project_name: PropTypes.string,
  }),
  accept: PropTypes.func,
  decline: PropTypes.func,
}

JobRow.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.string,
    asset_type: PropTypes.string,
    asset_uuid: PropTypes.string,
    asset_name: PropTypes.string,
  }),
  remove: PropTypes.func,
}

Notifications.propTypes = {
  modals: PropTypes.shape({
    confirm: PropTypes.func,
  }),
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
}

export default compose(withModals, withAlerts)(Notifications)
