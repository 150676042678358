import React from 'react'
import { Redirect } from 'react-router-dom'
import LoadingSpinner from 'components/Shared/LoadingSpinner'

import { useGetAccountQuery } from 'services/user'

const WorkspaceRedirect = () => {
  const { data: account = {}, isLoading } = useGetAccountQuery()

  if (isLoading || !account.workspace) {
    return <LoadingSpinner />
  }

  return (
    <Redirect to={{ pathname: `/workspaces/${account.workspace}/home` }} />
  )
}

export default WorkspaceRedirect
