import React, { useEffect, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Col,
  Row,
} from 'reactstrap'

import GlossaryTree from 'components/Catalog/GlossaryPage/GlossaryTree'
import Content from 'components/Catalog/GlossaryPage/Content'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { useGetGlossaryQuery } from 'services/catalogs'

const GlossaryPage = () => {
  const history = useHistory()
  const { workspaceUUID, uuid, section } = useParams()
  const { data = [], isLoading, isError } = useGetGlossaryQuery(workspaceUUID)

  useEffect(() => {
    if (isLoading || !data.length) return
    if (!uuid)
      history.push(`/workspaces/${workspaceUUID}/catalog/${section}/${data[0].hash}`)
  }, [isLoading, uuid])

  const formattedData = useMemo(() => {
    if (isLoading) return {}
    const hashTable = {}
    data.forEach((aData) => {
      if (!hashTable[aData.name]) hashTable[aData.name] = []
      hashTable[aData.name].push(aData)
    })
    return hashTable
  }, [isLoading, uuid])

  const glossary = useMemo(() => {
    if (isLoading || !uuid) return {}
    return data.find(d => d.hash === uuid)
  }, [isLoading, uuid, data])

  if (isError)
    return (
      <Row className="h-100">
        <Col className="d-flex flex-column justify-content-center align-items-center h3 text-warning">
          No graph here yet, connect sources first
        </Col>
      </Row>
    )

  return (
    <Row className="h-100">
      <Col xs={2} className="py-2 ml-3 mt-3 mb-3 bg-white border">
        {isLoading ? <Spinner /> : <GlossaryTree data={formattedData} />}
      </Col>

      <Col className="d-flex flex-column">
        {isLoading ? <Spinner /> : <Content glossary={glossary} />}
      </Col>
    </Row>
  )
}

export default GlossaryPage
