import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import partition from 'lodash/partition'
import {
  Row,
  Col,
  Button,
} from 'reactstrap'

import ScrollBar from 'components/Custom/ScrollBar'
import ModalContainer from 'components/Custom/ModalContainer'
import TableBodyWrapper, { EmptyContentWithText } from 'components/Main/ModelViewPage/ModelStructure/TableBodyWrapper'
import { SmallButton } from 'components/Custom/Buttons'

import { useModalContext } from 'contexts/modalProvider'

const SCROLL_HEIGHT = '350px'

const ModelFeaturesModal = ({
  variables,
  predictorNames,
  setData,
}) => {
  const { hideModal } = useModalContext()
  const [selectedFeatures, setSelectedFeatures] = useState(variables.filter(({ name }) => predictorNames.includes(name)))

  const [features, availableVariables] = useMemo(() => {
    const featuresNames = selectedFeatures.map(el => el.name)
    return partition(variables, el => featuresNames.includes(el.name))
  }, [selectedFeatures])

  const addFeature = el => setSelectedFeatures(selectedFeatures.concat(el))
  const removeFeature = el => setSelectedFeatures(selectedFeatures.filter(({ name }) => name !== el.name))
  const selectAll = () => setSelectedFeatures(features.concat(availableVariables))
  const unSelectAll = () => setSelectedFeatures([])

  const selectFeaturesHandler = () => {
    setData(state => ({ ...state, predictors_list: selectedFeatures }))
    hideModal()
  }

  return (
    <ModalContainer
      title="Select Features"
      isOpen={true}
      toggle={hideModal}
      size="lg"
    >
      <Row>
        <Col xs={6}>
          <div className="d-flex justify-content-between align-items-center">
            <span className="text-uppercase font-weight-bold">Variables</span>
            <SelectBtn text="Select All" disabled={isEmpty(availableVariables)} onClick={selectAll} />
          </div>
          <ScrollBar style={{ height: SCROLL_HEIGHT }} className="border mt-2">
            {isEmpty(availableVariables)
              ? <EmptyContentWithText text="No variables" />
              : <TableBodyWrapper>
                {availableVariables.map(el => (
                  <tr className="border-bottom" key={el.name} onClick={() => addFeature(el)} role="button">
                    <td className="align-middle border-right text-truncate">{el.name}</td>
                    <td className="align-middle text-truncate">{el.type_main}</td>
                  </tr>))}
              </TableBodyWrapper>}
          </ScrollBar>
        </Col>
        <Col xs={6}>
          <div className="d-flex justify-content-between align-items-center">
            <span className="text-uppercase font-weight-bold">Features</span>
            <SelectBtn text="Unselect all" disabled={isEmpty(selectedFeatures)} onClick={unSelectAll} />
          </div>
          <ScrollBar style={{ height: SCROLL_HEIGHT }} className="border mt-2">
            {isEmpty(features)
              ? <EmptyContentWithText text="No features selected" />
              : <TableBodyWrapper>
                {features.map(feature => (
                  <tr className="border-bottom" key={feature.name} onClick={() => removeFeature(feature)} role="button">
                    <td className="align-middle border-right text-truncate">{feature.name}</td>
                    <td className="align-middle text-truncate">{feature.type_main}</td>
                  </tr>
                ))}
              </TableBodyWrapper>}
          </ScrollBar>
        </Col>
        <Col xs={12} className="text-right my-1 pt-3">
          <Button outline color="info" onClick={selectFeaturesHandler}>
            Done
          </Button>
        </Col>
      </Row>
    </ModalContainer>
  )
}

const SelectBtn = ({ text, onClick, disabled }) => (
  <SmallButton onClick={onClick} outline disabled={disabled}>{text}</SmallButton>
)

SelectBtn.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
}

ModelFeaturesModal.propTypes = {
  variables: PropTypes.arrayOf(PropTypes.shape({})),
  predictorNames: PropTypes.arrayOf(PropTypes.string),
  setData: PropTypes.func,
}

export default ModelFeaturesModal
