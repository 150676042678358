import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import lodashKeys from 'lodash/keys'
import take from 'lodash/take'
import isEmpty from 'lodash/isEmpty'
import {
  Collapse,
  ButtonGroup,
} from 'reactstrap'

import StickyTable from 'components/Catalog/Shared/StickyTable'
import { ActionButton } from 'components/Catalog/Shared/Buttons'
import ICONS from 'helpers/iconConstants'

const Data = ({ data: { data } = {} }) => {
  const [isCollapsed, setCollapsed] = useState(true)

  const { header, body } = useMemo(() => {
    const tableData = data || []

    return {
      body: take(tableData, 20),
      header: lodashKeys(tableData[0]),
    }
  }, [data])

  const toggle = () => setCollapsed(!isCollapsed)

  if (!data) return null

  if (isEmpty(data)) return (
    <div className="border mt-2 bg-white p-3 rounded">
      The query has not returned any data
    </div>
  )

  if (typeof data === 'string') return (
    <div className="border mt-2 bg-white p-3 rounded">
      {data}
    </div>
  )

  return (
    <div className="border mt-2 bg-white p-3 rounded">
      <div className="d-flex justify-content-between align-items-center">
        <span className="flex-grow-1">Data:</span>
        <ButtonGroup className="ml-3">
          <ActionButton
            label={<i className={isCollapsed ? ICONS.ANGLE_UP : ICONS.ANGLE_DOWN} />}
            onClick={toggle}
            className="ha-info-icon"
          />
        </ButtonGroup>
      </div>

      <Collapse isOpen={isCollapsed}>
        <div className="pt-3">
          <StickyTable header={header} body={body} maxHeight="40rem" />
        </div>
      </Collapse>
    </div>
  )
}

Data.propTypes = {
  data: PropTypes.object,
}

export default Data
