import React from 'react'
import { useField, useFormikContext } from 'formik'
import {
  Input,
  Label,
  Badge,
} from 'reactstrap'

const SourceNameSection = () => {
  const [field, { error, touched }] = useField('sourceName')
  const { submitCount } = useFormikContext()

  return (
    <Label className="w-100 my-4">
      <span className="font-weight-bold">Connector name</span>

      <Badge
        className="text-capitalize"
        style={{ transform: 'translateY(-0.35rem)' }}
      >
        Required
      </Badge>

      <Input
        {...field}
        autoComplete="off"
        className="mt-2"
        invalid={!!(error && (touched || submitCount))}
      />
    </Label>
  )
}

export default SourceNameSection
