import { createReducer } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'

const initialState = {}

export const loadingState = createSelector(
  state => state.isLoading,
  isLoading => !isEmpty(isLoading),
)

const isPendingAction = action => action.type.endsWith('/pending')
const isWebsocketAction = action => action.type.startsWith('WEBSOCKET')
const isDoneAction = action => (action.type.endsWith('/fulfilled') || action.type.endsWith('/rejected'))

export default createReducer(initialState, (builder) => {
  builder
    .addMatcher(isPendingAction, (state, action) => {
      state[action.meta.requestId] = 'pending'
    })
    .addMatcher(isDoneAction, (state, action) => {
      delete state[action.meta.requestId]
    })
    .addMatcher(isWebsocketAction, state => state)
    .addDefaultCase(() => initialState)
})

