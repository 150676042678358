import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Table } from 'reactstrap'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'
import { MODEL_TYPES } from 'helpers/constants'

const StyledRow = styled.tr`
  td { font-size: 1rem; }
`

const TableRow = ({ name, text }) => (
  <StyledRow>
    <td>
      <strong>{name}:</strong>
    </td>
    <td className="text-wrap">{text}</td>
  </StyledRow>
)

const FourthStep = ({ values, isLoading }) => {
  if (isLoading) return (
    <div className="d-flex align-items-center h-100">
      <Spinner />
    </div>
  )

  return (
    <Table size="sm" borderless className="mt-3" style={{ tableLayout: 'fixed' }}>
      <colgroup>
        <col width={'200px'} style={{ minWidth: '190px' }} />
        <col />
      </colgroup>
      <tbody>
        <TableRow name="Model name" text={values.name} />
        <TableRow name="Model type" text={values.modelType === MODEL_TYPES.TIME_SERIES_MODEL ? 'Divian Timeseries' : 'Linear Regression'} />
        <TableRow name="Selected dataset" text={values.datasetName} />
        <TableRow name="Target variable" text={values.targetVariable} />
      </tbody>
    </Table>
  )
}

TableRow.propTypes = {
  name: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

FourthStep.propTypes = {
  isLoading: PropTypes.bool,
  values: PropTypes.shape({
    datasetName: PropTypes.string,
    targetVariable: PropTypes.string,
    modelType: PropTypes.string,
    name: PropTypes.string,
  }),
}

export default FourthStep
