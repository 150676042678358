import React from 'react'
import { useParams, Link } from 'react-router-dom'
import {
  Col,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'

import ScrollBar from 'components/Custom/ScrollBar'
import NavigationTabs from 'components/Catalog/AssetPageView/NavigationTabs'
import OverviewTab from 'components/Catalog/AssetPageView/OverviewTab'
import DataTab from 'components/Catalog/AssetPageView/DataTab'
import SchemaTab from 'components/Catalog/AssetPageView/SchemaTab'
import StatsTab from 'components/Catalog/AssetPageView/StatsTab'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { useGetCatalogQuery } from 'services/catalogs'
import { useActiveTab } from 'hooks/useActiveTab'
import { useQuery } from 'hooks/useQuery'

import ICONS from 'helpers/iconConstants'
import { removeYes } from 'helpers/utils'

const AssetPageView = () => {
  const activeTab = useActiveTab('overview')
  const { workspaceUUID, uuid } = useParams()
  const { getParam } = useQuery()
  const { data = {}, isLoading, isError } = useGetCatalogQuery({
    uuid,
    db_name: getParam('db_name'),
    name: getParam('name'),
    schema: getParam('schema'),
    workspaceUUID,
  })

  if (isError) return (
    <div>OOPS! Something is going wrong!! Please try to reload the page!</div>
  )

  return (
    <Row className="h-100">
      <Col xs={12} className="d-flex flex-column">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Row className="bg-white">
              <Col className="p-0">
                <div className="px-4 py-3">
                  <h2>
                    <small>
                      <Link to={`/workspaces/${workspaceUUID}/catalog/assets`}><i className={`${ICONS.ARROW_LEFT} mr-2 text-info`} /></Link>
                    </small>
                    {data.graph_meta.name}
                  </h2>

                  <Row>
                    <Col xs="auto" className="text-uppercase text-muted small">
                      <i className={`${ICONS.TABLE} mr-1`} />
                      {data.graph_meta.type}
                    </Col>
                    <Col xs="auto" className="text-uppercase text-muted small">
                      <i className={`${ICONS.DATABASE} mr-1`} />
                      {data.graph_meta.db_name}
                    </Col>
                    <Col xs="auto" className="text-uppercase text-muted small">
                      <i className={`${ICONS.SITEMAP} mr-1`} />
                      {removeYes(data.graph_meta.schema)}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row className="bg-white">
              <Col className="p-0">
                <NavigationTabs />
              </Col>
            </Row>

            <Row className="flex-grow-1">
              <Col className="px-0">
                <ScrollBar>
                  <TabContent activeTab={activeTab} className="pt-3 h-100 d-flex flex-column">
                    <TabPane tabId="overview" className="flex-grow-1">
                      <OverviewTab data={data} />
                    </TabPane>
                    <TabPane tabId="data" className="flex-grow-1">
                      <DataTab data={data} />
                    </TabPane>
                    <TabPane tabId="schema" className="flex-grow-1">
                      <SchemaTab data={data} />
                    </TabPane>
                    <TabPane tabId="stats" className="flex-grow-1">
                      <StatsTab data={data} />
                    </TabPane>
                  </TabContent>
                </ScrollBar>
              </Col>
            </Row>
          </>
        )}
      </Col>

      {/* <Col xs={2} className="bg-white border-right">
        <div className="h-100 d-flex align-items-center justify-content-center">
          TODO: \_(O_=)_/
        </div>
      </Col> */}
    </Row>
  )
}

export default AssetPageView
