import React from 'react'
import PropTypes from 'prop-types'
import { ModalBody, Modal } from 'reactstrap'

const SIZE = {
  bg: 'big-screen-modal',
}

const ModalContainer = ({
  isOpen,
  title,
  toggle,
  customSize,
  children,
  ...rest
}) => (
  <Modal
    isOpen={isOpen}
    centered
    toggle={toggle}
    className={SIZE[customSize]}
    {...rest}
  >
    {title ? (
      <div className="modal-header border-bottom p-3">
        <h2 className="w-100 text-uppercase text-center mb-0">
          {title}
          <button
            type="button"
            className="close position-absolute right-4"
            aria-hidden={true}
            onClick={toggle}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </h2>
      </div>
    ) : (
      <div className="modal-header px-3 pt-3 pb-0">
        <button
          type="button"
          className="close position-absolute right-2 top-1"
          aria-hidden={true}
          onClick={toggle}
          style={{ zIndex: 1 }}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
    )}
    <ModalBody className="container-fluid d-flex flex-column p-3">
      {children}
    </ModalBody>
  </Modal>
)

ModalContainer.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  customSize: PropTypes.string,
  children: PropTypes.node,
}

export default ModalContainer
