import divianApi from 'services/api'

const TYPES = {
  NOTIFICATIONS: 'NOTIFICATIONS',
  JOBS: 'JOBS',
  HISTORY: 'HISTORY',
  NOTES: 'NOTES',
  REASONING: 'REASONING',
}

const HISTORY_MAPPINGS = {
  DATASET: 'getDatasetHistory',
}

// const JOBS_MAPPINGS = {
//   DATASET: 'getDatasetJobs',
// }

/*
const ACTION_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
}
*/

/*
MESSAGE FORMAT:
{
  "type": "NOTIFICATIONS",
  "action": "CREATE",
  "payload": {
    "data": {
        "username": "Alexandr Romanovich",
        "uuid": "df982e86-2915-11ed-ab05-ebb50b014c1d",
        "project_name": "New Workspace"
    },
    "type": "INVITATION"
  }
}*/

export default class SocketMessageHandler {
  constructor(message, dispatch) {
    this.message = message
    this.dispatch = dispatch
  }

  process() {
    const { type, payload } = this.message
    switch (type) {
    case TYPES.NOTIFICATIONS:
      //{"data":{"type":"NOTIFICATIONS","action":"CREATE","payload":{"type":"DATASET","data":{"asset_name":"test2","asset_type":"dataset","asset_uuid":"6c0941ae-6785-11ed-84e7-0a58a9feac02","completed":1668806902,"status":"updated"}}}}
      this.dispatch(divianApi.util.updateQueryData('getNotifications', undefined, (draft) => {
        draft.unshift(payload)
      }))
      break
    case TYPES.HISTORY:
      this.dispatch(divianApi.util.updateQueryData(HISTORY_MAPPINGS[payload.type], payload.uuid, (draft) => {
        const item = draft.find(it => it.uuid === payload.data.uuid)
        if (item) {
          Object.assign(item, payload.data)
        } else {
          const currentVersion = draft.find(it => it.current)
          Object.assign(currentVersion, { current: false })
          draft.unshift(payload.data)
        }
      }))
      break
    case TYPES.JOBS:
      // TODO: REMOVE THIS AFTER UPDATE
      // this.dispatch(divianApi.util.updateQueryData(JOBS_MAPPINGS[payload.type], payload.uuid, (draft) => {
      //   const job = draft.find(job => job.uuid === payload.data.uuid)
      //   job ? Object.assign(job, payload.data) : draft.unshift(payload.data)
      // }))
      if (payload.update_ui) this.dispatch(divianApi.endpoints.refetchDataset.initiate())
      break
    case TYPES.NOTES:
      this.dispatch(divianApi.util.updateQueryData('getAssetDocuments', { uuid: payload.uuid, type: `${payload.type.toLowerCase()}s` }, (draft) => {
        draft.unshift(payload.data)
      }))
      break
    case TYPES.REASONING:
      if (payload.update_ui) this.dispatch(divianApi.endpoints.refetchReason.initiate())
      break
    default:
      console.error(`Unknown type ${type}`)
    }
  }
}
