import React, { useState } from 'react'

import ModalContainer from 'components/Custom/ModalContainer'
import EditExtraction from 'components/Main/ContainerViewPage/MergeActions/EditExtraction'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { useGetContainerQuery } from 'services/containers'

import { useModalContext } from 'contexts/modalProvider'

const EditExtractionModal = () => {
  const { hideModal, store: { modalProps } } = useModalContext()
  const { datasetUUID } = modalProps

  const [values, setValues] = useState({
    query: '',
    selectedKeys: [],
    job: null,
  })

  const { data: container = {}, isLoading } = useGetContainerQuery({ uuid: datasetUUID })

  return (
    <ModalContainer
      title="Edit Data Extraction"
      isOpen={true}
      toggle={hideModal}
      customSize="bg"
    >
      {isLoading ? (
        <div className="flex-grow-1 d-flex align-items-center">
          <Spinner />
        </div>
      ) : (
        <EditExtraction
          values={values}
          setValues={setValues}
          container={container}
          onClose={hideModal}
          showCloseButton={false}
        />
      )}
    </ModalContainer>
  )
}

export default EditExtractionModal
