import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Row,
  Col,
  Table,
} from 'reactstrap'

import SlidePanel from 'components/Shared/SlidePanel'
import SimpleTextRow from 'components/Shared/SummaryTab/SimpleTextRow'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'
import { TextButton } from 'components/Catalog/Shared/Buttons'

import {
  useDeleteWorkspaceCatalogMutation,
  useUpdateWorkspaceMutation,
} from 'services/workspaces'

import ICONS from 'helpers/iconConstants'
import { SOURCE_TYPES } from 'helpers/constants'

const AssetConfigSlidePanel = ({
  isOpen,
  setOpen,
  data,
}) => {
  const { workspaceUUID } = useParams()

  const [deleteCatalog, { isLoading: isDeleting }] = useDeleteWorkspaceCatalogMutation(workspaceUUID)
  const [updateWorkspace, { isLoading: isUpdating }] = useUpdateWorkspaceMutation()

  const deleteCatalogHandler = async () => {
    try {
      if (data.source_type === SOURCE_TYPES.GOOGLE_DRIVE) await updateWorkspace({ workspaceUUID, google_drive: false }).unwrap()
      if (data.source_type === SOURCE_TYPES.SNOWFLAKE) await deleteCatalog(workspaceUUID).unwrap()
    } catch (err) {
      console.error(err.message)
    } finally { setOpen(false) }
  }

  return (
    <SlidePanel
      isOpen={isOpen}
      onRequestClose={() => setOpen(false)}
      title=""
    >
      <Card className="rounded-0 shadow-none">
        <CardHeader>
          <Row className="align-items-center">
            <Col>
              <h5 className="h3 mb-0"><i className={`${ICONS.SERVER} text-info mr-2`}/>Connection parameters</h5>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          <Table size="sm" style={{ tableLayout: 'fixed' }} borderless>
            <colgroup>
              <col width={'140px'} style={{ minWidth: '140px' }} />
              <col />
            </colgroup>
            <tbody>
              {data.source_type === SOURCE_TYPES.SNOWFLAKE && (
                <>
                  <SimpleTextRow label="Username:" text="Divian" />
                  <SimpleTextRow label="Password:" text="********" />
                </>)}

              {data.source_type === SOURCE_TYPES.GOOGLE_DRIVE && (
                <SimpleTextRow label="Folder name:" text={data.folder_name} />)}
            </tbody>
          </Table>
        </CardBody>

        <CardFooter className="text-right">
          {isUpdating || isDeleting ? (
            <Col xs="2">
              <Spinner />
            </Col>
          ) : (
            <TextButton
              onClick={deleteCatalogHandler}
              label="Delete"
              disabled={isUpdating || isDeleting}
            />
          )}
        </CardFooter>
      </Card>
    </SlidePanel>
  )
}

AssetConfigSlidePanel.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.shape({
    folder_name: PropTypes.string,
    source_type: PropTypes.string,
  }),
}

export default AssetConfigSlidePanel
