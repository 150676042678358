import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import {
  Row,
  Col,
  Input,
  ButtonGroup,
} from 'reactstrap'

import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'
import { ActionButton } from 'components/Catalog/Shared/Buttons'

import { useUpdateGlossaryMutation } from 'services/catalogs'
import { withAlerts } from 'contexts/alertsProvider'

import ICONS from 'helpers/iconConstants'

const DescriptionBlock = ({ alerts, hash, description }) => {
  const { workspaceUUID } = useParams()
  const [value, setValue] = useState(description)
  const [editMode, setEditMode] = useState(false)

  const [updateGlossary, { isLoading: isUpdating }] = useUpdateGlossaryMutation()

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') cancelEdit()
  }, [])

  const cancelEdit = () => {
    setEditMode(false)
    setValue(description)
  }

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [])

  const updateHandler = async () => {
    if (value === description) return
    try {
      await updateGlossary({ hash, workspaceUUID, description: value }).unwrap()
      setEditMode(false)
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  return (
    <Col xs={12}>
      <Row className="align-items-center">
        <Col>
          <span className="d-block small">Description</span>
        </Col>
        <Col xs="auto">
          <ButtonGroup>
            {editMode ? (
              <>
                <ActionButton label={<i className={ICONS.SAVE_ICON} />} onClick={updateHandler} />
                <ActionButton label={<i className={ICONS.CANCEL_ICON} />} onClick={cancelEdit} />
              </>
            ) : (
              <ActionButton label={<i className={ICONS.PENCIL_SQUARE} />} onClick={() => setEditMode(true)} />
            )}
          </ButtonGroup>
        </Col>
      </Row>

      {isUpdating ? (
        <Spinner />
      ) : (
        <Row>
          <Col>
            {editMode ? (
              <Input
                autoComplete="off"
                bsSize="sm"
                className="text-monospace mt-2"
                type="textarea"
                value={value}
                onChange={e => setValue(e.target.value)}
                rows="3"
                autoFocus
              />
            ) : (
              <p className="small text-break">
                {description?.length ? description : 'No description yet'}
              </p>
            )}
          </Col>
        </Row>
      )}
    </Col>
  )
}

DescriptionBlock.propTypes = {
  description: PropTypes.string,
  hash: PropTypes.string,
  alerts: PropTypes.shape({
    error: PropTypes.func.isRequired,
  }),
}

export default withAlerts(DescriptionBlock)
