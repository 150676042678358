import React, {
  useRef,
  useMemo,
  useState,
  useContext,
  createContext,
} from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap'

const ModalsContext = createContext({})

const useModals = () => {
  const { confirm, error } = useContext(ModalsContext)
  return { confirm, error }
}

export const withModals = Comp => (props) => {
  const modals = useModals()
  return <Comp {...props} modals={modals} />
}

const ModalsProvider = ({ children }) => {
  const resolveConfirmation = useRef(null)
  const [confirmation, setConfirmation] = useState(null)

  const confirm = config => new Promise((resolve) => {
    setConfirmation(config)
    resolveConfirmation.current = resolve
  })

  const cancel = () => {
    resolveConfirmation.current?.(false)
    resolveConfirmation.current = null
    setConfirmation(null)
  }

  const ok = () => {
    resolveConfirmation.current?.(true)
    resolveConfirmation.current = null
    setConfirmation(null)
  }

  const error = config => setConfirmation(config)

  return (
    <ModalsContext.Provider
      value={{
        confirm: useMemo(() => confirm, []),
        error: useMemo(() => error, []),
      }}>
      {children}
      <Modal
        isOpen={!!confirmation}
        toggle={cancel}
      >
        <ModalBody className="text-center pb-2">
          <p>{confirmation?.message}</p>
          {confirmation?.description && <p>{confirmation?.description}</p>}
        </ModalBody>
        <ModalFooter className="pt-0">
          {resolveConfirmation.current && (
            <Button outline size="sm" color="info" onClick={ok}>
              {confirmation?.cancelButton || 'Yes'}
            </Button>)}
          <Button outline size="sm" color="info" onClick={cancel}>
            {confirmation?.okButton || 'No'}
          </Button>
        </ModalFooter>
      </Modal>
    </ModalsContext.Provider>
  )
}

ModalsProvider.propTypes = {
  children: PropTypes.node,
}

export default ModalsProvider
