import WebSocket from 'isomorphic-ws'
import { createAction } from '@reduxjs/toolkit'
import { getSessionUser } from 'helpers/cognito'
import SocketMessageHandler from 'helpers/socketMessageHandler'
const { REACT_APP_WEB_SOCKET_URL } = process.env

let interval
let token
let websocket = {}

getSessionUser().then((res) => {
  token = res?.accessToken?.jwtToken
  websocket = new WebSocket(`${REACT_APP_WEB_SOCKET_URL}?token=${token}`)
})

const websocketOpenAction = createAction('WEBSOCKET:OPEN')
const websocketCloseAction = createAction('WEBSOCKET:CLOSE')

const socketMiddleware = ({ dispatch }) => next => (action) => {
  // Attach the callbacks
  websocket.onopen = () => dispatch(websocketOpenAction())
  websocket.onclose = (err) => {
    console.log('Socket is closed. Reconnect will be attempted in 1 second.', err.reason)
    dispatch(websocketCloseAction())
  }
  websocket.onmessage = (event) => {
    const { data, type } = event
    if (type === 'message' && data) {
      const { data: message } = JSON.parse(data)
      new SocketMessageHandler(message, dispatch).process()
    }
  }
  websocket.onerror = (err) => {
    console.error('Socket encountered error: ', err.message, 'Closing socket')
    websocket.close()
  }

  switch (action.type) {
  // User request to send a message
  case websocketOpenAction.type:
    interval = setInterval(() => {
      websocket.send(JSON.stringify({ action: 'ping' }))
    }, 9 * 60_000)
    break

  // User request to disconnect
  case websocketCloseAction.type:
    clearInterval(interval)
    websocket = new WebSocket(`${REACT_APP_WEB_SOCKET_URL}?token=${token}`)
    break

  default:
    break
  }

  return next(action)
}

export default socketMiddleware
