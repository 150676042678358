import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { formatDateAndTime } from 'helpers/utils'
import {
  Col,
  Row,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'

import { MessageSpinner as Typing } from 'components/Custom/CustomSpinners'

import ICONS from 'helpers/iconConstants'

const StyledItem = styled(ListGroupItem)`
  &:hover {
    border-color: var(--info) !important;
  }
`

export const ListItem = ({ data, onClick }) => (
  <StyledItem className="mb-3 border rounded" role="button" onClick={onClick}>
    <Row className="align-items-center">
      <Col style={{ fontSize: '0.85rem' }}>
        <i className={`${ICONS.MESSAGE} mr-2 text-info`} />
        <span className="font-weight-bold">{data.input}</span>
      </Col>
    </Row>

    <Row className="align-items-center mt-2">
      <Col className="col-auto" />
      <Col style={{ fontSize: '0.85rem' }}>
        <i className={`${ICONS.REPLY} mr-2 text-info`} style={{ transform: 'rotate(-180deg)' }} />
        {data.status === 'in_progress' ? (
          <div className="d-inline-block">
            <Typing />
          </div>
        ) : (
          <span className="font-weight-bold">{data.output}</span>
        )}
      </Col>
    </Row>

    <Row>
      <Col className="small mt-2">
        <ListGroup horizontal className="justify-content-end">
          <ListGroupItem className="border-0 px-0 pb-0 pt-1 text-muted">
            {formatDateAndTime(data.created)}
          </ListGroupItem>
        </ListGroup>
      </Col>
    </Row>
  </StyledItem>
)

ListItem.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.shape({
    input: PropTypes.string,
    output: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    created: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    data: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  }),
}

export default ListItem
