/* eslint-disable quotes */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import take from 'lodash/take'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import { Row, Col, Table } from 'reactstrap'

import ScrollBar from 'components/Custom/ScrollBar'
import ColumnsTable from 'components/Main/DatasetViewPage/CreateNewModal/Tables/ColumnsTable'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { shouldReduceIndex } from 'helpers/datasets'

const HeadersPreview = ({
  values,
  setValues,
  header,
  isLoading,
  selectAll,
  noSourceMessage,
}) => {
  const fields = useMemo(() => {
    if (!header?.schema?.fields) return []
    const { fields: dataFields } = header.schema
    const selected = [...Array(dataFields.length).keys()]
    if (shouldReduceIndex(dataFields)) selected.shift()
    if (values.showQuery) setValues(state => ({ ...state, selectedKeys: selected }))
    return dataFields
  }, [header, values.showQuery])

  const { thead, tbody } = useMemo(() => {
    let thead = []
    const tbody = []
    if (!values.selectedKeys.length || !header) return { thead, tbody }
    thead = fields.filter((_, i) => values.selectedKeys.includes(i))
    const selectedKeyNames = thead.map(f => f.name)
    take(header.data, 100).forEach(row => tbody.push(pick(row, selectedKeyNames)))
    setValues(state => ({ ...state, selectedKeyNames }))
    return { thead, tbody }
  }, [header, values.selectedKeys])

  if (isLoading) return (
    <div className="d-flex position-absolute top-0 right-0 bottom-0 left-0 align-items-center py-3">
      <Spinner />
    </div>
  )

  if (!fields.length) return (
    <Row className="align-items-center flex-grow-1">
      <Col sm={12} className="text-center py-3 text-muted">
        {noSourceMessage}
      </Col>
    </Row>
  )

  return (
    <Row className="flex-grow-1">
      {!values.showQuery && (
        <Col lg={4}>
          <ColumnsTable fields={fields} values={values} setValues={setValues} selectAll={selectAll} />
        </Col>
      )}
      <Col lg={values.showQuery ? 12 : 8}>
        <Row className="h-100">
          <Col className="d-flex flex-column">
            <span className="py-2 text-uppercase font-weight-bold">Preview data</span>
            <div className="border flex-grow-1">
              <ScrollBar>
                {isEmpty(thead) && isEmpty(tbody) ? (
                  <div className="d-flex align-items-center justify-content-center h-100 text-muted px-4 text-center">
                    No data here yet, select columns on the left or switch to the query mode
                  </div>
                ) : (
                  <Table hover size="sm">
                    <thead style={{ backgroundColor: '#fafafa' }}>
                      <tr>
                        {thead.map(({ name }) => {
                          const fieldIndex = fields.findIndex(f => f.name === name)
                          return (
                            <td key={name} className="border-top-0 border-right">
                              <div
                                className="d-flex flex-nowrap text-uppercase font-weight-bold justify-content-between text-default"
                                style={{ letterSpacing: '0.02rem', fontSize: '0.9rem', lineHeight: '1rem' }}
                              >
                                {name}
                                <span
                                  aria-hidden={true}
                                  className="ml-2"
                                  onClick={() => setValues(state => ({ ...state, selectedKeys: state.selectedKeys.filter(i => i !== fieldIndex) }))}
                                  role="button"
                                >×</span>
                              </div>
                            </td>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {tbody.map((row, index) => (
                        <tr key={index} className="border-bottom">
                          {Object.values(row).map((col, i) => <td key={i} className="border-right">{col}</td>)}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </ScrollBar>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

HeadersPreview.defaultProps = {
  selectAll: false,
  noSourceMessage: <span className="text-monospace text-muted" style={{ fontSize: '1.3rem' }}>Empty source</span>,
}

HeadersPreview.propTypes = {
  values: PropTypes.shape({
    selectedKeys: PropTypes.array,
    sourceUUID: PropTypes.string,
    showQuery: PropTypes.bool,
  }),
  header: PropTypes.shape({
    schema: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  setValues: PropTypes.func,
  handleItemSelection: PropTypes.func,
  isLoading: PropTypes.bool,
  selectAll: PropTypes.bool,
  noSourceMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

export default HeadersPreview
