import 'cross-fetch/polyfill'
import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js'

const { REACT_APP_COGNITO_USER_POOL_ID, REACT_APP_COGNITO_USER_CLIENT_ID } = process.env

const poolData = {
  UserPoolId: REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: REACT_APP_COGNITO_USER_CLIENT_ID,
}

const userPool = new CognitoUserPool(poolData)

const fixUserName = name => name?.toLowerCase()?.trim()

export const logOut = () => {
  const cognitoUser = userPool.getCurrentUser()
  cognitoUser?.signOut()
}

export const confirmUser = async (username, code) => {
  const userData = {
    Username: fixUserName(username),
    Pool: userPool,
  }

  const cognitoUser = new CognitoUser(userData)
  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) reject(err)
      resolve(result)
    })
  })
}

export const resendConfirmationCode = (username) => {
  const userData = {
    Username: fixUserName(username),
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)
  return new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) reject(err)
      resolve(result)
    })
  })
}

export const registerUser = ({
  email,
  password,
  // TODO: COMMENTING THIS FOR NOW
  // firstName,
  // lastName,
}) => {
  const attributes = []
  const emailAttr = { Name: 'email', Value: fixUserName(email) }
  //const firstNameAttr = { Name: 'custom:first_name', Value: firstName }
  //const lastNameAttr = { Name: 'custom:last_name', Value: lastName }

  const attributeEmail = new CognitoUserAttribute(emailAttr)
  //const attributeFirstName = new CognitoUserAttribute(firstNameAttr)
  //const attributeLastName = new CognitoUserAttribute(lastNameAttr)
  attributes.push(attributeEmail)
  //attributes.push(attributeFirstName)
  //attributes.push(attributeLastName)

  return new Promise((resolve, reject) => {
    userPool.signUp(fixUserName(email), password, attributes, null, (err, result) => {
      if (err) reject(err)
      resolve(result)
    })
  })
}

export const authenticateUser = async (username, password) => {
  const authenticationData = { Username: fixUserName(username), Password: password }
  const authenticationDetails = new AuthenticationDetails(authenticationData)
  const userData = { Username: fixUserName(username), Pool: userPool }
  const cognitoUser = new CognitoUser(userData)

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: resolve,
      onFailure: reject,
    })
  })
}

export const getSessionUser = () => {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    if (cognitoUser === null) {
      logOut()
      reject()
    } else {
      cognitoUser.getSession((err, session) => {
        if (err) reject(err)
        if (!session?.isValid()) {
          logOut()
          reject()
        }
        resolve(session)
      })
    }
  })
}

export const changePassword = (username, oldPassword, newPassword) => {
  const authenticationData = { Username: fixUserName(username), Password: oldPassword }
  const authenticationDetails = new AuthenticationDetails(authenticationData)
  const userData = { Username: fixUserName(username), Pool: userPool }
  const cognitoUser = new CognitoUser(userData)

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        cognitoUser.changePassword(oldPassword, newPassword, (err, res) => {
          if (err) reject(err)
          resolve(res)
        })
      },
      onFailure: reject,
    })
  })
}

export const sendForgotPasswordCode = (username) => {
  const userData = { Username: fixUserName(username), Pool: userPool }
  const cognitoUser = new CognitoUser(userData)

  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: resolve,
      onFailure: reject,
    })
  })
}

export const confirmNewPassword = (username, code, newPassword) => {
  const userData = { Username: fixUserName(username), Pool: userPool }
  const cognitoUser = new CognitoUser(userData)
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess: resolve,
      onFailure: reject,
    })
  })
}

export const getAttributes = async () => {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getUserAttributes((err, attributes) => {
      if (err) reject(err)
      resolve(attributes)
    })
  })
}
