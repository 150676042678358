import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import ICONS from 'helpers/iconConstants'

const CustomSpinner = ({ height, width }) => (
  <div className="w-100 align-items-center h-100 d-flex justify-content-center">
    <Spinner color="primary" style={{ height, width }}>
      Loading...
    </Spinner>
  </div>
)

const TypingSpinner = ({ fontSize, count = 3 }) => (
  <div className="d-flex align-items-center">
    {[...Array(count)]
      .map((_,i) => (
        <i
          key={i}
          className={`${ICONS.DOT} typing-animation mr-1`}
          style={{
            animationDelay: `${i}00ms`,
            fontSize,
          }}
        />
      ))}
  </div>
)

export const SmallSpinner = () => <CustomSpinner height="2rem" width="2rem" />
export const LoadingSpinner = () => <CustomSpinner height="3rem" width="3rem" />
export const MessageSpinner = () => <TypingSpinner fontSize="0.55rem" />

CustomSpinner.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
}

TypingSpinner.propTypes = {
  fontSize: PropTypes.string.isRequired,
  count: PropTypes.number,
}
