import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import getValue from 'lodash/get'

import {
  Button,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
} from 'reactstrap'

import ProfilePicture from 'components/Main/AccountPage/ProfilePicture'
import { withAlerts } from 'contexts/alertsProvider'

import { useGetOrganizationQuery, useUpdateOrganizationMutation, useUploadLogoMutation } from 'services/organization'

const Settings = ({ alerts }) => {
  const { data: organization, isFetching } = useGetOrganizationQuery()

  const [updateOrganization, { isLoading: isUpdating }] = useUpdateOrganizationMutation()
  const [uploadLogo, { isLoading: isUploading }] = useUploadLogoMutation()

  const imageUrl = useMemo(() => `${organization?.logo}?${Date.now()}`, [isFetching])

  const submitForm = async (data) => {
    try {
      await updateOrganization(data).unwrap()
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  const formik = useFormik({
    initialValues: {
      public_name: getValue(organization, 'public_name', ''),
    },
    enableReinitialize: true,
    onSubmit: submitForm,
  })

  return (
    <>
      <h2 className="m-0">Settings</h2>
      <hr />
      <ProfilePicture
        imageUrl={imageUrl}
        label="Organization Logo"
        uploadImage={uploadLogo}
        isUploading={isUploading}
      />
      <Form>
        <Row>
          <Col md={8}>
            <FormGroup>
              <label className="form-control-label">Organization name</label>
              <Input
                type="text"
                placeholder="Organization name"
                name="public_name"
                value={formik.values.public_name}
                onChange={formik.handleChange}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button
              className="mt-4"
              size="sm"
              outline
              color="info"
              type="submit"
              role="button"
              onClick={formik.handleSubmit}
              disabled={!(formik.dirty && formik.isValid) || isUpdating}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

Settings.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
}

export default withAlerts(Settings)
