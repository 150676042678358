import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Editor } from '@tinymce/tinymce-react'
import SanitizeHTML from 'sanitize-html'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import {
  apiKey,
  allowedSchema,
  plugins,
  toolbar,
} from 'helpers/tinymce'

const TextEditor = ({
  height,
  name,
  value,
  placeholder,
  handleUpdate,
  handleChange,
}) => {
  const refValue = useRef()
  const [isLoading, setIsLoading] = useState(true)
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    refValue.current = currentValue
  }, [currentValue])

  useEffect(() => () => handleUpdate(refValue.current), [])

  return (
    <>
      {isLoading && (
        <div className="d-flex align-items-center">
          <Spinner />
        </div>
      )}
      <div style={{ display: isLoading ? 'none' : 'block' }}>
        <Editor
          apiKey={apiKey}
          initialValue={value}
          onChange={handleChange}
          onEditorChange={setCurrentValue}
          textareaName={name}
          onInit={() => {
            setIsLoading(false)
          }}
          init={{
            paste_preprocess(_, args) {
              Object.assign(args, { content: SanitizeHTML(args.content, allowedSchema) })
            },
            external_plugins: {
              'tiny_mce_wiris': 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js',
            },
            height,
            width: '100%',
            menubar: false,
            branding: false,
            statusbar: false,
            placeholder,
            selector: `textarea[name="${name}"]`,
            plugins,
            contextmenu: false,
            toolbar_sticky: true,
            toolbar,
            content_style: `
              body {
                  background: #fff;
              }

              @media (min-width: 840px) {
                  html {
                      background: #eceef4;
                      min-height: 100%;
                      padding: 0 .5rem
                  }

                  body {
                      background-color: #fff;
                      box-shadow: 0 0 4px rgba(0, 0, 0, .15);
                      box-sizing: border-box;
                      margin: 1rem auto 0;
                      max-width: 820px;
                      min-height: calc(100vh - 1rem);
                      padding:4rem 6rem 6rem 6rem
                  }
              }
            `,
          }}
        />
      </div>
    </>
  )
}


TextEditor.propTypes = {
  height: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  handleUpdate: PropTypes.func,
  handleChange: PropTypes.func,
}

export default TextEditor
