import React from 'react'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import AssetList from 'components/Catalog/AssetsPage/AssetList'
import RightBar from 'components/Catalog/AssetsPage/RightBar'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'
import { TextButton } from 'components/Catalog/Shared/Buttons'

import { useGetWorkspaceCatalogsQuery } from 'services/workspaces'
import { useModalContext, MODAL_TYPES } from 'contexts/modalProvider'

const AssetsPage = () => {
  const { showModal } = useModalContext()
  const { workspaceUUID } = useParams()

  const showCreateSourceModal = () => {
    showModal(MODAL_TYPES.ADD_CONNECTION_MODAL, { workspaceUUID })
  }

  const { data = [], isFetching } = useGetWorkspaceCatalogsQuery(workspaceUUID)

  return (
    <Row className="h-100">
      <Col xs="auto" className="d-flex flex-column flex-grow-1">
        {isFetching ? (
          <Spinner />
        ) : (
          data.length ? (
            <AssetList data={data} />
          ) : (
            <Row className="flex-grow-1 align-items-center">
              <Col className="text-center">
                <TextButton size="bg" label="+ Add Connection" onClick={showCreateSourceModal} />
              </Col>
            </Row>
          )
        )}
      </Col>

      <RightBar />
    </Row>
  )
}

export default AssetsPage
