import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Wizard } from 'react-use-wizard'

import ModalContainer from 'components/Custom/ModalContainer'
import WizardWrapper from 'components/Main/ModelViewPage/CreateNewModal/WizardWrapper'
import WizardSteps from 'components/Main/ModelViewPage/CreateNewModal/WizardSteps'
import WizardFooter from 'components/Main/ModelViewPage/CreateNewModal/WizardFooter'
import FirstStep from 'components/Main/ModelViewPage/CreateNewModal/FirstStep'
import SecondStep from 'components/Main/ModelViewPage/CreateNewModal/SecondStep'
import ThirdStep from 'components/Main/ModelViewPage/CreateNewModal/ThirdStep'
import FourthStep from 'components/Main/ModelViewPage/CreateNewModal/FourthStep'
import { useAddModelMutation } from 'services/models'

import { useModalContext } from 'contexts/modalProvider'
import { withAlerts } from 'contexts/alertsProvider'

const CreateNewModal = ({ alerts }) => {
  const history = useHistory()
  const { hideModal, store: { modalProps } } = useModalContext()
  const [addModel, { isLoading }] = useAddModelMutation()
  const { workspaceUUID } = modalProps

  const [values, setValues] = useState({})

  const handleFormSubmit = async () => {
    const data = {
      dataset_uuid: values.datasetUUID,
      model_type: values.modelType,
      name: values.name,
      target_variable: values.targetVariable,
      ...workspaceUUID && { workspace_uuid: workspaceUUID },
    }
    try {
      const payload = await addModel(data).unwrap()
      history.push(`/workspaces/${workspaceUUID}/models/${payload.uuid}`)
      hideModal()
    } catch ({ data: { message } = {} }) {
      alerts.error({ message })
    }
  }

  return (
    <ModalContainer
      title="Create New Model"
      isOpen={true}
      toggle={hideModal}
      customSize="bg"
    >
      <Wizard
        header={<WizardSteps />}
        footer={<WizardFooter
          handleFormSubmit={handleFormSubmit}
          setValues={setValues}
        />}
        wrapper={<WizardWrapper />}
      >
        <FirstStep values={values} setValues={setValues} />
        <SecondStep values={values} setValues={setValues} />
        <ThirdStep values={values} setValues={setValues} />
        <FourthStep values={values} isLoading={isLoading}/>
      </Wizard>
    </ModalContainer>
  )
}

CreateNewModal.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
}

export default withAlerts(CreateNewModal)
