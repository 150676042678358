import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import {
  Row,
  Col,
  Button,
  Card,
  Container,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Form,
} from 'reactstrap'

import AuthHeader from 'components/Auth/AuthHeader'
import ICONS from 'helpers/iconConstants'

import { withAlerts } from 'contexts/alertsProvider'
import { resendConfirmationCode, confirmUser } from 'helpers/cognito'
import { useQuery } from 'hooks/useQuery'

const validationSchema = Yup.object().shape({
  code: Yup.string().min(6).required(),
})

const ConfirmPage = ({ alerts }) => {
  const history = useHistory()
  const { getParam } = useQuery()
  const [state, setState] = useState({})

  const resendCode = async () => {
    const email = getParam('email')
    try {
      await resendConfirmationCode(email)
      alerts.success({ message: `The code was sent to ${email}` })
    } catch ({ message }) {
      alerts.error({ message })
    }
  }

  const submitForm = async ({ code }) => {
    const email = getParam('email')
    try {
      await confirmUser(email, code)
      alerts.success({ message: 'Your email has been confirmed. Please login.' })
      history.push('/login')
    } catch ({ message }) {
      alerts.error({ message })
    }
  }

  const formik = useFormik({
    initialValues: { code: '' },
    validationSchema,
    onSubmit: submitForm,
  })

  return (
    <>
      <AuthHeader title="Email confirmation" lead="Enter the code sent your email." />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form onSubmit={formik.handleSubmit}>
                  <FormGroup
                    className={classnames({
                      focused: state.codeFocus,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className={ICONS.LOCK_CIRCLE_OPEN} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirmation Code"
                        type="text"
                        name="code"
                        onFocus={() => setState({ ...state, codeFocus: true })}
                        onBlur={() => setState({ ...state, codeFocus: false })}
                        value={formik.values.code}
                        invalid={formik.touched.code && Boolean(formik.errors.code)}
                        onChange={formik.handleChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center mt-3">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={formik.handleSubmit}
                    >
                      Verify Email
                    </Button>
                    <Button
                      color="info"
                      type="button"
                      onClick={resendCode}
                    >
                      Resend Code
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

ConfirmPage.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
}

export default withAlerts(ConfirmPage)
