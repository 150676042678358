import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useFormik } from 'formik'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import {
  Button,
  Card,
  Row,
  CardBody,
  Container,
  Col,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Form,
  FormGroup,
} from 'reactstrap'

import PasswordTips from 'components/Auth/PasswordTips'
import AuthHeader from 'components/Auth/AuthHeader'
import ICONS from 'helpers/iconConstants'

import { withAlerts } from 'contexts/alertsProvider'
import { registerUser } from 'helpers/cognito'

const PASSWORD_REGEXP = /(?!.*\s)(?=.*([a-z]))(?=.*([A-Z]))(?=.*\d)([\x20-\x7E]){8,}/
const validationSchema = Yup.object().shape({
  // firstName: Yup.string().max(256).required(),
  // lastName: Yup.string().max(256).required(),
  email: Yup.string().email().required(),
  password: Yup.string().required().matches(PASSWORD_REGEXP, 'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number'),
  terms: Yup.boolean().required().oneOf([true], 'This field must be checked'),
  passwordConfirmation: Yup.string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    }),
})

const RegistrationPage = ({ alerts }) => {
  const [state, setState] = useState({})
  const history = useHistory()

  const submitForm = async (data) => {
    try {
      await registerUser(data)
      alerts.success({ message: 'Verification code has been sent to your email' })
      history.push(`/confirm?email=${data.email}`)
    } catch ({ message }) {
      alerts.error({ message })
    }
  }

  const formik = useFormik({
    initialValues: {
      // firstName: '',
      // lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      terms: false,
    },
    validationSchema,
    onSubmit: submitForm,
  })

  return (
    <>
      <AuthHeader title="Welcome!" lead="Sign up for Divian" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  {/*<div style={{ display: 'flex', justifyContent: 'space-between', height: '48px' }}>
                    <InputGroup
                      style={{ marginRight: '5px' }}
                      className={classnames({
                        'input-group-focus': state.firstNameFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="First Name"
                        type="text"
                        name="firstName"
                        autoComplete="new-password"
                        onFocus={() => setState({ ...state, firstNameFocus: true })}
                        onBlur={() => setState({ ...state, firstNameFocus: false })}
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        invalid={formik.touched.firstName && Boolean(formik.errors.firstName)}
                      />
                    </InputGroup>
                    <InputGroup
                      style={{ marginLeft: '5px' }}
                      className={classnames({
                        'input-group-focus': state.lastNameFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Last Name"
                        type="text"
                        name="lastName"
                        autoComplete="new-password"
                        onFocus={() => setState({ ...state, lastNameFocus: true })}
                        onBlur={() => setState({ ...state, lastNameFocus: false })}
                        value={formik.values.emalastNameil}
                        onChange={formik.handleChange}
                        invalid={formik.touched.lastName && Boolean(formik.errors.lastName)}
                      />
                    </InputGroup>
                  </div>*/}
                  <FormGroup
                    className={classnames({
                      focused: state.emailFocus,
                    })}
                  >
                    <InputGroup
                      className="input-group-merge input-group-alternative mb-3"
                      style={{ ...formik.touched.email && Boolean(formik.errors.email) && { border: '1px solid red' } }}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className={ICONS.HAT} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        name="email"
                        autoComplete="new-password"
                        onFocus={() => setState({ ...state, emailFocus: true })}
                        onBlur={() => setState({ ...state, emailFocus: false })}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        invalid={formik.touched.email && Boolean(formik.errors.email)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: state.passFocus,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className={ICONS.LOCK_CIRCLE_OPEN} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        autoComplete="off"
                        onFocus={() => setState({ ...state, passFocus: true })}
                        onBlur={() => setState({ ...state, passFocus: false })}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        invalid={formik.touched.password && Boolean(formik.errors.password)}
                        id="password"
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: state.confirmPassFocus,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className={ICONS.LOCK_CIRCLE_OPEN} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password Confirmation"
                        type="password"
                        name="passwordConfirmation"
                        onFocus={() => setState({ ...state, confirmPassFocus: true })}
                        onBlur={() => setState({ ...state, confirmPassFocus: false })}
                        value={formik.values.passwordConfirmation}
                        onChange={formik.handleChange}
                        invalid={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <PasswordTips />
                  <Row className="my-4">
                    <Col xs="12">
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <Input
                          id="customCheckRegister"
                          type="checkbox"
                          className="custom-control-input"
                          name="terms"
                          onChange={formik.handleChange}
                          invalid={formik.touched.terms && Boolean(formik.errors.terms)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckRegister"
                        >
                          <span className="text-muted">
                            I agree to the
                            {' '}
                            <a href="https://divian.com/terms" target="_blank" rel="noopener noreferrer">terms</a>
                            {' '}and{' '}
                            <a href="https://divian.com/privacy" target="_blank" rel="noopener noreferrer">privacy policy</a>.
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button
                      className="mt-4"
                      color="info"
                      type="submit"
                      onClick={formik.handleSubmit}
                    >
                      Sign Up
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <Link
                  className="text-light"
                  to="/login"
                >
                  <small>Already have an account?&nbsp;&nbsp;Log In</small>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

RegistrationPage.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
}

export default withAlerts(RegistrationPage)
