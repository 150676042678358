import { useState, useEffect } from 'react'
import { getSessionUser } from 'helpers/cognito'

export const useToken = () => {
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState(null)

  useEffect(() => {
    const fetchUserSession = async () => {
      try {
        const res = await getSessionUser()
        setToken(res?.accessToken?.jwtToken)
        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    }
    fetchUserSession()
  }, [])

  return [loading, token]
}
