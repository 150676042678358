import React from 'react'

import ChangeEmail from 'components/Main/AccountPage/Security/ChangeEmail'
import ChangePassword from 'components/Main/AccountPage/Security/ChangePassword'
import TwoFactor from 'components/Main/AccountPage/Security/TwoFactor'

const Security = () => (
  <>
    <h2 className="m-0">Security</h2>
    <hr />
    <ChangeEmail />
    <ChangePassword />
    <TwoFactor />
  </>
)

export default Security
