import React, { useEffect } from 'react'
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'select2/dist/css/select2.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
// plugins styles downloaded
import 'assets/vendor/nucleo/css/nucleo.css'
// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.2.0'
// slide panel styles
import 'react-sliding-pane/dist/react-sliding-pane.css'

import AuthRoutes from 'components/Routes/AuthRoutes'
import MainRoutes from 'components/Routes/MainRoutes'
import DocumentationRoutes from 'components/Routes/DocumentationRoutes'
import BindersRoutes from 'components/Routes/BindersRoutes'
import Catalog from 'components/Catalog'

import AlertsProvider from 'contexts/alertsProvider'
import ModalsProvider from 'contexts/modalsProvider'
import { ModalProvider } from 'contexts/modalProvider'
import BreadcrumbsProvider from 'contexts/breadcrumbsProvider'

// REGISTRATION
import LoginPage from 'components/Auth/LoginPage'
import RegistrationPage from 'components/Auth/RegistrationPage'
import ConfirmPage from 'components/Auth/ConfirmPage'
import ForgotPasswordPage from 'components/Auth/ForgotPasswordPage'
import NewPasswordPage from 'components/Auth/NewPasswordPage'

import { pageview } from 'helpers/gtag'

const App = () => {
  const history = useHistory()

  useEffect(() => {
    pageview(window.location.pathname + window.location.search)
  }, [history])

  return (
    <BrowserRouter>
      <DndProvider backend={HTML5Backend}>
        <AlertsProvider>
          <BreadcrumbsProvider>
            <ModalsProvider>
              <ModalProvider>
                <Switch>
                  <AuthRoutes path="/login" component={LoginPage} />
                  <AuthRoutes path="/registration" component={RegistrationPage} />
                  <AuthRoutes path="/confirm" component={ConfirmPage} />
                  <AuthRoutes path="/forgot-password" component={ForgotPasswordPage} />
                  <AuthRoutes path="/new-password" component={NewPasswordPage} />
                  <Route path="/binders/:uuid" component={BindersRoutes} />
                  <Route path="/documentation" component={DocumentationRoutes} />
                  <Route
                    path={[
                      '/workspaces/:workspaceUUID/catalog/:section/:uuid',
                      '/workspaces/:workspaceUUID/catalog/:section',
                      '/workspaces/:workspaceUUID/catalog',
                    ]}
                    component={Catalog}
                  />
                  <MainRoutes />
                </Switch>
              </ModalProvider>
            </ModalsProvider>
          </BreadcrumbsProvider>
        </AlertsProvider>
      </DndProvider>
    </BrowserRouter>
  )
}

export default App
