import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormGroup,
} from 'reactstrap'

import { ErrorUncontrolledTooltip } from 'components/Custom/UncontrolledTooltip'
import ICONS from 'helpers/iconConstants'

const InputGroupComponent = ({
  icon = ICONS.HAT,
  focus,
  errorText,
  ...rest
}) => (
  <FormGroup
    className={classnames({
      focused: focus,
    })}
  >
    <InputGroup className="input-group-merge input-group-alternative mb-3">
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <i className={icon} />
        </InputGroupText>
      </InputGroupAddon>
      <Input {...rest} />
      {errorText?.length && (
        <ErrorUncontrolledTooltip
          delay={0}
          target={rest.id}
          placement="right"
        >
          {errorText}
        </ErrorUncontrolledTooltip>
      )}
    </InputGroup>
  </FormGroup>
)

InputGroupComponent.propTypes = {
  icon: PropTypes.string,
  focus: PropTypes.bool,
  errorText: PropTypes.string,
  rest: PropTypes.shape({}),
}

export default InputGroupComponent
