import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Col,
  Row,
  Container,
} from 'reactstrap'
import { Scrollbars } from 'react-custom-scrollbars-2'

import SummarySection from 'components/Catalog/RequestPageView/DataViewTilesOnly/SummarySection'
import LogSection from 'components/Catalog/RequestPageView/DataViewTilesOnly/LogSection'

import { useGetRequestQuery } from 'services/catalogs'

const DataViewTilesOnly = () => {
  const [shouldScroll, setShouldScroll] = useState(true)
  const { uuid, workspaceUUID } = useParams()
  const scrollBarRef = useRef(null)
  const { data = {}, isLoading } = useGetRequestQuery({ uuid, workspaceUUID })

  useEffect(() => {
    if (isLoading) return
    if (data.status === 'done' && shouldScroll) return
    setShouldScroll(data.status === 'done')
    scrollBarRef.current.view.scroll({
      top: scrollBarRef?.current?.getScrollHeight(),
      behavior: 'smooth',
    })
  }, [data])

  return (
    <Row className="flex-grow-1">
      <Col className="px-0 pt-3">
        <Scrollbars ref={scrollBarRef}>
          <Container fluid>
            {data.status === 'done' && <SummarySection />}
            <LogSection />
          </Container>
        </Scrollbars>
      </Col>
    </Row>
  )
}

export default DataViewTilesOnly
