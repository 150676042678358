import React, {
  useRef,
  createContext,
  useContext,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import NotificationAlert from 'react-notification-alert'

const AlertsContext = createContext({})

const useAlerts = () => {
  const {
    info,
    success,
    error,
    warning,
  } = useContext(AlertsContext)
  return {
    info, success, error, warning,
  }
}

export const withAlerts = Comp => (props) => {
  const alerts = useAlerts()
  return <Comp {...props} alerts={alerts} />
}

const AlertsProvider = ({ children }) => {
  const DEFAULT_OPTIONS = {
    place: 'tc',
    autoDismiss: 2,
  }

  const TYPES = {
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'danger',
    SUCCESS: 'success',
  }

  const notificationAlertRef = useRef()

  const notification = (options) => {
    const completedOptions = {
      ...DEFAULT_OPTIONS,
      ...options,
    }
    notificationAlertRef.current.notificationAlert(completedOptions)
  }
  const info = (options) => {
    notification({ ...options, type: TYPES.INFO })
  }

  const success = (options) => {
    notification({ ...options, type: TYPES.SUCCESS })
  }

  const error = (options) => {
    notification({ ...options, type: TYPES.ERROR })
  }

  const warning = (options) => {
    notification({ ...options, type: TYPES.ERROR })
  }

  return (
    <AlertsContext.Provider
      value={{
        warning: useCallback(warning, []),
        error: useCallback(error, []),
        success: useCallback(success, []),
        info: useCallback(info, []),
      }}
    >
      {children}
      <NotificationAlert ref={notificationAlertRef} />
    </AlertsContext.Provider>
  )
}

AlertsProvider.propTypes = {
  children: PropTypes.node,
}

export default AlertsProvider
