import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import kebabCase from 'lodash/kebabCase'
import partition from 'lodash/partition'

import { Nav, NavItem, Input } from 'reactstrap'
import ChildItem from './ChildItem'
import ActionsMenu from './ActionsMenu'

import { getUserData, selectKnowledgeBase } from 'reducers/user'
import { createKnowledgeBase, updateKnowledgeBase, deleteKnowledgeBase } from 'reducers/knowledgeBase'
import { withAlerts } from 'contexts/alertsProvider'
import { withModals } from 'contexts/modalsProvider'
import { getDocPath } from 'components/Documentation/utils'

const RootItem = ({
  alerts,
  modals,
  pages,
  rootPage,
  setIsLoading,
  setEditMode,
}) => {
  const knowledgeBase = useSelector(state => selectKnowledgeBase(state))
  const dispatch = useDispatch()

  const [mode, setMode] = useState('')

  const [, root] = useMemo(() => {
    if (!knowledgeBase.length) return [[],[]]
    return partition(knowledgeBase, doc => doc.parent_id)
  }, [knowledgeBase])

  const editChapterName = async (e, page) => {
    const name = e.target.value
    try {
      if (name === page.name) return
      setIsLoading(true)
      const path = `/${kebabCase(name)}`
      await dispatch(updateKnowledgeBase({ uuid: page.uuid, name, path })).unwrap()
      await dispatch(getUserData({ type: 'knowledgebase' }))
    } catch ({ message }) {
      alerts.error({ message })
    } finally {
      setMode('')
      setIsLoading(false)
    }
  }

  const deleteHandler = async () => {
    try {
      const confirmed = await modals.confirm({
        message: 'Are you sure you want to remove this chapter? All child pages will be removed permanently!',
      })
      if (!confirmed) return
      setIsLoading(true)
      await dispatch(deleteKnowledgeBase(rootPage.uuid)).unwrap()
      await dispatch(getUserData({ type: 'knowledgebase' }))
    } catch ({ message }) {
      alerts.error({ message })
    } finally { setIsLoading(false) }
  }

  const createChapter = async (name, withParent = false) => {
    try {
      if (!name.length) return
      setIsLoading(true)
      const currIndex = root.indexOf(rootPage)
      const path = `/${kebabCase(name)}`
      await dispatch(createKnowledgeBase({
        name,
        prev_position: rootPage.position,
        next_position: root[currIndex + 1]?.position,
        ...withParent && { parent_id: rootPage.uuid, path },
      })).unwrap()
      await dispatch(getUserData({ type: 'knowledgebase' }))
    } catch ({ message }) {
      alerts.error({ message })
    } finally {
      setMode('')
      setIsLoading(false)
    }
  }

  return (
    <div className="ct-toc-item active">
      <div className="ct-toc-link-editable">
        {mode !== 'edit' && (
          <Link className="ct-toc-link" to={getDocPath(pages[0])} onClick={() => setEditMode(false)}>
            {rootPage.name}
          </Link>
        )}
        {mode === 'edit' && (
          <Input
            autoFocus
            onBlur={e => editChapterName(e, rootPage)}
            onKeyPress={e => (e.code === 'Enter' || e.code === 'NumpadEnter') && editChapterName(e, rootPage)}
            className="form-control-sm mx-4 py-1 px-2 font-weight-bold"
            defaultValue={rootPage.name}
            type="text"
          />
        )}
        {mode === '' && (
          <ActionsMenu
            page={rootPage}
            editHandler={() => setMode('edit')}
            addChapterHandler={() => setMode('addChapter')}
            addPageHandler={() => setMode('addPage')}
            deleteHandler={deleteHandler}
          />
        )}
      </div>
      {mode === 'addChapter' && (
        <div className="ct-toc-link-editable">
          <Input
            autoFocus
            onBlur={e => createChapter(e.target.value)}
            onKeyPress={e => (e.code === 'Enter' || e.code === 'NumpadEnter') && createChapter(e.target.value)}
            className="form-control-sm mx-4 font-weight-bold my-1"
            placeholder="Chapter Name"
            type="text"
          />
        </div>
      )}
      <Nav vertical className="ct-sidenav">
        <>
          {mode === 'addPage' && (
            <NavItem className="nav-item-editable">
              <span className="ml-4" />
              <Input
                autoFocus
                onBlur={e => createChapter(e.target.value, true)}
                onKeyPress={e => (e.code === 'Enter' || e.code === 'NumpadEnter') && createChapter(e.target.value, true)}
                className="form-control-sm ml-2 mr-4 my-1"
                placeholder="Page Name"
                type="text"
              />
            </NavItem>
          )}
          {pages.map(page => (
            <ChildItem
              rootPage={rootPage}
              page={page}
              editChapterName={editChapterName}
              setIsLoading={setIsLoading}
              setEditMode={setEditMode}
            />
          ))}
        </>
      </Nav>
    </div>
  )
}

RootItem.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({})),
  rootPage: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
  }),
  modals: PropTypes.shape({
    confirm: PropTypes.func,
  }),
  setIsLoading: PropTypes.func,
  setEditMode: PropTypes.func,
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
}

export default compose(withModals, withAlerts)(RootItem)
