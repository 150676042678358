import React, {
  useState,
  useEffect,
  useRef,
  Suspense,
} from 'react'
import PropTypes from 'prop-types'

import {
  Route,
  Redirect,
  useHistory,
  useLocation,
  Switch,
} from 'react-router-dom'

import Sidebar from 'components/Main/Sidebar'
import AdminNavbar from 'components/Main/AdminNavbar'
import logo from 'assets/img/divian-logo-montserrat-dark-alt.png'
import routes from 'mainRoutes'
import AccountPage from 'components/Main/AccountPage'
import CardsHeader from 'components/Main/CardsHeader'
import WorkspaceRedirect from 'components/Routes/WorkspaceRedirect'
import LoadingSpinner from 'components/Shared/LoadingSpinner'

import { useToken } from 'hooks/useToken'
import { useCollapsedMode } from 'hooks/useCollapsedMode'

import { useGetWorkspaceQuery } from 'services/workspaces'

const MainRoutes = () => {
  const [collapsed, collapsedToggler] = useCollapsedMode()
  const history = useHistory()
  const location = useLocation()
  const [loading, token] = useToken()
  const [sidenavOpen, setSidenavOpen] = useState(collapsed === 'false')
  const mainContentRef = useRef(null)
  const [,,workspaceUUID] = location.pathname.split('/')

  const { data = {} } = useGetWorkspaceQuery(workspaceUUID, { skip: !workspaceUUID })

  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    mainContentRef.current.scrollTop = 0
  }, [location])

  if (!loading && !token) {
    return (
      <Redirect
        to={{ pathname: '/login', state: { from: history.location } }}
      />
    )
  }

  const toggleSidenav = () => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned')
      document.body.classList.add('g-sidenav-hidden')
    } else {
      document.body.classList.add('g-sidenav-pinned')
      document.body.classList.remove('g-sidenav-hidden')
    }
    collapsedToggler()
    setSidenavOpen(!sidenavOpen)
  }

  return (
    <>
      <Sidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          text: 'Divian',
          imgSrc: logo,
        }}
        isPersonal={data.is_personal}
        workspaceUUID={workspaceUUID}
      />
      <Suspense fallback={<LoadingSpinner />}>
        <div className="main-content" ref={mainContentRef}>
          <AdminNavbar
            theme="light"
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            workspaceUUID={workspaceUUID}
          />
          <CardsHeader />
          <div className="mt--6 container-fluid">
            <Switch>
              <Route path={`/workspaces/${workspaceUUID}/account`} component={AccountPage} />
              {routes.map(({ path, component }) => (<Route key={path} path={path} component={component} />))}
              <Route exact path={['/', '', '/*']} component={WorkspaceRedirect} />
            </Switch>
          </div>
        </div>
        {sidenavOpen ? (<div className="backdrop d-xl-none" onClick={toggleSidenav} />) : null}
      </Suspense>
    </>
  )
}

MainRoutes.propTypes = {
  component: PropTypes.func,
  name: PropTypes.string,
}

export default MainRoutes
