import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { getDocPath, closeSearch, openSearch } from 'components/Documentation/utils'
import Logo from 'components/Documentation/assets/images/divian-logo-montserrat-light.png'

import {
  Collapse,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  FormGroup,
  NavbarBrand,
} from 'reactstrap'
import ICONS from 'helpers/iconConstants'

const Header = ({ theme, sidenavOpen, toggleSidenav }) => (
  <Navbar expand tag="header" className={classnames(
    'align-items-md-center', 'ct-navbar', 'flex-row', 'navbar-horizontal',
    { 'navbar-dark bg-info': theme === 'dark' },
    { 'navbar-light bg-secondary': theme === 'light' },
  )}
  >
    <NavbarBrand className="mr-0 mr-md-2" href={getDocPath()}>
      <img src={Logo} alt="" /> Docs
    </NavbarBrand>
    <Collapse navbar isOpen={true} className="justify-content-end">
      <Form
        className={classnames(
          'navbar-search form-inline mr-sm-3',
          { 'navbar-search-light': theme === 'dark' },
          { 'navbar-search-dark': theme === 'light' },
        )}
      >
        <FormGroup className="mb-0">
          <InputGroup className="input-group-alternative input-group-merge">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className={ICONS.SEARCH} />
              </InputGroupText>
            </InputGroupAddon>
            <Input placeholder="Search" type="text" />
          </InputGroup>
        </FormGroup>
        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={closeSearch}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </Form>
      <Nav className="align-items-center" navbar>
        <NavItem className="d-md-none">
          <div
            className={classnames(
              'pr-3 sidenav-toggler',
              { active: sidenavOpen },
              { 'sidenav-toggler-dark': theme === 'dark' },
            )}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className={ICONS.SIDENAV_TOGGLER_LINE} />
              <i className={ICONS.SIDENAV_TOGGLER_LINE} />
              <i className={ICONS.SIDENAV_TOGGLER_LINE} />
            </div>
          </div>
        </NavItem>
        <NavItem className="d-sm-none">
          <NavLink onClick={openSearch}>
            <i className={ICONS.ZOOM_SPLIT_IN} />
          </NavLink>
        </NavItem>
      </Nav>
    </Collapse>
  </Navbar>
)

Header.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark',
}

Header.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
}

export default Header
