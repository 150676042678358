import React, { useState, useEffect, useMemo } from 'react'
import { Container, Row } from 'reactstrap'
import {
  Route,
  Redirect,
  useHistory,
  Switch,
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import partition from 'lodash/partition'

import Header from 'components/Documentation/Header'
import Sidebar from 'components/Documentation/Sidebar'
import MainContent from 'components/Documentation/MainContent'
import LoadingSpinner from 'components/Shared/LoadingSpinner'

import { useToken } from 'hooks/useToken'
import { getDocPath } from 'components/Documentation/utils'

import 'components/Documentation/assets/styles/index.scss'

import { getUserData, selectKnowledgeBase } from 'reducers/user'

const DocumentationRoutes = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, token] = useToken()
  const [sidenavOpen, setSidenavOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [editMode, setEditMode] = useState(false)

  const knowledgeBase = useSelector(state => selectKnowledgeBase(state))

  useEffect(async () => {
    if (!knowledgeBase.length) {
      await dispatch(getUserData({ type: 'knowledgebase' }))
    }
    setIsLoading(false)
  }, [])

  const [children, root] = useMemo(() => {
    if (!knowledgeBase.length) return [[],[]]
    return partition(knowledgeBase, doc => doc.parent_id)
  }, [knowledgeBase])

  const firstDocPage = useMemo(() => {
    if (!knowledgeBase.length) return []
    return knowledgeBase.find(doc => doc.parent_id === root[0]?.uuid)
  }, [knowledgeBase])

  if (!loading && !token) {
    return (
      <Redirect
        to={{ pathname: '/login', state: { from: history.location } }}
      />
    )
  }

  return (
    <div className="documentation-page-wrapper">
      <Header sidenavOpen={sidenavOpen} toggleSidenav={() => setSidenavOpen(!sidenavOpen)} />
      <Container fluid>
        <Row className="flex-xl-nowrap">
          <Sidebar
            rootPages={root}
            childrenPages={children}
            sidenavOpen={sidenavOpen}
            setIsLoading={setIsLoading}
            setEditMode={setEditMode}
          />
          <Switch>
            {children.map(child => (
              <Route
                key={child.uuid}
                path={getDocPath(child)}
                render={() => (
                  <MainContent
                    {...child}
                    editMode={editMode}
                    setIsLoading={setIsLoading}
                  />
                )}
              />
            ))}
            <Redirect exact from="/documentation/" to={getDocPath(firstDocPage)} />
          </Switch>
          <div className="d-none d-xl-block ct-toc col-xl-2" />
        </Row>
      </Container>
      <LoadingSpinner visible={isLoading} />
    </div>
  )
}

export default DocumentationRoutes
