import React, { useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'

import CreateNewDatasetModal from 'components/Main/DatasetViewPage/CreateNewModal'
import ExtractDataModal from 'components/Main/ContainerViewPage/ExtractDataModal'
import EditExtractionModal from 'components/Main/ContainerViewPage/EditExtractionModal'
import CreateNewSourceModal from 'components/Main/SourceViewPage/CreateSourceModal'
import VariableDetailsModal from 'components/Main/DatasetViewPage/ColumnsTab/VariableDetailsModal'
import AddMemberModal from 'components/Main/AccountPage/Members/AddMemberModal'
import AddWorkspaceMemberModal from 'components/Main/WorkspacePage/Members/AddWorkspaceMemberModal'
import AddModelModal from 'components/Main/ModelViewPage/CreateNewModal'
import ModelTargetModal from 'components/Main/ModelViewPage/ModelStructure/ModelTargetModal'
import ModelFeaturesModal from 'components/Main/ModelViewPage/ModelStructure/ModelFeaturesModal'
import WorkspacesModal from 'components/Main/WorkspacePage/WorkspacesModal'
import CreateNewScheduleModal from 'components/Main/SchedulersPage/CreateNewScheduleModal'
import AddConnectionModal from 'components/Catalog/AssetsPage/AddConnectionModal'
import HelpModal from 'components/Catalog/HelpModal'
import PreviewTableModal from 'components/Catalog/RequestPageView/DataViewTilesOnly/BotChat/PreviewTableModal'

export const MODAL_TYPES = {
  NEW_DATASET_MODAL: 'NEW_DATASET_MODAL',
  NEW_SOURCE_MODAL: 'NEW_SOURCE_MODAL',
  VARIABLE_DETAILS_MODAL: 'VARIABLE_DETAILS_MODAL',
  ADD_MEMBERS_MODAL: 'ADD_MEMBERS_MODAL',
  ADD_WORKSPACE_MEMBERS_MODAL: 'ADD_WORKSPACE_MEMBERS_MODAL',
  NEW_MODEL_MODAL: 'NEW_MODEL_MODAL',
  NEW_SCHEDULE_MODAL: 'NEW_SCHEDULE_MODAL',
  MODEL_TARGET_MODAL: 'MODEL_TARGET_MODAL',
  MODEL_FEATURES_MODAL: 'MODEL_FEATURES_MODAL',
  EXTRACT_DATA_MODAL: 'EXTRACT_DATA_MODAL',
  CHANGE_WORKSPACE_MODAL: 'CHANGE_WORKSPACE_MODAL',
  EDIT_EXTRACTION_DATA_MODAL: 'EDIT_EXTRACTION_DATA_MODAL',
  HELP_MODAL: 'HELP_MODAL',
  ADD_CONNECTION_MODAL: 'ADD_CONNECTION_MODAL',
  PREVIEW_TABLE_MODAL: 'PREVIEW_TABLE_MODAL',
}

const MODAL_COMPONENTS = {
  [MODAL_TYPES.NEW_DATASET_MODAL]: CreateNewDatasetModal,
  [MODAL_TYPES.NEW_SOURCE_MODAL]: CreateNewSourceModal,
  [MODAL_TYPES.VARIABLE_DETAILS_MODAL]: VariableDetailsModal,
  [MODAL_TYPES.ADD_MEMBERS_MODAL]: AddMemberModal,
  [MODAL_TYPES.ADD_WORKSPACE_MEMBERS_MODAL]: AddWorkspaceMemberModal,
  [MODAL_TYPES.NEW_MODEL_MODAL]: AddModelModal,
  [MODAL_TYPES.MODEL_TARGET_MODAL]: ModelTargetModal,
  [MODAL_TYPES.MODEL_FEATURES_MODAL]: ModelFeaturesModal,
  [MODAL_TYPES.EXTRACT_DATA_MODAL]: ExtractDataModal,
  [MODAL_TYPES.CHANGE_WORKSPACE_MODAL]: WorkspacesModal,
  [MODAL_TYPES.EDIT_EXTRACTION_DATA_MODAL]: EditExtractionModal,
  [MODAL_TYPES.NEW_SCHEDULE_MODAL]: CreateNewScheduleModal,
  [MODAL_TYPES.ADD_CONNECTION_MODAL]: AddConnectionModal,
  [MODAL_TYPES.HELP_MODAL]: HelpModal,
  [MODAL_TYPES.PREVIEW_TABLE_MODAL]: PreviewTableModal,
}

const initialState = {
  showModal: () => {},
  hideModal: () => {},
  store: {},
}

const ModalContext = createContext(initialState)

export const useModalContext = () => useContext(ModalContext)

export const ModalProvider = ({ children }) => {
  const [store, setStore] = useState()
  const { modalType, modalProps } = store || {}

  const showModal = (modalType, modalProps = {}) => {
    setStore({
      ...store,
      modalType,
      modalProps,
    })
  }

  const hideModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {},
    })
  }

  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType]
    if (!modalType || !ModalComponent) {
      return null
    }
    return <ModalComponent {...modalProps} />
  }

  return (
    <ModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: PropTypes.node,
}
