import { useState } from 'react'

export const useCollapsedMode = () => {
  const [collapsed, setCollapsed] = useState(window.localStorage.getItem('collapsed') || 'false')

  const setMode = (mode) => {
    window.localStorage.setItem('collapsed', mode)
    setCollapsed(mode)
  }

  const collapsedToggler = () => {
    collapsed === 'false' ? setMode('true') : setMode('false')
  }

  return [collapsed, collapsedToggler]
}
