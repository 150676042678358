import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import classnames from 'classnames'
import {
  Col,
  Navbar,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'

import { useModalContext, MODAL_TYPES } from 'contexts/modalProvider'
import ICONS from 'helpers/iconConstants'

const StyledNavItem = styled(NavItem)`
  &:hover {
    background: var(--lightest);
    .nav-link { color: var(--dark); }
  }
  .nav-link { color: var(--gray); }
`

const NavRow = ({
  icon,
  name,
  onClick,
  isOpen,
}) => (
  <StyledNavItem onClick={onClick}>
    <NavLink
      tag="div"
      className={classnames('p-2 nav-link flex-nowrap d-flex align-items-center', { 'justify-content-center': !isOpen })}
      role="button"
    >
      <i className={icon} style={{ fontSize: '1.2rem' }} />
      {isOpen && <span style={{ fontSize: '0.75rem' }} className="pl-2 w-100 overflow-hidden">{name}</span>}
    </NavLink>
  </StyledNavItem>
)

const RightBar = () => {
  const { showModal } = useModalContext()
  const { workspaceUUID } = useParams()
  const [isOpen, setIsOpen] = useState(false)

  const showCreateSourceModal = () => {
    showModal(MODAL_TYPES.ADD_CONNECTION_MODAL, { workspaceUUID })
  }

  return (
    <Col xs="auto" className="bg-white border-left overflow-hidden p-0">
      <Navbar className="p-0 h-100 flex-column">
        <Nav navbar className="w-100">
          <NavRow icon={isOpen ? ICONS.RIGHT : ICONS.LEFT} isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
          <div className="border-bottom w-75 mx-auto my-2" />
          <NavRow icon={ICONS.PLUS} name="Add connection" onClick={showCreateSourceModal} isOpen={isOpen} />
        </Nav>
      </Navbar>
    </Col>
  )
}

NavRow.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default RightBar
