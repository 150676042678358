import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Tree from 'rc-tree'
import 'rc-tree/assets/index.css'

import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import divianApi from 'services/api'
import { useGetSourceQuery } from 'services/sources'

import { SOURCE_TYPES } from 'helpers/constants'

const DatabaseTree = ({ values, setValues, sourceType }) => {
  const dispatch = useDispatch()
  const [treeData, setTreeData] = useState([])

  const { data = {}, isLoading } = useGetSourceQuery(values.sourceUUID)

  useEffect(() => {
    if (isLoading) return
    const newTree = data.databases.map((dbName, index) => ({ title: dbName, key: `database-${index}` }))
    setTreeData(newTree)
  }, [isLoading])

  const onSelect = (_, nodeProps) => {
    const {
      node: {
        isLeaf,
        title: tableName,
        schemaName,
        databaseName,
      },
    } = nodeProps
    if (!isLeaf) return
    setValues(state => ({
      ...state,
      tableName,
      schemaName,
      databaseName,
    }))
  }

  const onLoadData = async (treeNode) => {
    const treeKey = treeData.find(data => data.key === treeNode.props.eventKey)
    if (!treeKey) return Promise.resolve()
    const databaseName = treeKey.title
    setValues(state => ({
      ...state,
      databaseName,
      tableName: undefined,
      schemaName: undefined,
    }))
    return new Promise(async (resolve) => {
      const payload = await dispatch(divianApi.endpoints.getDatabaseSchema.initiate({ uuid: values.sourceUUID, databaseName })).unwrap()
      if (sourceType === SOURCE_TYPES.MYSQL) {
        const schemaTypes = Object.values(payload)[0]
        treeKey.children = Object.keys(schemaTypes).map((typeName) => {
          if (!schemaTypes[typeName].length) return null
          const object = { title: typeName, key: `${treeKey.key}-${typeName}` }
          object.children = schemaTypes[typeName].map(name => ({
            title: name,
            tableType: typeName,
            databaseName,
            key: `${treeKey.key}-${typeName}-${name}`,
            isLeaf: true,
          }))
          return object
        }).filter(Boolean)
      } else {
        treeKey.children = Object.keys(payload).map((schemaName) => {
          const object = { title: schemaName, key: `${treeKey.key}-${schemaName}` }
          object.children = Object.keys(payload[schemaName]).map((typeName) => {
            if (!payload[schemaName][typeName].length) return null
            const typeNameObject = { title: typeName, key: `${treeKey.key}-${schemaName}-${typeName}` }
            typeNameObject.children = payload[schemaName][typeName].map(name => ({
              title: name,
              schemaName,
              databaseName,
              key: `${treeKey.key}-${schemaName}-${typeName}-${name}`,
              isLeaf: true,
            }))
            return typeNameObject
          }).filter(Boolean)
          return object
        })
      }
      setTreeData([...treeData])
      return resolve()
    })
  }

  if (isLoading) return (
    <div className="position-absolute top-0 left-0 right-0 bottom-0">
      <Spinner />
    </div>
  )

  return (
    <Tree
      className="rc-tree-custom p-2"
      onSelect={onSelect}
      expandAction="click"
      selectable
      showLine
      loadData={onLoadData}
      treeData={treeData}
    />
  )
}

DatabaseTree.propTypes = {
  values: PropTypes.shape({
    sourceUUID: PropTypes.string,
  }),
  sourceType: PropTypes.string,
  setValues: PropTypes.func,
}

export default DatabaseTree
