import React, { useEffect } from 'react'
import { useWizard } from 'react-use-wizard'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
} from 'reactstrap'

import HeadersPreview from 'components/Main/DatasetViewPage/CreateNewModal/HeadersPreview'
import QueryPreview from 'components/Main/DatasetViewPage/CreateNewModal/QueryPreview'
import ColumnsTable from 'components/Main/DatasetViewPage/CreateNewModal/Tables/ColumnsTable'
import DatabaseHeadersPreview from 'components/Main/DatasetViewPage/CreateNewModal/DatabaseHeadersPreview'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { useGetContainerSourceQuery } from 'services/containers'
import { FILE_SOURCES } from 'helpers/constants'

const SecondStep = ({ values, setValues }) => {
  const {
    sourceUUID,
    type,
    sourceType,
  } = values
  const { handleStep } = useWizard()
  const { data = {}, isLoading, error } = useGetContainerSourceQuery({ uuid: sourceUUID, type }, { skip: !sourceUUID })

  useEffect(() => {
    if (isLoading) return
    setValues(state => ({
      ...state,
      sourceType: data.type || 'file',
      ...data.version_uuid && { versionUUID: data.version_uuid },
    }))
  }, [isLoading])

  handleStep(() => {
    if (!values?.selectedKeys?.length) throw new Error('No source keys')
  })

  if (isLoading) return <Spinner />
  if (!sourceType) return null

  if (!isLoading && error) {
    return (
      <div className="text-danger">{error.data.message}</div>
    )
  }

  return (
    <>
      {FILE_SOURCES.includes(sourceType) ? (
        <>
          <Row>
            <Col className="d-flex align-items-center flex-nowrap py-3">
              <label className="custom-toggle mr-1">
                <input
                  type="checkbox"
                  checked={values.showQuery}
                  onChange={() => setValues(state => ({ ...state, showQuery: !values.showQuery }))}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
              <small className="text-primary font-weight-bold">Use query</small>
            </Col>
          </Row>
          {values.showQuery ? (
            <Row className="flex-grow-1">
              <Col xs="4">
                <ColumnsTable preview fields={(data.data || data.header)?.schema?.fields || []} />
              </Col>
              <Col xs="8" className="d-flex flex-column">
                <QueryPreview values={values} setValues={setValues} />
              </Col>
            </Row>
          ) : (
            <HeadersPreview
              header={data.data || data.header}
              values={values}
              setValues={setValues}
            />
          )}
        </>
      ) : (
        <DatabaseHeadersPreview
          values={values}
          setValues={setValues}
          sourceType={sourceType}
        />
      )}
    </>
  )
}

SecondStep.propTypes = {
  values: PropTypes.shape({
    sourceUUID: PropTypes.string,
    selectedKeys: PropTypes.array,
    type: PropTypes.string,
    sourceType: PropTypes.string,
    showQuery: PropTypes.bool,
  }),
  setValues: PropTypes.func,
}

export default SecondStep
