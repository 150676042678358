import React, { } from 'react'
import PropTypes from 'prop-types'
import SlidingPane from 'react-sliding-pane'
import styled from 'styled-components'

import ScrollBar from 'components/Custom/ScrollBar'
import ICONS from 'helpers/iconConstants'

const StyledSlidingPane = styled(SlidingPane)`
  max-width: 700px;

  > .slide-pane__content {
    overflow: hidden;
    padding: 1rem;
  }

  .scroll-content-wrapper {
    min-height: 100%;
    padding: 1rem;
  }
`

const SlidePanel = ({
  children,
  ...props
}) => (
  <StyledSlidingPane
    from="right"
    subtitle=""
    title="Actions"
    width="100%"
    closeIcon={<i className={ICONS.CLOSE} />}
    overlayClassName="slide-panel-overlay-wrapper"
    {...props}
  >
    <ScrollBar>
      <div className="scroll-content-wrapper">
        {children}
      </div>
    </ScrollBar>
  </StyledSlidingPane>

)

SlidePanel.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
  children: PropTypes.node,
}

export default SlidePanel
