import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import styled from 'styled-components'

import {
  Row,
  Col,
  Input,
  Table,
  Container,
} from 'reactstrap'
import Select from 'react-select'
import ScrollBar from 'components/Custom/ScrollBar'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import ModalContainer from 'components/Custom/ModalContainer'

import { useModalContext } from 'contexts/modalProvider'
import { useGetAccountQuery } from 'services/user'
import { useGetWorkspacesQuery, useDeleteWorkspaceMutation } from 'services/workspaces'
import { useUpdateAccountMutation } from 'services/user'

import { withAlerts } from 'contexts/alertsProvider'

import ICONS from 'helpers/iconConstants'


const StyledTable = styled(Table)`
  table-layout: fixed;
  tbody > tr {
    .show-on-row-hover { visibility: hidden; }
    &:hover .show-on-row-hover { visibility: visible; }
  }
`

const FILTER_SOURCE_OPTIONS = [
  { label: 'Show all', value: 'all' },
  { label: 'Member', value: 'member' },
  { label: 'Owner', value: 'owner' },
]

const WorkspacesModal = ({ alerts }) => {
  const history = useHistory()
  const location = useLocation()
  const [,,workspaceUUID, path] = location?.pathname?.split('/')
  const { hideModal } = useModalContext()
  const [filter, setFilter] = useState(FILTER_SOURCE_OPTIONS[0])
  const [workspaceKeyword, setWorkspaceKeyword] = useState('')

  const { data = [], isFetching } = useGetWorkspacesQuery('all')
  const [updateAccount, { isLoading: isUpdating }] = useUpdateAccountMutation()
  const [deleteWorkspace] = useDeleteWorkspaceMutation()
  const { data: account = {} } = useGetAccountQuery()

  const changeWorkspaceHandler = async (uuid) => {
    try {
      await updateAccount({ workspace: uuid }).unwrap()
      history.push(`/workspaces/${uuid}/${path || 'home'}`)
      hideModal()
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  const deleteWorkspaceHandler = async (uuid) => {
    try {
      await deleteWorkspace(uuid).unwrap()
      if (uuid === account.workspace) history.push(`/workspaces/${data[0].uuid}/home`)
      else if (uuid === workspaceUUID) history.push(`/workspaces/${account.workspace}/home`)
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  const filteredWorkspaces = useMemo(() => {
    if (!data.length) return []
    if (filter.value === 'all') return data
    return data.filter(workspace => workspace.role === filter.value)
  }, [data?.length, filter])

  const workspaces = useMemo(() => {
    if (!workspaceKeyword.length) return filteredWorkspaces
    return filteredWorkspaces.filter(workspace => workspace.name.toLocaleUpperCase().includes(workspaceKeyword.toLocaleUpperCase()))
  }, [filteredWorkspaces, workspaceKeyword])

  return (
    <ModalContainer
      title="Workspaces"
      isOpen={true}
      toggle={hideModal}
    >
      <Container fluid className="px-4 py-5">
        <Row className="h-100 flex-column" style={{ minHeight: '400px' }}>
          <Col xs={12} className="flex-basis-0">
            <Row>
              <Col>
                <Input
                  autoComplete="off"
                  bsSize="sm"
                  className="text-monospace"
                  name="search"
                  style={{ height: 'calc(0.45em + 1.25rem + 12px)' }}
                  placeholder="Search Workspace..."
                  type="text"
                  onChange={e => setWorkspaceKeyword(e.target.value)}
                  value={workspaceKeyword}
                />
              </Col>
              <Col md={5}>
                <Select
                  className="select2-container text-muted mt-3 mt-md-0 w-100"
                  classNamePrefix="select2-dropdown"
                  options={FILTER_SOURCE_OPTIONS}
                  onChange={selected => setFilter(FILTER_SOURCE_OPTIONS.find(option => option.value === selected.value))}
                  value={filter}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="pt-4 flex-grow-1 flex-basis-0">
            {(isFetching || isUpdating) ? (
              <Spinner />
            ) : (
              workspaces.length ? (
                <ScrollBar autoHeight autoHeightMax="60vh">
                  <StyledTable hover size="sm">
                    <colgroup>
                      <col width="50px" />
                      <col />
                      <col width="75px"/>
                      <col width="40px" />
                    </colgroup>
                    <tbody>
                      {workspaces.map(({
                        name, uuid, role, logo,
                      }) => (
                        <tr
                          key={uuid}
                          role="button"
                          style={{ ...workspaceUUID === uuid && { backgroundColor: '#f6f9fc' } }}
                          className={classnames({ 'font-weight-bold': workspaceUUID === uuid })}
                          onClick={(e) => {
                            if (e.target?.tagName === 'I') return
                            changeWorkspaceHandler(uuid)
                          }}
                        >
                          <td>
                            <span className="avatar avatar-sm rounded-circle">
                              <img src={logo} alt="..." />
                            </span>
                          </td>
                          <td className="text-truncate align-middle">
                            {name}
                          </td>
                          <td className="text-truncate align-middle">{role}</td>
                          <td className="align-middle py-2">
                            <i role="button" onClick={() => deleteWorkspaceHandler(uuid)} className={`${ICONS.DELETE} ml-2 show-on-row-hover text-danger`} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </StyledTable>
                </ScrollBar>
              ) : (
                <Row className="align-items-center justify-content-center" style={{ height: '30vh' }}>
                  No workspaces
                </Row>
              )
            )}
          </Col>
        </Row>
      </Container>
    </ModalContainer>
  )
}

WorkspacesModal.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
}

export default withAlerts(WorkspacesModal)
