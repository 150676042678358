export const apiKey = 'iydwjez5p7r2j3kls3t0hwp3sniw1sahojhr3idzvi3p8e5t'
export const allowedSchema = {
  allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'span', 'a', 'ul', 'ol',
    'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div', 'img',
    'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'code', 'math', 'mfenced',
    'mrow', 'mi', 'msub', 'semantics', 'mroot', 'mn', 'menclose'],
  allowedAttributes: {
    a: ['href', 'name', 'target'],
    math: ['xmlns'],
    menclose: ['notation'],
    mfenced: ['open', 'close'],
    img: ['src', 'data-mathml'],
    '*': ['style', 'class'],
  },
  selfClosing: ['br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'img'],
}

// PLUGINS: https://www.tiny.cloud/docs/plugins/opensource/
// BUTTOMS: https://www.tiny.cloud/docs/advanced/available-toolbar-buttons/
export const plugins = 'fullscreen image link lists table quickbars code codesample'
const TOOLBAR_VALUES = {
  undoRedo: 'undo redo',
  text: 'bold italic underline strikethrough forecolor backcolor',
  font: 'fontselect fontsizeselect formatselect',
  alignment: 'alignleft aligncenter alignright alignjustify',
  list: 'numlist bullist',
  formula: 'tiny_mce_wiris_formulaEditor',
  image: 'quickimage',
  link: 'link',
  indents: 'outdent indent',
  removeformat: 'removeformat',
  code: 'code',
  table: 'table',
  codesample: 'codesample',
}

export const toolbar = Object.values(TOOLBAR_VALUES).join(' | ')
