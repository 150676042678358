import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Row, Col, Table } from 'reactstrap'
import ScrollBar from 'components/Custom/ScrollBar'
import { SmallButton } from 'components/Custom/Buttons'

import { shouldReduceIndex } from 'helpers/datasets'

const ColumnsTable = ({
  setValues,
  values,
  fields,
  selectAll,
  preview,
}) => {
  useEffect(() => {
    if (!selectAll) return
    handleItemSelection(getTitle('none', 'all'))
  }, [])

  const handleItemSelection = (index) => {
    if (index === 'all') {
      const selected = [...Array(fields.length).keys()]
      if (shouldReduceIndex(fields)) selected.shift()
      setValues(state => ({ ...state, selectedKeys: selected }))
    } else if (index === 'none') {
      setValues(state => ({ ...state, selectedKeys: [] }))
    } else {
      const updated = new Set(values.selectedKeys)
      if (!values.selectedKeys.includes(index)) {
        updated.add(index)
      } else {
        updated.delete(index)
      }
      setValues(state => ({ ...state, selectedKeys: [...updated] }))
    }
  }

  const getTitle = (forTrue, forFalse) => {
    let length = fields.length
    if (shouldReduceIndex(fields)) length -= 1
    return length === values.selectedKeys.length ? forTrue : forFalse
  }

  const handleAllSelection = (e) => {
    e.preventDefault()
    handleItemSelection(getTitle('none', 'all'))
  }

  return (
    <Row className="h-100">
      <Col className="d-flex flex-column">
        <div className="d-flex flex-nowrap align-items-center justify-content-between">
          <span className="py-2 text-uppercase font-weight-bold">Columns</span>
          {!preview && (
            <SmallButton outline className="ml-3 text-truncate" onClick={handleAllSelection}>
              {getTitle('Unselect', 'Select')} all
            </SmallButton>
          )}
        </div>
        <div className="border flex-grow-1">
          <ScrollBar>
            <Table hover size="sm">
              <thead style={{ backgroundColor: '#fafafa' }}>
                <tr>
                  <td
                    className="text-uppercase font-weight-bold text-default border-right border-top-0"
                    style={{ letterSpacing: '0.02rem', fontSize: '0.9rem', lineHeight: '1rem' }}
                  >
                    Name
                  </td>
                  <td
                    className="text-uppercase font-weight-bold text-default border-top-0"
                    style={{ letterSpacing: '0.02rem', fontSize: '0.9rem', lineHeight: '1rem' }}
                  >
                    Type
                  </td>
                </tr>
              </thead>
              <tbody>
                {fields.map((field, index) => {
                  if (index === 0 && field.name === 'index' && field.type === 'integer') return null
                  const fieldIndex = fields.findIndex(f => f.name === field.name)

                  return (
                    <tr key={field.name} className="border-bottom">
                      <td className="align-middle border-right">
                        <div className="d-flex flex-nowrap align-items-center">
                          {!preview && (
                            <div className={classnames('custom-control custom-checkbox', { 'custom-checkbox-info': fieldIndex >= 0 })} style={{ minHeight: 'auto' }}>
                              <input
                                className="custom-control-input"
                                onChange={() => handleItemSelection(fieldIndex)}
                                type="checkbox"
                                checked={values.selectedKeys.includes(fieldIndex)}
                                id={`chk-todo-task-${fieldIndex}`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`chk-todo-task-${fieldIndex}`}
                              />
                            </div>
                          )}
                          {field.name}
                        </div>
                      </td>
                      <td className="align-middle">
                        {field.type}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </ScrollBar>
        </div>
      </Col>
    </Row>
  )
}

ColumnsTable.defaultProps = {
  selectAll: false,
  preview: false,
}

ColumnsTable.propTypes = {
  values: PropTypes.shape({
    selectedKeys: PropTypes.array,
  }),
  setValues: PropTypes.func,
  fields: PropTypes.array,
  selectAll: PropTypes.bool,
  preview: PropTypes.bool,
}

export default ColumnsTable
