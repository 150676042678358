import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { NavItem } from 'reactstrap'
import ActionsMenu from 'components/Binders/Sidebar/ActionsMenu'
import InputField from 'components/Binders/Sidebar/InputField'

const MODE = {
  EDIT_CHILD: 'EDIT_CHILD',
}

const ChildItem = ({
  isActive,
  item,
  onClick,
}) => {
  const [mode, setMode] = useState(null)

  const editChildHandle = (value) => {
    alert(`EDIT_CHILD to: ${value}`)
    setMode(null)
  }

  return (
    <NavItem active={isActive} className="nav-item-editable">
      {mode === MODE.EDIT_CHILD && (
        <>
          <span className="ml-4" />
          <InputField item={item} onCloseHandler={() => setMode(null)} onSaveHandler={editChildHandle} />
        </>)}

      {mode !== MODE.EDIT_CHILD && <>
        <Link className="pr-2" onClick={onClick} to="#">{item.name}</Link>
        <ActionsMenu
          deleteHandler={() => alert('DELETE CHILD')}
          editChildHandler={() => setMode(MODE.EDIT_CHILD)}
        /></>}
    </NavItem>
  )
}

ChildItem.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  item: PropTypes.shape({
    name: PropTypes.string,
  }),
}

export default ChildItem
