import React from 'react'
import PropTypes from 'prop-types'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  NavItem,
  NavLink,
} from 'reactstrap'

import ICONS from 'helpers/iconConstants'

const CATALOG_ICONS = {
  assets: ICONS.EYE,
  assetsV2: ICONS.EYE,
  discover: ICONS.SEARCH,
  glossary: ICONS.HAT,
  requests: ICONS.DATABASE,
  help: ICONS.INFO,
}

const StyledNavItem = styled(NavItem)`
  &.active {
    background: var(--lighter);

    span,
    a { color: var(--dark); }
  }

  &:not(.active):hover {
    background: var(--lightest);

    span,
    a { color: var(--dark); }
  }

  span,
  a { line-height: 1.15rem; }
`

const NavRow = ({ name, onClick }) => {
  const { workspaceUUID, section } = useParams()

  return (
    <StyledNavItem className="text-center" active={section === name} onClick={onClick}>
      <NavLink
        {...onClick
          ? { tag: 'span', className: 'p-2 nav-link', role: 'button' }
          : { tag: Link, className: 'p-2', to: `/workspaces/${workspaceUUID}/catalog/${name}` }}
      >
        <i className={CATALOG_ICONS[name]} style={{ fontSize: '1.15rem' }} />
        <div style={{ fontSize: '0.75rem' }} className="text-capitalize">{name}</div>
      </NavLink>
    </StyledNavItem>
  )
}

NavRow.defaultProps = {
  active: false,
}

NavRow.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
}

export default NavRow
