import divianApi from './api'
import moment from 'moment'
import remove from 'lodash/remove'

const convertToUtc = date => `${moment(date).utc().format('YYYY-MM-DD HH:mm')}:00`

const formatSchedulerBody = (data) => {
  const {
    dtstart,
    until,
    ends,
    frequency,
  } = data
  data.dtstart = convertToUtc(dtstart)
  if (ends === 'on') {
    data.count = null
    data.until = convertToUtc(until)
  }
  else if (ends === 'after') data.until = null
  else {
    data.until = null
    data.count = null
  }
  if (frequency !== 'WEEKLY') data.byweekday = []
  return data
}

export const schedulersApi = divianApi.injectEndpoints({
  endpoints: build => ({
    getSchedulers: build.query({
      query: () => 'schedulers',
      providesTags: ['Schedulers'],
    }),
    getScheduler: build.query({
      query: uuid => `schedulers/${uuid}`,
      providesTags: ['Scheduler'],
    }),
    addScheduler: build.mutation({
      query: body => ({
        url: 'schedulers',
        method: 'POST',
        body: { ...body, ...formatSchedulerBody(body) },
      }),
      invalidatesTags: ['Schedulers', 'Scheduler', 'DatasetSchedulers'],
    }),
    updateScheduler: build.mutation({
      query: ({ uuid, ...patch }) => ({
        url: `schedulers/${uuid}`,
        method: 'PUT',
        body: { ...patch, ...formatSchedulerBody(patch) },
      }),
      invalidatesTags: ['Schedulers', 'Scheduler', 'DatasetSchedulers'],
    }),
    deleteScheduler: build.mutation({
      query: uuid => ({
        url: `schedulers/${uuid}`,
        method: 'DELETE',
      }),
      onQueryStarted: (uuid, { dispatch, queryFulfilled }) => {
        const schedulerResult = dispatch(schedulersApi.util.updateQueryData('getSchedulers', undefined, (draft) => {
          remove(draft, scheduler => scheduler.uuid === uuid)
        }))
        queryFulfilled.catch(schedulerResult.undo)
      },
      invalidatesTags: ['DatasetSchedulers'],
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetSchedulersQuery,
  useGetSchedulerQuery,
  useAddSchedulerMutation,
  useUpdateSchedulerMutation,
  useDeleteSchedulerMutation,
} = schedulersApi
