import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container,
} from 'reactstrap'

import Table from 'components/Catalog/Shared/Table'
import { BadgeTag } from 'components/Catalog/Shared/Tags'
import ICONS from 'helpers/iconConstants'

const COLUMNS = [
  {
    Header: 'Column name',
    accessor: 'name',
    disableFilters: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    disableFilters: true,
  },
  {
    Header: 'Description',
    accessor: 'comment',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Tags',
    accessor: 'tags',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ cell: { value } }) => value ? value.map((tag, i) => <BadgeTag key={`${tag}-${i}`} label={tag} />) : '', // eslint-disable-line
  },
]

const SchemaTab = ({ data }) => {
  const history = useHistory()
  const { workspaceUUID } = useParams()

  const onRowClick = ({ hash }) => {
    hash && history.push(`/workspaces/${workspaceUUID}/catalog/glossary/${hash}`)
  }

  return (
    <Container fluid>
      <Card className="rounded-0 position-relative shadow-none">
        <CardHeader className="position-sticky top-0 bg-white" style={{ zIndex: 1040 }}>
          <Row>
            <Col className="d-flex align-items-center">
              <i className={ICONS.TABLE_COLUMNS} />
              <h3 className="m-0 pl-2 d-inline-block">Schema</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="pt-0">
          <Row>
            <Col>
              <Table
                columns={COLUMNS}
                data={data.graph_meta.meta}
                type="assets"
                onRowClick={onRowClick}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  )
}

SchemaTab.propTypes = {
  data: PropTypes.object,
}

export default SchemaTab
