import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Row, Col, Table } from 'reactstrap'

import ModalContainer from 'components/Custom/ModalContainer'
import CategoricalChart from 'components/Main/DatasetViewPage/ColumnsTab/CategoricalChart'
import ContinuousChart from 'components/Main/DatasetViewPage/ColumnsTab/ContinuousChart'

import { useModalContext } from 'contexts/modalProvider'

const RowItem = ({ title, value, red = false }) => (
  <tr>
    <td className={classnames('h5 mb-0 border-bottom', { 'text-danger': red })}>{title}:</td>
    <td className="mb-0 border-bottom text-muted ">{value}</td>
  </tr>
)

RowItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  red: PropTypes.bool,
}

const VariableDetailsModal = () => {
  const { hideModal, store: { modalProps } } = useModalContext()
  const { variable } = modalProps

  const chartDataLength = useMemo(() => (variable.type === 'categorical'
    ? Object.keys(variable.show_categories || {}).length
    : variable.hist?.length && variable.hist[1]?.length
  ), [variable])

  return (
    <ModalContainer
      title={variable.name}
      isOpen={true}
      toggle={hideModal}
      size="xl"
    >
      {variable.description && (
        <Row>
          <Col xs={12}>
            <p className="mb-4">{variable.description}</p>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={5}>
          <Table size="sm" style={{ tableLayout: 'fixed' }}>
            <tbody>
              {variable.type !== 'categorical' && (
                <>
                  <RowItem value={variable.min} title="Minimum" />
                  <RowItem value={variable.max} title="Maximum" />
                  {variable.frequency
                    ? (<RowItem value={variable.frequency} title="Frequency" />)
                    : (<>
                      <RowItem value={variable.mean_} title="Mean" />
                      <RowItem value={variable.median_} title="Median" />
                    </>)
                  }
                </>
              )}
              <RowItem value={variable.unique_pct} title="Unique (%)" />
              {('missing_count' in variable) && !['0', 0].includes(variable.missing_count) && ( // TODO: After fix BE need to change this row
                <>
                  <RowItem value={variable.missing_count} title="Missing" red />
                  <RowItem value={variable.missing_pct} title="Missing (%)" red />
                </>
              )}
            </tbody>
          </Table>
        </Col>
        <Col md={7}>
          <div
            className="chart-area"
            style={{
              maxHeight: '380px',
              maxWidth: '100%',
              ...variable.type === 'categorical'
                ? chartDataLength && { height: `${chartDataLength * 40}px` }
                : chartDataLength && { width: `${chartDataLength * 40}px` },
            }}
          >
            {variable.type !== 'categorical'
              ? <ContinuousChart hist={variable.hist} />
              : <CategoricalChart showCategories={variable.show_categories} />}
          </div>
        </Col>
      </Row>
    </ModalContainer>
  )
}

export default VariableDetailsModal
