import React from 'react'
import PropTypes from 'prop-types'

import StyledTr from 'components/Shared/SummaryTab/StyledTr'

const SimpleTextRow = ({ label, text }) => (
  <StyledTr>
    <td className="align-middle font-weight-bold">{label}</td>
    <td className="align-middle">
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="pr-2 w-100 text-truncate">
          {text}
        </div>
      </div>
    </td>
  </StyledTr>
)

SimpleTextRow.propTypes = {
  label: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
}

export default SimpleTextRow
