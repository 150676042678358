import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  List,
  Collapse,
  ButtonGroup,
} from 'reactstrap'

import { ActionButton } from 'components/Catalog/Shared/Buttons'

import ICONS from 'helpers/iconConstants'
import { useModalContext, MODAL_TYPES } from 'contexts/modalProvider'

const Tables = ({ data = {} }) => {
  const { tables } = data
  const { showModal } = useModalContext()
  const [isCollapsed, setCollapsed] = useState(true)

  const showPreviewTableModal = tableName => showModal(MODAL_TYPES.PREVIEW_TABLE_MODAL, { tableName })
  const toggle = () => setCollapsed(!isCollapsed)

  if (!tables) return null

  return (
    <div className="border mt-2 bg-white p-3 rounded">
      <div className="d-flex justify-content-between align-items-center">
        <span className="flex-grow-1">I have used the following tables:</span>
        <ButtonGroup className="ml-3">
          <ActionButton
            label={<i className={isCollapsed ? ICONS.ANGLE_UP : ICONS.ANGLE_DOWN} />}
            onClick={toggle}
            className="ha-info-icon"
          />
        </ButtonGroup>
      </div>

      <Collapse isOpen={isCollapsed}>
        <div className="pt-3">
          <List className="m-0 pl-2" type="unstyled">
            {tables.map(table => (
              <li role="button" className="mb-1 ha-info-icon" key={table} onClick={() => showPreviewTableModal(table)}>
                <i className={`${ICONS.TABLE} mr-2`} />
                {table}
              </li>
            ))}
          </List>
        </div>
      </Collapse>
    </div>
  )
}

Tables.propTypes = {
  data: PropTypes.object,
}

export default Tables
