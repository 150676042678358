import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Badge,
} from 'reactstrap'

import ICONS from 'helpers/iconConstants'
import { numberToHuman } from 'helpers/utils'

const SummarySection = ({ data }) => (
  <Card className="rounded-0 position-relative shadow-none">
    <CardHeader className="bg-white position-sticky top-0" style={{ zIndex: 1040 }}>
      <Row>
        <Col className="d-flex align-items-center">
          <i className={ICONS.STREAM} />
          <h3 className="m-0 pl-2 d-inline-block">Summary</h3>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Row className="flex-wrap">
        <Col>
          <span className="d-block text-muted small">Rows</span>
          <span className="small">{numberToHuman(data?.graph_meta.rows, '')}</span>
        </Col>
        <Col>
          <span className="d-block text-muted small">Columns</span>
          <span className="small">{data?.graph_meta.columns}</span>
        </Col>
        <Col>
          <span className="d-block text-muted small">Connection</span>
          <span className="small">
            <i className={`${ICONS.SNOWFLAKE} text-info mr-2`} />
            <span className="text-info">snowflake/your-connection</span>
          </span>
        </Col>
        <Col>
          <span className="d-block text-muted small">Owners</span>

          <Badge color="light border" pill>
            <i className={`${ICONS.USER} mr-2`} role="button" />
            Valentin
          </Badge>
        </Col>
      </Row>
    </CardBody>
  </Card>
)

SummarySection.propTypes = {
  data: PropTypes.object,
}

export default SummarySection
