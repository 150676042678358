import divianApi from './api'
import { customRequest } from 'helpers/request'

export const userApi = divianApi.injectEndpoints({
  endpoints: build => ({
    getUser: build.query({
      query: uuid => `users/${uuid}`,
    }),
    getAccount: build.query({
      query: () => 'user',
      providesTags: ['User'],
    }),
    updateAccount: build.mutation({
      query: body => ({
        url: 'user',
        method: 'PUT',
        body,
      }),
      onQueryStarted: (patch, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(userApi.util.updateQueryData('getAccount', undefined, (draft) => { Object.assign(draft, patch) }))
        queryFulfilled.catch(patchResult.undo)
      },
      invalidatesTags: ['OrganizationUsers', 'User'],
    }),
    createToken: build.mutation({
      query: () => ({
        url: 'user/api_key',
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    uploadAvatar: build.mutation({
      queryFn: async (...args) => {
        const [base64File,,,fetchWithBQ] = args
        const { data: { url, fields } } = await fetchWithBQ({ url: 'user/avatar' })

        const file = await fetch(base64File)
          .then(res => res.blob())
          .then(blob => new File([blob], 'avatar',{ type: 'image/png' }))

        await customRequest({
          url,
          method: 'POST',
          multipart: true,
          data: { ...fields, file, acl: 'public-read' },
        })
        return fetchWithBQ({
          url: 'user/avatar',
          method: 'POST',
        })
      },
      invalidatesTags: ['User', 'OrganizationUsers'],
    }),
    changeEmail: build.mutation({
      query: email => ({
        url: 'user/change_email',
        method: 'POST',
        body: { email },
      }),
    }),
    confirmEmail: build.mutation({
      query: token => ({
        url: 'user/confirm_email',
        method: 'POST',
        body: { token },
      }),
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetUserQuery,
  useGetAccountQuery,
  useChangeEmailMutation,
  useConfirmEmailMutation,
  useUpdateAccountMutation,
  useCreateTokenMutation,
  useUploadAvatarMutation,
} = userApi
