import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SanitizedHTML from 'react-sanitized-html'
import TextEditor from './TextEditor'
import isEmpty from 'lodash/isEmpty'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Container } from 'reactstrap'
import { getDocument, selectDocument, updateDocument } from 'reducers/document'
import { allowedSchema } from 'helpers/tinymce'

const MainContent = ({
  setIsLoading,
  uuid,
}) => {
  const [editMode, setEditMode] = useState(false)
  const dispatch = useDispatch()

  const document = useSelector(state => selectDocument(state, { uuid }))

  useEffect(async () => {
    try {
      if (uuid && isEmpty(document)) {
        setIsLoading(true)
        await dispatch(getDocument(uuid)).unwrap()
      }
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image&async=true'
      document.head.appendChild(script)
      return () => {
        document.head.removeChild(script)
      }
    } catch (e) {
      console.error(e.message)
    } finally { setIsLoading(false) }
  }, [document])

  const handleChange = async (e) => {
    try {
      setIsLoading(true)
      const html = e.target.getContent()
      await dispatch(updateDocument({ uuid, data: { doc_html: html } })).unwrap()
    } catch (e) {
      console.error(e.message)
    } finally { setIsLoading(false) }
  }

  return isEmpty(document)
    ? <div className="py-md-3 pl-md-5 ct-content col-12 col-md-9 col-xl-8 tinymce-doc-wrapper"/>
    : (
      <div className="py-md-3 pl-md-5 ct-content col-12 col-md-9 col-xl-8 tinymce-doc-wrapper">
        {editMode
          ? <TextEditor value={document.doc_html} name={uuid} height="70vh" handleChange={handleChange} />
          : <SanitizedHTML
            allowedAttributes={allowedSchema.allowedAttributes}
            allowedSchemes={['data']}
            allowedTags={allowedSchema.allowedTags}
            disabled
            html={document.doc_html}
          />
        }
        <Container fluid className="py-3 text-right">
          <Button color="primary" onClick={() => setEditMode(!editMode)} type="button">
            <span className="btn-inner--text">
              {editMode ? 'Cancel' : 'Edit' }
            </span>
          </Button>
        </Container>
      </div>
    )
}

MainContent.propTypes = {
  setIsLoading: PropTypes.func,
  uuid: PropTypes.string,
}

export default MainContent
