import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Collapse,
  ButtonGroup,
} from 'reactstrap'

import { ActionButton } from 'components/Catalog/Shared/Buttons'
import ICONS from 'helpers/iconConstants'

const ThoughtInstructions = ({ data = {} }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [isCollapsed, setCollapsed] = useState(true)
  const {
    instructions, target, thought, tool,
  } = data

  const toggleVisible = () => setIsVisible(!isVisible)
  const toggleCollapsed = () => setCollapsed(!isCollapsed)

  if (!(thought || instructions || target || tool)) return null

  return (
    <div className="border bg-white p-3 rounded d-flex flex-nowrap align-items-center">
      <div className="flex-grow-1">
        {thought && (
          thought.replace('Thought: ', '')
        )}

        {instructions && (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <span className="flex-grow-1">Here is my sequence of actions:</span>
              <ButtonGroup className="ml-3">
                <ActionButton
                  label={<i className={isCollapsed ? ICONS.ANGLE_UP : ICONS.ANGLE_DOWN} />}
                  onClick={toggleCollapsed}
                  className="ha-info-icon"
                />
              </ButtonGroup>
            </div>

            <Collapse isOpen={isCollapsed}>
              <div className="pt-3">
                {instructions.split('\n').map((str, i) => <div className="mb-1 ml-3" key={i}>{str}</div>)}
              </div>
            </Collapse>
          </>
        )}
      </div>

      {(target || tool) && (
        <div className="ml-2 mr-n1 mt-n2 mb-3 text-right">
          <UncontrolledDropdown isOpen={isVisible} toggle={toggleVisible} size="sm">
            <DropdownToggle caret={false} className="shadow-none text-gray ha-info-icon" tag="span">
              <i className={ICONS.QUESTION_CIRCLE} onClick={toggleVisible} role="button" />
            </DropdownToggle>

            <DropdownMenu className="p-2">
              {target && (
                <div className="mb-1 text-nowrap small">
                  <span className="font-weight-bold mr-2">Target:</span>
                  {target}
                </div>
              )}

              {tool && (
                <div className="text-nowrap small">
                  <span className="font-weight-bold mr-2">Tool:</span>
                  {tool}
                </div>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      )}
    </div>
  )
}

ThoughtInstructions.propTypes = {
  data: PropTypes.shape({
    instructions: PropTypes.string,
    target: PropTypes.string,
    thought: PropTypes.string,
    tool: PropTypes.string,
  }),
}

export default ThoughtInstructions
