import divianApi from './api'
import remove from 'lodash/remove'

export const notificationsApi = divianApi.injectEndpoints({
  endpoints: build => ({
    getNotifications: build.query({
      query: () => 'user/notifications',
      providesTags: ['Notifications'],
    }),
    addInvite: build.mutation({
      query: ({ username, type, workspaceUUID }) => ({
        url: 'invites',
        method: 'POST',
        body: { username, type, workspace_uuid: workspaceUUID },
      }),
      invalidatesTags: ['WorkspaceMembers'],
    }),
    acceptInvite: build.mutation({
      query: uuid => ({
        url: `invites/${uuid}`,
        method: 'POST',
      }),
      async onQueryStarted(uuid, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(divianApi.util.updateQueryData('getNotifications', undefined, (draftNotifications) => {
          remove(draftNotifications, notification => notification.data?.uuid === uuid || notification.uuid === uuid)
        }))
        queryFulfilled.catch(patchResult.undo)
      },
      invalidatesTags: ['Workspaces'],
    }),
    declineInvite: build.mutation({
      query: uuid => ({
        url: `invites/${uuid}`,
        method: 'DELETE',
      }),
      async onQueryStarted(uuid, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(divianApi.util.updateQueryData('getNotifications', undefined, (draftNotifications) => {
          remove(draftNotifications, notification => notification?.data?.uuid === uuid || notification.uuid === uuid)
        }))
        queryFulfilled.catch(patchResult.undo)
      },
    }),
    deleteNotification: build.mutation({
      query: body => ({
        url: 'user/notifications',
        method: 'DELETE',
        body,
      }),
      async onQueryStarted({ uuid }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(divianApi.util.updateQueryData('getNotifications', undefined, (draftNotifications) => {
            remove(draftNotifications, notification => notification?.data?.asset_uuid === uuid)
          }))
        } catch {}
      },
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetNotificationsQuery,
  useAddInviteMutation,
  useAcceptInviteMutation,
  useDeclineInviteMutation,
  useDeleteNotificationMutation,
} = notificationsApi
