import React from 'react'
import PropTypes from 'prop-types'
import { Button, Spinner } from 'reactstrap'
import styled from 'styled-components'
import classnames from 'classnames'

const ActionButtonStyled = styled(Button)`
  &.disabled {
    color: inherit;
  }
`

export const ActionButton = ({
  label,
  className,
  size,
  isUpdating,
  ...options
}) => (
  <ActionButtonStyled
    outline
    className={classnames('border', { [className]: !!className })}
    size={size}
    {...options}
  >
    {isUpdating ? (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    ) : label}
  </ActionButtonStyled>
)

ActionButton.defaultProps = {
  size: 'sm',
  isUpdating: false,
}

ActionButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  size: PropTypes.string,
  isUpdating: PropTypes.bool,
}


const TextButtonStyled = styled(Button)`
  color: #525f7f;
  font-weight: 500;
  background: var(--secondary);

  &:hover {
    transform: none;
    border-color: var(--gray-dark) !important;
    background: var(--white);
  }

  &.disabled {
    color: inherit;
  }
`

export const TextButton = ({
  label,
  className,
  size,
  isUpdating,
  ...options
}) => (
  <TextButtonStyled
    outline
    className={classnames('border border-light shadow-none', { [className]: !!className })}
    size={size}
    {...options}
  >
    {isUpdating && (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        className="mr-2"
      />
    )}
    {label}
  </TextButtonStyled>
)

TextButton.defaultProps = {
  size: 'sm',
  isUpdating: false,
}

TextButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  size: PropTypes.string,
  isUpdating: PropTypes.bool,
}
