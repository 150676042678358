import React from 'react'

import {
  Col,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'

import ScrollBar from 'components/Custom/ScrollBar'
import NavigationTabs from 'components/Catalog/RequestPageView/KnowledgeView/NavigationTabs'
import OutputTab from 'components/Catalog/RequestPageView/KnowledgeView/OutputTab'
import ChatTab from 'components/Catalog/RequestPageView/KnowledgeView/ChatTab'

import { useActiveTab } from 'hooks/useActiveTab'

const KnowledgeView = () => {
  const activeTab = useActiveTab('output')
  return (
    <>
      <Row className="bg-white">
        <Col className="p-0">
          <NavigationTabs />
        </Col>
      </Row>

      <Row className="flex-grow-1">
        <Col className="px-0">
          <ScrollBar>
            <TabContent activeTab={activeTab} className="pt-3 h-100 d-flex flex-column">
              <TabPane tabId="output" className="flex-grow-1">
                <OutputTab />
              </TabPane>
              <TabPane tabId="chat" className="flex-grow-1">
                <ChatTab />
              </TabPane>
            </TabContent>
          </ScrollBar>
        </Col>
      </Row>
    </>
  )
}

export default KnowledgeView
