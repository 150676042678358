import React from 'react'
import PropTypes from 'prop-types'

const TableBody = ({
  page,
  prepareRow,
  onClick,
}) => (
  <tbody>
    {page.map((row) => {
      prepareRow(row)
      return (
        <tr {...row.getRowProps()}>
          {row.cells.map(cell => (
            <td
              {...cell.getCellProps()}
              className="text-truncate"
              {...onClick && {
                role: 'button',
                onClick: () => onClick(row.original),
              }}
            >
              {cell.render('Cell')}
            </td>))}
        </tr>
      )
    })}
  </tbody>
)

TableBody.defaultProps = {
  page: [],
}

TableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.object),
  prepareRow: PropTypes.func.isRequired,
  onClick: PropTypes.func,
}

export default TableBody
