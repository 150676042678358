import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
  Col,
  Row,
  Form,
  FormGroup,
  Input,
} from 'reactstrap'
import { ButtonWithSpinner } from 'components/Custom/Buttons'

import { withAlerts } from 'contexts/alertsProvider'
import { useChangeEmailMutation } from 'services/user'

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
})

const ChangePassword = ({ alerts }) => {
  const [changeEmail, { isLoading: isUpdating }] = useChangeEmailMutation()

  const submitForm = async ({ email }) => {
    try {
      await changeEmail(email).unwrap()
      alerts.success({ message: 'Confirmation link has been sent to your new email address' })
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: submitForm,
  })

  return (
    <Form>
      <Row>
        <Col md={4}>
          <FormGroup>
            <label className="form-control-label">Change Email</label>
            <Input
              autoComplete="off"
              placeholder="New Email"
              name="email"
              onChange={formik.handleChange}
              invalid={formik.touched.email && Boolean(formik.errors.email)}
            />
            { formik.touched.email && Boolean(formik.errors.email) && (
              <small className="text-danger d-block pt-1">{formik.errors.email}</small>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonWithSpinner
            label="Change Email"
            isUpdating={isUpdating}
            onClick={formik.handleSubmit}
          />
        </Col>
      </Row>
    </Form>
  )
}

ChangePassword.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
}

export default withAlerts(ChangePassword)
