import React from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'

import ICONS from 'helpers/iconConstants'
import { numberToHuman, removeYes } from 'helpers/utils'

export const TableInfo = ({ data }) => (
  <Row>
    <Col className="small">
      <ListGroup horizontal className="flex-wrap">

        {data.type && (
          <ListGroupItem className="border-0 pl-0 pb-0 pt-1 mt-2 text-muted text-truncate">
            <i className={`${ICONS.TABLE} mr-1`} />
            {data.type}
          </ListGroupItem>)}

        {data.rows && (
          <ListGroupItem className="border-0 pl-0 pb-0 pt-1 mt-2 text-muted text-truncate">
            {numberToHuman(data.rows, 'row')}
          </ListGroupItem>)}

        {data.columns && (
          <ListGroupItem className="border-0 pl-0 pb-0 pt-1 mt-2 text-muted text-truncate">
            {numberToHuman(data.columns, 'column')}
          </ListGroupItem>)}

        {data.db_name && (
          <ListGroupItem className="border-0 pl-0 pb-0 pt-1 mt-2 text-muted text-truncate">
            <i className={`${ICONS.DATABASE} mr-1`} />
            {data.db_name}
          </ListGroupItem>)}

        {data?.schema && (
          <ListGroupItem className="border-0 pl-0 pb-0 pt-1 mt-2 text-muted text-truncate">
            <i className={`${ICONS.SITEMAP} mr-1`} />
            {removeYes(data.schema)}
          </ListGroupItem>
        )}

      </ListGroup>
    </Col>
  </Row>
)

TableInfo.defaultProps = {
  data: {},
}

TableInfo.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    db_name: PropTypes.string,
    folder_name: PropTypes.string,
    schema: PropTypes.string,
    type: PropTypes.string,
    source_type: PropTypes.string,
  }),
}

export default TableInfo
