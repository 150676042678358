import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import {
  Row,
  Col,
  Button,
} from 'reactstrap'

import ScrollBar from 'components/Custom/ScrollBar'
import ModalContainer from 'components/Custom/ModalContainer'
import TableBodyWrapper, { EmptyContentWithText } from 'components/Main/ModelViewPage/ModelStructure/TableBodyWrapper'

import { useModalContext } from 'contexts/modalProvider'

const SCROLL_HEIGHT = '350px'

const ModelTargetModal = ({
  variables = [],
  targetVariable = {},
  setData,
}) => {
  const { hideModal } = useModalContext()
  const [target, setTarget] = useState(targetVariable)

  const availableVariables = useMemo(() => variables.filter(({ name }) => name !== target.name), [target])

  const selectTargetHandler = () => {
    setData(state => ({ ...state, forecast_variable_name: target?.name }))
    hideModal()
  }

  return (
    <ModalContainer
      title="Select Target"
      isOpen={true}
      toggle={hideModal}
      size="lg"
    >
      <Row>
        <Col xs={6}>
          <span className="text-uppercase font-weight-bold">Variables</span>
          <ScrollBar style={{ height: SCROLL_HEIGHT }} className="border mt-2">
            {isEmpty(availableVariables)
              ? <EmptyContentWithText text="No variables" />
              : <TableBodyWrapper>
                {availableVariables.map(el => (
                  <tr className="border-bottom" key={el.name} onClick={() => setTarget(el)} role="button">
                    <td className="align-middle border-right text-truncate">{el.name}</td>
                    <td className="align-middle text-truncate">{el.type_main}</td>
                  </tr>))}
              </TableBodyWrapper>}
          </ScrollBar>
        </Col>
        <Col xs={6}>
          <span className="text-uppercase font-weight-bold">Target</span>
          <ScrollBar style={{ height: SCROLL_HEIGHT }} className="border mt-2">
            {isEmpty(target)
              ? <EmptyContentWithText text="No target selected" />
              : <TableBodyWrapper>
                <tr className="border-bottom" onClick={() => setTarget({})} role="button">
                  <td className="align-middle border-right text-truncate">{target.name}</td>
                  <td className="align-middle text-truncate">{target.type_main}</td>
                </tr>
              </TableBodyWrapper>}
          </ScrollBar>
        </Col>
        <Col xs={12} className="text-right my-1 pt-3">
          <Button outline color="info" onClick={selectTargetHandler}>
            Done
          </Button>
        </Col>
      </Row>
    </ModalContainer>
  )
}

ModelTargetModal.propTypes = {
  variables: PropTypes.arrayOf(PropTypes.shape({})),
  targetVariable: PropTypes.shape({}),
  setData: PropTypes.func,
}

export default ModelTargetModal
