import React from 'react'
import PropTypes from 'prop-types'

import StyledWrapper from 'components/Catalog/RequestPageView/DataViewTilesOnly/BotChat/Message/StyledWrapper'
import Nodes from 'components/Catalog/RequestPageView/DataViewTilesOnly/BotChat/Message/Nodes'
import Data from 'components/Catalog/RequestPageView/DataViewTilesOnly/BotChat/Message/Data'
import Observation from 'components/Catalog/RequestPageView/DataViewTilesOnly/BotChat/Message/Observation'
import Tables from 'components/Catalog/RequestPageView/DataViewTilesOnly/BotChat/Message/Tables'
import Query from 'components/Catalog/RequestPageView/DataViewTilesOnly/BotChat/Message/Query'
import ThoughtInstructions from 'components/Catalog/RequestPageView/DataViewTilesOnly/BotChat/Message/ThoughtInstructions'

import ICONS from 'helpers/iconConstants'

const Message = ({ intermediateSteps = {} }) => (
  <div className="d-flex flex-nowrap mb-3 w-100">
    <i className={`${ICONS.ROBOT} mb-1 display-4`} />

    <StyledWrapper className="bg-lightest rounded border ml-3 p-3 mb-4">
      <ThoughtInstructions data={intermediateSteps} />
      <Query data={intermediateSteps} />
      <Tables data={intermediateSteps} />
      <Nodes data={intermediateSteps} />
      <Data data={intermediateSteps} />
      <Observation data={intermediateSteps} />
    </StyledWrapper>
  </div>
)

Message.propTypes = {
  intermediateSteps: PropTypes.shape({}),
}

export default Message
