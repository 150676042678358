import React from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Table,
} from 'reactstrap'

import { NewButton } from 'components/Custom/Buttons'
import MemberRow from 'components/Main/AccountPage/Members/MemberRow'

import { useGetOrganizationQuery, useGetUsersQuery } from 'services/organization'
import { useModalContext, MODAL_TYPES } from 'contexts/modalProvider'

const Members = () => {
  const { data: members = [] } = useGetUsersQuery()
  const { data: organization = {} } = useGetOrganizationQuery()
  const { showModal: showAddMemberModal } = useModalContext()

  return (
    <>
      <h2 className="m-0">Members</h2>
      <hr />
      <Row>
        <Col className="text-right mb-3">
          <NewButton label="Invite" onClick={() => showAddMemberModal(MODAL_TYPES.ADD_MEMBERS_MODAL, { organisationName: organization.public_name })} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table style={{ tableLayout: 'fixed' }}>
            <colgroup>
              <col width="60%" style={{ minWidth: '300px' }} />
              <col width="40%" style={{ minWidth: '150px' }} />
              <col width="80px" style={{ minWidth: '80px' }} />
            </colgroup>
            <thead className="thead-light">
              <tr>
                <th>Name</th>
                <th>Account Type</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {members.map(member => (
                <MemberRow key={member.sub} data={member} />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  )
}

Members.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
}

export default Members
