import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'
import RestrictionListSection from 'components/Main/SourceViewPage/CreateSourceModal/Forms/FileForm/RestrictionListSection'
import FileUploadIcon from 'assets/img/document.png'
import { Col, Row } from 'reactstrap'

const StyledSection = styled.section`
  transition: border .24s ease-in-out;
  height: 200px;
  border: ${({ isDragReject, isDragAccept }) => (isDragReject ? '#fb6340' : isDragAccept ? '#2dce89' : '#525f7f')} 1px dotted;
`

const DropSection = ({ onDrop }) => (
  <Row>
    <Col md={12}>
      <Dropzone onDrop={onDrop} accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
        {({
          getRootProps, getInputProps, isDragAccept, isDragReject,
        }) => (
          <StyledSection isDragAccept={isDragAccept} isDragReject={isDragReject} className="d-flex justify-content-center align-items-center rounded p-2">
            <div role="button" className="d-flex align-items-center justify-content-center flex-column w-100 h-100" {...getRootProps()}>
              <input {...getInputProps()} multiple={false} />
              <img src={FileUploadIcon} alt="Upload file" width="45vh" /><br/>
              <p className="title text-default">Please drag and drop .CSV or .XLSX file or click here</p>
            </div>
          </StyledSection>
        )}
      </Dropzone>
    </Col>

    <RestrictionListSection />
  </Row>
)

DropSection.propTypes = {
  onDrop: PropTypes.func,
}

export default DropSection
