import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { format } from 'sql-formatter'
import {
  Collapse,
  ButtonGroup,
} from 'reactstrap'

import CopyToClipboardButton from 'components/Shared/CopyToClipboardButton'
import { ActionButton } from 'components/Catalog/Shared/Buttons'
import ICONS from 'helpers/iconConstants'

const Query = ({ data = {} }) => {
  const [isCollapsed, setCollapsed] = useState(true)
  const { query, type } = data

  const toggle = () => setCollapsed(!isCollapsed)

  if (!query) return null

  return (
    <div className="border mt-2 bg-white p-3 rounded">

      <div className="d-flex justify-content-between align-items-center">
        <span className="flex-grow-1">
          I have generated the following {type === 'knowledge' ? 'Cypher' : 'SQL'} query:
        </span>
        <ButtonGroup className="ml-3">
          <ActionButton
            label={<CopyToClipboardButton asIcon textToCopy={query} />}
            className="ha-info-icon"
          />
          <ActionButton
            label={<i className={isCollapsed ? ICONS.ANGLE_UP : ICONS.ANGLE_DOWN} />}
            onClick={toggle}
            className="ha-info-icon"
          />
        </ButtonGroup>
      </div>

      <Collapse isOpen={isCollapsed}>
        <div className="pt-3">
          <pre className="ml-3">
            <code className="small">{format(query, { uppercase: true })}</code>
          </pre>
        </div>
      </Collapse>
    </div>
  )
}

Query.propTypes = {
  data: PropTypes.shape({
    query: PropTypes.string,
    type: PropTypes.string,
  }),
}

export default Query
