import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  Badge,
} from 'reactstrap'

export const BadgeTag = ({
  label,
  className,
  ...options
}) => (
  <Badge
    color="gray"
    pill
    className={classnames('border border-dark rounded', { [className]: className })}
    {...options}
  >
    {label}
  </Badge>
)

BadgeTag.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
}
