import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useWizard } from 'react-use-wizard'

import {
  Col,
  Input,
  Label,
  Row,
} from 'reactstrap'

import DatasetList from 'components/Main/ModelViewPage/CreateNewModal/DatasetList'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { useGetDatasetsQuery } from 'services/datasets'

const SecondStep = ({ values, setValues }) => {
  const [errors, setErrors] = useState({})

  const [datasetKeyword, setDatasetKeyword] = useState('')

  const { data = [], isLoading } = useGetDatasetsQuery(false)

  const handleChange = (field, value) => {
    setValues(state => ({ ...state, [field]: value }))
    setErrors(state => ({ ...state, [field]: !value.length }))
  }

  const datasets = useMemo(() => {
    if (!datasetKeyword.length) return data
    return data.filter(dataset => dataset.name.toLocaleUpperCase().includes(datasetKeyword.toLocaleUpperCase()))
  }, [data, datasetKeyword])

  const { handleStep } = useWizard()

  handleStep(() => {
    if (!values.datasetUUID) {
      setErrors(state => ({ ...state, datasetUUID: !values?.datasetUUID }))
      throw new Error('No dataset uuid')
    }
  })

  return (
    <Row className="h-100 flex-column">
      <Col xs={12} className="flex-basis-0">
        <Label className="w-100 mt-3 mb-2 font-weight-bold">
          Select Dataset
        </Label>
      </Col>

      <Col xs={12} className="flex-grow-1 flex-basis-0">
        <div className={classnames('border p-3 h-100', { 'border-warning': errors.datasetUUID })}>
          <Row className="h-100 flex-column">
            <Col sm={6} md={4} className="flex-basis-0">
              <Input
                autoComplete="off"
                bsSize="sm"
                className="text-monospace"
                name="search"
                style={{ height: 'calc(0.45em + 1.25rem + 12px)' }}
                placeholder="Search Dataset..."
                type="text"
                onChange={e => setDatasetKeyword(e.target.value)}
                value={datasetKeyword}
              />
            </Col>

            <Col xs={12} className="pt-4 flex-grow-1 flex-basis-0">
              {isLoading
                ? <Spinner />
                : datasets.length
                  ? <DatasetList data={datasets} values={values} onChange={handleChange} />
                  : <Row className="h-100 align-items-center justify-content-center text-muted">No datasets found</Row>}
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  )
}

SecondStep.propTypes = {
  values: PropTypes.shape({
    datasetUUID: PropTypes.string,
  }),
  setValues: PropTypes.func,
}

export default SecondStep
