import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ModalContainer from 'components/Custom/ModalContainer'

import FirstStep from 'components/Catalog/AssetsPage/AddConnectionModal/FirstStep'
import SecondStep from 'components/Catalog/AssetsPage/AddConnectionModal/SecondStep'
import WizardWrapper from 'components/Main/DatasetViewPage/CreateNewModal/WizardWrapper'
import WizardFooter from 'components/Catalog/AssetsPage/AddConnectionModal/WizardFooter'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { Wizard } from 'react-use-wizard'
import { useAddWorkspaceCatalogMutation, useUpdateWorkspaceMutation } from 'services/workspaces'
import { useModalContext } from 'contexts/modalProvider'
import { withAlerts } from 'contexts/alertsProvider'

const GOOGLE_DRIVE_UUID = '00000000-0000-0000-0000-000000000000'

const AddConnectionModal = ({ alerts }) => {
  const { hideModal, store: { modalProps } } = useModalContext()
  const { workspaceUUID } = modalProps
  const [addWorkspaceCatalog, { isLoading }] = useAddWorkspaceCatalogMutation()
  const [updateWorkspace, { isLoading: isUpdating }] = useUpdateWorkspaceMutation()

  const [values, setValues] = useState({
    title: 'Add New Connection',
    workspaceUUID,
    tables: [],
    uuid: null,
  })

  const handleFormSubmit = async () => {
    try {
      if (values.uuid === GOOGLE_DRIVE_UUID) {
        await updateWorkspace({ workspaceUUID, google_drive: true }).unwrap()
      } else {
        await addWorkspaceCatalog(values).unwrap()
      }
      hideModal()
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  return (
    <ModalContainer
      isOpen={true}
      toggle={hideModal}
      centered={true}
      title={values.title}
    >
      {(isLoading || isUpdating) ? (
        <Spinner />
      ) : (
        <Wizard
          footer={<WizardFooter
            setValues={setValues}
            values={values}
            handleFormSubmit={handleFormSubmit}
          />}
          wrapper={<WizardWrapper />}
        >
          <FirstStep values={values} setValues={setValues} />
          <SecondStep values={values} setValues={setValues} />
        </Wizard>
      )}
    </ModalContainer>
  )
}

AddConnectionModal.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
}

export default withAlerts(AddConnectionModal)
