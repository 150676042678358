import { lazy } from 'react'
import ICONS from 'helpers/iconConstants'

// MAIN
const HomePage = lazy(() => import('components/Main/HomePage'))
const SourcesPage = lazy(() => import('components/Main/SourcesPage'))
const DatasetsPage = lazy(() => import('components/Main/DatasetsPage'))
const SchedulersPage = lazy(() => import('components/Main/SchedulersPage'))
const ModelsPage = lazy(() => import('components/Main/ModelsPage'))
const ContainerViewPage = lazy(() => import('components/Main/ContainerViewPage'))
//const BindersPage = lazy(() => import('components/Main/BindersPage'))
const DocumentsPage = lazy(() => import('components/Main/DocumentsPage'))
const OntologyViewPage = lazy(() => import('components/Main/OntologyViewPage'))
const DocumentViewPage = lazy(() => import('components/Main/DocumentViewPage'))
const WorkspacePage = lazy(() => import('components/Main/WorkspacePage'))
const ModelViewPage = lazy(() => import('components/Main/ModelViewPage'))
const DatasetViewPage = lazy(() => import('components/Main/DatasetViewPage'))
const SourceViewPage = lazy(() => import('components/Main/SourceViewPage'))
const UserPage = lazy(() => import('components/Main/UserPage'))
const WorkbooksPage = lazy(() => import('components/Main/WorkbooksPage'))
const NotebookViewPage = lazy(() => import('components/Main/NotebookViewPage'))

const ConfirmEmail = lazy(() => import('components/Main/InternalLinks/ConfirmEmail'))
const AcceptInvitePage = lazy(() => import('components/Main/InternalLinks/AcceptInvitePage'))

const { REACT_APP_ENVIRONMENT } = process.env

// const getLabsPath = () => {
//   let path = ''
//   if (window.location.hostname.includes('polar')) path = 'polar-'
//   return path
//}

const mainRoutes = [
  {
    name: 'Home',
    path: '/workspaces/:workspaceUUID/home',
    icon: `${ICONS.HOME} text-primary`,
    state: 'cloudCollapse',
    component: HomePage,
    miniName: 'H',
  },
  {
    path: '/workspaces/:workspaceUUID/workbooks',
    name: 'Dashboards',
    icon: `${ICONS.DASH} text-primary`,
    miniName: 'S',
    component: WorkbooksPage,
  },
  {
    path: '/workspaces/:workspaceUUID/ontology',
    name: 'Ontology',
    miniName: 'O',
    icon: `${ICONS.DIAGRAM} text-primary`,
    component: OntologyViewPage,
  },
  {
    path: '/workspaces/:workspaceUUID/connectors/:uuid',
    name: '',
    component: SourceViewPage,
    nested: true,
  },
  {
    path: '/workspaces/:workspaceUUID/connectors',
    name: 'Connectors',
    icon: `${ICONS.SERVER} text-primary`,
    miniName: 'D',
    component: SourcesPage,
  },
  {
    path: '/workspaces/:workspaceUUID/datasets/:uuid',
    name: '',
    component: DatasetViewPage,
    nested: true,
  },
  {
    path: '/workspaces/:workspaceUUID/datasets',
    name: 'Datasets',
    icon: `${ICONS.STREAM} text-primary`,
    miniName: 'D',
    component: DatasetsPage,
  },
  {
    path: '/workspaces/:workspaceUUID/models/:uuid',
    name: '',
    component: ModelViewPage,
    nested: true,
  },
  {
    path: '/workspaces/:workspaceUUID/models',
    name: 'Models',
    miniName: 'M',
    icon: `${ICONS.MODELS} text-primary`,
    component: ModelsPage,
  },
  // {
  //   path: '/binders',
  //   name: 'Binders',
  //   icon: `${ICONS.FOLDER} text-primary`,
  //   miniName: 'B',
  //   component: BindersPage,
  // }
  {
    path: '/workspaces/:workspaceUUID/schedulers',
    name: 'Schedulers',
    icon: `${ICONS.SCHEDULERS} text-primary`,
    miniName: 'S',
    component: SchedulersPage,
  },
  {
    path: '/workspaces/:workspaceUUID/containers/:uuid',
    name: '',
    component: ContainerViewPage,
    nested: true,
  },
  {
    path: '/workspaces/:workspaceUUID/notes/new',
    name: '',
    component: DocumentViewPage,
    nested: true,
  },
  {
    path: '/workspaces/:workspaceUUID/notes/:uuid',
    name: 'Documents',
    component: DocumentViewPage,
    nested: true,
  },
  {
    path: '/workspaces/:workspaceUUID/notes',
    name: 'Notes',
    miniName: 'N',
    icon: `${ICONS.NOTE} text-primary`,
    component: DocumentsPage,
  },
  {
    path: '/workspaces/:workspaceUUID/users/:uuid',
    name: 'User',
    component: UserPage,
    nested: true,
  },
  {
    path: '/invites/:uuid',
    name: 'Invite',
    component: AcceptInvitePage,
    nested: true,
  },
  {
    path: '/email_confirm/:uuid',
    name: 'Email',
    component: ConfirmEmail,
    nested: true,
  },
  ...REACT_APP_ENVIRONMENT === 'dev' ? [{
    path: '/workspaces/:workspaceUUID/notebook',
    name: 'Code Notebook',
    icon: `${ICONS.CODE} text-primary`,
    miniName: 'N',
    component: NotebookViewPage,
  }] : [],
  {
    path: '/workspaces/:workspaceUUID',
    name: '',
    component: WorkspacePage,
    nested: true,
  },
  // {
  //   path: '/api',
  //   url: `https://${getLabsPath()}labs.divian.com/`,
  //   name: 'Labs',
  //   icon: `${ICONS.SPACESHIP} text-primary`,
  // },
]

export default mainRoutes
