import React, { useEffect } from 'react'
import { useWizard } from 'react-use-wizard'
import PropTypes from 'prop-types'

import { Row, Col } from 'reactstrap'
import HeadersPreview from 'components/Main/DatasetViewPage/CreateNewModal/HeadersPreview'
import QueryPreview from 'components/Main/DatasetViewPage/CreateNewModal/QueryPreview'
import ColumnsTable from 'components/Main/DatasetViewPage/CreateNewModal/Tables/ColumnsTable'
import DatabaseHeadersPreview from 'components/Main/DatasetViewPage/CreateNewModal/DatabaseHeadersPreview'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { useGetContainerSourceQuery } from 'services/containers'

import { findNames } from 'helpers/datasets'
import { FILE_SOURCES } from 'helpers/constants'

const FirstStep = ({
  values,
  setValues,
  namePrefix,
  selectAll,
}) => {
  const { handleStep } = useWizard()
  const {
    sourceUUID,
    sourceType,
    showQuery,
    type,
  } = values

  const { data = {}, isFetching } = useGetContainerSourceQuery({ uuid: sourceUUID, type }, { skip: !sourceUUID })

  useEffect(() => {
    if (isFetching) return
    setValues(state => ({
      ...state,
      sourceType: data.type || 'file',
      datasetName: `${namePrefix}${data.name}`,
      ...data.version_uuid && { versionUUID: data.version_uuid },
    }))
  }, [isFetching, data])

  useEffect(() => {
    if (!values.selectedKeys.length || !values.datasetKeys) return
    setValues(state => ({
      ...state,
      ...values.job && {
        selectedKeyNames: findNames(values.selectedKeys, data.data?.schema?.fields || data.header?.schema?.fields),
        datasetFieldNames: findNames(values.datasetKeys, data.data?.schema?.fields || data.header?.schema?.fields),
      },
    }))
  }, [isFetching, values.job])

  handleStep(() => {
    if (!values?.selectedKeys?.length) throw new Error('No source keys')
  })

  if (isFetching) return <Spinner />
  if (!sourceType) return null

  return (
    <>
      {FILE_SOURCES.includes(sourceType) ? (
        <>
          <Row>
            <Col className="d-flex align-items-center flex-nowrap py-3">
              <label className="custom-toggle mr-1">
                <input
                  type="checkbox"
                  checked={showQuery}
                  onChange={() => setValues(state => ({ ...state, showQuery: !showQuery }))}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
              <small className="text-primary font-weight-bold">Use query</small>
            </Col>
          </Row>
          {showQuery ? (
            <Row className="flex-grow-1">
              <Col xs="4">
                <ColumnsTable preview fields={(data.data || data.header)?.schema?.fields || []} />
              </Col>
              <Col xs="8" className="d-flex flex-column">
                <QueryPreview values={values} setValues={setValues} />
              </Col>
            </Row>
          ) : (
            <HeadersPreview
              header={data.data || data.header}
              values={values}
              setValues={setValues}
              selectAll={selectAll}
            />
          )}
        </>
      ) : (
        <DatabaseHeadersPreview values={values} setValues={setValues} sourceType={sourceType} />
      )}
    </>
  )
}

FirstStep.propTypes = {
  values: PropTypes.shape({
    job: PropTypes.string,
    type: PropTypes.string,
    showQuery: PropTypes.bool,
    sourceType: PropTypes.string,
    sourceUUID: PropTypes.string,
    selectedKeys: PropTypes.array,
    datasetKeys: PropTypes.array,
    sourceKeys: PropTypes.array,
  }),
  setValues: PropTypes.func,
  namePrefix: PropTypes.string,
  selectAll: PropTypes.bool,
}

export default FirstStep
