import React from 'react'
import PropTypes from 'prop-types'

const Observation = ({ data = {} }) => {
  const { observation } = data

  if (!observation) return null

  return (
    <div className="border mt-2 bg-white p-3 rounded">
      {observation.replace('Answer: ', '')}
    </div>
  )
}

Observation.propTypes = {
  data: PropTypes.shape({
    observation: PropTypes.string,
  }),
}

export default Observation
