import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'
import {
  Col,
  Row,
  Collapse,
  ListGroupItem,
  ButtonGroup,
} from 'reactstrap'

import AssetConfigSlidePanel from 'components/Catalog/AssetsPage/AssetConfigSlidePanel'
import ParentInfo from 'components/Catalog/AssetsPage/ListItem/ParentInfo'
import TableInfo from 'components/Catalog/AssetsPage/ListItem/TableInfo'
import FolderInfo from 'components/Catalog/AssetsPage/ListItem/FolderInfo'
import { ActionButton } from 'components/Catalog/Shared/Buttons'

import ICONS from 'helpers/iconConstants'
import { SOURCE_TYPES } from 'helpers/constants'

const StyledItem = styled(ListGroupItem)`
border-color: ${props => props.selected ? 'var(--info)' : '#e9ecef'} !important;
  &:hover {
    border-color: var(--info) !important;
  }
`

export const ListItem = ({
  data,
  onClick,
  selected,
  children,
  fullWidth,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [isOpen, setOpen] = useState(false)

  const toggle = () => setIsCollapsed(!isCollapsed)

  return (
    <>
      <StyledItem
        className={classnames('mb-3 border rounded position-relative', {
          'ml-5': !fullWidth && !children,
        })}
        selected={selected}
        role="button"
        onClick={onClick || toggle}
      >
        <Row className="align-items-center">
          <Col style={{ fontSize: '0.85rem' }} className="text-truncate">
            <i className={classnames('mr-2', {
              [`${ICONS.SNOWFLAKE} text-info`]: data.source_type === SOURCE_TYPES.SNOWFLAKE,
              [`${ICONS.GOOGLE_DRIVE} text-info`]: data.source_type === SOURCE_TYPES.GOOGLE_DRIVE,
              [`${ICONS.DATABASE} text-green`]: ![SOURCE_TYPES.SNOWFLAKE, SOURCE_TYPES.GOOGLE_DRIVE].includes(data.source_type),
            })} />
            <span className="font-weight-bold">{data.name}</span>
          </Col>

          {children && (
            <Col xs="auto" className="small">
              <ButtonGroup>
                <ActionButton
                  label={<i className={`${ICONS.GEAR} text-muted`}/>}
                  onClick={(e) => {
                    e.stopPropagation()
                    setOpen(true)
                  }}
                />
                <ActionButton label={<i className={`text-muted ${isCollapsed ? ICONS.ANGLE_UP : ICONS.ANGLE_DOWN}`} />} />
              </ButtonGroup>
            </Col>
          )}
        </Row>

        {children
          ? <ParentInfo data={data} />
          : data.source_type === SOURCE_TYPES.GOOGLE_DRIVE
            ? <FolderInfo data={data} />
            : <TableInfo data={data} />}
      </StyledItem>

      {children && (
        <Collapse isOpen={isCollapsed}>
          {children}
        </Collapse>
      )}

      <AssetConfigSlidePanel isOpen={isOpen} setOpen={setOpen} data={data} />
    </>
  )
}

ListItem.defaultProps = {
  selected: false,
  fullWidth: false,
}

ListItem.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.shape({
    rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    db_name: PropTypes.string,
    folder_name: PropTypes.string,
    schema: PropTypes.string,
    type: PropTypes.string,
    source_type: PropTypes.string,
    source_uuid: PropTypes.string,
  }),
  selected: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
}

export default ListItem
