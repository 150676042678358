import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import lodashKeys from 'lodash/keys'
import take from 'lodash/take'

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container,
} from 'reactstrap'

import StickyTable from 'components/Catalog/Shared/StickyTable'

import ICONS from 'helpers/iconConstants'

const DataTab = ({ data }) => {
  const { header, body } = useMemo(() => {
    const tableData = data?.header?.data || []

    return {
      body: take(tableData, 200),
      header: lodashKeys(tableData[0]),
    }
  }, [data])

  return (
    <Container fluid>
      <Card className="rounded-0 position-relative shadow-none">
        <CardHeader className="position-sticky top-0 bg-white" style={{ zIndex: 1040 }}>
          <Row>
            <Col className="d-flex align-items-center">
              <i className={ICONS.DIAGRAM} />
              <h3 className="m-0 pl-2 d-inline-block">Data preview</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <StickyTable header={header} body={body} maxHeight="67vh" />
        </CardBody>
      </Card>
    </Container>
  )
}

DataTab.propTypes = {
  data: PropTypes.object,
}

export default DataTab
