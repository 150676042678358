import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import last from 'lodash/last'
import {
  Col,
  Row,
} from 'reactstrap'

import BillingChart from 'components/Main/AccountPage/Billing/BillingChart'

import { formatDecimalNumber } from 'helpers/utils'
import { useGetBillingQuery } from 'services/organization'
import { useGetAccountQuery } from 'services/user'

const Billing = ({ activeTab }) => {
  const { data: billing = {} } = useGetBillingQuery()
  const { data: account = {} } = useGetAccountQuery()

  const isAdmin = useMemo(() => account.usertype === 'admin', [account.usertype])

  const chartData = useMemo(() => (billing.total_costs?.reduce((res, { TimePeriod, Total }) => {
    res[0].push(formatDecimalNumber(Total.AmortizedCost.Amount))
    res[1].push(moment(TimePeriod.Start).format('MMM'))
    return res
  }, [[], []])
  ), [billing.total_costs])

  const chartDataLength = useMemo(() => chartData?.[1]?.length, [chartData])

  if (!isAdmin) return null
  if (activeTab !== 'billing') return null

  return (
    <>
      <Row>
        <Col>
          <table>
            <tbody>
              <tr>
                <td className="h2 py-2 pr-4">Total {moment(last(billing.total_costs)).format('MMMM')} Cost, USD</td>
                <td className="h2 text-monospace text-primary">
                  {formatDecimalNumber(billing.total_cost)}
                </td>
              </tr>
              <tr>
                <td className="h4 py-2 pr-4">Storage Cost, USD</td>
                <td className="h4 text-monospace">
                  {formatDecimalNumber(billing.storage_cost)}
                </td>
              </tr>
              <tr>
                <td className="h4 py-2 pr-4">Computational Cost, USD</td>
                <td className="h4 text-monospace">
                  {formatDecimalNumber(billing.computational_cost)}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          {chartDataLength && (
            <div
              className="chart-area"
              style={{
                maxHeight: '300px',
                maxWidth: `${chartDataLength * 60}px`,
              }}
            >
              <BillingChart hist={chartData} />
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

Billing.propTypes = {
  activeTab: PropTypes.string.isRequired,
}

export default Billing
