import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Wizard } from 'react-use-wizard'
import { useHistory } from 'react-router-dom'

import ModalContainer from 'components/Custom/ModalContainer'
import WizardSteps from 'components/Main/DatasetViewPage/CreateNewModal/WizardSteps'
import WizardWrapper from 'components/Main/DatasetViewPage/CreateNewModal/WizardWrapper'
import WizardFooter from 'components/Main/DatasetViewPage/CreateNewModal/WizardFooter'
import FirstStep from 'components/Main/ContainerViewPage/ExtractDataModal/FirstStep'
import SecondStep from 'components/Main/ContainerViewPage/ExtractDataModal/SecondStep'

import { withAlerts } from 'contexts/alertsProvider'
import { useModalContext } from 'contexts/modalProvider'
import { useAddDatasetMutation } from 'services/datasets'
import { useAddContainerMutation } from 'services/containers'

import { FILE_SOURCES, CONTAINER_ACTIONS } from 'helpers/constants'

const ExtractDataModal = ({ alerts }) => {
  const [addDataset, { isLoading }] = useAddDatasetMutation()
  const [addContainer, { isLoading: isAdding }] = useAddContainerMutation()

  const { hideModal, store: { modalProps } } = useModalContext()
  const history = useHistory()
  const {
    type,
    actionType,
    sourceUUID,
    workspaceUUID,
  } = modalProps

  const [values, setValues] = useState({
    selectedKeys: [],
    showQuery: false,
    sourceUUID,
    type,
  })

  const handleFormSubmit = async () => {
    const {
      tableName,
      query,
      schemaName,
      databaseName,
      datasetName,
      showQuery,
      versionUUID,
    } = values
    const data = {
      type,
      indicators_list: values.selectedKeys,
      name: datasetName,
      uuid: values.sourceUUID,
      ...versionUUID && { version_uuid: versionUUID },
      ...!showQuery && tableName && {
        table_name: tableName,
        schema_name: schemaName,
        db_name: databaseName,
      },
      ...showQuery && query && {
        query,
        db_name: databaseName,
      },
    }
    try {
      if (actionType === CONTAINER_ACTIONS.MERGE) {
        const { uuid: containerUUID } = await addContainer({
          ...data,
          action_type: CONTAINER_ACTIONS.MERGE,
        }).unwrap()
        history.push(`/workspaces/${workspaceUUID}/containers/${containerUUID}`)
      } else {
        const { uuid: datasetUUID } = await addDataset({
          ...data,
          action_type: CONTAINER_ACTIONS.EXTRACTION,
        }).unwrap()
        history.push(`/workspaces/${workspaceUUID}/datasets/${datasetUUID}`)
      }
      hideModal()
    } catch ({ data: { message } = {} }) {
      alerts.error({ message })
    }
  }

  const steps = useMemo(() => ({
    1: FILE_SOURCES.includes(values.sourceType) ? 'Select Columns' : 'Select Table and Columns',
    2: 'Summary',
  }), [values.sourceType])

  return (
    <ModalContainer
      title={actionType === CONTAINER_ACTIONS.MERGE ? 'Merge Data' : 'Extract Data'}
      isOpen={true}
      toggle={hideModal}
      customSize="bg"
    >
      <Wizard
        header={<WizardSteps steps={steps} size="sm" />}
        footer={<WizardFooter
          handleFormSubmit={handleFormSubmit}
          setValues={setValues}
          finishButtonTitle={actionType === CONTAINER_ACTIONS.MERGE ? 'Merge with...' : 'Extract'}
        />}
        wrapper={<WizardWrapper />}
      >
        <FirstStep
          values={values}
          setValues={setValues}
          namePrefix={actionType === CONTAINER_ACTIONS.MERGE ? 'data_merge_' : 'data_extractions_'}
          selectAll
        />
        <SecondStep isLoading={isLoading || isAdding} values={values} setValues={setValues} />
      </Wizard>
    </ModalContainer>
  )
}

ExtractDataModal.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
}

export default withAlerts(ExtractDataModal)
