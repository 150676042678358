import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Wizard } from 'react-use-wizard'

import WizardSteps from 'components/Main/DatasetViewPage/CreateNewModal/WizardSteps'
import WizardWrapper from 'components/Main/DatasetViewPage/CreateNewModal/WizardWrapper'
import WizardFooter from 'components/Main/DatasetViewPage/CreateNewModal/WizardFooter'
import FirstStep from 'components/Main/ContainerViewPage/ExtractDataModal/FirstStep'
import SecondStep from 'components/Main/ContainerViewPage/ExtractDataModal/SecondStep'

import { useUpdateStepMutation } from 'services/containers'
import { withAlerts } from 'contexts/alertsProvider'

import { convertIndexes } from 'helpers/datasets'

const EditExtraction = ({
  alerts,
  values,
  setValues,
  container,
  onClose,
  showCloseButton,
}) => {
  const [updateStep, { isLoading: isUpdating }] = useUpdateStepMutation()

  useEffect(() => {
    const job = container.jobs[0]
    const {
      uuid: sourceUUID,
      indicators_list,
      type,
      db_name,
      table_name,
      schema_name,
      query,
    } = job.job_parameters
    const selectedKeys = convertIndexes(indicators_list)
    setValues(state => ({
      ...state,
      type: type === 'dataset' ? 'dataset' : 'connector',
      sourceUUID,
      selectedKeys,
      showQuery: query?.length,
      query,
      databaseName: db_name,
      schemaName: schema_name,
      tableName: table_name,
    }))
  }, [])

  const handleFormSubmit = async () => {
    const {
      tableName,
      query,
      schemaName,
      databaseName,
      sourceUUID,
      showQuery,
      versionUUID,
    } = values

    const data = {
      containerUUID: container.uuid,
      uuid: sourceUUID,
      indicators_list: values.selectedKeys,
      merge_type: 'left_join',
      ...versionUUID && { version_uuid: versionUUID },
      ...!showQuery && tableName && {
        table_name: tableName,
        schema_name: schemaName,
        db_name: databaseName,
      },
      ...showQuery && query && {
        query,
        db_name: databaseName,
      },
    }
    try {
      await updateStep({ ...data, jobUUID: container.jobs[0].uuid }).unwrap()
      onClose()
    } catch ({ data: { message } = {} }) {
      alerts.error({ message })
    }
  }

  const steps = {
    2: 'Columns',
    4: 'Summary',
  }
  return (
    <Wizard
      header={<WizardSteps steps={steps} onClose={onClose} size="sm" compact showCloseButton={showCloseButton} />}
      footer={<WizardFooter
        handleFormSubmit={handleFormSubmit}
        setValues={setValues}
      />}
      wrapper={<WizardWrapper />}
    >
      <FirstStep values={values} setValues={setValues} />
      <SecondStep isLoading={isUpdating} values={values} showNameField={false} />
    </Wizard>
  )
}

EditExtraction.defaultProps = {
  showCloseButton: true,
}

EditExtraction.propTypes = {
  values: PropTypes.shape({
    selectedKeys: PropTypes.array,
    tableName: PropTypes.string,
    query: PropTypes.string,
    schemaName: PropTypes.string,
    databaseName: PropTypes.string,
    sourceUUID: PropTypes.string,
    showQuery: PropTypes.bool,
    versionUUID: PropTypes.string,
    job: PropTypes.string,
  }).isRequired,
  container: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    jobs: PropTypes.array,
    data: PropTypes.object,
  }).isRequired,
  setValues: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
  showCloseButton: PropTypes.bool,
}

export default withAlerts(EditExtraction)
