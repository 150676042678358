import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import SanitizedHTML from 'react-sanitized-html'
import TextEditor from './TextEditor'
import isEmpty from 'lodash/isEmpty'
import { allowedSchema } from 'helpers/tinymce'

import { getKnowledgeBase, selectKnowledgeBase, updateKnowledgeBase } from 'reducers/knowledgeBase'

const MainContent = ({
  editMode,
  setIsLoading,
  uuid,
}) => {
  const dispatch = useDispatch()

  const knowledgeBase = useSelector(state => selectKnowledgeBase(state, uuid))

  useEffect(async () => {
    if (isEmpty(knowledgeBase)) {
      setIsLoading(true)
      await dispatch(getKnowledgeBase(uuid))
    }
    setIsLoading(false)
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image&async=true'
    document.head.appendChild(script)
    return () => {
      document.head.removeChild(script)
    }
  }, [knowledgeBase])

  const { doc_html } = knowledgeBase

  const handleChange = async (e) => {
    try {
      await dispatch(updateKnowledgeBase({ uuid, doc_html: e.target.getContent() })).unwrap()
    } catch (e) {
      console.error(e.message)
    }
  }

  const handleUpdate = async () => {
    try {
      setIsLoading(true)
      await dispatch(getKnowledgeBase(uuid))
    } catch (e) {
      console.error(e.message)
    } finally { setIsLoading(false) }
  }

  return (
    <>
      <div className="py-md-3 pl-md-5 ct-content col-12 col-md-9 col-xl-8 tinymce-doc-wrapper">
        {editMode
          ? <TextEditor value={doc_html} name={uuid} height="70vh" handleChange={handleChange} handleUpdate={handleUpdate} />
          : <SanitizedHTML
            allowedAttributes={allowedSchema.allowedAttributes}
            allowedTags={allowedSchema.allowedTags}
            disabled
            html={doc_html}
          />
        }
      </div>
    </>
  )
}

MainContent.propTypes = {
  uuid: PropTypes.string,
  setIsLoading: PropTypes.func,
  editMode: PropTypes.bool,
}

export default MainContent
