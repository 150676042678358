import logger from 'redux-logger'
import { configureStore } from '@reduxjs/toolkit'

import reducer from 'reducers/index'
import socketMiddleware from 'middlewares/socketMiddleware'

import divianApi from 'services/api'

const middlewares = [
  socketMiddleware,
  divianApi.middleware,
]

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}

export default configureStore({
  reducer: {
    [divianApi.reducerPath]: divianApi.reducer,
    ...reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(...middlewares),
  devTools: process.env.NODE_ENV === 'development',
})
