import { apiV2Request } from 'helpers/request'
import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'
const { REACT_APP_ENVIRONMENT } = process.env

const selectUserData = createSelector(
  state => state.user,
  (_, type) => type,
  (data, type) => data[type] || [],
)

export const selectDataByType = () => createSelector(
  state => state.user,
  (_, type) => type,
  (data, type) => data[type] || [],
)

export const selectKnowledgeBase = state => selectUserData(state, 'knowledgebase')
export const selectBinders = state => selectUserData(state, 'binders')

export const getUserData = createAsyncThunk('GET_USER_DATA', async ({ type = 'datasets', ...rest }) => {
  let params = ''
  if (rest) params += '?' + Object.entries(rest).map(([key, value]) => `${key}=${value}`).join('&')
  try {
    const { data } = await apiV2Request({ url: `${type}${params}`, ...type === 'knowledgebase' && { baseURL: `https://api.divian.${REACT_APP_ENVIRONMENT}` } })
    return { data, type }
  } catch ({ response = {} }) {
    throw Error(response?.data?.message || 'Something went wrong!')
  }
})

export const deleteUserDataItem = createAsyncThunk('DELETE_USER_DATA_ITEM', async ({ type, uuid }) => {
  try {
    await apiV2Request({
      url: `${type}/${uuid}`,
      method: 'DELETE',
    })
    return { type, uuid }
  } catch ({ response = {} }) {
    throw Error(response?.data?.message || 'Something went wrong!')
  }
})

export default createReducer({}, {
  [getUserData.fulfilled]: (state, { payload }) => ({ ...state, [payload.type]: payload.data }),
  UPDATE_STORE_STATE: (state, { payload: { data, name } }) => {
    const origState = { ...state }
    if (!isEmpty(origState) && !isEmpty(origState[name])) {
      return { ...state, [name]: [data, ...state[name]] }
    }
    return state
  },
  [deleteUserDataItem.fulfilled]: (state, { payload: { type, uuid } }) => ({ ...state, [type]: [...state[type].filter(item => item.uuid !== uuid)] }),
  'UPDATE_DOCUMENT/fulfilled': (state, { payload, meta: { arg: { uuid } } }) => {
    const origState = { ...state }
    if (!isEmpty(origState) && !isEmpty(origState.documents)) return { ...state, documents: [...state.documents.map(item => item.uuid === uuid ? { ...item, ...payload } : item)] }
    return state
  },
  'UPDATE_BINDER/fulfilled': (state, { payload, meta: { arg: { uuid } } }) => {
    const origState = { ...state }
    if (!isEmpty(origState) && !isEmpty(origState.binders)) return { ...state, binders: [...state.binders.map(item => item.uuid === uuid ? { ...item, ...payload } : item)] }
    return state
  },
})
