import React from 'react'
import {
  Row,
  Col,
} from 'reactstrap'

const Topology = () => (
  <Row className="mt-3">
    <Col>
      <div className="topology-schema-wrapper d-flex justify-content-between align-items-center">

        <div className="left-side h-100 d-flex flex-column">
          <div className="pt-2 pl-3 text-truncate">Business concepts</div>

          <div className="flex-grow-1 d-flex flex-column justify-content-center">
            <div className="cloud cloud-l-1">
              <span className="text-truncate">
                clients
              </span>
            </div>
            <div className="cloud cloud-l-2">
              <span className="text-truncate">
                orders
              </span>
            </div>
            <div className="cloud cloud-l-3">
              <span className="text-truncate">
                products
              </span>
            </div>
            <div className="cloud cloud-l-4">
              <span className="text-truncate">
                suppliers
              </span>
            </div>
          </div>
        </div>

        <div className="divided" />

        <div className="right-side h-100">
          <div className="pt-2 pl-3 text-truncate">Tech data</div>

          <div className="cloud cloud-3">
            <span className="text-truncate">
              category
            </span>
          </div>

          <div className="line-cloud-2-top" />
          <div className="line-cloud-2-left" />

          <div className="cloud cloud-5">
            <span className="text-truncate">
              measure
            </span>
          </div>

          <div className="line-cloud-5-top" />
          <div className="line-cloud-5-right" />

          <div className="cloud cloud-2">
            <span className="text-truncate">
              data source
            </span>
          </div>

          <div className="line-cloud-6-bottom" />
          <div className="line-cloud-6-left" />

          <div className="cloud cloud-4">
            <span className="text-truncate">
              time period
            </span>
          </div>

          <div className="line-cloud-4-bottom" />
          <div className="line-cloud-4-right" />

          <div className="cloud cloud-6">
            <span className="text-truncate">
              geography
            </span>
          </div>
        </div>
      </div>
    </Col>
  </Row>
)

export default Topology
