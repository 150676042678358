import React, { useMemo } from 'react'
import lodashKeys from 'lodash/keys'

import { Container } from 'reactstrap'
import ModalContainer from 'components/Custom/ModalContainer'
import StickyTable from 'components/Catalog/Shared/StickyTable'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { useGetTableHeaderQuery } from 'services/sources'
import { useModalContext } from 'contexts/modalProvider'

const PreviewTableModal = () => {
  const { hideModal, store: { modalProps } } = useModalContext()
  const { tableName } = modalProps

  const { data = {}, isFetching } = useGetTableHeaderQuery({
    uuid: '04c637c2-cc93-11ed-83ea-0a58a9feac02',
    databaseName: 'SALES',
    tableName,
    schemaName: 'RETAIL',
  }, { skip: !tableName })

  const { header, body } = useMemo(() => {
    const tableData = data.header?.data || []

    return {
      body: tableData,
      header: lodashKeys(tableData[0]),
    }
  }, [data.header?.data])

  return (
    <ModalContainer
      isOpen={true}
      toggle={hideModal}
      centered={true}
      size="xl"
    >
      {!tableName
        ? <Spinner />
        : (
          <Container fluid>
            <h3 className="mb-3">{tableName}</h3>
            <StickyTable
              stickyHeaderCount={1}
              leftStickyColumnCount={1}
              header={header}
              body={body}
              isLoading={isFetching}
              maxHeight="69vh"
            />
          </Container>
        )}
    </ModalContainer>
  )
}

export default PreviewTableModal
