import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
  Col,
  Row,
  Form,
  FormGroup,
  Input,
} from 'reactstrap'
import { ButtonWithSpinner } from 'components/Custom/Buttons'

import { withAlerts } from 'contexts/alertsProvider'
import { changePassword } from 'helpers/cognito'
import { useGetAccountQuery } from 'services/user'

const PASSWORD_REGEXP = /(?!.*\s)(?=.*([a-z]))(?=.*([A-Z]))(?=.*\d)([\x20-\x7E]){8,}/
const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is empty'),
  newPassword: Yup.string()
    .matches(PASSWORD_REGEXP, 'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number')
    .required('New Password is empty'),
})

const ChangePassword = ({ alerts }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { data: account = {} } = useGetAccountQuery()

  const submitForm = async ({ password, newPassword }) => {
    try {
      setIsLoading(true)
      await changePassword(account.email, password, newPassword)
    } catch ({ message }) {
      alerts.error({ message })
    } finally { setIsLoading(false) }
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: submitForm,
  })

  return (
    <Form className="mt-4">
      <Row>
        <Col md={4}>
          <FormGroup>
            <label className="form-control-label">Change Password</label>
            <Input
              autoComplete="new-password"
              type="password"
              placeholder="Current Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              invalid={formik.touched.password && Boolean(formik.errors.password)}
            />
            { formik.touched.password && Boolean(formik.errors.password) && (
              <small className="text-danger d-block pt-1">{formik.errors.password}</small>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Input
              type="password"
              placeholder="New Password"
              name="newPassword"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              invalid={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
            />
            { formik.touched.newPassword && Boolean(formik.errors.newPassword) && (
              <small className="text-danger d-block pt-1">{formik.errors.newPassword}</small>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonWithSpinner
            label="Change Password"
            isUpdating={isLoading}
            onClick={formik.handleSubmit}
          />
        </Col>
      </Row>
    </Form>
  )
}

ChangePassword.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
}

export default withAlerts(ChangePassword)
