import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Media,
} from 'reactstrap'
import divianApi from 'services/api'
import Notifications from 'components/Main/Notifications'

import { useGetWorkspaceQuery, useAddWorkspaceMutation } from 'services/workspaces'
import { useGetAccountQuery, useUpdateAccountMutation } from 'services/user'
import { useModalContext, MODAL_TYPES } from 'contexts/modalProvider'
import { logOut } from 'helpers/cognito'
import { withAlerts } from 'contexts/alertsProvider'
import ICONS from 'helpers/iconConstants'

const StyledDropdownItem = styled(DropdownItem)`
  font-size: 0.625rem !important;
  color: var(--gray);
`

const Icon = ({ name }) => (
  <div className="d-inline-block mr-2 text-center" style={{ width: '16px' }}>
    <i className={`${name} text-gray`} style={{ fontSize: '0.9rem' }} />
  </div>
)

const AdminNavbar = ({
  alerts,
  theme,
  sidenavOpen,
  toggleSidenav,
  workspaceUUID,
}) => {
  const { showModal } = useModalContext()
  const history = useHistory()
  const dispatch = useDispatch()

  const { data: account = {} } = useGetAccountQuery()
  const { data = {}, isLoading } = useGetWorkspaceQuery(workspaceUUID, { skip: !workspaceUUID })
  const [addWorkspace] = useAddWorkspaceMutation()
  const [updateAccount] = useUpdateAccountMutation()

  const showManageWorkspaceModal = (e) => {
    e.preventDefault()
    showModal(MODAL_TYPES.CHANGE_WORKSPACE_MODAL)
  }

  const changeWorkspaceHandler = async (uuid) => {
    try {
      await updateAccount({ workspace: uuid }).unwrap()
      history.push(`/workspaces/${uuid}/home`)
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  const createNewWorkspace = async () => {
    try {
      const { uuid } = await addWorkspace().unwrap()
      history.push(`/workspaces/${uuid}`)
    } catch ({ message }) {
      alerts.error({ message })
    }
  }

  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add('g-navbar-search-showing')
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-showing')
      document.body.classList.add('g-navbar-search-show')
    }, 150)
    setTimeout(function () {
      document.body.classList.add('g-navbar-search-shown')
    }, 300)
  }
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove('g-navbar-search-shown')
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-show')
      document.body.classList.add('g-navbar-search-hiding')
    }, 150)
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hiding')
      document.body.classList.add('g-navbar-search-hidden')
    }, 300)
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hidden')
    }, 500)
  }

  const signOut = () => {
    dispatch(divianApi.util.resetApiState())
    logOut()
    alerts.success({ message: 'You logged out! See you soon!' })
    history.push('/login')
  }

  return (
    <>
      <Navbar
        className={classnames(
          'navbar-top navbar-expand border-bottom',
          { 'navbar-dark bg-info': theme === 'dark' },
          { 'navbar-light bg-secondary': theme === 'light' },
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Form
              className={classnames(
                'navbar-search form-inline mr-sm-3',
                { 'navbar-search-light': theme === 'dark' },
                { 'navbar-search-light': theme === 'light' },
              )}
            >
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative input-group-merge">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className={ICONS.SEARCH} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
              <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={closeSearch}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </Form>

            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    'pr-3 sidenav-toggler',
                    { active: sidenavOpen },
                    { 'sidenav-toggler-dark': theme === 'dark' },
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className={ICONS.SIDENAV_TOGGLER_LINE} />
                    <i className={ICONS.SIDENAV_TOGGLER_LINE} />
                    <i className={ICONS.SIDENAV_TOGGLER_LINE} />
                  </div>
                </div>
              </NavItem>
              <NavItem className="d-sm-none">
                <NavLink onClick={openSearch}>
                  <i className={ICONS.ZOOM_SPLIT_IN} style={{ fontSize: '1.2rem' }} />
                </NavLink>
              </NavItem>
              {!isLoading && (<Notifications />)}
            </Nav>
            {!isLoading && (
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" tag="a" role="button">
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <img alt="..." src={data.logo} />
                      </span>
                      <Media className="ml-2 d-none d-lg-block text-truncate" style={{ maxWidth: '190px' }}>
                        <span className="mb-0 text-sm font-weight-bold">
                          {data.name}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>

                  <DropdownMenu right className="p-0">
                    <StyledDropdownItem text tag="div" className="text-uppercase text-truncate font-weight-bold">
                      Workspaces
                    </StyledDropdownItem>

                    {account?.workspaces_list?.map(({ uuid, logo, name }) => (
                      <DropdownItem key={uuid} onClick={() => changeWorkspaceHandler(uuid)} className="d-flex align-items-center py-1">
                        <img height="16" width="16" src={logo} alt="..." className="rounded-circle" />
                        <span className="text-truncate" style={{ maxWidth: '200px' }}>{name}</span>
                      </DropdownItem>
                    ))}

                    <StyledDropdownItem onClick={e => showManageWorkspaceModal(e)} className="d-flex align-items-center justify-content-center py-1 font-weight-bold">
                      Show all
                    </StyledDropdownItem>

                    <DropdownItem divider className="m-0" />
                    <DropdownItem onClick={createNewWorkspace} className="d-flex align-items-center">
                      <Icon name={ICONS.PLUS} />
                      Add workspace
                    </DropdownItem>

                    <DropdownItem divider className="m-0" />
                    <StyledDropdownItem text tag="div">
                      <span className="text-uppercase text-truncate font-weight-bold">Settings</span>
                    </StyledDropdownItem>

                    <DropdownItem onClick={() => history.push(`/workspaces/${workspaceUUID}/account`)} className="d-flex align-items-center">
                      <Icon name={ICONS.USER} />
                      Account
                    </DropdownItem>

                    {!data.is_personal && (
                      <DropdownItem onClick={() => history.push(`/workspaces/${workspaceUUID}`)} className="d-flex align-items-center">
                        <Icon name={ICONS.PEOPLE_ARROWS} />
                        <span>Workspace</span>
                      </DropdownItem>
                    )}

                    <DropdownItem divider className="m-0" />
                    <DropdownItem onClick={signOut} className="d-flex align-items-center">
                      <Icon name={ICONS.RIGHT_BRACKET} />
                      Log out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark',
}

AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
  workspaceUUID: PropTypes.string,
}

Icon.propTypes = {
  name: PropTypes.string,
}

export default withAlerts(AdminNavbar)
