import React, { useState, useContext, createContext } from 'react'
import PropTypes from 'prop-types'

const BreadcrumbsContext = createContext({
  setBreadcrumbs: () => {},
  store: {},
})

export const useBreadcrumbs = () => {
  const { setBreadcrumbs, store } = useContext(BreadcrumbsContext)
  return { setBreadcrumbs, store }
}

export const withBreadcrumbs = Comp => (props) => {
  const breadcrumbs = useBreadcrumbs()
  return <Comp {...props} breadcrumbs={breadcrumbs} />
}

const BreadcrumbsProvider = ({ children }) => {
  const [store, setStore] = useState({})

  const setBreadcrumbs = ({
    parentName, icon, name, status, type,
  }) => {
    setStore({
      parentName, icon, name, status, type,
    })
  }

  return (
    <BreadcrumbsContext.Provider
      value={{
        store,
        setBreadcrumbs,
      }}>
      {children}
    </BreadcrumbsContext.Provider>
  )
}

BreadcrumbsProvider.propTypes = {
  children: PropTypes.node,
}

export default BreadcrumbsProvider
