import React, { useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import Header from 'components/Binders/Header'
import Sidebar from 'components/Binders/Sidebar'
import MainContent from 'components/Binders/MainContent'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import { Container, Row } from 'reactstrap'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useToken } from 'hooks/useToken'
import { getBinder, selectBinder } from 'reducers/binder'
import 'components/Documentation/assets/styles/index.scss'

// const json = {
//   name: 'Roman',
//   desc: '',
//   binders: [
//     {
//       uuid: 1,
//       position: 0,
//       name: 'First',
//       parent_uuid: null,
//     },
//     {
//       uuid: 2,
//       position: 0,
//       name: 'First - Second',
//       parent_uuid: 1,
//       document_uuid: '9ad6d410-c14f-11ec-a92f-0a58a9feac02',
//     },
//     {
//       uuid: 3,
//       position: 1,
//       name: 'Second',
//       parent_uuid: null,
//     },
//     {
//       uuid: 4,
//       position: 1,
//       name: 'Second - First',
//       parent_uuid: 3,
//       document_uuid: '9ad6d410-c14f-11ec-a92f-0a58a9feac01',
//     },
//     {
//       uuid: 5,
//       position: 0,
//       name: 'Second - Second',
//       parent_uuid: 3,
//       document_uuid: '9ad6d410-c14f-11ec-a92f-0a58a9feac03',
//     },
//   ],
// }

const BindersRoutes = () => {
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const [loading, token] = useToken()
  const [sidenavOpen, setSidenavOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [documentId, setDocumentId] = useState(null)

  const binder = useSelector(state => selectBinder(state, params))

  useEffect(async () => {
    if (isEmpty(binder)) await dispatch(getBinder(params))
  }, [])

  if (!loading && !token) return <Redirect to={{ pathname: '/login', state: { from: history.location } }} />

  return (
    <div className="documentation-page-wrapper">
      <Header data={binder} sidenavOpen={sidenavOpen} toggleSidenav={() => setSidenavOpen(!sidenavOpen)} />
      <Container fluid>
        <Row className="flex-xl-nowrap">
          <Sidebar
            data={binder}
            documentId={documentId}
            onItemClick={id => setDocumentId(id)}
            sidenavOpen={sidenavOpen}
          />
          <MainContent setIsLoading={setIsLoading} uuid={documentId} />
          <div className="d-none d-xl-block ct-toc col-xl-2" />
        </Row>
      </Container>
      <LoadingSpinner visible={isLoading} />
    </div>
  )
}

export default BindersRoutes
