import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap'
import ICONS from 'helpers/iconConstants'

const ActionsMenu = ({
  addChildHandler,
  addRootHandler,
  deleteHandler,
  editChildHandler,
  editRootHandler,
}) => (
  <UncontrolledDropdown className="doc-page-acions-wrapper">
    <DropdownToggle nav className="px-1">
      <Button color="light" outline size="sm" >
        <span className="btn-inner--icon">
          <i className={ICONS.BULLET_LIST} />
        </span>
      </Button>
    </DropdownToggle>
    <DropdownMenu right tag="ul">
      {editRootHandler && (
        <NavLink tag="li" onClick={editRootHandler}>
          <DropdownItem>Edit Root</DropdownItem>
        </NavLink>)}

      {editChildHandler && (
        <NavLink tag="li" onClick={editChildHandler}>
          <DropdownItem>Edit Child</DropdownItem>
        </NavLink>)}

      {addRootHandler && (
        <NavLink tag="li" onClick={addRootHandler}>
          <DropdownItem>Add Root</DropdownItem>
        </NavLink>)}

      {addChildHandler && (
        <NavLink tag="li" onClick={addChildHandler}>
          <DropdownItem>Add Child</DropdownItem>
        </NavLink>)}

      {deleteHandler && (
        <NavLink tag="li" onClick={deleteHandler}>
          <DropdownItem>Delete</DropdownItem>
        </NavLink>)}
    </DropdownMenu>
  </UncontrolledDropdown>
)

ActionsMenu.propTypes = {
  addChildHandler: PropTypes.func,
  addRootHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  editChildHandler: PropTypes.func,
  editRootHandler: PropTypes.func,
}

export default ActionsMenu
