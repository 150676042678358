import React from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

import Message from 'components/Catalog/RequestPageView/DataViewTilesOnly/BotChat/Message'
import TypingMsg from 'components/Catalog/RequestPageView/DataViewTilesOnly/BotChat/Message/TypingMsg'

import { useGetRequestQuery } from 'services/catalogs'

const BotChat = () => {
  const { uuid, workspaceUUID } = useParams()
  const { data = {} } = useGetRequestQuery({ uuid, workspaceUUID })

  return (
    <Row>
      <Col xs={12}>
        {(data.intermediate_steps || []).map((row, i) => (
          <Message intermediateSteps={row} key={i} />
        ))}

        {data.status === 'in_progress' && <TypingMsg /> }
      </Col>
    </Row>
  )
}

export default BotChat
