import React from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'

import ICONS from 'helpers/iconConstants'

export const ParentInfo = ({ data }) => (
  <Row>
    <Col className="small">
      <ListGroup horizontal>
        {data.db_name && (
          <ListGroupItem className="border-0 mt-2 pl-0 pb-0 pt-1 text-muted">
            <i className={`${ICONS.DATABASE} mr-1`} />
            {data.db_name}
          </ListGroupItem>)}
        {data.folder_name && (
          <ListGroupItem className="border-0 mt-2 pl-0 pb-0 pt-1 text-muted">
            <i className={`${ICONS.FOLDER} mr-1`} />
            {data.folder_name}
          </ListGroupItem>)}
      </ListGroup>
    </Col>
  </Row>
)

ParentInfo.defaultProps = {
  data: {},
}

ParentInfo.propTypes = {
  data: PropTypes.shape({
    db_name: PropTypes.string,
    folder_name: PropTypes.string,
  }),
}

export default ParentInfo
