import React from 'react'
import PropTypes from 'prop-types'
import { Editor } from '@tinymce/tinymce-react'
import SanitizeHTML from 'sanitize-html'
import {
  apiKey,
  allowedSchema,
  plugins,
  toolbar,
} from 'helpers/tinymce'

const TextEditor = ({
  height,
  name,
  value,
  placeholder,
  handleChange,
}) => (
  <Editor
    apiKey={apiKey}
    initialValue={value}
    onChange={handleChange}
    textareaName={name}
    init={{
      paste_preprocess(_, args) {
        Object.assign(args, { content: SanitizeHTML(args.content, allowedSchema) })
      },
      external_plugins: {
        'tiny_mce_wiris': 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js',
      },
      height,
      width: '100%',
      menubar: false,
      branding: false,
      statusbar: false,
      placeholder,
      selector: `textarea[name="${name}"]`,
      plugins,
      contextmenu: false,
      toolbar,
    }}
  />
)


TextEditor.propTypes = {
  height: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
}

export default TextEditor
