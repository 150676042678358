import React from 'react'
import { useParams, Link } from 'react-router-dom'

import { Col, Row } from 'reactstrap'

import KnowledgeView from 'components/Catalog/RequestPageView/KnowledgeView'
import DataView from 'components/Catalog/RequestPageView/DataViewTilesOnly'

import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { useGetRequestQuery } from 'services/catalogs'

import ICONS from 'helpers/iconConstants'

const RequestPageView = () => {
  const { uuid, workspaceUUID } = useParams()

  const { data = {}, isLoading, isError } = useGetRequestQuery({ uuid, workspaceUUID })

  if (isLoading) return <Spinner />
  if (isError) return (
    <div>OOPS! Something is going wrong!!</div>
  )

  return (
    <Row className="h-100">
      <Col xs={12} className="d-flex flex-column">
        <Row className="bg-white">
          <Col className="p-0">
            <div className="px-4 py-3">
              <h2>
                <small>
                  <Link to={`/workspaces/${workspaceUUID}/catalog/discover`}><i className={`${ICONS.ARROW_LEFT} mr-2 text-info`} /></Link>
                </small>
                {data.input}
              </h2>
            </div>
          </Col>
        </Row>

        {data.type === 'knowledge' && <KnowledgeView />}
        {data.type === 'data' && <DataView />}
      </Col>
    </Row>
  )
}

export default RequestPageView
