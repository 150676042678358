import { apiV2Request } from 'helpers/request'
import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

export const selectBinder = createSelector(
  state => state.binder,
  (_, data) => data.uuid,
  (binders, uuid) => binders[uuid] || {},
)

export const createBinder = createAsyncThunk('CREATE_BINDER', async (data, { dispatch }) => {
  try {
    const response = await apiV2Request({
      url: 'binders',
      method: 'POST',
      data,
    })
    dispatch({
      type: 'UPDATE_STORE_STATE',
      payload: {
        name: 'binders',
        data: response.data,
      },
    })
    return response.data
  } catch ({ response = {} }) {
    throw Error(response?.data?.message || 'Something went wrong!')
  }
})

export const getBinder = createAsyncThunk('GET_BINDER', async ({ uuid }) => {
  try {
    const { data } = await apiV2Request({ url: `binders/${uuid}` })
    return data
  } catch ({ response = {} }) {
    throw Error(response?.data?.message || 'Something went wrong!')
  }
})

export const updateBinder = createAsyncThunk('UPDATE_BINDER', async ({ uuid, data }) => {
  try {
    const response = await apiV2Request({
      url: `binders/${uuid}`,
      method: 'PUT',
      data,
    })
    return response.data
  } catch ({ response = {} }) {
    throw Error(response?.data?.message || 'Something went wrong!')
  }
})

export default createReducer({}, {
  [getBinder.fulfilled]: (state, { payload }) => ({ ...state, [payload.uuid]: { ...payload } }),
  [updateBinder.fulfilled]: (state, { payload, meta: { arg: { uuid } } }) => ({ ...state, [uuid]: { ...state[uuid], ...payload } }),
})
