import divianApi from './api'
import { customRequest } from 'helpers/request'

export const organizationApi = divianApi.injectEndpoints({
  endpoints: build => ({
    getOrganization: build.query({
      query: () => 'organization',
      providesTags: ['Organization'],
    }),
    updateOrganization: build.mutation({
      query: body => ({
        url: 'organization',
        method: 'PUT',
        body,
      }),
      onQueryStarted: (patch, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(organizationApi.util.updateQueryData('getOrganization', undefined, (draft) => { Object.assign(draft, patch) }))
        queryFulfilled.catch(patchResult.undo)
      },
    }),
    uploadLogo: build.mutation({
      queryFn: async (...args) => {
        const [base64File,,,fetchWithBQ] = args
        const { data: { url, fields } } = await fetchWithBQ({ url: 'organization/logo' })

        const file = await fetch(base64File)
          .then(res => res.blob())
          .then(blob => new File([blob], 'avatar', { type: 'image/png' }))

        await customRequest({
          url,
          method: 'POST',
          multipart: true,
          data: { ...fields, file, acl: 'public-read' },
        })
        return fetchWithBQ({
          url: 'organization/logo',
          method: 'POST',
        })
      },
      invalidatesTags: ['Organization'],
    }),
    getUsers: build.query({
      query: () => 'organization/users',
      providesTags: ['OrganizationUsers'],
    }),
    addUser: build.mutation({
      query: body => ({
        url: 'organization/users',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OrganizationUsers'],
    }),
    deleteUser: build.mutation({
      query: uuid => ({
        url: `organization/users/${uuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['OrganizationUsers'],
    }),
    getBilling: build.query({
      query: () => 'organization/billing',
      providesTags: ['OrganizationBilling'],
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetOrganizationQuery,
  useGetUsersQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useUpdateOrganizationMutation,
  useUploadLogoMutation,
  useGetBillingQuery,
} = organizationApi
