import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { formatDateAndTime } from 'helpers/utils'
import { Table } from 'reactstrap'

import ScrollBar from 'components/Custom/ScrollBar'

import ICONS from 'helpers/iconConstants'
import { SOURCE_TYPES } from 'helpers/constants'

const SourceList = ({ data, onChange, values }) => {
  const getType = type => type ? 'connector' : 'dataset'
  return (
    <ScrollBar>
      <Table hover size="sm" style={{ tableLayout: 'fixed' }}>
        <colgroup>
          <col width="50%" style={{ minWidth: '180px' }} />
          <col width="25%" style={{ minWidth: '130px' }} />
          <col width="25%" style={{ minWidth: '180px' }} />
        </colgroup>
        <tbody>
          {data.map(({
            created, name, uuid, type,
          }) => (
            <tr
              key={uuid}
              onClick={() => {
                onChange('sourceUUID', uuid)
                onChange('sourceName', name)
                onChange('type', getType(type))
              }}
              role="button"
              style={{ ...values.sourceUUID === uuid && { backgroundColor: '#f6f9fc' } }}
              className={classnames({ 'font-weight-bold': values.sourceUUID === uuid })}
            >
              <td className="text-truncate">
                <i className={classnames('mr-2', {
                  [ICONS.FILE]: [SOURCE_TYPES.FILE, SOURCE_TYPES.DIVIAN_API].includes(type),
                  [ICONS.SNOWFLAKE]: SOURCE_TYPES.SNOWFLAKE === type,
                  [ICONS.DATABASE]: [SOURCE_TYPES.MYSQL, SOURCE_TYPES.PGSQL].includes(type),
                  [ICONS.STREAM]: !type,
                })} />
                {name}
              </td>
              <td className="text-truncate">{getType(type)}</td>
              <td className="text-truncate">{formatDateAndTime(created)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </ScrollBar>
  )
}

SourceList.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  values: PropTypes.shape({
    sourceUUID: PropTypes.string,
  }),
}

export default SourceList
