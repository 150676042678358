import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Col, Row } from 'reactstrap'
import { ButtonWithSpinner } from 'components/Custom/Buttons'
import CopyToClipboardButton from 'components/Shared/CopyToClipboardButton'

import { withAlerts } from 'contexts/alertsProvider'
import { useGetAccountQuery, useCreateTokenMutation } from 'services/user'


const ApiToken = ({ alerts }) => {
  const [hovered, setHovered] = useState(false)

  const { data: account = {} } = useGetAccountQuery()
  const [createToken, { isLoading: isUpdating }] = useCreateTokenMutation()

  const getNewToken = async () => {
    try {
      await createToken().unwrap()
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  return (
    <>
      <h2 className="m-0">API Key</h2>
      <hr />
      <Row>
        <Col
          xs="auto"
          className="d-flex align-items-center"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <span className="flex-grow-1 text-monospace mr-4 text-truncate">
            {account.api_key}
          </span>
          <div style={{ width: '30px' }} className="pt-1">
            {hovered && <CopyToClipboardButton asIcon textToCopy={account.api_key} />}
          </div>
          <ButtonWithSpinner
            className="ml-3"
            label="Refresh"
            isUpdating={isUpdating}
            onClick={getNewToken}
          />
        </Col>
      </Row>
    </>
  )
}

ApiToken.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
}

export default withAlerts(ApiToken)
