import React from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'

import ICONS from 'helpers/iconConstants'

export const FolderInfo = ({ data }) => (
  <Row>
    <Col className="small">
      <ListGroup horizontal className="flex-wrap">
        {data.folder_name && (
          <ListGroupItem className="border-0 pl-0 pb-0 pt-1 mt-2 text-muted text-truncate">
            <i className={`${ICONS.FOLDER} mr-1`} />
            {data.folder_name}
          </ListGroupItem>
        )}
      </ListGroup>
    </Col>
  </Row>
)

FolderInfo.defaultProps = {
  data: {},
}

FolderInfo.propTypes = {
  data: PropTypes.shape({
    folder_name: PropTypes.string,
  }),
}

export default FolderInfo
