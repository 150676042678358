import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap'

import DescriptionBlock from 'components/Catalog/GlossaryPage/Content/DescriptionBlock'
import ScrollBar from 'components/Custom/ScrollBar'
import { BadgeTag } from 'components/Catalog/Shared/Tags'
import ICONS from 'helpers/iconConstants'
import { removeYes } from 'helpers/utils'

const PropertiesSection = ({ glossary }) => (
  <Card className="rounded-0 h-100 pb-3 shadow-none">
    <CardHeader>
      <Row>
        <Col className="d-flex align-items-center">
          <i className={ICONS.STREAM} />
          <h3 className="m-0 pl-2 d-inline-block">Properties</h3>
        </Col>
      </Row>
    </CardHeader>

    <ScrollBar>
      <CardBody>
        <Row className="flex-wrap">
          {glossary.type && (
            <Col xs={12} className="pb-4 small">
              <span className="d-block mb-1">Type</span>
              <BadgeTag label={glossary.type} />
            </Col>
          )}
          {glossary.iso && (
            <Col xs={12} className="pb-4 small">
              <span className="d-block mb-1">Iso</span>
              <BadgeTag label={glossary.iso} />
            </Col>
          )}
          {glossary.data_type && (
            <Col xs={12} className="pb-4 small">
              <span className="d-block mb-1">Type</span>
              <BadgeTag label={glossary.data_type} />
            </Col>
          )}
          {glossary.db_entity && (
            <Col xs={12} className="pb-4 small">
              <span className="d-block mb-1">Entity</span>
              <BadgeTag label={removeYes(glossary.db_entity)} />
            </Col>
          )}
          {glossary.labels && (
            <Col xs={12} className="pb-4 small">
              <span className="d-block mb-1">Label</span>
              {glossary.labels.map(property => <BadgeTag label={property} className="mb-1" key={`label-${property}`} />)}
            </Col>
          )}
          {glossary.keywords && (
            <Col xs={12} className="pb-4 small">
              <span className="d-block mb-1">Tags</span>
              {glossary.keywords.map(property => <BadgeTag label={removeYes(property)} className="mb-1" key={`tags-${property}`} />)}
            </Col>
          )}
          {glossary?.properties && glossary?.properties[0] !== '' && (
            <Col xs={12} className="pb-4 small">
              <span className="d-block mb-1">Properties</span>
              {glossary.properties.map(property => <BadgeTag label={property} className="mb-1" key={`prop-${property}`} />)}
            </Col>
          )}
          <DescriptionBlock description={glossary.description} hash={glossary.hash} />
        </Row>
      </CardBody>
    </ScrollBar>
  </Card>
)

PropertiesSection.propTypes = {
  glossary: PropTypes.shape({
    properties: PropTypes.arrayOf(PropTypes.string),
    keywords: PropTypes.arrayOf(PropTypes.string),
    labels: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    db_entity: PropTypes.string,
    data_type: PropTypes.string,
    type: PropTypes.string,
    iso: PropTypes.string,
    hash: PropTypes.string,
  }),
}

export default PropertiesSection
