import React from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button, DropdownItem } from 'reactstrap'

import { withAlerts } from 'contexts/alertsProvider'
import ICONS from 'helpers/iconConstants'

const dropdownItem = name => <DropdownItem href="">{name}</DropdownItem>
const icon = () => <i role="button" className={ICONS.SINGLE_COPY} />

const CopyToClipboardButton = ({
  alerts,
  textToCopy,
  buttonClassName = 'btn-icon btn-simple',
  buttonColor = 'info',
  asText = false,
  asIcon = false,
  size = 'sm',
  name = 'Copy ID',
}) => {
  const notifyCopied = () => alerts.success({ message: 'Copied to clipboard!' })
  const button = () => (
    <Button
      className={buttonClassName}
      color={buttonColor}
      size={size}
    >
      <span className="btn-inner--icon">
        <i className={ICONS.SINGLE_COPY} />
      </span>
    </Button>
  )

  return (
    <CopyToClipboard text={textToCopy} onCopy={notifyCopied}>
      {asText
        ? dropdownItem(name)
        : asIcon
          ? icon()
          : button()
      }
    </CopyToClipboard>
  )
}

CopyToClipboardButton.propTypes = {
  alerts: PropTypes.shape({
    success: PropTypes.func,
  }),
  textToCopy: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonColor: PropTypes.string,
  size: PropTypes.string,
  asText: PropTypes.bool,
  asIcon: PropTypes.bool,
  name: PropTypes.string,
}

export default withAlerts(CopyToClipboardButton)
