import { customRequest } from 'helpers/request'
import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'

export const uploadFileLambda = createAsyncThunk('UPLOAD_LAMBDA_FILE', async (data) => {
  try {
    const response = await customRequest({
      url: 'https://j6ywrz5vcl.execute-api.us-east-2.amazonaws.com/api/notebook_dev',
      method: 'POST',
      data,
      headers: { 'Content-Type': 'application/json' },
    })
    return response.data
  } catch ({ response = {} }) {
    throw Error(response?.data?.message || response?.data || 'Something went wrong!')
  }
})

export default createReducer({}, () => {})
