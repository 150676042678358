import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Spinner } from 'reactstrap'
import ICONS from 'helpers/iconConstants'

export const SmallButton = ({
  onClick,
  children,
  className,
  ...rest
}) => (
  <Button
    color="primary"
    size="sm"
    tag="label"
    className={`btn-simple mb-0 text-truncate ${className}`}
    onClick={onClick}
    {...rest}
  >
    {children}
  </Button>
)

export const FullWidthButton = ({
  onClick,
  children,
  className = 'btn-simple',
  ...rest
}) => (
  <Button
    type="button"
    color="info"
    style={{
      fontSize: '.75rem', width: '100%', margin: '0 0 15px 0', display: 'flex', justifyContent: 'center', alignItems: 'center',
    }}
    size="sm"
    className={className}
    onClick={onClick}
    {...rest}
  >
    {children}
  </Button>
)

export const FullWidthButtonAddSourceModal = ({
  onClick,
  children,
  className = 'btn-outline-info',
  ...rest
}) => (
  <Button
    type="button"
    color="default"
    style={{
      fontSize: '.75rem',
      width: '100%',
      margin: '0 0 15px 0',
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
    }}
    size="sm"
    className={className}
    onClick={onClick}
    {...rest}
  >
    {children}
  </Button>
)

const OutlinedSmallButton = ({
  label, onClick, icon, size = 'sm', className, ...rest
}) => (
  <Button
    {...rest}
    className={`btn-icon buttons-html5 ${className}`}
    type="button"
    size={size}
    outline
    color="primary"
    onClick={onClick}
  >
    <span className="btn-inner--icon"><i className={icon} /></span>
    <span className="btn-inner--text">{label}</span>
  </Button>
)

export const NewButton = props => (<OutlinedSmallButton {...props} icon={ICONS.FAT_ADD}/>)
export const BackButton = props => (<OutlinedSmallButton {...props} icon={ICONS.ARROW_LEFT}/>)

export const ButtonWithSpinner = ({
  isUpdating, label, className, ...props
}) => (
  <Button
    outline
    size="sm"
    color="primary"
    type="submit"
    className={`text-truncate ${className}`}
    {...props}
  >
    {isUpdating && (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        className="mr-2"
      />
    )}
    {label}
  </Button>
)

export const NewJobButton = ({ onClick }) => (
  <NewButton label="New Job" onClick={onClick} />
)

export const RestoreButton = styled(Button)`
  padding: 0.25rem 0.5rem;
  font-size: 0.62475rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #1b9be0;
  border: #1b9be0 1px solid;
  border-radius: 0.4285rem;
  background: transparent;
`

SmallButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

FullWidthButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

FullWidthButtonAddSourceModal.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

OutlinedSmallButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
}

ButtonWithSpinner.propTypes = {
  isUpdating: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
}

NewJobButton.propTypes = {
  onClick: PropTypes.func,
}
