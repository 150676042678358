import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactPaginate from 'react-paginate'
import Select from 'react-select'
import {
  Col,
  Row,
} from 'reactstrap'

import ICONS from 'helpers/iconConstants'

const OPTIONS = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
]

const StyledSelect = styled(Select)`
  max-width: 90px;
  margin: 0 0.5rem;
  > .pagination__control { min-height: auto; border-color: #dee2e6; }
  .pagination__indicator-separator { margin: 5px 0; }
  .pagination__single-value { font-size: 12px !important; }
  .pagination__indicator.pagination__dropdown-indicator { padding: 0; cursor: pointer; }
  .pagination__input-container { max-height: 13px; }
  .pagination__input { max-height: 13px; opacity: 0 !important; }
`

const Pagination = ({
  gotoPage,
  pageCount,
  preGlobalFilteredRows,
  rows,
  setPageSize,
  state,
}) => {
  if (pageCount < 2) return null

  return (
    <Row className="justify-content-between">
      <Col xs="auto">
        <div className="d-flex align-items-center" style={{ fontSize: '0.9rem' }}>
          Show
          <StyledSelect
            menuPosition="fixed"
            classNamePrefix="pagination"
            onChange={({ value }) => setPageSize(value)}
            options={OPTIONS}
            value={OPTIONS.find(({ value }) => value === state.pageSize)}
          />
          entries.
          {state.globalFilter && (
            <span className="px-2">
              Filtered {rows.length} from {preGlobalFilteredRows.length} rows.
            </span>
          )}
        </div>
      </Col>
      <Col xs="auto">
        <ReactPaginate
          activeLinkClassName="border-info text-info"
          breakClassName="text-muted px-1"
          containerClassName={'pagination pagination-sm m-0'}
          disabledClassName="disabled opacity-6"
          nextClassName="page-item"
          nextLabel={<i className={ICONS.RIGHT} />}
          nextLinkClassName="page-link ca-hover"
          pageClassName="page-item"
          pageCount={pageCount}
          pageLinkClassName="page-link ca-hover"
          previousClassName="page-item"
          previousLabel={<i className={ICONS.LEFT} />}
          previousLinkClassName="page-link ca-hover"
          forcePage={state.pageIndex}
          onPageChange={({ selected }) => gotoPage(selected)}
        />
      </Col>
    </Row>
  )
}

Pagination.defaultProps = {
  globalFilter: '',
  pageSize: 50,
  preGlobalFilteredRows: [],
  rows: [],
}

Pagination.propTypes = {
  gotoPage: PropTypes.func,
  pageCount: PropTypes.number,
  preGlobalFilteredRows: PropTypes.array,
  rows: PropTypes.array,
  setPageSize: PropTypes.func,
  state: PropTypes.shape({
    pageSize: PropTypes.number,
    globalFilter: PropTypes.string,
    pageIndex: PropTypes.number,
  }),
}

export default Pagination
