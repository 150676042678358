import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { LoadingSpinner } from 'components/Custom/CustomSpinners'

const Styled = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Container = styled.div`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  align-items: center;
  background: hsla(0,0%,100%,.35);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10000;
`

const Spinner = ({ visible = true }) => (
  <Container visible={visible}>
    <Styled>
      <LoadingSpinner />
    </Styled>
  </Container>
)

Spinner.propTypes = {
  visible: PropTypes.bool,
}

export default Spinner
