import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'

import ReadmeSection from 'components/Catalog/AssetPageView/OverviewTab/ReadmeSection'
import SummarySection from 'components/Catalog/AssetPageView/OverviewTab/SummarySection'

const OverviewTab = ({ data }) => (
  <Container fluid>
    <SummarySection data={data} />
    <ReadmeSection data={data} />
  </Container>
)

OverviewTab.propTypes = {
  data: PropTypes.object,
}

export default OverviewTab
