import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
} from 'reactstrap'

import StatusBadge from 'components/Shared/StatusBadge'

import { withBreadcrumbs } from 'contexts/breadcrumbsProvider'
import { pathIcons } from 'helpers/utils'
import ICONS from 'helpers/iconConstants'

const CardsHeader = ({ breadcrumbs }) => {
  const location = useLocation()
  const {
    store: {
      name,
      parentName,
      icon = pathIcons()[parentName],
      status,
      type,
    },
  } = breadcrumbs

  const [,,workspacesUUID] = location.pathname.split('/')

  if (parentName === 'home') return <div className="header pb-6">&nbsp;</div>

  return (
    <div className="header pb-6">
      <Container fluid>
        <div className="header-body">
          <Row className="align-items-center py-4 ml-lg-1">
            <Col xs="12">
              <Breadcrumb className="w-100" listClassName="breadcrumb-links flex-nowrap align-items-center">
                <BreadcrumbItem>
                  <Link to={`/workspaces/${workspacesUUID}/${parentName}`} className="h2 mb-0 d-inline-block text-capitalize text-truncate">
                    {icon && <i className={`${icon} mr-3 text-default`} />}
                    {parentName}
                  </Link>
                </BreadcrumbItem>
                {name &&
                  <>
                    <BreadcrumbItem>
                      <i className={`${ICONS.ANGLE_RIGHT} text-default pt-1`} />
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="overflow-hidden">
                      <div className="h2 mb-0 d-inline-block text-truncate font-weight-bold">
                        {name}
                      </div>
                    </BreadcrumbItem>
                    {status && (
                      <BreadcrumbItem className="ml-2">
                        <StatusBadge status={status} />
                      </BreadcrumbItem>
                    )}
                    {type && (
                      <BreadcrumbItem className="ml-2">
                        <StatusBadge status={type} />
                      </BreadcrumbItem>
                    )}
                  </>
                }
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

CardsHeader.propTypes = {
  breadcrumbs: PropTypes.shape({
    store: PropTypes.shape({
      parentName: PropTypes.string,
      icon: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
}

export default withBreadcrumbs(CardsHeader)
