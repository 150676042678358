/* eslint-disable object-curly-newline */
import * as Yup from 'yup'
import { SOURCE_TYPES } from 'helpers/constants'

// VALIDATION SCHEMAS
const COMMON_VALIDATION = {
  host: Yup.string().required(),
  password: Yup.string().required(),
  username: Yup.string().required(),
}

const SSH_VALIDATION = {
  use_ssh: Yup.number().integer(),
  ssh_ip: Yup.string()
    .when('use_ssh', { is: 1, then: Yup.string().required() }),
  ssh_port: Yup.string()
    .when('use_ssh', { is: 1, then: Yup.string().required() }),
  ssh_user: Yup.string()
    .when('use_ssh', { is: 1, then: Yup.string().required() }),
}

export const FORM_VALIDATION = {
  [SOURCE_TYPES.MYSQL]: {
    ...SSH_VALIDATION,
    ...COMMON_VALIDATION,
    port: Yup.string().required(),
  },
  [SOURCE_TYPES.PGSQL]: {
    ...SSH_VALIDATION,
    ...COMMON_VALIDATION,
    port: Yup.string().required(),
  },
  [SOURCE_TYPES.SNOWFLAKE]: {
    ...COMMON_VALIDATION,
    role: Yup.string(),
    warehouse: Yup.string(),
  },
}

// FORM FILEDS
const COMMON_FIELDS = [
  { label: 'Database User', name: 'username', required: true },
  { label: 'Database Password', name: 'password', required: true, inputType: 'password' },
]

export const FORM_FIELDS = {
  [SOURCE_TYPES.MYSQL]: [
    { label: 'Database Host', name: 'host', required: true, description: 'IP Address or DNS name for your database server' },
    { label: 'Database Port', name: 'port', required: true, description: 'The port on which database is accepting connections' },
    ...COMMON_FIELDS,
  ],
  [SOURCE_TYPES.PGSQL]: [
    { label: 'Database Host', name: 'host', required: true, description: 'IP Address or DNS name for your database server' },
    { label: 'Database Port', name: 'port', required: true, description: 'The port on which database is accepting connections' },
    ...COMMON_FIELDS,
  ],
  [SOURCE_TYPES.SNOWFLAKE]: [
    { label: 'Account ID', name: 'host', required: true },
    ...COMMON_FIELDS,
    { label: 'Role', name: 'role' },
    { label: 'Warehouse', name: 'warehouse' },
  ],
  SSH: [
    { label: 'SSH IP', name: 'ssh_ip', required: true },
    { label: 'SSH Port', name: 'ssh_port', required: true },
    { label: 'SSH User', name: 'ssh_user', required: true },
  ],
}
