import React from 'react'
import PropTypes from 'prop-types'
import {
  useGlobalFilter, usePagination, useTable, useSortBy,
} from 'react-table'
import {
  Col,
  Row,
  Table,
} from 'reactstrap'

import { ReactTableFilter } from 'components/Catalog/Shared/FilterInput'
import TableHeader from 'components/Catalog/Shared/Table/TableHeader'
import TableBody from 'components/Catalog/Shared/Table/TableBody'
import Pagination from 'components/Catalog/Shared/Pagination'
import { generateTableColgroup } from 'helpers/utils'

const CatalogTable = ({
  columns,
  data,
  pageSize,
  type,
  filterable,
  onRowClick,
}) => {
  const options = useTable(
    {
      initialState: { pageSize, pageIndex: 0 },
      columns,
      data,

    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const {
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
  } = options

  return (
    <Row>
      {filterable && (
        <Col xs={12} className="pt-3">
          <ReactTableFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        </Col>
      )}

      <Col xs={12} className="pt-2">
        <Table size="sm" hover style={{ tableLayout: 'fixed' }}>
          {generateTableColgroup(type, columns)}
          <TableHeader {...options} />
          <TableBody {...options} onClick={onRowClick} />
        </Table>
      </Col>

      {!!preGlobalFilteredRows.length && (
        <Col className="pt-3">
          <Pagination {...options} />
        </Col>
      )}

    </Row>
  )
}

CatalogTable.defaultProps = {
  columns: [],
  data: [],
  pageSize: 50,
  filterable: true,
}

CatalogTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  pageSize: PropTypes.number,
  type: PropTypes.string,
  filterable: PropTypes.bool,
  onRowClick: PropTypes.func,
}

export default CatalogTable
