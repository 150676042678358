import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Col,
  Row,
  Form,
  FormGroup,
  Input,
} from 'reactstrap'

import ModalContainer from 'components/Custom/ModalContainer'
import { ButtonWithSpinner } from 'components/Custom/Buttons'

import { useAddUserMutation } from 'services/organization'
import { withAlerts } from 'contexts/alertsProvider'
import { useModalContext } from 'contexts/modalProvider'

const validationSchema = Yup.object().shape({
  email: Yup.string().required(),
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
})

const AddMemberModal = ({ alerts }) => {
  const { hideModal, store: { modalProps } } = useModalContext()
  const { organisationName } = modalProps
  const [addUser, { isLoading: isAdding }] = useAddUserMutation()

  const submitForm = async (data) => {
    try {
      await addUser(data).unwrap()
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      firstname: '',
      lastname: '',
    },
    enableReinitialize: true,
    onSubmit: submitForm,
    validationSchema,
  })

  return (
    <ModalContainer
      title={`Add Member to ${organisationName || ''}`}
      isOpen={true}
      toggle={hideModal}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label">Email:</label>
              <Input
                placeholder="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={formik.touched.email && !!formik.errors.email}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label">First Name:</label>
              <Input
                placeholder="First Name"
                name="firstname"
                value={formik.values.firstname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={formik.touched.firstname && !!formik.errors.firstname}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label">Last Name:</label>
              <Input
                placeholder="Last Name"
                name="lastname"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={formik.touched.lastname && !!formik.errors.lastname}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="text-right my-1 pt-3">
            <ButtonWithSpinner
              label="Add"
              type="submit"
              disabled={!(formik.dirty && formik.isValid)}
              onClick={formik.handleSubmit}
              isUpdating={isAdding}
            />
          </Col>
        </Row>
      </Form>
    </ModalContainer>
  )}

AddMemberModal.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
}

export default withAlerts(AddMemberModal)
