import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import {
  Button,
  Card,
  Container,
  CardBody,
  Row,
  Form,
  Col,
} from 'reactstrap'

import AuthHeader from 'components/Auth/AuthHeader'
import InputGroupComponent from 'components/Auth/InputGroupComponent'
import PasswordTips from 'components/Auth/PasswordTips'

import { withAlerts } from 'contexts/alertsProvider'
import { confirmNewPassword } from 'helpers/cognito'
import { useQuery } from 'hooks/useQuery'
import ICONS from 'helpers/iconConstants'

const PASSWORD_REGEXP = /(?!.*\s)(?=.*([a-z]))(?=.*([A-Z]))(?=.*\d)([\x20-\x7E]){8,}/
const validationSchema = Yup.object().shape({
  code: Yup.string().required('Please provide your code'),
  password: Yup.string().required('Please provide new password').matches(PASSWORD_REGEXP, 'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number'),
  passwordConfirmation: Yup.string().required('Please provide password confirmation')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    }),
})

const NewPasswordPage = ({ alerts }) => {
  const [state, setState] = useState({})
  const { getParam } = useQuery()
  const history = useHistory()

  const submitForm = async ({ code, password }) => {
    try {
      const email = getParam('email')
      await confirmNewPassword(email, code, password)
      alerts.success({ message: 'Your password has been changed. Please login.' })
      history.push('/login')
    } catch ({ message }) {
      alerts.error({ message })
    }
  }

  const formik = useFormik({
    initialValues: {
      code: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema,
    onSubmit: submitForm,
  })

  return (
    <>
      <AuthHeader title="New password" lead="Enter the code sent to the email and a new password." />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" onSubmit={formik.handleSubmit}>
                  <InputGroupComponent
                    id="inputEmail"
                    focus={state.codeFocus}
                    errorText={formik.errors.code}
                    placeholder="Code"
                    type="text"
                    name="code"
                    onFocus={() => setState({ ...state, codeFocus: true })}
                    onBlur={() => setState({ ...state, codeFocus: false })}
                    value={formik.values.code}
                    invalid={formik.touched.code && Boolean(formik.errors.code)}
                    onChange={formik.handleChange}
                  />
                  <InputGroupComponent
                    id="inputPassword"
                    icon={ICONS.LOCK_CIRCLE_OPEN}
                    focus={state.passwordFocus}
                    errorText={formik.errors.password}
                    placeholder="New Password"
                    type="password"
                    name="password"
                    onFocus={() => setState({ ...state, passwordFocus: true })}
                    onBlur={() => setState({ ...state, passwordFocus: false })}
                    value={formik.values.password}
                    invalid={formik.touched.password && Boolean(formik.errors.password)}
                    onChange={formik.handleChange}
                  />
                  <InputGroupComponent
                    id="inputPasswordConfirmation"
                    icon={ICONS.LOCK_CIRCLE_OPEN}
                    focus={state.passwordConfirmationFocus}
                    errorText={formik.errors.passwordConfirmation}
                    placeholder="Password Confirmation"
                    type="password"
                    name="passwordConfirmation"
                    onFocus={() => setState({ ...state, passwordConfirmationFocus: true })}
                    onBlur={() => setState({ ...state, passwordConfirmationFocus: false })}
                    value={formik.values.passwordConfirmation}
                    invalid={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
                    onChange={formik.handleChange}
                  />
                  <PasswordTips />
                  <div className="text-center">
                    <Button
                      className="mt-4"
                      color="primary"
                      type="submit"
                      onClick={formik.handleSubmit}
                    >
                      Change Password
                    </Button>
                    <Link to="/login">
                      <Button
                        className="mt-4"
                        color="info"
                        type="submit"
                      >
                        Log In
                      </Button>
                    </Link>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

NewPasswordPage.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
}

export default withAlerts(NewPasswordPage)
