import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'
import { format } from 'sql-formatter'
import capitalize from 'lodash/capitalize'

import ScrollBar from 'components/Custom/ScrollBar'
import EditableRow from 'components/Shared/EditableRow'
import TableRow from 'components/Main/ContainerViewPage/ExtractDataModal/TableRow'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

const SecondStep = ({
  values,
  isLoading,
  setValues,
  showNameField,
}) => {
  const {
    type,
    query,
    tableName,
    showQuery,
    schemaName,
    selectedKeyNames,
    databaseName,
    datasetName,
    sourceFieldNames,
    datasetFieldNames,
  } = values

  if (isLoading) return (
    <div className="d-flex align-items-center h-100">
      <Spinner />
    </div>
  )

  return (
    <ScrollBar>
      <Table size="sm" borderless className="mt-3" style={{ tableLayout: 'fixed' }}>
        <colgroup>
          <col width={'200px'} style={{ minWidth: '190px' }} />
          <col />
        </colgroup>
        <tbody>
          {showNameField && <EditableRow name="New dataset name:" value={datasetName} onUpdate={datasetName => setValues(state => ({ ...state, datasetName }))} />}
          <TableRow name="Selected columns" text={selectedKeyNames?.join(', ')} />
          {datasetFieldNames &&
            <TableRow name={`${capitalize(type)} merge key`} text={datasetFieldNames?.join(', ')} />
          }
          {sourceFieldNames &&
            <TableRow name="Source merge key" text={sourceFieldNames.join(', ')} />
          }
          {databaseName &&
            <TableRow name="Database name" text={databaseName} />
          }
          {!showQuery && schemaName &&
            <TableRow name="Schema name" text={schemaName} />
          }
          {!showQuery && tableName &&
            <TableRow name="Table name" text={tableName} />
          }
          {showQuery && query &&
            <TableRow name="Query" text={<pre><code>{format(query, { uppercase: true })}</code></pre>} />
          }
        </tbody>
      </Table>
    </ScrollBar>
  )
}

SecondStep.defaultProps = {
  showNameField: true,
}


SecondStep.propTypes = {
  isLoading: PropTypes.bool,
  showNameField: PropTypes.bool,
  setValues: PropTypes.func,
  values: PropTypes.shape({
    databaseName: PropTypes.string,
    datasetFieldNames: PropTypes.array,
    datasetName: PropTypes.string,
    query: PropTypes.string,
    schemaName: PropTypes.string,
    selectedKeyNames: PropTypes.array,
    showQuery: PropTypes.bool,
    source: PropTypes.string,
    sourceFieldNames: PropTypes.array,
    tableName: PropTypes.string,
    type: PropTypes.string,
  }),
}

export default SecondStep
