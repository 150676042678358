import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import classnames from 'classnames'
import _ from 'lodash'
import groupBy from 'lodash/groupBy'

import {
  ListGroup,
  ListGroupItem,
  CardHeader,
  Collapse,
  Row,
  Col,
} from 'reactstrap'

import ScrollBar from 'components/Custom/ScrollBar'
import { FilterInput } from 'components/Catalog/Shared/FilterInput'

import { useGetGlossaryQuery } from 'services/catalogs'

import { GRAPH_LABELS } from 'helpers/constants'
import ICONS from 'helpers/iconConstants'
import { removeYes } from 'helpers/utils'

const StyledRow = styled(ListGroupItem)`
  border-radius: 0.2rem !important;
  font-size: 0.9rem;
  &:hover {
    background: var(--lightest);
  }
  &.selected > i {
    transform: rotate(90deg);
  }
`

const RowList = ({ items, value }) => {
  const history = useHistory()
  const [collapse, setCollapse] = useState(false)
  const { workspaceUUID, uuid, section } = useParams()

  useEffect(() => {
    if (value.length === 0) return
    setCollapse(true)
  }, [value])

  const [item, ...rest] = items

  const toggle = (e) => {
    e.stopPropagation()
    rest.length
      ? setCollapse(!collapse)
      : history.push(`/workspaces/${workspaceUUID}/catalog/${section}/${item.hash}`)
  }

  return (
    <>
      <StyledRow
        className={classnames(
          'mt-1 py-1 px-2 border-0 ca-hover text-truncate',
          {
            'bg-lighter text-dark': items.length === 1 && items[0].hash === uuid,
            selected: collapse,
          },
        )}
        role="button"
        onClick={toggle}
      >
        <span className="d-inline-block" style={{ width: '16px' }} />
        {!!rest.length && <i className={`${ICONS.ANGLE_RIGHT} mr-2`} />}
        {value.length !== 0 ? removeYes(item.db_entity || item.name) : removeYes(item.name)}
      </StyledRow>

      {!!rest.length && (
        <Collapse isOpen={collapse}>
          {items.map(nested => (
            <StyledRow
              key={nested.hash}
              className={classnames(
                'mt-1 py-1 px-2 border-0 ca-hover text-truncate',
                {
                  'bg-lighter text-dark': nested.hash === uuid,
                  selected: collapse,
                },
              )}
              role="button"
              onClick={(e) => {
                e.preventDefault()
                history.push(`/workspaces/${workspaceUUID}/catalog/${section}/${nested.hash}`)
              }}
            >
              <span className="d-inline-block" style={{ width: '32px' }} />
              {removeYes(nested.db_entity || nested.name)}
            </StyledRow>
          ))}
        </Collapse>
      )}
    </>
  )
}

const LabelItem = ({ label, items, value }) => {
  const [collapse, setCollapse] = useState(false)

  const formattedData = useMemo(() => _(items)
    .groupBy(key => key.name)
    .orderBy(group => group[0].name)
    .value())

  useEffect(() => {
    if (value.length === 0) return
    setCollapse(true)
  }, [value])

  const toggle = (e) => {
    e.stopPropagation()
    setCollapse(!collapse)
  }

  return (
    <>
      <StyledRow
        className={classnames(
          'mt-1 py-1 px-2 border-0 ca-hover text-truncate',
          {
            selected: collapse,
          },
        )}
        role="button"
        onClick={toggle}
      >
        <i className={`${ICONS.ANGLE_RIGHT} mr-2`} />
        {GRAPH_LABELS[label].name}
      </StyledRow>
      <Collapse isOpen={collapse}>
        {Object.keys(formattedData).map(key => <RowList key={key} items={formattedData[key]} value={value} />)}
      </Collapse>
    </>
  )
}

const GlossaryTree = () => {
  const { workspaceUUID } = useParams()
  const [value, setValue] = useState('')

  const { data = [], isLoading } = useGetGlossaryQuery(workspaceUUID)

  const onChange = ({ target }) => setValue(target.value)

  const filtered = useMemo(() => {
    if (value.length === 0) return data
    return data.filter(({ name, db_entity }) => name.toLocaleUpperCase().includes(value.toLocaleUpperCase()) || db_entity?.toLocaleUpperCase()?.includes(value.toLocaleUpperCase()))
  }, [value])

  const formattedData = useMemo(() => {
    if (isLoading) return {}
    return groupBy(filtered, key => key.labels[0])
  }, [filtered, isLoading])

  return (
    <Row className="flex-column h-100">
      <Col xs="auto">
        <CardHeader className="px-0 py-3">
          <FilterInput size="sm" onChange={onChange} value={value} />
        </CardHeader>
      </Col>
      <Col className="mb-3">
        <Row className="h-100">
          <Col>
            <ScrollBar>
              <ListGroup flush>
                {Object.keys(GRAPH_LABELS).map(key => formattedData[key] && <LabelItem key={key} label={key} items={formattedData[key]} value={value} />)}
              </ListGroup>
            </ScrollBar>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

RowList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    hash: PropTypes.string,
    name: PropTypes.string,
  })),
  value: PropTypes.string,
}

LabelItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    hash: PropTypes.string,
    name: PropTypes.string,
  })),
  value: PropTypes.string,
  label: PropTypes.string,
}


export default GlossaryTree
