import React, { useRef } from 'react'
import Measure from 'react-measure'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars-2'

const renderThumb = ({ style, ...props }) => (
  <div {...props} style={{
    ...style,
    backgroundColor: 'var(--light)',
    borderRadius: '3px',
    cursor: 'pointer',
  }} />
)

const renderTrackHorizontal = ({ style, ...props }) => (
  <div {...props} style={{
    ...style,
    backgroundColor: 'var(--lighter)',
    inset: 'auto 8px 0 0px',
    zIndex: 1039,
  }} />
)

const renderTrackVertical = ({ style, ...props }) => (
  <div {...props} style={{
    ...style,
    backgroundColor: 'var(--lighter)',
    inset: '2px 0 8px auto',
    zIndex: 1039,
  }} />
)

const ScrollBar = ({
  children,
  forceOnUpdateSize = false,
  ...props
}) => {
  const childRef = useRef(null)
  const childSize = useRef({})

  const onResize = ({ bounds = {} }) => {
    if (!forceOnUpdateSize || !childRef.current) return
    const { height, width } = bounds
    const { current } = childSize

    if (height !== current.height || width !== current.width) {
      current.height = height
      current.width = width
      childRef.current.forceUpdate()
    }
  }

  return (
    <Scrollbars
      ref={childRef}
      hideTracksWhenNotNeeded
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      renderTrackHorizontal={renderTrackHorizontal}
      renderTrackVertical={renderTrackVertical}
      {...props}
    >
      {forceOnUpdateSize
        ? (
          <Measure bounds onResize={onResize}>
            {({ measureRef }) => <div ref={measureRef}>{children}</div>}
          </Measure>
        )
        : children}
    </Scrollbars>
  )
}

ScrollBar.propTypes = {
  children: PropTypes.node,
  forceOnUpdateSize: PropTypes.bool,
}

export default ScrollBar
