import divianApi from './api'
import lodashKeys from 'lodash/keys'

export const catalogsApi = divianApi.injectEndpoints({
  endpoints: build => ({
    getCatalog: build.query({
      queryFn: async (...args) => {
        const [values, { dispatch },,fetchWithBQ] = args
        const {
          uuid,
          db_name,
          name,
          type,
          schema,
          workspaceUUID,
        } = values
        const { data: catalog } = await fetchWithBQ({
          url: `catalogs/${uuid}`,
          params: {
            db_name,
            name,
            type,
            schema,
            workspace_uuid: workspaceUUID,
          },
        })
        const { header } = await dispatch(divianApi.endpoints.getTableHeader.initiate({
          uuid,
          databaseName: db_name,
          tableName: name,
          schemaName: schema,
        })).unwrap()
        const tableData = header?.data || []
        const thead = lodashKeys(tableData[0])
        const meta = thead.reduce((acc, key) => {
          const foundMeta = catalog.graph_meta.meta.find(({ name }) => name.toLocaleUpperCase() === key.toLocaleUpperCase())
          if (foundMeta) acc.push(foundMeta)
          return acc
        }, [])
        catalog.graph_meta.meta = meta
        return { data: { ...catalog, header } }
      },
      providesTags: ['Catalog'],
    }),
    updateCatalog: build.mutation({
      query: ({ uuid, ...patch }) => ({
        url: `catalogs/${uuid}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: ['Catalog'],
    }),
    getGlossary: build.query({
      query: workspaceUUID => `catalogs/glossary?workspace_uuid=${workspaceUUID}`,
      providesTags: ['Glossary'],
    }),
    getGlossaryStatus: build.query({
      query: workspaceUUID => `catalogs/graph_status?workspace_uuid=${workspaceUUID}`,
      providesTags: ['Glossary'],
    }),
    getReason: build.mutation({
      query: ({ question, workspaceUUID }) => ({
        url: `reason?workspace_uuid=${workspaceUUID}`,
        method: 'POST',
        body: { question },
      }),
      invalidatesTags: ['Request', 'Requests'],
    }),
    refetchReason: build.mutation({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['Request', 'Requests'],
    }),
    getRequests: build.query({
      query: workspaceUUID => `catalogs/requests?workspace_uuid=${workspaceUUID}`,
      providesTags: ['Requests'],
    }),
    getRequest: build.query({
      query: ({ uuid, workspaceUUID }) => `catalogs/requests/${uuid}?workspace_uuid=${workspaceUUID}`,
      providesTags: ['Request'],
    }),
    getGlossaryPath: build.query({
      query: ({ hash, workspaceUUID }) => `catalogs/glossary/${hash}?workspace_uuid=${workspaceUUID}`,
      providesTags: ['GlossaryPath'],
    }),
    fetchGlossaryPath: build.query({
      query: ({ hashes, workspaceUUID }) => {
        const hashesParams = hashes.map(hash => `hashes[]=${hash}`).join('&')
        return `catalogs/glossary/fetch?workspace_uuid=${workspaceUUID}&${hashesParams}`
      },
      providesTags: ['GlossaryPath'],
    }),
    updateGlossary: build.mutation({
      query: ({ hash, workspaceUUID, ...patch }) => ({
        url: `catalogs/glossary/${hash}?workspace_uuid=${workspaceUUID}`,
        method: 'PUT',
        body: patch,
      }),
      onQueryStarted({ hash, workspaceUUID, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(catalogsApi.util.updateQueryData('getGlossary', workspaceUUID, (draft) => {
          const glossary = draft.find(g => g.hash === hash)
          Object.assign(glossary, patch)
        }))
        queryFulfilled.catch(patchResult.undo)
      },
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetCatalogQuery,
  useGetGlossaryQuery,
  useGetGlossaryStatusQuery,
  useGetReasonMutation,
  useGetRequestQuery,
  useGetRequestsQuery,
  useGetGlossaryPathQuery,
  useUpdateGlossaryMutation,
  useUpdateCatalogMutation,
} = catalogsApi
