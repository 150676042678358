import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap'
import ICONS from 'helpers/iconConstants'

const ActionsMenu = ({
  addChapterHandler,
  addPageHandler,
  deleteHandler,
  editHandler,
  editTextHandler,
  isActive,
  page,
}) => (
  <UncontrolledDropdown className="doc-page-acions-wrapper">
    <DropdownToggle nav className="px-1">
      <Button color="light" outline size="sm" >
        <span className="btn-inner--icon">
          <i className={ICONS.BULLET_LIST} />
        </span>
      </Button>
    </DropdownToggle>
    <DropdownMenu right tag="ul">
      <NavLink tag="li" onClick={editHandler}>
        <DropdownItem>Change Name</DropdownItem>
      </NavLink>
      {isActive && page.parent_id && (
        <NavLink tag="li" onClick={editTextHandler}>
          <DropdownItem>Edit Text</DropdownItem>
        </NavLink>
      )}
      {!page.parent_id && (
        <NavLink tag="li" onClick={addChapterHandler}>
          <DropdownItem>Add Chapter</DropdownItem>
        </NavLink>
      )}
      <NavLink tag="li" onClick={addPageHandler}>
        <DropdownItem>Add Page</DropdownItem>
      </NavLink>
      <NavLink tag="li" onClick={deleteHandler}>
        <DropdownItem>Delete</DropdownItem>
      </NavLink>
    </DropdownMenu>
  </UncontrolledDropdown>
)

ActionsMenu.propTypes = {
  page: PropTypes.shape({
    parent_id: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }),
  editHandler: PropTypes.func,
  addChapterHandler: PropTypes.func,
  addPageHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  editTextHandler: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  isActive: PropTypes.bool,
}

export default ActionsMenu
