import React from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'

import store from 'helpers/store'
import App from 'App'

const mode = localStorage.getItem('collapsed') || 'false'

if (mode === 'true') {
  document.body.classList.remove('g-sidenav-pinned')
  document.body.classList.remove('g-sidenav-show')
  document.body.classList.add('g-sidenav-hidden')
} else {
  document.body.classList.add('g-sidenav-pinned')
  document.body.classList.remove('g-sidenav-hidden')
}

ReactDOM.render(
  <Provider store={store}>
    <Helmet defaultTitle="Divian App" titleTemplate="%s | Divian" />
    <App />
  </Provider>,
  document.getElementById('root'),
)
