import React from 'react'
import { Col } from 'reactstrap'

const RestrictionListSection = () => (
  <Col md={12} className="mt-4">
    <h5>CSV File Format Requirements:</h5>
    <h6>• Max file size: 100mb, min file size: 1kb</h6>
    <h6>• Use dot symbol "." to separate decimals</h6>
    <h6>• Use commas as delimiter</h6>
    <h6>• If using separators for thousands, use commas ","</h6>
    <h6>• Place variable names in the first row</h6>
    <h6>• Orient your data vertically: place observations in rows, variables in columns</h6>
  </Col>
)

export default RestrictionListSection
