import React from 'react'
import PropTypes from 'prop-types'
import ICONS from 'helpers/iconConstants'
import { Col, Row } from 'reactstrap'

const SelectedFileSection = ({
  fileName,
  onUnselect,
}) => (
  <Row className=" justify-content-center align-items-center h-100">
    <Col className="col-auto pr-0" style={{ color: 'var(--light)', fontSize: '2rem' }}>
      <i className={ICONS.FILE_ALT} />
    </Col>

    <Col className="text-break col-auto" style={{ color: 'var(--info)', fontSize: '1.2rem' }}>
      {fileName}
    </Col>

    <Col className="col-auto pl-0" style={{ color: 'var(--light)' }}>
      <i role="button" className={ICONS.CLOSE} onClick={onUnselect} />
    </Col>
  </Row>
)

SelectedFileSection.propTypes = {
  fileName: PropTypes.string,
  onUnselect: PropTypes.func,
}

export default SelectedFileSection
