export const MODEL_TYPES = {
  TIME_SERIES_MODEL: 'TimeSeries',
  CROSS_SECTION_MODEL: 'CrossSectional',
  PARAMETRIC_LINEAR_MODEL: 'LinearRegression',
}

export const SOURCE_TYPES = {
  PGSQL: 'postgresql',
  MYSQL: 'mysql',
  FILE: 'file',
  DIVIAN_API: 'divian_api',
  SNOWFLAKE: 'snowflake',
  GOOGLE_DRIVE: 'google_drive',
}

export const FILTER_SOURCE_OPTIONS = [
  { label: 'All sources', value: 'all' },
  { label: 'Datasets', value: 'datasets' },
  { label: 'Files', value: SOURCE_TYPES.FILE },
  { label: 'MySQL', value: SOURCE_TYPES.MYSQL },
  { label: 'PostgresSQL', value: SOURCE_TYPES.PGSQL },
  { label: 'Snowflake', value: SOURCE_TYPES.SNOWFLAKE },
]

export const FILE_SOURCES = [
  SOURCE_TYPES.FILE,
  SOURCE_TYPES.DIVIAN_API,
]

export const DATASET_SOURCES = {
  IEX: 'IEXConnector',
  FILE: 'UserFileConnector',
  MYSQL: 'MySQLConnector',
  PGSQL: 'PostgreSQLConnector',
  MSSQL: 'MSSQLConnector',
}

export const IMPORT_OPTIONS = {
  SOURCE: 'source',
  FILE: 'file',
  LIBRARY: 'library',
  MSSQL: 'mssql',
  MYSQL: 'mysql',
  PGSQL: 'pgsql',
  SNOWFLAKE: 'snowflake',
}

export const EDITION_TYPES = {
  UPLOAD: 'upload',
  EDIT: 'edit',
  MERGE: 'merge',
}

export const DATA_MAPPING = {
  int: 'numeric',
  integer: 'numeric',
  double: 'numeric',
  number: 'numeric',
  datetime: 'datetime',
  string: 'text',
}

export const STATUS_TYPES = {
  COMPLETED: 'completed',
  FAILED: 'failed',
  INITIALIZING: 'initializing',
  RUNNING: 'running',
  PENGING: 'pending',
  CANCELED: 'canceled',
}

export const CONTAINER_ACTIONS = {
  EDITION: 'edition',
  EXTRACTION: 'extraction',
  MERGE: 'merge',
}

export const DATASET_STATUSES = {
  UPDATING: 'updating',
  FAILED: 'failed',
  INITIALIZING: 'initializing',
  PENDING: 'pending',
  READY: 'ready',
}

export const ERROR_ROWS = [
  'has_bracket_symbols',
  'has_finance_symbols',
  'has_missing_values',
  'has_pct_symbols',
  'has_weight_symbols',
]

export const GRAPH_LABELS = {
  CATEGORY: {
    name: 'Category',
    background: 'rgb(247, 151, 103)',
    border: '#e07740',
  },
  DATA_SOURCE: {
    name: 'Data source',
    background: 'rgb(255, 196, 84)',
    border: '#e79b0d',
    textColor: '#000',
  },
  DATA_TYPE: {
    name: 'Data type',
    background: 'rgb(86, 148, 128)',
    border: '#1f7357',
  },
  GEO: {
    name: 'Geo',
    background: 'rgb(87, 199, 227)',
    border: '#198daa',
    textColor: '#000',
  },
  GEO_COLUMN: {
    name: 'Geo column',
    background: 'rgb(96, 74, 14)',
    border: '#392a02',
  },
  MEASURE: {
    name: 'Measure',
    background: 'rgb(76, 142, 218)',
    border: '#195fb0',
  },
  PERSON: {
    name: 'Person',
    background: 'rgb(96, 74, 14)',
    border: '#392a02',
  },
  PRODUCT: {
    name: 'Product',
    background: 'rgb(86, 148, 128)',
    border: '#1f7357',
  },
  TEMPORAL: {
    name: 'Temporal',
    background: 'rgb(236, 181, 201)',
    border: '#eb77a1',
    textColor: '#000',
  },
  formula: {
    name: 'Formula',
    background: 'rgb(236, 181, 201)',
    border: '#eb77a1',
    textColor: '#000',
  },
  ORGANIZATION: {
    name: 'Organization',
    background: 'rgb(236, 181, 201)',
    border: '#eb77a1',
    textColor: '#000',
  },
  TS_DIMENSION: {
    name: 'Dimension',
    background: 'rgb(96, 74, 14)',
    border: '#392a02',
  },
  TS_VALUE: {
    name: 'Value',
    background: 'rgb(87, 199, 227)',
    border: '#198daa',
    textColor: '#000',
  },
}
