import styled from 'styled-components'
import { UncontrolledTooltip } from 'reactstrap'

export const ErrorUncontrolledTooltip = styled(UncontrolledTooltip)`
  .tooltip-inner {
    background-color: #ff8d72 ;
    color: #fff;
  }

  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before{
    border-bottom-color: #ff8d72;
  }

  .bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before{
    border-top-color:#ff8d72 ;
  }

  .bs-tooltip-auto[x-placement^="left"] .arrow::before, .bs-tooltip-left .arrow::before{
    border-left-color: #ff8d72;
  }

  .bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-right .arrow::before{
    border-right-color: #ff8d72;
  }
`
