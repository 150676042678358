import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'
import {
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'

import ICONS from 'helpers/iconConstants'

const TabLink = ({ name, active }) => (
  <NavItem className="pb-2">
    <NavLink
      className={classnames({ 'font-weight-bold': active })} href={`#${name.toLowerCase().split(' ').join('_')}`}
      style={{ color: 'inherit' }}
    >
      {name}
    </NavLink>
  </NavItem>
)

TabLink.propTypes = {
  active: PropTypes.bool,
  name: PropTypes.string,
}

const StyledIcon = styled.i`
  position: absolute;
  left: -15px;
  top: 2px;
`

const AccountPage = ({ activeTab, isAdmin }) => (
  <Nav vertical className="pl-3 pr-2">
    <NavItem className="pb-2">
      <div className="position-relative">
        <StyledIcon className={ICONS.ID_CARD} />
        <b className="text-capitalize py-1 px-3 ml-n1">Account</b><br/>
      </div>
    </NavItem>
    <NavItem>
      <Nav className="flex-row flex-md-column">
        <TabLink name="Profile" active={activeTab === 'profile'} />
        <TabLink name="Security" active={activeTab === 'security'} />
        <TabLink name="API Key" active={activeTab === 'api_key'} />
        <TabLink name="Notifications" active={activeTab === 'notifications'} />
      </Nav>
    </NavItem>
    <NavItem className="pb-2 mt-5">
      <div className="position-relative">
        <StyledIcon className={ICONS.ORG} />
        <b className="text-capitalize py-1 px-3 ml-n1">Organization</b><br/>
      </div>
    </NavItem>
    <NavItem>
      <Nav className="flex-row flex-md-column">
        <TabLink name="Settings" active={activeTab === 'settings'} />
        <TabLink name="Members" active={activeTab === 'members'} />
        {isAdmin && <TabLink name="Billing" active={activeTab === 'billing'} />}
      </Nav>
    </NavItem>
  </Nav>
)

AccountPage.propTypes = {
  activeTab: PropTypes.string,
  isAdmin: PropTypes.bool,
}

export default AccountPage
