import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Row,
  Col,
} from 'reactstrap'

import AvatarUpload from 'components/Main/AccountPage/AvatarUpload'
import { defaultAvatarSrc } from 'helpers/utils'

const ProfilePicture = ({
  label,
  imageUrl,
  isUploading,
  uploadImage,
}) => {
  const [avatarModal, showAvatarModal] = useState(false)

  return (
    <Row>
      <Col className="pb-4">
        {label && <label className="form-control-label">{label}</label>}
        <div className="account-profile-image">
          <img
            alt="..."
            src={imageUrl}
            onClick={() => showAvatarModal(true)}
            role="button"
            onError={defaultAvatarSrc}
          />
          <AvatarUpload
            avatarModal={avatarModal}
            showAvatarModal={showAvatarModal}
            isUploading={isUploading}
            uploadImage={uploadImage}
          />
        </div>
      </Col>
    </Row>
  )
}

ProfilePicture.defaultProps = {
  label: '',
}

ProfilePicture.propTypes = {
  label: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  isUploading: PropTypes.bool,
  uploadImage: PropTypes.func,
}

export default ProfilePicture
