import React from 'react'
import PropTypes from 'prop-types'

import { Col, Row } from 'reactstrap'

const WizardWrapper = ({ children }) => (
  <Row className="justify-content-center flex-grow-1">
    <Col xs={11} xl={10}>
      {children}
    </Col>
  </Row>
)

WizardWrapper.propTypes = {
  children: PropTypes.node,
}

export default WizardWrapper
