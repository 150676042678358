import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useWizard } from 'react-use-wizard'
import {
  Col,
  Row,
} from 'reactstrap'

import { TextButton } from 'components/Catalog/Shared/Buttons'

import { withAlerts } from 'contexts/alertsProvider'

import ICONS from 'helpers/iconConstants'
const GOOGLE_DRIVE_UUID = '00000000-0000-0000-0000-000000000000'

const WizardFooter = ({
  alerts,
  values,
  setValues,
  handleFormSubmit,
}) => {
  const {
    previousStep,
    nextStep,
    isLastStep,
    isFirstStep,
  } = useWizard()

  const goToNextStep = async () => {
    try {
      if (GOOGLE_DRIVE_UUID === values.uuid) {
        return handleFormSubmit()
      }
      if(isFirstStep) {
        setValues(state => ({ ...state, title: 'Select Tables' }))
      }
      if (isLastStep) {
        if (!values?.tables?.length) throw new Error('No tables selected')
        await handleFormSubmit()
      }
      await nextStep()
    } catch (err) {
      console.error(err.message)
      alerts.error({ message: err.message })
    }
  }

  const goToPrevStep = () => {
    if (!isFirstStep) {
      setValues(state => ({
        ...state,
        tables: [],
        uuid: null,
        title: 'Add New Connection',
      }))
    }
    return previousStep()
  }

  return (
    <Row className="justify-content-center">
      <Col xs={11} xl={10} className={classnames('d-flex m-1 p-3 align-items-center', {
        'justify-content-end': isFirstStep,
        'justify-content-between': !isFirstStep,
      })}>
        {!isFirstStep && (
          <TextButton
            onClick={goToPrevStep}
            label={(
              <><i className={ICONS.ARROW_LEFT} /> Previous</>
            )}
          />
        )}
        <TextButton
          onClick={goToNextStep}
          label={isLastStep
            ? 'Add'
            : <>Next <i className={ICONS.ARROW_RIGHT} /></>}
        />
      </Col>
    </Row>
  )
}

WizardFooter.propTypes = {
  setValues: PropTypes.func,
  handleFormSubmit: PropTypes.func,
  values: PropTypes.shape({
    uuid: PropTypes.string,
    tables: PropTypes.arrayOf(PropTypes.string),
  }),
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
}

export default withAlerts(WizardFooter)
