import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {
  Col,
  Label,
  Row,
  Badge,
} from 'reactstrap'

const SelectSourceWrapper = ({ errors, children }) => (
  <Row className="flex-grow-1 flex-column">
    <Col xs={12} className="flex-basis-0">
      <Label className="w-100 mt-3 mb-2">
        <span className="font-weight-bold">Select source</span>
        <Badge className="text-capitalize" style={{ transform: 'translateY(-0.35rem)' }}>
          Required
        </Badge>
      </Label>
    </Col>
    <Col xs={12} className="flex-grow-1 flex-basis-0">
      <div className={classnames('border p-3 h-100 d-flex flex-column', { 'border-warning': errors.sourceUUID })}>
        {children}
      </div>
    </Col>
  </Row>
)

SelectSourceWrapper.propTypes = {
  errors: PropTypes.shape({
    sourceUUID: PropTypes.bool,
  }),
  children: PropTypes.node,
}

export default SelectSourceWrapper
