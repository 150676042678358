import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react'
import { getSessionUser } from 'helpers/cognito'
import findDomain from 'helpers/domains'

const { REACT_APP_ENVIRONMENT } = process.env

const baseQueryHandler = async (args, api, extraOptions) => {
  const res = await getSessionUser()
  const token = res?.accessToken?.jwtToken

  const domain = findDomain(token)
  const baseUrl = `https://${domain}api.divian.${REACT_APP_ENVIRONMENT}/v2`
  // const baseUrl = 'http://127.0.0.1:5000/v2'

  const response = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      if (token) headers.set('Authorization', token)
      return headers
    },
  })(args, api, extraOptions)
  if (response?.data) return response
  console.error(response?.error)
  return {
    ...response,
    error: {
      ...response.error,
      data: { ...response.error.data, message: response?.error?.data?.message || 'Something went wrong!' },
    },
  }
}

export default createApi({
  baseQuery: baseQueryHandler,
  endpoints: () => ({}),
  tagTypes: [
    'Datasets', 'Dataset', 'DatasetDetails', 'DatasetJobs', 'DatasetHistory', 'DatasetNotes', 'DatasetVariable', 'DatasetLineage', 'DatasetSchedulers',
    'Sources', 'Source', 'SourceTableHeader', 'SourceDatabaseSchema',
    'Models', 'Model',
    'Workspaces', 'Workspace', 'WorkspaceMembers', 'WorkspaceDocuments', 'WorkspaceModels', 'WorkspaceSources', 'WorkspaceOntology', 'WorkspaceDatasets', 'WorkspaceCatalogs',
    'Documents', 'Document', 'AssetDocuments',
    'Schedulers', 'Scheduler',
    'User',
    'Ontology',
    'Catalog', 'Glossary', 'GlossaryPath', 'Requests', 'Request',
    'Container', 'ContainerVariable', 'ContainerSources', 'ContainerSource',
    'Organization', 'OrganizationUsers', 'OrganizationBilling',
    'Notifications',
  ],
})
