import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
import {
  Col,
  Row,
  ListGroup,
} from 'reactstrap'
import groupBy from 'lodash/groupBy'

import ScrollBar from 'components/Custom/ScrollBar'
import ListItem from 'components/Catalog/AssetsPage/ListItem'

const AssetList = ({ data }) => {
  const history = useHistory()
  const { workspaceUUID, section } = useParams()

  const onClick = ({
    source_uuid, name, db_name, schema, webViewLink,
  }) => webViewLink
    ? window.open(webViewLink, '_blank', 'noreferrer')
    : history.push(`/workspaces/${workspaceUUID}/catalog/${section}/${source_uuid}?db_name=${db_name}&schema=${schema}&name=${name}`)

  const groupedData = useMemo(() => groupBy(data, item => item.source_type), [data])

  return (
    <ScrollBar>
      <Row className="justify-content-center m-0">
        <Col xs={12} md={10} lg={7}>
          {Object.keys(groupedData).map(key => (
            <ListGroup flush className="my-3" key={key}>
              <ListItem data={{ ...groupedData[key][0], name: key }}>
                {groupedData[key].map((row, i) => <ListItem data={row} onClick={() => onClick(row)} key={i} />)}
              </ListItem>
            </ListGroup>
          ))}
        </Col>
      </Row>
    </ScrollBar>
  )
}

AssetList.defaultProps = {
  data: [],
}

AssetList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
}

export default AssetList
