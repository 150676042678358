import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useAsyncDebounce } from 'react-table'
import {
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from 'reactstrap'

import ICONS from 'helpers/iconConstants'

const StyledInput = styled(Input)`
  &:focus { border-color: #dee2e6; }
`

export const FilterInput = ({
  onChange,
  onIconClick,
  onKeyPress,
  placeholder,
  size,
  value,
}) => (
  <InputGroup size={size}>
    <InputGroupAddon addonType="prepend" {...onIconClick && { onClick: onIconClick, role: 'button' }}>
      <InputGroupText>
        <i className={ICONS.SEARCH} />
      </InputGroupText>
    </InputGroupAddon>
    <StyledInput
      placeholder={placeholder}
      onChange={onChange}
      value={value || ''}
      {...onKeyPress && { onKeyPress }}
    />
  </InputGroup>
)

export const ReactTableFilter = ({
  globalFilter,
  placeholder,
  setGlobalFilter,
  size,
}) => {
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => setGlobalFilter(value || undefined), 200)
  const onChangeHandler = ({ target }) => {
    setValue(target.value)
    onChange(target.value)
  }

  return <FilterInput size={size} placeholder={placeholder} onChange={onChangeHandler} value={value} />
}

ReactTableFilter.defaultProps = {
  placeholder: 'Type for filter...',
  size: 'sm',
}

ReactTableFilter.propTypes = {
  globalFilter: PropTypes.string,
  placeholder: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  size: PropTypes.string,
}

FilterInput.defaultProps = {
  placeholder: 'Type for search...',
}

FilterInput.propTypes = {
  onChange: PropTypes.func,
  onIconClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.string,
}
