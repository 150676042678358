import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from 'reactstrap'

import BotChat from 'components/Catalog/RequestPageView/DataViewTilesOnly/BotChat'
import ICONS from 'helpers/iconConstants'

const LogSection = () => (
  <Card className="rounded-0 position-relative shadow-none">
    <CardHeader className="bg-white position-sticky top-0" style={{ zIndex: 1040 }}>
      <Row>
        <Col className="d-flex align-items-center">
          <i className={ICONS.COMMENT_DOTS} />
          <h3 className="m-0 pl-2 d-inline-block">Log</h3>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <BotChat />
    </CardBody>
  </Card>
)

export default LogSection
