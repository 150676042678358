import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import styled from 'styled-components'

import {
  Col,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

import ICONS from 'helpers/iconConstants'
import { defaultAvatarSrc } from 'helpers/utils'
import { useDeleteUserMutation } from 'services/organization'

import { withAlerts } from 'contexts/alertsProvider'
import { withModals } from 'contexts/modalsProvider'

const StyledDropdownItem = styled(DropdownItem)`
  font-size: 0.85rem !important;
  display: flex;
  align-items: center;
  line-height: initial;
`

const MemberRow = ({ data, alerts, modals }) => {
  const [deleteUser] = useDeleteUserMutation()

  const deleteUserHandler = async (uuid) => {
    const confirmed = await modals.confirm({
      message: 'Are you sure you want to remove this member?',
    })
    if (!confirmed) return
    try {
      await deleteUser(uuid)
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  return (
    <tr>
      <td className="align-middle py-2">
        <Row className="align-items-center">
          <Col className="col-auto d-flex align-items-center">
            <span className="avatar rounded-circle">
              <img alt="..." src={data.avatar_url} onError={defaultAvatarSrc} />
            </span>
          </Col>
          <div className="col ml--2">
            <h4 className="mb-0">{[data.firstname, data.lastname].join(' ')}</h4>
            <small>{data.email}</small>
          </div>
        </Row>
      </td>
      <td className="align-middle py-2 text-capitalize">{data.usertype}</td>
      <td className="align-middle py-2">
        <UncontrolledDropdown size="sm">
          <DropdownToggle className="ml-3 mr-0" color="link text-default" type="button">
            <i className={ICONS.ELLIPSIS_V} />
          </DropdownToggle>
          <DropdownMenu right>
            <StyledDropdownItem onClick={() => deleteUserHandler(data.sub)} className="text-danger">
              <i className={ICONS.DELETE} /> Delete User
            </StyledDropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  )
}

MemberRow.propTypes = {
  data: PropTypes.shape({
    sub: PropTypes.string,
    avatar_url: PropTypes.string,
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    name: PropTypes.string,
    usertype: PropTypes.string,
  }),
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
  modals: PropTypes.shape({
    confirm: PropTypes.func,
  }),
}

export default compose(withAlerts, withModals)(MemberRow)
