import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Styled = styled.div`
  max-width: 80vw;
  font-size: 0.9rem;
`

const StyledWrapper = ({ children, ...rest }) => <Styled {...rest}>{children}</Styled>

StyledWrapper.propTypes = {
  children: PropTypes.node,
}

export default StyledWrapper
