import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import truncate from 'lodash/truncate'

const chartExample8 = {
  options: {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: '#f5f5f5',
      titleFontColor: '#333',
      bodyFontColor: '#666',
      bodySpacing: 4,
      xPadding: 12,
      mode: 'nearest',
      intersect: 0,
      position: 'nearest',
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          display: false,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            padding: 20,
            fontColor: '#9e9e9e',
            callback: value => truncate(value, { length: 20 }),
          },
        },
      ],
    },
  },
}

const BillingChart = ({ hist, shortView }) => {
  const data = useCallback((canvas) => {
    if (!hist?.length) return { labels: [], datasets: [{}] }

    const ctx = canvas.getContext('2d')
    const gradientStroke = ctx.createLinearGradient(0, 0, 0, canvas.height)
    gradientStroke.addColorStop(0, '#1b9be0')
    gradientStroke.addColorStop(1, '#344675')

    const [data, labels] = hist
    return {
      labels,
      datasets: [
        {
          fill: true,
          backgroundColor: gradientStroke,
          hoverBackgroundColor: '#1b9be0',
          borderColor: '#344675',
          borderDash: [],
          borderDashOffset: 0.0,
          categoryPercentage: 0.95,
          barPercentage: 0.95,
          data: [...data],
          ...!shortView && { maxBarThickness: 45 },
        },
      ],
    }
  }, [hist])

  chartExample8.options.scales.xAxes = [
    {
      display: !shortView,
      gridLines: {
        display: false,
      },
      ticks: {
        fontColor: '#9e9e9e',
      },
    },
  ]

  chartExample8.options.tooltips = {
    enabled: !shortView,
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
  }

  return (
    <Bar
      data={data}
      options={chartExample8.options}
      className="chart-canvas"
    />
  )
}

BillingChart.propTypes = {
  hist: PropTypes.array,
  shortView: PropTypes.bool,
}

export default BillingChart
