import axios from 'axios'
import { getSessionUser } from 'helpers/cognito'
import findDomain from 'helpers/domains'

const apiInstance = axios.create()
const requestInstance = axios.create()
const { REACT_APP_ENVIRONMENT } = process.env

function createFormData(data, formData = new window.FormData(), previousKey) {
  if (Array.isArray(data)) {
    data.forEach((val, index) => {
      createFormData(val, formData, `${previousKey}[${index}]`)
    })
  } else if (data instanceof Object && !(data instanceof window.Blob)) {
    Object.keys(data).forEach((key) => {
      const value = data[key]
      if (previousKey) key = `${previousKey}[${key}]` //eslint-disable-line
      createFormData(value, formData, key)
    })
  } else if (data !== undefined && data !== null) {
    formData.append(previousKey, data)
  }

  return formData
}

export const customRequest = async ({
  multipart,
  data,
  ...rest
}) => {
  const formData = multipart ? createFormData(data) : data
  return requestInstance({
    method: 'GET',
    headers: {
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      'Cache-Control': 'no-cache',
    },
    data: formData,
    ...rest,
  })
}

export const request = async ({
  multipart,
  data,
  ...rest
}) => {
  const res = await getSessionUser()
  const token = res?.accessToken?.jwtToken
  const domain = findDomain(token)
  const formData = multipart ? createFormData(data) : data
  return apiInstance({
    method: 'GET',
    baseURL: `https://${domain}api.divian.${REACT_APP_ENVIRONMENT}`,
    // baseURL: 'http://localhost:5000',
    headers: {
      Authorization: token,
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      'Cache-Control': 'no-cache',
    },
    data: formData,
    ...rest,
  })
}

export const apiRequest = ({
  url,
  ...rest
}) => request({
  url: `divianapi/${url}`,
  ...rest,
})

export const apiV2Request = ({
  url,
  ...rest
}) => request({
  url: `v2/${url}`,
  ...rest,
})

export const lambdaApiV2Request = ({
  ...rest
}) => request({ ...rest, baseURL: 'https://qapi.divian.dev/v2/' })
