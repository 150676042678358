import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import {
  Col,
  Row,
} from 'reactstrap'

import KnowledgeGraphSection from 'components/Catalog/GlossaryPage/Content/KnowledgeGraphSection'
import PropertiesSection from 'components/Catalog/GlossaryPage/Content/PropertiesSection'

const Content = ({ glossary = {} }) => (
  <>
    <Row className="flex-grow-1 py-3">
      {isEmpty(glossary) ? (
        <Col xs={12} className="d-flex align-items-center justify-content-center text-muted">
          No data
        </Col>
      ) : (
        <>
          <Col xs={9}>
            <KnowledgeGraphSection glossary={glossary} />
          </Col>
          <Col xs={3} className="pl-0">
            <PropertiesSection glossary={glossary} />
          </Col>
        </>
      )}
    </Row>
  </>
)

Content.propTypes = {
  glossary: PropTypes.shape({}),
}

export default Content
