import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Table } from 'reactstrap'
import { format } from 'sql-formatter'

import ScrollBar from 'components/Custom/ScrollBar'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

const StyledRow = styled.tr`
  td {
    font-size: 1rem;
  }
`

const TableRow = ({ name, text }) => (
  <StyledRow>
    <td>
      <strong>{name}:</strong>
    </td>
    <td className="text-wrap">{text}</td>
  </StyledRow>
)

TableRow.propTypes = {
  name: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

const ThirdStep = ({ values, isLoading }) => {
  const {
    query,
    tableName,
    showQuery,
    schemaName,
    selectedKeyNames,
    databaseName,
    sourceName,
    sourceFieldNames,
    datasetFieldNames,
  } = values

  if (isLoading) return (
    <div className="d-flex align-items-center h-100">
      <Spinner />
    </div>
  )

  return (
    <ScrollBar>
      <Table size="sm" borderless className="mt-3" style={{ tableLayout: 'fixed' }}>
        <colgroup>
          <col width={'200px'} style={{ minWidth: '190px' }} />
          <col />
        </colgroup>
        <tbody>
          <TableRow name="Connector" text={sourceName} />
          <TableRow name="Selected columns" text={selectedKeyNames.join(', ')} />
          {datasetFieldNames &&
            <TableRow name="Dataset merge key" text={datasetFieldNames?.join(', ')} />}
          {sourceFieldNames &&
            <TableRow name="Source merge key" text={sourceFieldNames.join(', ')} />}
          {databaseName &&
            <TableRow name="Database name" text={databaseName} />}
          {!showQuery && schemaName &&
            <TableRow name="Schema name" text={schemaName} />}
          {!showQuery && tableName &&
            <TableRow name="Table name" text={tableName} />}
          {showQuery && query &&
            <TableRow name="Query" text={<pre><code>{format(query, { uppercase: true })}</code></pre>} />}
        </tbody>
      </Table>
    </ScrollBar>
  )
}


ThirdStep.propTypes = {
  isLoading: PropTypes.bool,
  values: PropTypes.shape({
    showQuery: PropTypes.bool,
    tableName: PropTypes.string,
    schemaName: PropTypes.string,
    databaseName: PropTypes.string,
    query: PropTypes.string,
    sourceName: PropTypes.string,
    source: PropTypes.string,
    selectedKeyNames: PropTypes.array,
    sourceFieldNames: PropTypes.array,
    datasetFieldNames: PropTypes.array,
  }),
}

export default ThirdStep
