import divianApi from './api'
import remove from 'lodash/remove'

export const modelsApi = divianApi.injectEndpoints({
  endpoints: build => ({
    getModels: build.query({
      query: () => 'models',
      providesTags: ['Models'],
    }),
    getModel: build.query({
      query: uuid => `models/${uuid}`,
      providesTags: ['Model'],
    }),
    addModel: build.mutation({
      query: body => ({
        url: 'models',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Models', 'WorkspaceModels'],
    }),
    updateModel: build.mutation({
      query: ({ uuid, ...patch }) => ({
        url: `models/${uuid}`,
        method: 'PUT',
        body: patch,
      }),
      async onQueryStarted({ uuid }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedSource } = await queryFulfilled
          dispatch(modelsApi.util.updateQueryData('getModel', uuid, (draft) => {
            Object.assign(draft, updatedSource)
          }))
          dispatch(modelsApi.util.updateQueryData('getModels', undefined, (draft) => {
            const source = draft.find(source => source.uuid === uuid)
            Object.assign(source, updatedSource)
          }))
        } catch {}
      },
      invalidatesTags: ['WorkspaceModels'],
    }),
    setModelVersion: build.mutation({
      query: ({ uuid, version_uuid }) => ({
        url: `models/${uuid}/history`,
        method: 'POST',
        body: { version_uuid },
      }),
      invalidatesTags: (_, error) => error ? [] : ['Model'],
    }),
    deleteModel: build.mutation({
      query: uuid => ({
        url: `models/${uuid}`,
        method: 'DELETE',
      }),
      onQueryStarted: (uuid, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(divianApi.util.updateQueryData('getModels', undefined, (draft) => {
          remove(draft, model => model.uuid === uuid)
        }))
        queryFulfilled.catch(patchResult.undo)
      },
    }),
    addForecast: build.mutation({
      query: ({ uuid, ...data }) => ({
        url: `models/${uuid}/forecast`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Model'],
    }),
    deleteForecast: build.mutation({
      query: ({ uuid, ...data }) => ({
        url: `models/${uuid}/forecast`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['Model'],
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetModelsQuery,
  useGetModelQuery,
  useAddModelMutation,
  useUpdateModelMutation,
  useSetModelVersionMutation,
  useDeleteModelMutation,
  useAddForecastMutation,
  useDeleteForecastMutation,
} = modelsApi
