import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, useHistory } from 'react-router-dom'

import { useToken } from 'hooks/useToken'

const AuthRoutes = ({ component: Component, ...rest }) => {
  const history = useHistory()
  const [loading, token] = useToken()

  if (loading) return null
  if (token) {
    return (
      <Redirect
        to={{ pathname: '/', state: { from: history.location } }}
      />
    )
  }

  return (
    <div className="main-content bg-default" style={{ height: '100vh' }}>
      <Route {...rest} render={props => (<Component {...props} />)} />
    </div>
  )
}

AuthRoutes.propTypes = {
  component: PropTypes.func,
}

export default AuthRoutes
