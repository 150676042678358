import React from 'react'
import PropTypes from 'prop-types'
import { useParams, Link } from 'react-router-dom'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import { BadgeTag } from 'components/Catalog/Shared/Tags'
import { removeYes } from 'helpers/utils'

const Wrapper = styled.div`
  width: 250px;
  z-index: 5;

  > .border { border-radius: 3px; }

  ul {
    list-style: none;
    font-size: 12px;
  }
`

const Mark = styled.div`
  border-right: 12px solid transparent;
  border-top: 12px solid ${({ color }) => color};
`

const NodeInfo = ({ node = {} }) => {
  const { workspaceUUID } = useParams()

  if (isEmpty(node)) return null

  return (
    <Wrapper className="position-absolute top-0 left-2 bg-white">
      <div className="border overflow-hidden position-relative">
        <ul className="m-0 p-2 text-left">
          <li>
            <Mark color={node.color.background} className="position-absolute top-0 left-0" />
            <Link to={`/workspaces/${workspaceUUID}/catalog/glossary/${node.hash}`} className="h5 m-0 text-break w-100 d-inline-block">
              {node.label}
            </Link>
          </li>

          <li className="border-bottom my-2" />

          {node.type && (
            <li>
              <span className="d-block mt-2 mb-1">Type</span>
              <BadgeTag label={node.type} />
            </li>
          )}

          {node.iso && (
            <li>
              <span className="d-block mt-2 mb-1">Iso</span>
              <BadgeTag label={node.iso} />
            </li>
          )}

          {node.data_type && (
            <li>
              <span className="d-block mt-2 mb-1">Type</span>
              <BadgeTag label={node.data_type} />
            </li>
          )}

          {node.db_entity && (
            <li>
              <span className="d-block mt-2 mb-1">Entity</span>
              <BadgeTag label={removeYes(node.db_entity)} />
            </li>
          )}

          {node._label && (
            <li>
              <span className="d-block mt-2 mb-1">Label</span>
              <BadgeTag label={node._label} className="mb-1" />
            </li>
          )}

          {node.keywords && (
            <li>
              <span className="d-block mt-2 mb-1">Tags</span>
              {node.keywords.map((property, i) => <BadgeTag label={removeYes(property)} className="mb-1" key={`tags-${property}-${i}`} />)}
            </li>
          )}

          {node.properties && node.properties[0] !== '' && (
            <li>
              <span className="d-block mt-2 mb-1">Properties</span>
              {node.properties.map((property, i) => <BadgeTag label={property} className="mb-1" key={`prop-${property}-${i}`} />)}
            </li>
          )}

          {node.description && (
            <li>
              <span className="d-block mt-2 mb-1">Description</span>
              {node.description}
            </li>
          )}
        </ul>
      </div>
    </Wrapper>
  )
}

NodeInfo.propTypes = {
  node: PropTypes.shape({
    label: PropTypes.string,
    color: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    name: PropTypes.string,
  }),
}

export default NodeInfo
