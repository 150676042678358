const DATE_TIME_COLUMN = {
  minWidth: '200px',
  width: '200px',
}

const COLUMNS = {
  actions: { minWidth: '80px', width: '80px' },
  created: DATE_TIME_COLUMN,
  desc: { width: '40%', minWidth: '300px' },
  description: { width: '40%', minWidth: '300px' },
  name: { width: '60%', minWidth: '300px' },
  projects: { width: '30%', minWidth: '300px' },
  workspaces: { width: '30%', minWidth: '300px' },
  role: { width: '200px', minWidth: '200px' },
  status: { width: '150px', minWidth: '150px' },
}

const REACT_TABLE_SIZES = {
  binders: COLUMNS,
  datasets: COLUMNS,
  documents: COLUMNS,
  workspaces: COLUMNS,

  jobs: {
    ...COLUMNS,
    actions: { minWidth: '65px', width: '5%' },
    asset_type: { width: '200px', minWidth: '200px' },
    started: DATE_TIME_COLUMN,
    elapsed: DATE_TIME_COLUMN,
  },

  models: {
    ...COLUMNS,
    score: { width: '250px', minWidth: '250px' },
    target: { width: '25%', minWidth: '300px' },
    type: { width: '250px', minWidth: '250px' },
  },

  connectors: {
    ...COLUMNS,
    type: { width: '200px', minWidth: '200px' },
  },

  users: {
    ...COLUMNS,
    id: { width: '40%', minWidth: '200px' },
  },

  schedulers: {
    name: { width: 'auto', minWidth: '300px' },
    actions: COLUMNS.actions,
    description: { width: 'auto', minWidth: '300px' },
    created: DATE_TIME_COLUMN,
    start: DATE_TIME_COLUMN,
    assetType: { width: '200px', minWidth: '200px' },
  },

  assets: {
    desc: { width: 'auto' },
    name: { width: 'auto' },
    type: { width: '200px' },
  },

  nodeStatus: {},
  tradeHistory: {},
  signalCard: {},
}

export default REACT_TABLE_SIZES
