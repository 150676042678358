import React from 'react'
import {
  Row,
  Col,
  Container,
  List,
} from 'reactstrap'

import ScrollBar from 'components/Custom/ScrollBar'
import ModalContainer from 'components/Custom/ModalContainer'
import Topology from 'components/Catalog/HelpModal/Topology'
import Example from 'components/Catalog/HelpModal/Example'

import { useModalContext } from 'contexts/modalProvider'
import { GRAPH_LABELS } from 'helpers/constants'

const ENTITY_TYPES_LIST = [
  {
    name: 'BUSINESS',
    text: 'A concept that is valid on the logical level only. It may not exist in the data store, but it is understandable to a user with a role other than tech.',
  },
  {
    name: 'TECH',
    text: 'A record that physically exists in the data store.',
  },
]

const GRAPH_ENTITIES_LIST = {
  leftColumn: [
    {
      name: 'GEO',
      type: 'BUSINESS',
      text: 'Geographical region or organization',
    },
    {
      name: 'GEO_COLUMN',
      type: 'TECH',
      text: 'Column in data store that point to the geographical region.',
    },
    {
      name: 'TEMPORAL',
      type: 'TECH',
      text: 'Column in data store that contains date and time.',
    },
    {
      name: 'MEASURE',
      type: 'TECH',
      text: 'Column in data store that contains time series value.',
    },
  ],
  rightColumn: [
    {
      name: 'CATEGORY',
      type: 'TECH',
      text: 'Column in data store that contains non-continuous value.',
    },
    {
      name: 'DATA_TYPE',
      type: 'BUSINESS',
      text: 'Type of the stored data.',
    },
    {
      name: 'DATA_SOURCE',
      type: 'TECH',
      text: 'Physical space where real data is stored.',
    },
  ],
}

const HelpModal = () => {
  const { hideModal } = useModalContext()

  return (
    <ModalContainer
      isOpen={true}
      toggle={hideModal}
      centered={true}
      size="xl"
    >
      <ScrollBar autoHeight autoHeightMax="85vh">
        <Container fluid>
          <Row className="position-relative mb-3">
            <Col>
              <h3 className="position-sticky top-0 border-bottom bg-white pb-1 m-0" style={{ zIndex: 1040 }}>ENTITY TYPES</h3>
              <List type="unstyled" className="mb-0">
                {ENTITY_TYPES_LIST.map(({ name, text }) => (
                  <li className="p-2 mt-2" key={name}>
                    <h5 className="mb-0">{name}</h5>
                    <p className="pl-2 m-0">{text}</p>
                  </li>
                ))}
              </List>
            </Col>
          </Row>

          <Row className="position-relative mb-3 d-none">
            <Col>
              <h3 className="position-sticky top-0 border-bottom bg-white pb-1 m-0" style={{ zIndex: 1040 }}>GRAPH ENTITIES</h3>

              <Row>
                <Col md={6}>
                  <List type="unstyled" className="mb-0">
                    {GRAPH_ENTITIES_LIST.leftColumn.map(({ name, text, type }) => (
                      <li className="p-2" key={name}>
                        <h5 className="mb-0 d-flex align-items-center text-uppercase">
                          <div className="p-1 mr-2 circle-status" style={{ background: GRAPH_LABELS[name]?.background }} />
                          {`${GRAPH_LABELS[name]?.name} [${type}]`}
                        </h5>
                        <p className="m-0">{text}</p>
                      </li>
                    ))}
                  </List>
                </Col>
                <Col md={6}>
                  <List type="unstyled" className="mb-0">
                    {GRAPH_ENTITIES_LIST.rightColumn.map(({ name, text, type }) => (
                      <li className="p-2" key={name}>
                        <h5 className="mb-0 d-flex align-items-center text-uppercase">
                          <div className="p-1 mr-2 circle-status" style={{ background: GRAPH_LABELS[name]?.background }} />
                          {`${GRAPH_LABELS[name]?.name} [${type}]`}
                        </h5>
                        <p className="m-0">{text}</p>
                      </li>
                    ))}
                  </List>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="position-relative mb-3">
            <Col>
              <h3 className="position-sticky top-0 border-bottom bg-white pb-1 m-0" style={{ zIndex: 1040 }}>TOPOLOGY</h3>
              <List type="unstyled" className="mb-0">
                <li className="p-2">
                  <Topology />
                </li>
              </List>
            </Col>
          </Row>

          <Row className="position-relative mb-3 d-none">
            <Col>
              <h3 className="position-sticky top-0 border-bottom bg-white pb-1 m-0">EXAMPLE</h3>
              <List type="unstyled" className="mb-0">
                <li className="p-2 text-center">
                  <Example />
                </li>
              </List>
            </Col>
          </Row>
        </Container>
      </ScrollBar>
    </ModalContainer>
  )
}

export default HelpModal
