import React from 'react'
import {
  Row,
  Col,
} from 'reactstrap'

import ModalContainer from 'components/Custom/ModalContainer'
import FormWrapper from 'components/Main/SourceViewPage/CreateSourceModal/Forms/FormWrapper'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import { ButtonWithSpinner } from 'components/Custom/Buttons'

import { useAddFileSourceMutation, useAddDatabaseSourceMutation } from 'services/sources'
import { useModalContext } from 'contexts/modalProvider'

const CreateSourceModal = () => {
  const { hideModal, store: { modalProps } } = useModalContext()
  const { workspaceUUID } = modalProps

  const [addFileSource, { isLoading }] = useAddFileSourceMutation()
  const [addDatabaseSource, { isLoading: isAdding }] = useAddDatabaseSourceMutation()

  return (
    <ModalContainer
      title="Create new connector"
      isOpen={true}
      toggle={hideModal}
      customSize="bg"
    >
      <Row className="justify-content-center flex-grow-1">
        <Col xs={11} xl={10} className="d-flex flex-column">
          <Row>
            <Col>
              <h3 className="pt-3">What&#8217;s a connector?</h3>
              <p>It&#8217;s a persistent link to a data source. A connector simplifies pulls from a source and lets you share its content without giving up credentials.</p>
            </Col>
          </Row>

          <Row className="flex-grow-1">
            <Col>
              <FormWrapper
                addFileSource={addFileSource}
                workspaceUUID={workspaceUUID}
                addDatabaseSource={addDatabaseSource}
                hideModal={hideModal}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs={11} xl={10} className="d-flex m-1 p-3 align-items-center justify-content-end">
          <ButtonWithSpinner
            label="Create connector"
            form="create-source-form"
            type="submit"
            isUpdating={isAdding}
          />
        </Col>
      </Row>

      <LoadingSpinner visible={isLoading || isAdding} />
    </ModalContainer>
  )
}

export default CreateSourceModal
