import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import getValue from 'lodash/get'

import {
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap'
import ProfilePicture from 'components/Main/AccountPage/ProfilePicture'
import { ButtonWithSpinner } from 'components/Custom/Buttons'

import { withAlerts } from 'contexts/alertsProvider'
import { useGetAccountQuery, useUpdateAccountMutation, useUploadAvatarMutation } from 'services/user'

const Profile = ({ alerts }) => {
  const { data: account = {}, isFetching } = useGetAccountQuery()
  const [updateAccount, { isLoading: isUpdating }] = useUpdateAccountMutation()
  const [uploadAvatar, { isLoading: isUploading }] = useUploadAvatarMutation()

  const imageUrl = useMemo(() => `${account?.avatar_url}?${Date.now()}`, [isFetching])

  const submitForm = async (data) => {
    try {
      await updateAccount(data).unwrap()
    } catch ({ data: { message } }) {
      alerts.error({ message })
    }
  }

  const formik = useFormik({
    initialValues: {
      firstname: getValue(account, 'firstname', ''),
      lastname: getValue(account, 'lastname', ''),
      about: getValue(account, 'about', ''),
    },
    enableReinitialize: true,
    onSubmit: submitForm,
  })

  return (
    <>
      <h2 className="m-0">Profile</h2>
      <hr className="mb-4" />
      <ProfilePicture
        imageUrl={imageUrl}
        isUploading={isUploading}
        uploadImage={uploadAvatar}
      />
      <Form>
        <Row>
          <Col md={8}>
            <FormGroup>
              <label className="form-control-label">Email</label>
              <p>{account?.email}</p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <FormGroup>
              <label className="form-control-label">First Name</label>
              <Input
                type="text"
                placeholder="First Name"
                name="firstname"
                value={formik.values.firstname}
                onChange={formik.handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <FormGroup>
              <label className="form-control-label">Last Name</label>
              <Input
                type="text"
                placeholder="Last Name"
                name="lastname"
                value={formik.values.lastname}
                onChange={formik.handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <FormGroup>
              <label className="form-control-label">About Me *</label>
              <Input
                cols="80"
                placeholder="Please Tell Us About"
                rows="4"
                type="textarea"
                name="about"
                value={formik.values.about}
                onChange={formik.handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <label className="form-control-label">* If you join a workspace, other workspace members will see this</label>

        <Row>
          <Col>
            <ButtonWithSpinner
              className="mt-4"
              label="Save"
              isUpdating={isUpdating}
              onClick={formik.handleSubmit}
              disabled={!(formik.dirty && formik.isValid) || isUpdating}
            />
          </Col>
        </Row>
      </Form>
    </>
  )
}

Profile.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
}

export default withAlerts(Profile)
