import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Styled = styled.tr`
td {
  font-size: 1rem;
  div { padding: 0; }
  label {
    margin-bottom: 0;
    padding: 0;
  }
}
`

const StyledTr = ({ children, ...rest }) => (<Styled {...rest}>{children}</Styled>)

StyledTr.propTypes = {
  children: PropTypes.node,
}

export default StyledTr
