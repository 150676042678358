import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  NavItem,
  NavLink,
} from 'reactstrap'

const StyledNavItem = styled(NavItem)`
  .active {
    background: var(--lightest);
    border-bottom-color: var(--lightest) !important;
  }

  &:not(.active):hover {
    background: var(--lightest);
  }

  &:not(:last-child) {
    margin-right: 0.3rem;
  }
`

const StyledTab = ({ children, activeTab, name }) => (
  <StyledNavItem>
    <NavLink
      className="text-muted border rounded-0 d-flex align-items-center"
      href={`#${name}`}
      active={activeTab === name}
    >
      {children}
    </NavLink>
  </StyledNavItem>
)

StyledTab.propTypes = {
  activeTab: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
}

export default StyledTab
