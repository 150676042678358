import React from 'react'
import { useParams } from 'react-router-dom'

import { Col, Row } from 'reactstrap'
import RequestList from 'components/Catalog/RequestsPage/RequestList'
import { SmallSpinner as Spinner } from 'components/Custom/CustomSpinners'

import { useGetRequestsQuery } from 'services/catalogs'

const RequestsPage = () => {
  const { workspaceUUID } = useParams()

  const { data = [], isLoading } = useGetRequestsQuery(workspaceUUID)

  return (
    <Row className="h-100">
      <Col xs={12} className="d-flex flex-column">
        {isLoading ? (
          <Spinner />
        ) : (
          data.length ? (
            <RequestList data={data} />
          ) : (
            <Row className="flex-grow-1 align-items-center">
              <Col className="text-muted text-center">No requests yet</Col>
            </Row>
          )
        )}
      </Col>
    </Row>
  )
}

export default RequestsPage
