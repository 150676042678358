import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input, InputGroup, Button } from 'reactstrap'
import classnames from 'classnames'
import ICONS from 'helpers/iconConstants'

const InputField = ({
  isRoot,
  item,
  onCloseHandler,
  onSaveHandler,
}) => {
  const [value, setValue] = useState(item.name)

  return (
    <InputGroup className={classnames('py-1', {
      'pl-3 pr-1': isRoot,
      'px-1': !isRoot,
    })}>
      <Input
        autoFocus
        className={classnames('form-control-sm py-1 px-2', { 'font-weight-bold': isRoot })}
        defaultValue={value}
        onChange={e => setValue(e.target.value)}
        placeholder="Enter Name"
      />
      <Button
        className="rounded-0"
        color="primary"
        disabled={item.name === value}
        onClick={() => onSaveHandler(value)}
        size="sm"
      >
        {item.uuid ? 'Save' : 'Create'}
      </Button>
      <Button
        className="rounded-0"
        color="primary"
        outline
        onClick={onCloseHandler}
        size="sm"
      >
        <i className={ICONS.TIMES} />
      </Button>
    </InputGroup>
  )
}

InputField.defaultProps = {
  isRoot: false,
  item: {
    name: '',
  },
}

InputField.propTypes = {
  isRoot: PropTypes.bool,
  item: PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.number,
  }),
  onCloseHandler: PropTypes.func,
  onSaveHandler: PropTypes.func,
}

export default InputField
