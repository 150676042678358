import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useWizard } from 'react-use-wizard'
import classnames from 'classnames'

import {
  Badge,
  Col,
  Input,
  Label,
  Row,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap'

import { MODEL_TYPES } from 'helpers/constants'

const FirstStep = ({
  values,
  setValues,
}) => {
  const [errors, setErrors] = useState({})
  const { handleStep } = useWizard()

  handleStep(() => {
    if (!values?.name?.length || !values?.modelType) {
      setErrors(state => ({ ...state, name: !values?.name?.length, modelType: !values?.modelType }))
      throw new Error('No name or model type')
    }
  })

  const handleChange = (field, value) => {
    setValues(state => ({ ...state, [field]: value }))
    setErrors(state => ({ ...state, [field]: !value.length }))
  }

  return (
    <>
      <h3 className="h2 pt-3">What's a model?</h3>
      <p>It's a mathematical tool to predict future values or generate estimations based on available data.</p>

      <Label className="w-100 my-4">
        <span className="font-weight-bold">Model name</span>
        <Badge className="text-capitalize" style={{ transform: 'translateY(-0.35rem)' }}>
          Required
        </Badge>
        <Input
          autoComplete="off"
          className="mt-2"
          defaultValue={values.name}
          name="name"
          onChange={e => handleChange('name', e.target.value)}
          invalid={errors.name}
        />
      </Label>

      <Row>
        <Col xs={12}>
          <Label className="w-100 mt-3 mb-2">
            <span className="font-weight-bold">Select model type</span>
          </Label>
        </Col>
        <Col>
          <Card
            className={classnames('card-stats', { 'border-warning': errors.modelType, 'border-info': values.modelType === MODEL_TYPES.TIME_SERIES_MODEL })}
            role="button"
            onClick={() => handleChange('modelType', MODEL_TYPES.TIME_SERIES_MODEL)}
          >
            <CardTitle tag="h4" className="text-uppercase text-muted mx-4 mt-3 mb-2">
              Divian Timeseries
            </CardTitle>
            <CardBody className="text-sm">
              Predict future values of the data which has a time dimension.
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card
            className={classnames('card-stats', { 'border-warning': errors.modelType, 'border-info': values.modelType === MODEL_TYPES.PARAMETRIC_LINEAR_MODEL })}
            role="button"
            onClick={() => handleChange('modelType', MODEL_TYPES.PARAMETRIC_LINEAR_MODEL)}
          >
            <CardTitle tag="h4" className="text-uppercase text-muted mx-4 mt-3 mb-2">
              Linear Regression
            </CardTitle>
            <CardBody className="text-sm">
              Predict a value of target variable based on associated features.
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

FirstStep.propTypes = {
  values: PropTypes.shape({
    name: PropTypes.string,
    modelType: PropTypes.string,
  }),
  setValues: PropTypes.func,
}

export default FirstStep
