import React from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Input,
  Label,
  Row,
} from 'reactstrap'
import CopyToClipboardButton from 'components/Shared/CopyToClipboardButton'
import { useField, useFormikContext } from 'formik'

const RSA_KEY = 'ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQCH9wTz1wZDOD0+n4Sn1IRkmVL2dGL7itvAlGT7npsgkRYiOg1rRYxLQZZj1aZT0P2Wxnl00RkiypTITOZ9/IR8FU10nO+5xV/mx8hYaSTht1blM/qbm7c/Qgl0i8FcYkqvU1g7YOQu4EnB+IPIrMpPtNofHnSrpATyeMkNXefboS3jvl0PUdDN5ejwQ+q50YuaTNQA5k/YIoBgNErgRcXXEaVO+pfJQ3uLfcWZaTwgixP75wsTx34f8Pqpi4m0FCn/xYse2Y3haeUKrl9O7hkKrPQXM30k0+RPt145bN9plj+3+o7zXcsa15lo2qg6JwZL/HjP+TuJ8fs1yFWKrw9z divian'

export const PublicKeyField = () => (
  <Row>
    <Col>
      <small className="d-inline-block" style={{ fontSize: '70%' }}>
        To authenticate via a public key, you'll need to add our public key to your .ssh/authorized_keys file on your SSH server:
      </small>
    </Col>
    <Col md={11}>
      <Input
        bsSize="sm"
        className="text-monospace mt-1 mb-2 bg-white"
        defaultValue={RSA_KEY}
        disabled
        rows="6"
        style={{ fontSize: '10px' }}
        type="textarea"
      />
    </Col>
    <Col md={1} className="d-flex align-items-center justify-content-end mb-2">
      <CopyToClipboardButton textToCopy={RSA_KEY} />
    </Col>
  </Row>
)

const Field = ({
  description,
  inputType,
  label,
  name,
  fullWidth,
  autoFocus,
  ...rest
}) => {
  const [{ value = '', ...field }, meta] = useField(name)
  const { submitCount } = useFormikContext()

  return (
    <Col {...fullWidth ? { xs: 12 } : { xm: 12, lg: 6 }}>
      <Label size="sm" className="w-100">
        {label}
        <Input
          {...field}
          {...rest}
          autoComplete="off"
          bsSize="sm"
          className="text-monospace"
          invalid={!!(meta.error && (meta.touched || submitCount))}
          name={name}
          type={inputType || 'text'}
          value={value}
          autoFocus={autoFocus}
        />
        {description && <small>{description}</small>}
      </Label>
    </Col>
  )
}

Field.propTypes = {
  description: PropTypes.string,
  inputType: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
}

export default Field
