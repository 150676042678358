import React from 'react'
import PropTypes from 'prop-types'
import Field, { PublicKeyField } from 'components/Main/SourceViewPage/CreateSourceModal/Forms/SourceForm/Field'
import { FORM_FIELDS } from 'components/Shared/AddSourceForm/fieldConfig'

import { Row } from 'reactstrap'

const SSHSection = () => (
  <>
    <Row>
      {FORM_FIELDS.SSH.map(options => (
        <Field {...options} key={options.name} />
      ))}
    </Row>
    <PublicKeyField />
  </>
)

SSHSection.propTypes = {
  type: PropTypes.string,
}

export default SSHSection
