import user from 'reducers/user'
import dataset from 'reducers/dataset'
import document from 'reducers/document'
import binder from 'reducers/binder'
import knowledgeBase from 'reducers/knowledgeBase'
import isLoading from 'reducers/isLoading'

const reducers = {
  user,
  dataset,
  document,
  binder,
  knowledgeBase,
  isLoading,
}

export default reducers
