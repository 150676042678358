import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Container,
  Form,
} from 'reactstrap'

import AuthHeader from 'components/Auth/AuthHeader'
import InputGroupComponent from 'components/Auth/InputGroupComponent'
import { withAlerts } from 'contexts/alertsProvider'
import { sendForgotPasswordCode } from 'helpers/cognito'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Please check email format').required('Please add your email'),
})

const ForgotPasswordPage = ({ alerts }) => {
  const [state, setState] = useState({})
  const history = useHistory()

  const submitForm = async ({ email }) => {
    try {
      await sendForgotPasswordCode(email)
      alerts.success({ message: 'New code has been sent to your email address.' })
      history.push(`/new-password?email=${email}`)
    } catch ({ message }) {
      alerts.error({ message })
    }
  }

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: submitForm,
  })

  return (
    <>
      <AuthHeader title="Forgot your password?" lead="Enter the email address linked to your Divian account" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <InputGroupComponent
                    id="inputEmail"
                    errorText={formik.errors.email}
                    focus={state.emailFocus}
                    placeholder="Email"
                    type="text"
                    name="email"
                    onFocus={() => setState({ ...state, emailFocus: true })}
                    onBlur={() => setState({ ...state, emailFocus: false })}
                    value={formik.values.email}
                    invalid={formik.touched.email && Boolean(formik.errors.email)}
                    onChange={formik.handleChange}
                  />
                  <div className="text-center">
                    <Button
                      className="mt-4"
                      color="info"
                      type="submit"
                      onClick={formik.handleSubmit}
                    >
                      Send Recovery Code
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <Link
                  className="text-light"
                  to="/login"
                >
                  <small>Remembered your login info?&nbsp;&nbsp;Log In</small>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

ForgotPasswordPage.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }),
}

export default withAlerts(ForgotPasswordPage)
