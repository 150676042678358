import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import RootItem from 'components/Binders/Sidebar/RootItem'
import partition from 'lodash/partition'
import orderBy from 'lodash/orderBy'
import isEmpty from 'lodash/isEmpty'

const Sidebar = ({
  data,
  documentId,
  onItemClick,
  sidenavOpen,
}) => {
  const [children, rootItems] = useMemo(() => {
    if (isEmpty(data.binders)) return [[], []]

    const [children, rootItems] = partition(data.binders, b => b.parent_uuid)
    return [orderBy(children, ['position']), orderBy(rootItems, ['position'])]
  }, [data.binders])

  return (
    <div className="ct-sidebar col-12 col-md-3 col-xl-2">
      <nav className={classnames('collapse ct-links h-100vh', { show: sidenavOpen })}>
        {rootItems.map(item => (
          <RootItem
            children={children.filter(c => c.parent_uuid === item.uuid)}
            documentId={documentId}
            item={item}
            key={item.uuid}
            onItemClick={onItemClick}
          />
        ))}
      </nav>
    </div>
  )
}

Sidebar.propTypes = {
  data: PropTypes.shape({
    binders: PropTypes.array,
  }),
  documentId: PropTypes.string,
  onItemClick: PropTypes.func,
  sidenavOpen: PropTypes.bool,
}

export default Sidebar
