import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useFormik } from 'formik'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import {
  Col,
  Row,
  Button,
  Card,
  Container,
  CardBody,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Form,
  FormGroup,
} from 'reactstrap'

import AuthHeader from 'components/Auth/AuthHeader'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import { withAlerts } from 'contexts/alertsProvider'
import { authenticateUser } from 'helpers/cognito'
import ICONS from 'helpers/iconConstants'

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
})

const LoginPage = ({ alerts }) => {
  const [state, setState] = useState({ emailFocus: false, passFocus: false })
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const submitForm = async ({ email, password }) => {
    try {
      setIsLoading(true)
      await authenticateUser(email, password)
      localStorage.setItem('email', email)
      history.push('/')
    } catch ({ code, message }) {
      alerts.error({ message })
      if (code === 'UserNotConfirmedException') history.push(`/confirm?email=${email}`)
    } finally { setIsLoading(false) }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: submitForm,
  })

  return (
    <>
      <AuthHeader title="Welcome!" lead="Log into Divian Platform below" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" onSubmit={formik.handleSubmit}>
                  <FormGroup
                    className={classnames('mb-3', {
                      focused: state.emailFocus,
                    })}
                  >
                    <InputGroup
                      className="input-group-merge input-group-alternative"
                      style={{ ...formik.touched.email && Boolean(formik.errors.email) && { border: '1px solid red' } }}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className={ICONS.EMAIL} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        name="email"
                        autoComplete="new-password"
                        onFocus={() => setState({ ...state, emailFocus: true })}
                        onBlur={() => setState({ ...state, emailFocus: false })}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: state.passFocus,
                    })}
                  >
                    <InputGroup
                      className="input-group-merge input-group-alternative"
                      style={{ ...formik.touched.password && Boolean(formik.errors.password) && { border: '1px solid red' } }}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className={ICONS.LOCK_CIRCLE_OPEN} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        autoComplete="new-password"
                        onFocus={() => setState({ ...state, passFocus: true })}
                        onBlur={() => setState({ ...state, passFocus: false })}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="info" type="submit">
                      Log in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <Link
                  className="text-light"
                  to="/forgot-password"
                >
                  <small>Forgot your password?</small>
                </Link>
              </Col>
              <Col className="text-right" xs="6">
                <Link
                  className="text-light"
                  to="/registration"
                >
                  <small>Create a new account</small>
                </Link>
              </Col>
            </Row>
            <LoadingSpinner visible={isLoading} />
          </Col>
        </Row>
      </Container>
    </>
  )
}

LoginPage.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
  }),
}

export default withAlerts(LoginPage)
