import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useWizard } from 'react-use-wizard'
import {
  Col,
  Row,
} from 'reactstrap'

import { ButtonWithSpinner } from 'components/Custom/Buttons'
import ICONS from 'helpers/iconConstants'

const WizardFooter = ({ setValues, handleFormSubmit }) => {
  const {
    previousStep,
    nextStep,
    isLastStep,
    isFirstStep,
  } = useWizard()

  const goToNextStep = async () => {
    try {
      await nextStep()
      if (isLastStep) await handleFormSubmit()
    } catch (err) {
      console.error(err.message)
    }
  }

  const goToPrevStep = () => {
    if (isFirstStep) setValues(state => ({
      ...state, targetVariable: null, datasetName: null, datasetUUID: null,
    }))
    return previousStep()
  }

  return (
    <Row className="justify-content-center">
      <Col xs={11} xl={10} className={classnames('d-flex m-1 p-3 align-items-center', {
        'justify-content-end': isFirstStep,
        'justify-content-between': !isFirstStep,
      })}>
        {!isFirstStep && (
          <ButtonWithSpinner
            onClick={goToPrevStep}
            label={(
              <><i className={ICONS.ARROW_LEFT} /> Previous</>
            )}
          />
        )}
        <ButtonWithSpinner
          onClick={goToNextStep}
          label={isLastStep
            ? 'Done'
            : <>Next <i className={ICONS.ARROW_RIGHT} /></>}
        />
      </Col>
    </Row>
  )
}

WizardFooter.propTypes = {
  setValues: PropTypes.func,
  handleFormSubmit: PropTypes.func,
}

export default WizardFooter
