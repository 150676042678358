import React from 'react'
import PropTypes from 'prop-types'

import SortIcon from 'components/Catalog/Shared/Table/SortIcon'

const TableHeader = ({ headerGroups }) => (
  <thead>
    {headerGroups.map(headerGroup => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
            <div className="d-flex justify-content-between align-items-center">
              <span>{column.render('Header')}</span>
              <SortIcon column={column} />
            </div>
          </th>
        ))}
      </tr>
    ))}
  </thead>
)

TableHeader.propTypes = {
  headerGroups: PropTypes.arrayOf(PropTypes.object),
}

export default TableHeader
