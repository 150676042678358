import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import RootItem from 'components/Documentation/Sidebar/RootItem'

const Sidebar = ({
  rootPages,
  childrenPages,
  sidenavOpen,
  setIsLoading,
  setEditMode,
}) => (
  <div className="ct-sidebar col-12 col-md-3 col-xl-2">
    <nav className={classnames('collapse ct-links h-100vh', { show: sidenavOpen })}>
      {rootPages.map((rootPage) => {
        const pages = childrenPages.filter(doc => doc.parent_id === rootPage.uuid)
        return <RootItem
          key={rootPage.uuid}
          pages={pages}
          rootPage={rootPage}
          setIsLoading={setIsLoading}
          setEditMode={setEditMode}
        />
      })}
    </nav>
  </div>
)

Sidebar.propTypes = {
  childrenPages: PropTypes.arrayOf(PropTypes.shape({})),
  editMode: PropTypes.bool,
  rootPages: PropTypes.arrayOf(PropTypes.shape({})),
  sidenavOpen: PropTypes.bool,
  setIsLoading: PropTypes.func,
  setEditMode: PropTypes.func,
}

export default Sidebar
