import React from 'react'

const PasswordTips = () => (
  <div className="text-muted font-italic">
    <small>
      passwords must contain at least 8 characters, lowercase, uppercase and digits
    </small>
  </div>
)

export default PasswordTips
